import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const putDiagnosis = (idCase, diagnosis) => {
    return new Promise((resolve, reject) => {
        const messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("PUT", RouterCtrl.diagnosis)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.withCredentials = true
        xhr.send(JSON.stringify({idCase: idCase, diagnosis: diagnosis}))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (res.type === "success") {
                    resolve(res)
                } else {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve([])
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve([])
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve([])
        }
    })
}

export { putDiagnosis }