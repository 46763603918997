import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { FaqsPublicScreenUI } from './FaqsPublicScreenUI'
import { useFaqsTypes } from '../../../../Hooks/useFaqsTypes'
import { useFaqs } from '../../../../Hooks/useFaqs'
import { UserContext } from '../../../../Contexts/User/UserContext'
import { useHistory } from 'react-router-dom'
import { useFiles } from '../../../../Hooks/useFiles'
import download from 'downloadjs'

const FaqsPublicScreen = ({ match }) => {
    const userContext = useContext(UserContext)
    let history = useHistory()
    const [imagePopUp, setImagePopUp]= useState("")
    const [faqs, setFaqs] = useState([])
    const [faqsTypes, setFaqsTypes] = useState([])
    const [selectedFaq, setSelectedFaq] = useState()
    const { retrieveFaqsTypes } = useFaqsTypes()
    const { retrieveFaqsList } = useFaqs()
    const {
        getFilesInfo,
        downloadFileByPath
    } = useFiles()

    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        if (!await userContext.checkSession()) {
            history.push("/")
            window.location.reload()
        } else {
            setFaqsTypes(await retrieveFaqsTypes())
            setFaqs(await retrieveFaqsList())
            let tmpSelectedFaq = Number.parseInt(match.params.id)
            if (Number.isInteger(tmpSelectedFaq)) {
                setSelectedFaq(tmpSelectedFaq)
                let domFaqElem = document.getElementById("faq" + tmpSelectedFaq)
                if (domFaqElem) domFaqElem.scrollIntoView()
            }
        }
    }, [])

    const retrieveAttachments = (idFaq) => {
        return getFilesInfo("faq", idFaq)
    }

    const downloadImage = async (path, mimetype) => {
        return await downloadFileByPath(path, mimetype)
    }

    const downloadFile = async (idFile, name, mimetype, extension, path) => {
        let result = await downloadFileByPath(path, mimetype)
        download(result, `${name}`)
    }

    const displayImagePopUp = (blob) => {
        setImagePopUp(blob);
        document.getElementById("imageModalButton").click();
    }

    return (
        <FaqsPublicScreenUI
            imagePopUp={imagePopUp}
            displayImagePopUp={displayImagePopUp}
            faqsTypes={faqsTypes}
            faqs={faqs}
            selectedFaq={selectedFaq}
            retrieveAttachments={retrieveAttachments}
            downloadImage={downloadImage}
            downloadFile={downloadFile}
        />
    )
}

export { FaqsPublicScreen }