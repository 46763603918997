import { useState} from 'react'

export const useFormObj = props => {
    const [obj, setObj] = useState({})

    const create = async (data) => {
        let fields = data[0]
        let dataRetrievers = data[1]
        let tmpObj = {}

        if (Array.isArray(fields)) {
            fields.forEach(field => {
                tmpObj[field[0]] = field[1]
            })
        }

        if (typeof data[1][0][2] !== "function") {
            try {
                let typeRetriever = typeof dataRetrievers[0][0]
                if (
                    typeRetriever !== "undefined" &&
                    dataRetrievers.length
                ) {
                    let checks = []
                    let allRecords = []
                    dataRetrievers.map(async dr => {
                        dr[2][0].forEach(field => {
                            if (typeof field.text === "undefined") {
                                checks.push({ id: field.id_diagnosis_option, label: field.Name, value: field.Used })
                            } else {
                                checks.push({ id: "otherDiagnsosisCheck", used: true, text: field.text })
                            }
                        })

                        allRecords = await dr[2][1]()
                        for (let index = 0; index < allRecords.length; index++) {
                            checks.map(check => {
                                if (check.id === allRecords[index].id) {
                                    allRecords[index].value = true
                                    if (check.id === "otherDiagnsosisCheck" &&
                                        allRecords[index].id === "otherDiagnsosisCheck") {
                                        allRecords[index].used = check.used
                                        allRecords[index].value = check.text
                                    }

                                }
                            })
                        }
                        tmpObj[dr[0]] = allRecords

                        setObj(tmpObj)
                    })
                }
            } catch (e) {

            }
        } else if (typeof data[1][0][2] === "function") {
            let diagnosis = await data[1][0][2]()
            let diagnosisName = await data[1][0][0]
            tmpObj[diagnosisName] = diagnosis
            setObj(tmpObj)
        }
    }

    const updateData = (index, value) => {
        setObj({ ...obj, [index]: value })
    }

    const multiUpdate = (data) => {
        let tmpObj = obj

        data.map(elem => {
            if (elem.field && elem.value) {
                tmpObj[elem.field] = elem.value
            }
        })

        setObj(tmpObj)
    }

    const updateDiagnosis = (index, value) => {
        let tmpObj = obj
        tmpObj.diagnosis[index].value = value
        console.log(tmpObj.diagnosis)
        setObj(tmpObj)
    }

    const updateForm = (newData) => {
        setObj({
            ...obj,
            objective: newData[0].Objetive,
            caseTypeId: newData[0].id_cases_types,
            editionId: newData[0].id_editions,
            patientId: newData[0].Patient_ID_invisalign,
            patientName: newData[0].Patient_name_invisalign,
            priorityId: newData[0].id_priorities === 0 ? true : false,
            revisionRequest: newData[0].Revision_request,
            revisionAccepted: newData[0].Revision_accepted,
            revisionCancelRequest: newData[0].Revision_cancel_request,
            stateId: newData[0].id_states,
            teacherId: newData[0].id_teacher_assigned,
            userId: newData[0].id_users

        })
    }

    return [
        obj,
        create,
        updateData,
        multiUpdate,
        updateDiagnosis,
        updateForm
    ]

}