import React, {useState} from 'react'

export default ({ visible,  loadingImg }) => {
    const [visibility, setVisibility] = useState(false)

    const divStyles = {
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(242 242 242 / 87%)",
        zIndex: "99"
    }
    
    const loadingImgStyles = {
        display: "block",
        width: "30%",
        margin: "0 auto"
    }

    const changeDivVisibility = (value) => {
        try {
            document.getElementById("loadingScreen").style.display = value
            return true
        } catch (e) {
            return false
        }
    }
    
    if (visibility !== visible) {
        setVisibility(visible)
        if (visible) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden"
            changeDivVisibility("flex")
        } else {
            setTimeout(() => {
                document.getElementsByTagName("body")[0].style.overflow = "auto"
                changeDivVisibility("none")
            }, 1000)
        }
    }

    return (
        <div id="loadingScreen" style={divStyles}>
            <div>
                <img src={loadingImg} style={loadingImgStyles} />
            </div>
        </div>
    )

}