import { useState, useEffect } from "react";
import GetCasesStates from "../Services/CasesStates/GetCasesStates";

export default function useStates() {

    const [states, setState] = useState([])
    const [defaultState, setDefaultState] = useState({})

    const getDataCasesState = async () => {
        let result = await GetCasesStates()
        const doc = []
        result.forEach(element => {
            doc.push({ value: element.id, label: element.Name })
            if (element.id === 1) {
                setDefaultState({ value: element.id, label: element.Name })
            }
        })
        setState(doc)
    }

    useEffect(() => {
        getDataCasesState()
    }, [])

    const testing = async () => {
        let result = await GetCasesStates()
        const doc = []
        result.forEach(element => {
            doc.push({ value: element.id, label: element.Name })
            if (element.id === 1) {
                setDefaultState({ value: element.id, label: element.Name })
            }
        })
        return doc
    }

    return { states, defaultState, testing }
}