import React, { useState } from 'react';
import { ResponsesRatingsUI } from './ResponsesRatingsUI';
import { useResponseRating } from '../../Hooks/useResponseRating'
import { useHistory } from 'react-router-dom'

const ResponsesRatings = (props) => {
    useHistory()
    const idUser = props.location.state === undefined ? '' : props.location.state.idUser
    const idRole = props.location.state === undefined ? '' : props.location.state.typeUser
    const nameUser = props.location.state === undefined ? '' : props.location.state.nameUser

    const { responseRating } = useResponseRating(idUser, idRole)
    const [search, setSearch] = useState("")

    const handleSearch = (e) => {
        const name = e.target.name
        const value = e.target.value
        setSearch({ ...search, [name]: value })
    }

    return <ResponsesRatingsUI
        id_role = {idRole}
        responseRating={responseRating}
        nameUser={nameUser} search={search}
        handleSearch={handleSearch} />
}

export { ResponsesRatings }