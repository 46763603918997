//import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const closeSession = async () => {
    return new Promise((resolve, reject) => {
        //let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("DELETE", RouterCtrl.closeSession)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            resolve()
        }

        xhr.onerror = () => {
            resolve()
        }
    })
}

export { closeSession }