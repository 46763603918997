import React, { useState, useEffect } from 'react'
import FaqElementCSS from './FaqElement.module.css'
import FileElem from '../CustomFileInput/FileElem'

import pngIco from '../../../img/attachments/img/png_ico.png'
import jpgIco from '../../../img/attachments/img/jpg_ico.png'
import jpegIco from '../../../img/attachments/img/jpeg_ico.png'
import docIco from '../../../img/attachments/doc/doc_ico.png'
import docxIco from '../../../img/attachments/doc/docx_ico.png'
import odtIco from '../../../img/attachments/doc/odt_ico.png'
import pptIco from '../../../img/attachments/presentation/ppt_ico.png'
import pptxIco from '../../../img/attachments/presentation/pptx_ico.png'
import odpIco from '../../../img/attachments/presentation/odp_ico.png'
import pdfIco from '../../../img/attachments/no_group/pdf_ico.png'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

export default ({
    number,
    question,
    response,
    id,
    selectedFaq,
    attachmentsRetriever,
    imageDownloader,
    downloader,
    displayFactor,
    alone = false,
    displayImagePopUp
}) => {
    const [faqFiles, setFaqFiles] = useState([])
    const [images, setImages] = useState([])
    const objState = {
        editorState: '',
    }
    const [stateEditor, setstateEditor] = useState(objState)


    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    useEffect(async () => {
        let tmpImages = []
        let tmpAttachments = []
        if (typeof id != "undefined") {
            let tmpFiles = await attachmentsRetriever(id)

            for (let index = 0; index < tmpFiles.length; index++) {
                if (
                    tmpFiles[index].Mimetype == "image/png" ||
                    tmpFiles[index].Mimetype == "image/jpeg"
                ) {
                    tmpImages.push(tmpFiles[index])
                } else {
                    tmpAttachments.push(tmpFiles[index])
                }
            }
        }

        let tmpDownloadedImages = await Promise.all(tmpImages.map(async tmpImage => {
            return await imageDownloader(tmpImage.Path, tmpImage.Mimetype)
        }))
        setFaqFiles(tmpAttachments)
        setImages(tmpDownloadedImages)
        UpdateStateEditor(response ? response : '')
    }, [id])

    const divStyles = {
        display: "flex",
        color: 'black',
        fontWeight: '500'
    }
    const [responseVisible, setResponseVisible] = useState(false)
    const [selected, setSelected] = useState(false)
    const [isDefaultOpened, setIsDefaultOpened] = useState(false)

    if (
        id == selectedFaq &&
        responseVisible == false &&
        !isDefaultOpened
    ) {
        setResponseVisible(true)
        setSelected(true)
        setIsDefaultOpened(true)
    }

    const returnHtmlText = text => {
        if (selected) {
            return <b>{text}</b>
        } else {
            return text
        }
    }

    const displayImage = (blob, index) => {
        let urlCreator = window.URL || window.webkitURL
        let imageUrl = urlCreator.createObjectURL(blob)
        return <div key={index} style={{ marginBottom: "1rem" }}>
            <img key={index} src={imageUrl} className={FaqElementCSS.faqImage} onClick={() => displayImagePopUp(imageUrl)} />
        </div>
    }

    const displayAttachment = (attachment, index) => {
        const ico = returnImage(attachment.Extension)

        attachment.name = attachment.Name
        attachment.size = attachment.Size
        return <FileElem
            key={index}
            ico={ico}
            file={attachment}
            width={"20%"}
            downloader={downloader}
            displayFactor={displayFactor}
            cssClasses={"faqAttach"}
        />
    }

    const returnImage = ext => {
        switch (ext) {
            case "png":
                return pngIco
            case "jpg":
                return jpgIco
            case "jpeg":
                return jpegIco
            case "doc":
                return docIco
            case "docx":
                return docxIco
            case "odt":
                return odtIco
            case "ppt":
                return pptIco
            case "pptx":
                return pptxIco
            case "odp":
                return odpIco
            case "pdf":
                return pdfIco
        }
    }

    return (
        <div id={"faq" + id} style={{ marginBottom: "0.5rem" }}>
            {!alone ? <hr style={{ marginBottom: "0.25rem" }} /> : ""}
            <div style={divStyles} onClick={!alone ? () => setResponseVisible(!responseVisible) : null}
                className={'clickable ' + FaqElementCSS.faqQuestion}>
                <p style={{ marginBottom: "0rem" }}>
                    {!responseVisible && !alone ? <span className={FaqElementCSS.faqIcon}>
                        <svg class="svg-inline--fa fa-chevron-down fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                    </span> : ""}
                    {responseVisible && !alone ? <span className={FaqElementCSS.faqIcon}>
                        <svg class="svg-inline--fa fa-chevron-up fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                    </span> : ""}
                    &nbsp;{Number.isInteger(number) ? returnHtmlText(`${number}. ${question}`) : question}
                </p>
            </div>
            {responseVisible ?
                <div style={{ backgroundColor: "#dfdfdf" }}>
                    <Editor
                        spellCheck
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={stateEditor.editorState} />
                    {
                        images.map((image, index) => displayImage(image, index))
                    }
                    {
                        faqFiles.map((faqFile, index) => displayAttachment(faqFile, index))
                    }
                </div> :
                null}
        </div>
    )
}