import React from 'react'

const ButtonModal = ({ id = null, text, onClick, color, bg, bsTarget, bsDimiss = null, cssClasses, close = false, style }) => {
    return (
        <button
            type="button"
            id={id}
            className={`btn btn-primary ${!close ? "button" : "buttonClose"}${cssClasses ? ` ${cssClasses}` : ""}`}
            style={style || { backgroundColor: bg, color: color }}
            data-bs-target={bsTarget}
            data-bs-toggle={bsTarget ? "modal" : null}
            data-bs-dismiss={bsDimiss}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default ButtonModal