import { useState, useEffect } from 'react'
import { getFilterTeacherStats } from '../Services/Teacher/getFilterTeacherStats'
import { getTeacherStats } from '../Services/Teacher/getTeacherStats'


const useTeacherStatistics = () => {

    const [teacherStats, setTeacherStats] = useState([])

    const getAllTeacherStats = async () => {
        return await getTeacherStats()
    }

    const filterDateTeacherStats = async (dateIni, dateEnd) => {
        return await getFilterTeacherStats(dateIni, dateEnd)
    }

    const getDataTeachers = async () => {
        setTeacherStats(await getAllTeacherStats())
    }

    useEffect(() => {
        getDataTeachers()
    }, [])


    return { teacherStats, setTeacherStats, getAllTeacherStats, filterDateTeacherStats }

}
export { useTeacherStatistics }