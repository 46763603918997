import React from 'react'
import Activation from '../Tools/Activation'
import logoWithText from '../../img/Logo_OIF_negro_350.svg'

const ActivationScreenUI = () => {
    return (
        <Activation
            corpLogo={logoWithText}
            msg={"Hola, tu cuenta necesita ser revisada por un administrador. Recibirás un email con el resultado cuando se haya revisado en un máximo de 72h."}
        />
    )
}

export { ActivationScreenUI }