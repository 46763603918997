import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const putDoubt = (idDoubt, idDoubtType, doubt) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("PUT", RouterCtrl.doubts)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({
            idDoubt: idDoubt,
            idDoubtType: idDoubtType,
            doubt: doubt
        }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                messageService.printToast(res.scope, res.type, res.code)
                if (res.type === "success") {
                    resolve(true)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            console.log("err")
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    })
}

export { putDoubt }