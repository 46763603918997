//#region ESPAÑA
const es_CheckNie = (nie) => {
    var nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
        case 'X': nie_prefix = 0; break;
        case 'Y': nie_prefix = 1; break;
        case 'Z': nie_prefix = 2; break;
        default: break
    }

    return es_ValidNif(nie_prefix + nie.substr(1));
}

const es_ValidNif = (dni) => {
    var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(8);
}
//#endregion

//#region PORTUGAL
const pt_validateNIF = (value) => {
    value = value + "";

    // has 9 digits?
    if (!/^[0-9]{9}$/.test(value)) return false;

    // is from a person?
    if (!/^[123]|45|5/.test(value)) return false;

    // digit check
    let tot =
        value[0] * 9 +
        value[1] * 8 +
        value[2] * 7 +
        value[3] * 6 +
        value[4] * 5 +
        value[5] * 4 +
        value[6] * 3 +
        value[7] * 2;
    let div = tot / 11;
    let mod = tot - parseInt(div) * 11;
    let tst = mod == 1 || mod == 0 ? 0 : 11 - mod;
    return value[8] == tst;
}
//#endregion

const ValidateIdentification = {
    'ES': {
        'NIE': (value) => es_CheckNie(value),
        'DNI': (value) => /^\d{8}[a-zA-Z]$/.test(value)
    },
    'PT': {
        'NIF': (value) => pt_validateNIF(value)
    }
}


export default ValidateIdentification