import React from 'react'
import { Editor } from 'react-draft-wysiwyg';
import { ContentItem } from '../../Tools/ContentItem'
import { CheckFormatDate } from '../../Tools/GeneralFunctions/CheckFormatDate'
import { ItemKanban } from '../../Tools/Kanban/ItemKanban'
import { KanbanCard } from '../../Tools/Kanban/KanbanCard'
import { TableList } from '../../Tools/Table/TableList';
import { ViewData } from '../../Tools/ViewsData/ViewData'

const ObservacionesUI = ({ observations, views, setters }) => {

    const ObjFields = {
        type: "Tipo",
        observation: "Observación",
        creator: "Profesor",
        creation: "Creación"
    }

    //#region "STRUCTURE"

    //#region "KANBAN"
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index}
            /* title={item.observation} */
            title={"Observaciones " + (index + 1)}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite`}
            dataTarget={"#observationForm"}
            onclickHead={() => { setters.index(index); setters.openModal(item) }}
            cardBody={[
                <ItemKanban classCol={"col"} label={ObjFields.type} value={item.type_observation} />,
                <ContentItem itemBody={[
                    <ItemKanban classCol={"col-6"} label={ObjFields.creator} value={item.creator} />,
                    <ItemKanban classCol={"col-6"} label={ObjFields.creation} value={CheckFormatDate(item.created)} />
                ]} />
            ]}
        />
    )

    //#endregion

    //#region "LIST"

    const ContentList = ({ item, index }) => (
        <tr key={item.id} className="clickable"
            data-bs-toggle="modal"
            data-bs-target="#observationForm"
            onClick={() => { setters.index(index); setters.openModal(item) }}>
            <th>{index + 1}</th>
            <td className='text-center' style={{ verticalAlign: 'middle', width: 180, minWidth: 180 }}>{item?.type_observation}</td>
            <td style={{ minWidth: 500, minHeight: 50 }}>
                <div style={{ height: 'auto', maxHeight:150, overflow: 'scroll', background: "#fff", paddingLeft: 5, paddingRight: 5 }}>
                    <Editor
                        spellCheck
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={item.observationHTML}
                        editorStyle={{paddingTop:0}}
                    />
                </div>
            </td>
            <td className='text-center' style={{ verticalAlign: 'middle', width: 120, minWidth: 120 }}>{CheckFormatDate(item.created)}</td>
            <td className='text-center' style={{ verticalAlign: 'middle', width: 370, minWidth: 370 }}>{item.creator}</td>
        </tr>
    )

    const HeadList = () => (
        <tr style={{ textAlign: 'center' }}>
            <th></th>
            <th>{ObjFields.type}</th>
            <th>{ObjFields.observation}</th>
            <th>{ObjFields.creation}</th>
            <th>{ObjFields.creator}</th>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMapping} />
    )

    //#endregion

    const ArrayMapping = observations?.data.map((item, index) => (
        views.type.kanban ? <ViewKanban item={item} index={index} /> : <ContentList index={index} item={item} />
    ))

    //#endregion

    return ([
        typeof observations !== "undefined" && observations.data.length > 0 && (
            <ViewData viewType={views.type.kanban} kanban={<ContentItem itemBody={ArrayMapping} />} list={<ViewList />} />),
        typeof observations !== "undefined" && observations.data.length === 0 && (<p>Este caso no tiene observaciones</p>)
    ])
}

export { ObservacionesUI }