import React from 'react'

import FaqElement from '../../Tools/FaqElement/FaqElement'

const FaqScreenUI = ({
    faq,
    retrieveAttachments,
    downloadImage,
    downloadFile
    }) => {

    return (
        <div style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
            <FaqElement
                question={faq.Questions}
                response={faq.Response}
                id={faq.id}
                attachmentsRetriever={retrieveAttachments}
                imageDownloader={downloadImage}
                downloader={downloadFile}
                displayFactor={1700}
                alone={true}
            />
        </div>
    )
}

export { FaqScreenUI }