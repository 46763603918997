//#region 'IMPORTS'
import React, { useState } from 'react'
import LoginScreenCSS from './LoginScreen.module.css'
import email from '../../img/email.avif'
import logo_oif_negro from '../../img/Logo_OIF_negro_unalinea.png'
import TextInputCustom from '../Tools/TextInputCustom'
import { useLocation } from 'react-router-dom'
import { ErrorText } from '../Tools/ErrorText'
import { CustomToggleFilter } from '../Tools/CustomToggleFilter/CustomToggleFilter'
import invisalign_fondo from '../../img/invisalign_fondo.avif'
import { ModalLopd } from '../Tools/Modals/ModalLopd'
import packageJson from '../../../package.json';
import ISO from '../../Constants/ISO_Country'
import CustomSelect from '../Tools/CustomSelect'
//#endregion

const LoginScreenUI = (props) => {

    //#region 'VARIABLES'
    const requiredFieldMsg = "Este campo es obligatorio"
    const passComplexMsg = `La contraseña debe:
    - Ser como mínimo de 8 caracteres
    - Al menos un carácter en mayúscula
    - Al menos un carácter en minúscula
    - Al menos un carácter especial (#, ?, !, @, $, %, ^, &, ~, €, *, -)`
    let location = useLocation().pathname.split("/")
    console.log("🚀 ~ file: LoginScreenUI.jsx:27 ~ LoginScreenUI ~ location:", location)

    const eyeVisibleDivStyles = {
        display: "flex",
        alignItems: "center",
        marginLeft: "0.5rem"
    }
    //#endregion

    //#region 'USE STATES'
    const [loginPasswordVisibility, setLoginPasswordVisibility] = useState(true)
    const [loginInputType, setLoginInputType] = useState("password")
    const [registerPasswordVisibility, setRegisterPasswordVisibility] = useState(true)
    const [registerPasswordInputType, setRegisterPasswordInputType] = useState("password")
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(true)
    const [confirmationPasswordInputType, setConfirmationInputType] = useState("password")
    //#endregion

    //#region 'FUNCTIONS'
    const changeLoginPasswordVisibility = () => {
        if (!loginPasswordVisibility) {
            setLoginInputType("password")
        } else {
            setLoginInputType("text")
        }
        setLoginPasswordVisibility(!loginPasswordVisibility)
    }

    const changeRegisterPasswordVisibility = () => {
        if (!registerPasswordVisibility) {
            setRegisterPasswordInputType("password")
        } else {
            setRegisterPasswordInputType("text")
        }
        setRegisterPasswordVisibility(!registerPasswordVisibility)
    }

    const changeRegisterConfirmationPasswordVisibility = () => {
        if (!confirmPasswordVisibility) {
            setConfirmationInputType("password")
        } else {
            setConfirmationInputType("text")
        }
        setConfirmPasswordVisibility(!confirmPasswordVisibility)
    }
    //#endregion

    //#region 'COMPONENTS'
    const FillBackground = () => (
        <figure>
            <img className='imgLogin' src={invisalign_fondo} alt={"invisalign_fondo"} />
        </figure>
    )

    const ImgLogo = () => (
        <figure>
            <img
                className="img-fluid mx-auto d-block"
                src={logo_oif_negro}
                style={{ width: 500 }}
                alt='OIF' />
        </figure>
    )

    const ResetPassword = (
        <div id="myTabContent" className="tab-content p-2">
            <TextInputCustom
                textLabel={"Contraseña"}
                nameInput={"password"}
                typeInput={"password"}
                required={true}
                evOnChange={props.forms.resetPassword.handle}
                requiredValidation={{
                    condition: (!props.forms.resetPassword.validators.password.validFlags.required &&
                        props.forms.resetPassword.try) ||
                        (!props.forms.resetPassword.validators.password.properties.pristine &&
                            !props.forms.resetPassword.validators.password.validFlags.required),
                    msg: requiredFieldMsg
                }}
                formatValidation={{
                    condition: !props.forms.resetPassword.validators.password.validFlags.regex &&
                        !props.forms.resetPassword.validators.password.properties.pristine,
                    msg: passComplexMsg
                }}
                value={props.resetPasswordObj.password}
            />
            <TextInputCustom
                textLabel={"Confirmar contraseña"}
                nameInput={"confirmPassword"}
                typeInput={"password"}
                required={true}
                evOnChange={props.forms.resetPassword.handle}
                requiredValidation={{
                    condition: (!props.forms.resetPassword.validators.confirmPassword.validFlags.required &&
                        props.forms.resetPassword.try) ||
                        (!props.forms.resetPassword.validators.confirmPassword.properties.pristine &&
                            !props.forms.resetPassword.validators.confirmPassword.validFlags.required),
                    msg: requiredFieldMsg
                }}
                formatValidation={{
                    condition: !props.forms.resetPassword.validators.confirmPassword.validFlags.regex &&
                        !props.forms.resetPassword.validators.confirmPassword.properties.pristine,
                    msg: passComplexMsg
                }}
                value={props.resetPasswordObj.confirmPassword}
            />

            {(!props.forms.resetPassword.validators.sync && !props.forms.resetPassword.validators.password.properties.pristine) ||
                (!props.forms.resetPassword.validators.sync && !props.forms.resetPassword.validators.confirmPassword.properties.pristine) ?
                <ErrorText msg={"Las contraseñas no coinciden"} /> :
                null}
            <div className="form-group">
                <input type="submit"
                    name="recover-submit"
                    id="recover-submit"
                    className="btn btn-primary btn-lg btn-block w-100"
                    style={{
                        background: "#5BC5F1",
                        color: "#fff",
                        border: 0,
                        fontWeight: 'bold'
                    }}
                    value="Confirmar contraseña"
                    onClick={props.forms.resetPassword.submit} />
            </div>
        </div>
    )

    const TabLogin = (
        <div className="tab-pane fade show active" id="login">
            <form id="login-form"
                method="post"
                className="card-body"
                onSubmit={props.handleLoginSubmit}>
                <fieldset>
                    <div className="form-group">
                        <input type="text"
                            name="vat"
                            id="vatLogin"
                            className="form-control"
                            placeholder="DNI"
                            /* pattern="^\d{8}[a-zA-Z]$" */
                            title="Formato DNI"
                            onChange={props.handleLoginInputChange}
                            required />
                    </div>
                    <div className="form-group" style={{ display: "flex", alignItems: "baseline" }}>
                        <input type={loginInputType}
                            required
                            name="password"
                            id="passwordLogin"
                            className="form-control"
                            placeholder="Contraseña"
                            onChange={props.handleLoginInputChange}
                        />
                        {loginPasswordVisibility ?
                            <div
                                className="clickable"
                                style={eyeVisibleDivStyles}
                                onClick={changeLoginPasswordVisibility}
                            >
                                <i className="clickable hovereableIcon fas fa-eye"></i>
                            </div>
                            : null}

                        {!loginPasswordVisibility ?
                            <div
                                className="clickable"
                                style={eyeVisibleDivStyles}
                                onClick={changeLoginPasswordVisibility}
                            >
                                <i className="clickable hovereableIcon fas fa-eye-slash"></i>
                            </div>
                            : null
                        }
                    </div>
                </fieldset>

                <fieldset>
                    <div className="form-group">
                        <input type="submit"
                            name="login-submit"
                            id="login-submit"
                            className="btn btn-primary btn-lg btn-block w-100"
                            style={{
                                background: "#5BC5F1",
                                color: "#fff",
                                border: 0,
                                fontWeight: 'bold'
                            }}
                            value="Entrar" />
                    </div>
                </fieldset>

                <fieldset>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <ul className="nav flex-xcenter">
                                    {props.showResendConf ?
                                        <a href="#"
                                            onClick={props.handleResendAccountConf}
                                            className={"text-centre " + LoginScreenCSS.resend}>
                                            <i className="fas fa-envelope"></i>Reenviar email de verificación
                                        </a> :
                                        null
                                    }
                                    <a ref={props.tabRefs.recover}
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#recover"
                                        style={{ fontSize: 18 }}
                                        onClick={props.handleRecoverTab}
                                        name="recover">
                                        ¿Has olvidado la contraseña?
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ textAlign: 'center' }}>
                    <em style={{ fontSize: 11 }}>{`Versión ${packageJson.version}`}</em>
                </fieldset>
            </form>
        </div>
    )

    const TabRegister = (
        <div className="tab-pane fade" id="register">
            <form id="register-form" method="post" onSubmit={props.handleRegisterSubmit}>
                <fieldset>
                    <TextInputCustom
                        textLabel={"Nombre"}
                        nameInput={"name"}
                        typeInput={"text"}
                        required={true}
                        value={props.registerValues.name}
                        evOnChange={props.handleRegisterInputChange}
                    />
                    <TextInputCustom
                        textLabel={"Apellidos"}
                        nameInput={"lastname"}
                        typeInput={"text"}
                        required={true}
                        value={props.registerValues.lastname}
                        evOnChange={props.handleRegisterInputChange}
                    />

                    <div className='row'>
                        <div className='col-12 col-xl-6'>
                            <CustomSelect name="ISO_country"
                                label={"*País"}
                                data={ISO}
                                defaultValue={{ value: 'ES', label: 'España' }}
                                value={props.selectNif.value}
                                onChange={(e) => props.selectNif.set(e)}
                            />
                        </div>
                        <div className="col-12 col-xl-6">
                            <TextInputCustom
                                textLabel={"DNI"}
                                nameInput={"vat"}
                                typeInput={"text"}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                //pattern={String.raw`^\d{8}[a-zA-Z]$`}
                                title={"Formato DNI/NIF"}
                                errorValidate={props.validationBooleans.vat}
                                textError={"El formato de DNI no es correcto"}
                                value={props.registerValues.vat}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-xl-6' style={{ display: "flex" }}>
                            <TextInputCustom
                                style={{ flex: "0.95" }}
                                textLabel={"Contraseña"}
                                nameInput={"password"}
                                typeInput={registerPasswordInputType}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                title={passComplexMsg}
                                pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&~€*-]).{8,}$"}
                                errorValidate={props.validationBooleans.passComplex}
                                textError={passComplexMsg}
                                value={props.registerValues.password}
                            />
                            {registerPasswordVisibility ?
                                <div
                                    className="clickable"
                                    style={eyeVisibleDivStyles}
                                    onClick={changeRegisterPasswordVisibility}
                                >
                                    <i className="clickable hovereableIcon fas fa-eye"></i>
                                </div>
                                : null}

                            {!registerPasswordVisibility ?
                                <div
                                    className="clickable"
                                    style={eyeVisibleDivStyles}
                                    onClick={changeRegisterPasswordVisibility}
                                >
                                    <i className="clickable hovereableIcon fas fa-eye-slash"></i>
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-12 col-xl-6" style={{ display: "flex" }}>
                            <TextInputCustom
                                style={{ flex: "0.95" }}
                                textLabel={"Confirmar contraseña"}
                                nameInput={"confirmPassword"}
                                typeInput={confirmationPasswordInputType}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                errorValidate={props.validationBooleans.passwordsMatch}
                                textError={`Las contraseñas no coinciden`}
                                value={props.registerValues.confirmPassword}
                            />
                            {confirmPasswordVisibility ?
                                <div
                                    className="clickable"
                                    style={eyeVisibleDivStyles}
                                    onClick={changeRegisterConfirmationPasswordVisibility}
                                >
                                    <i className="clickable hovereableIcon fas fa-eye"></i>
                                </div>
                                : null}

                            {!confirmPasswordVisibility ?
                                <div
                                    className="clickable"
                                    style={eyeVisibleDivStyles}
                                    onClick={changeRegisterConfirmationPasswordVisibility}
                                >
                                    <i className="clickable hovereableIcon fas fa-eye-slash"></i>
                                </div>
                                : null
                            }
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-xl-6'>
                            <TextInputCustom
                                textLabel={"Teléfono"}
                                nameInput={"phone"}
                                typeInput={"tel"}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                title={"Introduce un número de 9 dígitos"}
                                pattern={"[0-9]+"}
                                textError={"El formato de email no es correcto"}
                                value={props.registerValues.phone}
                            />
                        </div>
                        <div className='col-12 col-xl-6'>
                            <TextInputCustom
                                textLabel={"Email"}
                                nameInput={"email"}
                                typeInput={"email"}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                errorValidate={props.validationBooleans.email}
                                textError={"El formato de email no es correcto"}
                                value={props.registerValues.email}
                            />
                        </div>
                    </div>

                    <div className="row d-flex">
                        <div className="col-12 col-xl-6">
                            <TextInputCustom
                                textLabel={"Usuario Invisalign"}
                                nameInput={"userInvisalign"}
                                typeInput={"text"}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                value={props.registerValues.userInvisalign}
                            />
                        </div>
                        <div className="col-12 col-xl-6">
                            <TextInputCustom
                                textLabel={"Contraseña Invisalign"}
                                nameInput={"passwordInvisalign"}
                                typeInput={"password"}
                                required={true}
                                evOnChange={props.handleRegisterInputChange}
                                value={props.registerValues.passwordInvisalign}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Especialidad Dental</label>
                        <textarea
                            name="descriptionNotes"
                            maxLength="500"
                            className='m-0 w-100'
                            onChange={props.handleRegisterInputChange}
                            value={props.registerValues.descriptionNotes}></textarea>
                    </div>
                    <div className="form-group">
                        <label>Sube aquí tu foto de perfil como usuario</label>
                        <input type="file"
                            name="profilePhoto"
                            id="profilePhoto"
                            className="form-control"
                            accept=".png, .jpg, .jpeg"
                            onChange={props.handleImage} />
                        {props.validationBooleans.imageSize && (
                            <p className="text-danger error-validation">
                                La imagen excede el tamaño máximo
                            </p>)}
                    </div>
                    <div className="form-group">
                        <div className="d-flex" style={{ alignItems: 'self-start' }}>
                            <CustomToggleFilter filterName={"lopd"}
                                styleCheck={{ margin: 0 }}
                                event={props.handleRegisterInputChange}
                                checked={props.registerValues.lopd} style={{ margin: 0 }} />
                            <button type="button" className="btn btn-link text-justify"
                                data-bs-toggle="modal" data-bs-target="#modalLopd"
                                style={{ fontSize: 14, padding: 0 }}
                            >
                                * Marcando esta casilla se acepta la politica de privacidad
                            </button>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <div className="form-group">
                        <input type="submit" name="register-submit" id="register-submit"
                            className="btn btn-primary btn-block w-100" value="Enviar"
                            style={{
                                background: "#5BC5F1",
                                color: "#fff",
                                border: 0,
                                fontWeight: 'bold'
                            }}
                        />
                    </div>
                </fieldset>
            </form>
        </div>
    )

    const TabRecover = (
        <div className="tab-pane fade" id="recover">
            <form id="login-form"
                method="post"
                className="card-body"
                onSubmit={props.handleRecoverSubmit}>
                <TextInputCustom
                    textLabel={"Email"}
                    nameInput={"email"}
                    typeInput={"email"}
                    required={true}
                    evOnChange={props.handleRecoverInput}
                    value={props.recoverForm.email} href
                />
                <div className="form-group">
                    <input type="submit"
                        name="recover-submit"
                        id="recover-submit"
                        className="btn btn-primary btn-lg btn-block w-100"
                        style={{
                            background: "#5BC5F1",
                            color: "#fff",
                            border: 0,
                            fontWeight: 'bold'
                        }}
                        value="Recuperar contraseña" />
                </div>
            </form>
        </div>

    )

    const ContentLogin =
        <div className="container">
            <div className={`row justify-content-center align-items-center ${LoginScreenCSS.minh100}`}>
                <div className="col-12 col-sm-9 col-md-6">
                    <h1 className="pageTitle mb-2" style={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: "#000" }}>PLANNING CENTER</h1>
                    <p style={{ textAlign: 'center', marginBottom: 0 }}>Plataforma de ayuda con tus casos. Acceso exclusivo <br /> para Dres/as. Inscritos en nuestras formaciones.</p>
                    <hr className="my-4"></hr>

                    <div className="card">
                        {(location[2] === "reset-password" && location[3])
                            ?
                            <div id="myTabContent" className="tab-content p-2">
                                <TextInputCustom
                                    textLabel={"Contraseña"}
                                    nameInput={"password"}
                                    typeInput={"password"}
                                    required={true}
                                    evOnChange={props.forms.resetPassword.handle}
                                    requiredValidation={{
                                        condition: (!props.forms.resetPassword.validators.password.validFlags.required &&
                                            props.forms.resetPassword.try) ||
                                            (!props.forms.resetPassword.validators.password.properties.pristine &&
                                                !props.forms.resetPassword.validators.password.validFlags.required),
                                        msg: requiredFieldMsg
                                    }}
                                    formatValidation={{
                                        condition: !props.forms.resetPassword.validators.password.validFlags.regex &&
                                            !props.forms.resetPassword.validators.password.properties.pristine,
                                        msg: passComplexMsg
                                    }}
                                    value={props.resetPasswordObj.password}
                                />
                                <TextInputCustom
                                    textLabel={"Confirmar contraseña"}
                                    nameInput={"confirmPassword"}
                                    typeInput={"password"}
                                    required={true}
                                    evOnChange={props.forms.resetPassword.handle}
                                    requiredValidation={{
                                        condition: (!props.forms.resetPassword.validators.confirmPassword.validFlags.required &&
                                            props.forms.resetPassword.try) ||
                                            (!props.forms.resetPassword.validators.confirmPassword.properties.pristine &&
                                                !props.forms.resetPassword.validators.confirmPassword.validFlags.required),
                                        msg: requiredFieldMsg
                                    }}
                                    formatValidation={{
                                        condition: !props.forms.resetPassword.validators.confirmPassword.validFlags.regex &&
                                            !props.forms.resetPassword.validators.confirmPassword.properties.pristine,
                                        msg: passComplexMsg
                                    }}
                                    value={props.resetPasswordObj.confirmPassword}
                                />

                                {(!props.forms.resetPassword.validators.sync && !props.forms.resetPassword.validators.password.properties.pristine) ||
                                    (!props.forms.resetPassword.validators.sync && !props.forms.resetPassword.validators.confirmPassword.properties.pristine) ?
                                    <ErrorText msg={"Las contraseñas no coinciden"} /> :
                                    null}
                                <div className="form-group">
                                    <input type="submit"
                                        name="recover-submit"
                                        id="recover-submit"
                                        className="btn btn-primary btn-lg btn-block w-100"
                                        style={{
                                            background: "#5BC5F1",
                                            color: "#fff",
                                            border: 0,
                                            fontWeight: 'bold'
                                        }}
                                        value="Confirmar contraseña"
                                        onClick={props.forms.resetPassword.submit} />
                                </div>
                            </div>
                            :
                            <>
                                <ul className="nav nav-tabs nav-fill"
                                    ref={props.tabRefs.tabMenu}>
                                    <li className="nav-item">
                                        <a
                                            ref={props.tabRefs.login}
                                            className="nav-link active"
                                            data-bs-toggle="tab"
                                            href="#login"
                                            id="loginTab"
                                            onClick={props.handleRecoverTab}
                                            style={{ fontSize: 18 }}>
                                            Iniciar Sesión
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            ref={props.tabRefs.register}
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            href="#register"
                                            onClick={props.handleRecoverTab}
                                            style={{ fontSize: 18 }}>
                                            Registrar
                                        </a>
                                    </li>
                                </ul>

                                <div id="myTabContent" className="tab-content p-2" ref={props.tabRefs.myTabContent}>
                                    {TabLogin}
                                    {TabRegister}
                                    {TabRecover}
                                </div>

                                <div className={`${LoginScreenCSS.none} padding1"`}
                                    ref={props.tabRefs.activatePanel}>
                                    <h5 className="text-centre">CONFIRMA Y ACTIVA TU CUENTA</h5>
                                    <img className={LoginScreenCSS.email} src={email}></img>
                                    <p className="text-justify margin-auto width90">Consulta tu email con el enlace de verificación. Si el mensaje no se encuentra en la bandeja de entrada, revisa tu carpeta de spam o correos no deseados.</p>
                                    <a href="#"
                                        className={"text-centre " + LoginScreenCSS.resend}>
                                        <i className="fas fa-envelope"></i>Reenviar email de verificación
                                    </a>
                                </div>
                            </>}
                    </div>
                    {ImgLogo()}
                </div>
            </div>
        </div >


    //#endregion

    return ([
        <ModalLopd idLopd={1} dataTarget={"modalLopd"} />,
        FillBackground(),
        ContentLogin
    ])
}

export { LoginScreenUI }



/* 
const ContentAviso =
<div className="container">
    <div className={`row justify-content-center align-items-center ${LoginScreenCSS.minh100}`}>
        <div className="col-12 col-sm-9 col-md-6">
            <h1 className="pageTitle mb-2" style={{fontFamily:'Open Sans', fontWeight:'bold', color:"#000"}}>PLANNING CENTER</h1>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>Plataforma de ayuda con tus casos. Acceso exclusivo <br /> para Dres/as. Inscritos en nuestras formaciones.</p>
            <hr className="my-4"></hr>
            <p>Plataforma en mantenimiento desde las 16:00 hasta las 18:00.</p>
        </div> 
    </div>
</div >
*/