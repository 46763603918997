export default (state, action) => {
    const { payload, type } = action

    switch (type) {
        case "SET_SEARCH":
            return {
                ...state,
                searchData: payload
            }
        case "SET_SEARCH_USER":
            return {
                ...state,
                searchUser: payload
            }
        case "SET_TYPE_VIEWS":
            return {
                ...state,
                typeViews: payload
            }
        default:
            return state;
    }
}