import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import ButtonModal from '../../Tools/ButtonModal'
import ButtonSave from '../../Tools/ButtonSave'
import FullscreenImage from '../../Tools/FullscreenImage'
import { ModalFaqsForm } from '../../Tools/Modals/ModalFaqsForm'
import { ModalStoredResponses } from '../../Tools/Modals/ModalStoredResponses'
import OIFDoubtAttachments from '../../Tools/OIF_tools/OIFDoubtAttachment/OIFDoubtAttachments'
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal'
import TextInputCustom from '../../Tools/TextInputCustom'

const ObservationsFormUI = ({ caseRecord, userData, currentData, stateEditor,
    onEditorStateChange, handleCreate, handleUpdate, fullscreenImageBlob,
    setFullscreenImageBlob, observationFile, inputTypeObservation, resetState,
    editForm, setEditForm, customFileInputRef, selectedFaq,
    handleFaqResponse, selectedStoredResponse, handleStoredResponse }) => {

    //#region "Structure"
    const ModalObservationCreated = () => (
        <SimpleBootstrapModal
            id={"observationCreated"}
            title={"Observación creada"}
            closeButtonId={"closeObservationCreated"}
            cancelButtonMessage={"Aceptar"}>
            <p className="text-center">Observación creada correctamente!</p>
        </SimpleBootstrapModal>
    )

    const ModalObservationUpdate = () => (
        <SimpleBootstrapModal
            id={"observationUpdated"}
            title={"Observación modificada"}
            closeButtonId={"closeObservationModified"}
            cancelButtonMessage={"Aceptar"}>
            <p className="text-center">Observación modificada correctamente!</p>
        </SimpleBootstrapModal>
    )

    const ModalObservationForm = (
        <SimpleBootstrapModal
            id={"observationForm"}
            modalClasses={"padding0"}
            fullScreen={true}
            closeButtonId={"closeObservationForm"}
            bodyClose={true}
            closeFunction={resetState}
            footerVisibility={false}
            cancelButtonMessage="Atrás"
            title={"Observación " + (currentData?.getters.index === 0 ? '' : currentData.getters.index + 1)}
            bodyButtons={[
                (!currentData.getters.current.id || !editForm) &&
                userData.idRole !== 3 && (
                    <ButtonModal
                        text={"Guardar"}
                        onClick={currentData.getters.current.id && !editForm ? handleUpdate : handleCreate}
                        cssClasses={"marginr1"}
                        icoClasses={"fas fa-save"}
                    />),
                currentData.getters.current.id && editForm &&
                userData.idRole !== 3 &&
                !(caseRecord.stateId === 1) && (
                    <ButtonModal
                        text={"Editar"}
                        onClick={() => setEditForm(false)}
                        cssClasses={"marginr1"}
                        icoClasses={"fas fa-save"}
                    />),
                (!currentData.getters.current.id || !editForm) &&
                userData.idRole !== 3 && (
                    <ButtonModal
                        text={"Preguntas frecuentes"}
                        bsTarget={"#faqsListModal"}
                        cssClasses={"marginr1"}
                    />),
                (!currentData.getters.current.id || !editForm) &&
                userData.idRole !== 3 && (
                    <ButtonModal
                        text={"Respuestas guardadas"}
                        bsTarget={"#storedResponsesModal"}
                        cssClasses={"marginr1"}
                    />),
            ]}
        >
            <div className="row mt-3">
                <div className="col-12">
                    <TextInputCustom nameInput={"typeObservation"} textLabel={"Tipo de observación"}
                        value={inputTypeObservation.get}
                        style={{ marginLeft: 0, marginRight: 0 }}
                        disabled={currentData.getters.current.id && editForm}
                        evOnChange={(e) => inputTypeObservation.set(e.target?.value)} />
                </div>
                <div className="col-12 col-sm-8">
                    <Editor
                        spellCheck
                        toolbarHidden={currentData.getters.current.id && editForm}
                        readOnly={currentData.getters.current.id && editForm}
                        editorState={stateEditor.editorState}
                        toolbarClassName="rdw-storybook-toolbar"
                        wrapperClassName="rdw-storybook-wrapper"
                        editorClassName="rdw-storybook-editor"
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
                <div className="col-12 col-sm-4">
                    <h5>Adjuntos de la observación</h5>
                    <OIFDoubtAttachments
                        fileInputRef={customFileInputRef}
                        files={observationFile.get}
                        formName="observationDocuments"
                        id={"observationDocuments"}
                        nFiles={5}
                        cssClass={"margin0"}
                        formats={".pdf, .docx, .doc, .pptx, .ppt, .png, .jpg, .jpeg"}
                        idUser={userData.id}
                        idEntityOwner={userData.id}
                        entityId={currentData.getters.current.id}
                        uploader={observationFile.upload}
                        deleter={observationFile?.delete}
                        downloader={observationFile?.download}
                        imageDownloader={observationFile?.downloadImage}
                        deletable={true}
                        fileElemCSS={"width100"}
                        setFullscreenImageBlob={setFullscreenImageBlob}
                        readOnly={(!currentData.getters.current.id || !editForm) && userData.idRole !== 3}
                    />
                </div>
            </div>
        </SimpleBootstrapModal>
    )
    //#endregion

    return ([
        <ButtonSave buttonId="triggerCreatedObservationModal" dataTarget="#observationCreated" cssClasses="none" />,
        <ButtonSave buttonId="triggerModifiedObservationModal" dataTarget="#observationUpdated" cssClasses="none" />,
        <FullscreenImage blob={fullscreenImageBlob} previousModalButtonOpenerId="closeObservations" />,
        <ModalObservationCreated />,
        <ModalObservationUpdate />,
        ModalObservationForm,
        <ModalFaqsForm bsTarget={"#observationForm"} selected={selectedFaq} handleSelect={handleFaqResponse} />,
        <ModalStoredResponses bsTarget={"#observationForm"} selected={selectedStoredResponse} handleSelect={handleStoredResponse} />
    ])
}

export { ObservationsFormUI }