import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const postCasesReasonWaiting = (id_cases, reason_waiting, reason_waiting_other, id_students) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        /* xhr.open("POST", RouterCtrl.casesReasonWaiting)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ id_cases: id_cases, reason_waiting: reason_waiting, reason_waiting_other: reason_waiting_other })) */
        xhr.open("POST", RouterCtrl.casesReasonWaiting_insertGet)
        xhr.setRequestHeader("data", JSON.stringify({ id_cases: id_cases, reason_waiting: reason_waiting, reason_waiting_other: reason_waiting_other, id_students: id_students }))
        xhr.withCredentials = true
        xhr.send()
        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (typeof res.type === "undefined") {
                    resolve(res)
                } else {
                    resolve(false)
                }
            } catch (e) {
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}