import { useState, useEffect } from 'react'
import { getCasesEditions } from '../Services/CasesEditions/getCasesEditions'
import { postEditions } from '../Services/Editions/postEditions'
import { putEditions } from '../Services/Editions/putEditions'
import { delEditions } from '../Services/Editions/delEditions'
import { getEdition } from '../Services/Editions/getEdition'
import { archiveEdition } from '../Services/Editions/archiveEdition'
import { unarchiveEdition } from '../Services/Editions/unarchiveEdition'
import { getUserEditionsByUserId } from '../Services/Editions/getUserEditionsByUserId'

export default function useEditions({ onlyForm = false, onlySearchCases = false, onlySearch = false, together = false } = {}) {

    const [casesEditions, setCasesEditions] = useState([])
    const [casesEditionsCases, setCasesEditionsCases] = useState([])
    const [casesEditionsUsers, setCasesEditionsUsers] = useState([])

    const formatData = (item) => {
        const doc = []
        if (Array.isArray(item)) {
            item.forEach(element => {
                doc.push({
                    value: element.id,
                    label: element.Name,
                    number: element.Limit_cases,
                    consumed: element.consumed_cases || 0,
                    endDate: element.End_Date,
                    target: { name: "caseEditionFilter" }
                })
            });
        }
        return doc
    }

    useEffect(() => {
        if (onlyForm) {
            getFillEditions()
        } else if (onlySearchCases) {
            getFillEditionsCases()
        } else if (onlySearch) {
            getFillEditionsUsers()
        } else if (together) {
            getFillEditions()
            getFillEditionsUsers()
        }

    }, [])

    const getFillEditions = async () => {
        let result = await getCasesEditions()
        result = formatData(result)
        setCasesEditions(result)
    }

    const getFillEditionsCases = async () => {
        let result = await getCasesEditions()
        result = formatData(result)
        result.unshift({ value: -1, label: "Todos", target: { name: "caseEditionFilter" } })
        setCasesEditionsCases(result)
    }

    const getFillEditionsUsers = async () => {
        let result = await getCasesEditions()
        result = formatData(result)
        result.unshift({ value: -1, label: "Sin edición activa", target: { name: "caseEditionFilter" } })
        result.unshift({ value: -2, label: "Con edición activa", target: { name: "caseEditionFilter" } })
        result.unshift({ value: -3, label: "Todos", target: { name: "caseEditionFilter" } })
        setCasesEditionsUsers(result)
    }

    const getEditions = async () => {
        return await getEdition()
    }

    const deleteEditions = async data => {
        return await delEditions(data)
    }

    const createEditions = async data => {
        return await postEditions(data)
    }

    const updateEditions = async data => {
        return await putEditions(data)
    }

    const hideEdition = async id => {
        return await archiveEdition(id)
    }

    const showEdition = async id => {
        return await unarchiveEdition(id)
    }

    const retireveUserEditionsByUserId = idUser => getUserEditionsByUserId(idUser)

    return {
        casesEditions,
        casesEditionsUsers,
        casesEditionsCases,
        deleteEditions,
        getEditions,
        createEditions,
        updateEditions,
        hideEdition,
        showEdition,
        retireveUserEditionsByUserId,
    }
}