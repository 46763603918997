import React from 'react';
import { ContentGeneral } from '../Tools/ContentGeneral';
import { ContentItem } from '../Tools/ContentItem';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import { ItemKanban } from '../Tools/Kanban/ItemKanban';
import { KanbanCard } from '../Tools/Kanban/KanbanCard';
import { TableList } from '../Tools/Table/TableList';
import { ViewData } from '../Tools/ViewsData/ViewData';
import { ViewType } from '../Tools/ViewTypes/ViewType';
import { Editor } from 'react-draft-wysiwyg'
import { useHistory } from 'react-router-dom';

const LopdScreenUI = ({ data, views }) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const obFields = {
        title: "Listado de LOPD",
        name: "Nombre",
        content: "LOPD"
    }
    //#endregion

    //#region 'FUNCTIONS'
    const onClickItem = (item) => {
        history.push({ pathname: "/main-screen/lopd-editor", state: { idLopd: item.id } })
    }

    const handleCreateLopd = () => {
        history.push("/main-screen/lopd-editor")
    }
    //#endregion

    //#region 'STRUCTURE'
    //#region 'VIEWS KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard index={index} title={"LOPD " + index}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            onclickHead={() => onClickItem(item)}
            cardBody={[
                <ContentItem key={"keyCI_" + index} itemBody={[
                    <ItemKanban classCol={'col'} label={obFields.name} value={item.name} />,
                ]} />
            ]}
        />
    )
    //#endregion
    //#region 'VIEWS LIST'
    const HeadList = () => (
        <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>{obFields.name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.content}</th>
        </tr>
    )
    const ContentList = ({ item, index }) => (
        <tr key={item.id} className='clickable'
            onClick={() => onClickItem(item)}>
            <th>{index + 1}</th>
            <td style={{ width: 250, minWidth: 250, textAlign: 'center' }}>{item.name}</td>
            <td>
                <div style={{ height: 150, overflow: 'scroll', background: "#fff", paddingLeft: 5, paddingRight: 5 }}>
                    <Editor
                        spellCheck
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={item?.contentHTML}
                    />
                </div>
            </td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )

    //#endregion
    const ArrayMaping = data.map((item, index) => (
        views.type.kanban ? <ViewKanban item={item} index={index} /> : <ContentList item={item} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={obFields.title} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={false} views={views.type}
                kanbanFunction={views.funcKanban} listFunction={views.funcList}
                handleSubmit={handleCreateLopd} textButton={"Crear"} />,
            <ViewData kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
                viewType={views.type.kanban} />
        ]} />
    ])
}

export { LopdScreenUI }