import React from 'react'

const TableList = ({ headList, bodyList, footerList }) => (
    <div className="table-responsive mt-2">
        <table className="table table-hover table-striped align-middle">
            <colgroup>
                <col span="1" className="first-column" />
            </colgroup>
            <thead>{headList}</thead>
            <tbody>{bodyList}</tbody>
            {footerList ? <tfoot>{footerList}</tfoot> : null}
        </table>
    </div>
)

export { TableList }