import { getShowNotificationUser } from '../Services/ShowNotificationUser/getShowNotificationUser';
import { postShowNotificationUser } from '../Services/ShowNotificationUser/postShowNotificationUser';
import { putShowNotificationUser } from '../Services/ShowNotificationUser/putShowNotificationUser';

const useShowNotificationUser = () => {

    const getDataShowNotificationUser = async (id_user_student) => {
        return await getShowNotificationUser(id_user_student)
    }

    const insertDataShowNotificationUser = async (id_user, show_notification) => {
        return await postShowNotificationUser(id_user,show_notification)
    }

    const updateDataShowNotificationUser = async (id_user, show_notification) => {
        return await putShowNotificationUser(id_user, show_notification)
    }

    return {getDataShowNotificationUser, insertDataShowNotificationUser, updateDataShowNotificationUser}
}

export { useShowNotificationUser }