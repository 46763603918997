import moment from 'moment'

const GetDaysFromCreation = (date) => {
    if (date === null || date === undefined) {
        return 0
    } else {
        let creationDate = moment(date).format("YYYY/MM/DD")
        let dateCreate = new Date(creationDate)
        let currDate = new Date(new Date().toUTCString())
        let dateDiff = dateCreate - currDate
        let dateDiffDays = dateDiff / (1000 * 3600 * 24)
        return Math.floor(Math.abs(dateDiffDays))
    }
}

export { GetDaysFromCreation };