import React from 'react'
import noPhoto from '../../img/silueta.png'

const DisplayImageUser = ({ userBlob, isDiplayBig = false, styImg }) => {

    let styImgUser = {
        width: isDiplayBig ? 65 : 30,
        height: isDiplayBig ? 65 : 30,
        marginRight: isDiplayBig ? 10 : 5,
        borderRadius: isDiplayBig ? 65 : 30
        
    }

    const srcUserImmg = (userBlob) => {
        let urlCreator = window.URL || window.webkitURL
        return urlCreator.createObjectURL(userBlob)
    }

    
    return Number.isInteger(userBlob?.size) && userBlob.size !== 68 ?
        <img style={styImg ? styImg : styImgUser} alt={"imgProps"}  src={srcUserImmg(userBlob)} /> 
        : <img style={styImg ? styImg : styImgUser} alt={"noPhoto"} src={noPhoto} />
}

export { DisplayImageUser }