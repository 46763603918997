import React from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Contexts/User/UserContext.js';
import { useCasesDistribution } from '../../Hooks/useCasesDistribution.js';
import { useShowView } from '../../Hooks/useShowView.js';
import { CasesFilter } from '../Tools/GeneralFunctions/CasesFilter.js';
import { ElimiarDiacriticos } from '../Tools/GeneralFunctions/EliminarDiacriticos.js';
import { CasesDistributionUI } from './CasesDistributionUI.jsx';

const CasesDistribution = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]
    const obFilter = {
        valueDp: 1,
        textFilter: '',
    }
    //#endregion

    //#region 'USE REF'
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { casesDistribution } = useCasesDistribution()
    //#endregion

    //#region 'USE STATES'
    const [filter, setFilter] = useState(obFilter)
    //#endregion

    //#region 'FUNCTIONS'
    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFilter({ ...filter, [name]: value })
    }

    const onFilter = (values) => {
        switch (filter.valueDp) {
            case 1:
                return CasesFilter(filter.textFilter, values, ElimiarDiacriticos(values.teacher_name))
            default:
                return values
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        userContext.retrieveUserData().then(res => {
            if (res.idRole !== 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])
    //#endregion


    //#region 'STRUCTURE'

    //#endregion

    return <CasesDistributionUI
        casesDistribution={{
            data: casesDistribution
        }}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        filters={{
            selFilter: filter,
            dpFieldsFilters: cbfilterObj,
            onChange: handleInputChange,
            onFilter: onFilter
        }}
    />
}

export { CasesDistribution }