import React from 'react';

const ContentGeneral = ({content}) => {

    return (
        <div className="col-12" style={{ marginTop: 160}} translate="no">
            {content}
        </div>
    )
}

export { ContentGeneral }