import React, { useContext, useLayoutEffect, useState } from 'react';
import { UserContext } from '../../Contexts/User/UserContext';
import { useCasesPacks } from '../../Hooks/useCasesPacks';
import { useShowView } from '../../Hooks/useShowView';
import { CasesPackUI } from './CasesPackUI';
import { useHistory } from 'react-router-dom'
import { CasesFilter } from '../Tools/GeneralFunctions/CasesFilter';
import { ElimiarDiacriticos } from '../Tools/GeneralFunctions/EliminarDiacriticos';

const CasesPack = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]
    const obFilter = {
        valueDp: 1,
        textFilter: '',
    }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { casesPacks, setCasesPacks } = useCasesPacks()
    const [selItem, setSelItem] = useState([])
    const [selection, setSelection] = useState([])
    //#endregion

    //#region 'USE STATES'
    const [filter, setFilter] = useState(obFilter)
    //#endregion

    //#region 'FUNCTIONS'
    const getValues = (item) => {
        setSelection({
            id: item.id,
            packName: item.name,
            packDuration: { value: item.id_casesPacksDuration, label: item.duration, target: { name: "packDuration" } },
            cases_limit: item.case_limit,
            active: item.active
        })
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFilter({ ...filter, [name]: value })
    }

    const onFilter = (values) => {
        switch (filter.valueDp) {
            case 1:
                return CasesFilter(filter.textFilter, values, ElimiarDiacriticos(values.name))
            default:
                return values
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        userContext.retrieveUserData().then(res => {
            if (res.idRole != 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    //#endregion

    return <CasesPackUI
        casesPacks={{
            data: casesPacks,
            set: setCasesPacks
        }}
        filters={{
            selFilter: filter,
            dpFieldsFilters: cbfilterObj,
            onChange: handleInputChange,
            onFilter: onFilter
        }}
        selItem={{
            select: selItem,
            set: setSelItem,
        }}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        selection={{
            select: selection,
            set: setSelection
        }}
        events={{
            evOnClickItem: getValues
        }}
    />
}

export { CasesPack }