import React, { useReducer } from 'react'

import { LoadingContext } from './LoadingContext'
import LoadingReducer from './LoadingReducer'

const LoadingState = (props) => {
    const initialState = {
        visibility: false
    }

    const [state, dispatch] = useReducer(LoadingReducer, initialState)

    const setVisibility = (visible) => {
        dispatch({ type: "SET_VISIBILITY", payload: visible })
    }

    return (
        <LoadingContext.Provider
            value={{
                visibility: state.visibility,
                setVisibility
            }}
        >
            {props.children}
        </LoadingContext.Provider>
    )
}

export { LoadingState }