//#region 'IMPORTS'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ButtonSave from '../Tools/ButtonSave'
import CustomFilter from '../Tools/CustomFilter'
import TextInputCustom from '../Tools/TextInputCustom'
import ButtonModal from '../Tools/ButtonModal'
import SimpleBootstrapModal from '../Tools/SimpleBootstrapModal'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import { ViewData } from '../Tools/ViewsData/ViewData'
import { ContentItem } from '../Tools/ContentItem'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import { KanbanCard } from '../Tools/Kanban/KanbanCard'
import { TableList } from '../Tools/Table/TableList'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

const CasesTypesScreenUI = props => {
    //#region 'VARIABLES'
    useHistory()
    const obFields = {
        name: "Nombre",
        abbreviation: "Abrevación",
        color: "Color"
    }
    //#endregion

    //#region 'STRUCTURE'

    //#region 'VIEWS KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Name}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            dataTarget={"#casesTypeModal"} onclickHead={() => props.openModalModify(item.id, item.Name, item.Abbreviation, item.Color)}
            cardBody={
                <ContentItem itemBody={[
                    <ItemKanban key={"casesType_abbreviation_" + index} label={obFields.abbreviation} value={item.Abbreviation} classCol={"col-6"} />,
                    <ItemKanban key={"casesTyoe_color_" + index} label={obFields.color} value={<input type="color" value={item.Color} disabled />} classCol={"col-6"} />,
                ]} />
            } />
    )
    //#endregion

    //#region 'VIEWS LIST'
    const ContentList = ({ item, index }) => (
        <tr key={index}
            className="clickable"
            onClick={() => props.openModalModify(item.id, item.Name, item.Abbreviation, item.Color)}
            data-bs-toggle="modal"
            data-bs-target="#casesTypeModal"
        >
            <th scope="row">{index + 1}</th>
            <td>{item.Name}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{item.Abbreviation}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{<input type="color" value={item.Color} disabled />}</td>
        </tr>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.abbreviation}</th>
            <th style={{ textAlign: 'center' }}>{obFields.color}</th>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion

    const ArrayMaping = props.filteredCasesTypes.map((caseType, index) => (
        props.views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={caseType} index={index} /> : <ContentList key={"key_ContentList"} item={caseType} index={index} />
    ))

    //#endregion

    return ([
        <HeadTitle title={"Tipos de casos"} />,
        
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} textButton={"Crear"} handleSubmit={props.openModalCreate} dataTarget={"#casesTypeModal"}
                views={props.views.type} kanbanFunction={props.views.funcKanban} listFunction={props.views.funcList}
            />,

            <CustomFilter
                {...{
                    options: props.cbfilterObj,
                    onChange: props.filterCasesTypes,
                    nameSelect: "valueDp",
                    nameInput: "textFilter",
                    cssClasses: "paddinglr0"
                }}
            />,

            <ViewData
                viewType={props.views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
            />,

        ]} />,

        <SimpleBootstrapModal
            id={"deleteCaseTypeModal"}
            title={"Eliminar tipo de caso"}
            closeFunction={props.closeModal}
            closeButtonId={"closeDeleteCaseTypeModal"}
            message={`¿Está seguro que desea borrar este tipo de caso (${props.modalContent.name})?`}
            footerButtons={
                <>
                    <ButtonModal
                        onClick={props.deleteCaseType}
                        text={"Eliminar"}
                        cssClasses={"light-red"}
                    />
                </>
            }
        />,

        <SimpleBootstrapModal
            id={"casesTypeModal"}
            title={props.modalTitle}
            closeFunction={props.closeModal}
            closeButtonId={"closeCaseTypeModal"}
            footerButtons={
                <>
                    {props.btnsDisplay.saveBtn ? <ButtonModal onClick={props.update} text={props.modalConfirmText} /> : ""}
                    {props.btnsDisplay.deleteBtn ? <ButtonModal onClick={props.actions.delete} text={props.modalConfirmText} cssClasses={"btn-danger"} /> : ""}
                    {props.btnsDisplay.createBtn ? <ButtonModal onClick={props.create} text={props.modalConfirmText} /> : ""}
                </>
            }>
            {!props.btnsDisplay.createBtn ?
                <ButtonSave
                    textBtn={"Eliminar"}
                    cssClasses={"margin0 marginlauto"}
                    buttonClasses={"light-red"}
                    dataTarget={"#deleteCaseTypeModal"}
                    icoClasses={"fas fa-trash-alt"}
                />
                : null}
            <form action="">
                <div className="row">
                    <div className="row">
                        <div className="col-sm form-group">
                            <TextInputCustom
                                textLabel={"Nombre de tipo de caso"}
                                nameInput={"name"}
                                typeInput={"text"}
                                required={true}
                                pattern={/^[a-zA-Z0-9]+$/}
                                evOnChange={props.handleInputChange}
                                value={props.modalContent.name}
                                cssClassesGroup={"marginlr0 marginb05"}
                                cssClassesInput={"field"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm form-group">
                            <TextInputCustom
                                textLabel={"Abreviación"}
                                nameInput={"abbreviation"}
                                typeInput={"text"}
                                required={true}
                                pattern={/^[a-zA-Z0-9]+$/}
                                evOnChange={props.handleInputChange}
                                value={props.modalContent.abbreviation}
                                cssClassesGroup={"marginlr0 marginb05"}
                                cssClassesInput={"field"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm form-group">
                            <label htmlFor="color">*Color</label>
                            <input
                                className="block"
                                id="color"
                                name="color"
                                type="color"
                                value={props.modalContent.color}
                                onChange={props.handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </SimpleBootstrapModal>
    ])
}

export { CasesTypesScreenUI }