//#region 'IMPORTS'
import React, { useContext, useEffect, useState, useLayoutEffect } from 'react'
import { StoredResponsesScreenUI } from './StoredResponsesScreenUI'
import { useHistory } from 'react-router-dom'
import { useStoredResponses } from '../../Hooks/useStoredResponses'
import { useStoredResponsesTypes } from '../../Hooks/useStoredResponsesTypes'
import { UserContext } from '../../Contexts/User/UserContext'
import { useShowView } from '../../Hooks/useShowView'
//#endregion

const StoredResponsesScreen = () => {
    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Respuesta' },
    ]
    const obModalContent = {
        id: "",
        response: "",
        visible: 0,
        storedTypeId: ""
    }
    const obStateFilter = {
        textFilter: "",
        typeFilter: -1
    }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'CUSTOMIZED USE STATES'
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { retrieveStoredResponsesTypes } = useStoredResponsesTypes()
    const { retrieveStoredResponses, StoredQuestions,
        createStoredResponse, updateStoredResponse,
        removeStoredResponse, retrieveReturnStoredResponses } = useStoredResponses()
    //#endregion

    //#region 'USE STATE'
    const [userData, setUserData] = useState({})
    const [responses, setResponses] = useState([])
    const [filteredResponses, setFilteredResponses] = useState([])
    const [storedResponsesTypes, setStoredResponsesTypes] = useState([])
    const [modalVisibility, setModalVisibility] = useState(false)
    const [contentDisabled, setContentDisabled] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [stateFilter, setStateFilter] = useState(obStateFilter)
    const [modalConfirmText, setModalConfirmText] = useState("")
    const [modalContent, setModalContent] = useState(obModalContent);
    const [saveBtnDisplay, setSaveBtnDisplay] = useState(false)
    const [createBtnDisplay, setCreateBtnDisplay] = useState(false)
    const [deleteBtnDisplay, setDeleteBtnDisplay] = useState(false)
    //#endregion

    //#region 'FUNCTIONS'

    const doFilters = (e, list) => {
        let filters = updateFilters(e)

        let filteredResponses = Object.assign([], Array.isArray(list) ? list : responses)
        filteredResponses = filterResponsesByType(filteredResponses, filters)
        filteredResponses = filterResponses(filteredResponses, filters)

        setFilteredResponses(filteredResponses)
    }

    const updateFilters = e => {
        try {
            let name = e.target.name
            let tmpFilters = Object.assign({}, stateFilter)
            switch (name) {
                case "textFilter":
                    setStateFilter({
                        ...stateFilter, textFilter: e.target.value
                    })
                    tmpFilters.textFilter = e.target.value
                    break;
                case "typeFilter":
                    setStateFilter({
                        ...stateFilter, typeFilter: e.value
                    })
                    tmpFilters.typeFilter = e.value
                    break;
                default:
                    break;
            }
            return tmpFilters
        } catch (ex) {
            return stateFilter;
        }
    }

    const filterResponsesByType = (list, filters) => {
        let filtered = list.filter(storedResponse => {
            if (
                storedResponse.id_chats_responses_recorded_types === filters.typeFilter ||
                filters.typeFilter === -1
            ) {
                return true
            }
        })

        return filtered
    }

    const filterResponses = (list, filters) => {
        let filtered = list.filter(storedResponse => {
            if (
                storedResponse.Response.toLowerCase().includes(filters.textFilter.toLowerCase())
            ) {
                return true
            }
        })
        return filtered
    }

    const createStoredQuestion = async () => {
        if (await createStoredResponse(modalContent)) {
            let storedResponses = await retrieveReturnStoredResponses()
            setResponses(storedResponses)
            doFilters(null, storedResponses)
            closeModal()
        }
    }

    const deleteStoredQuestion = async () => {
        if (await removeStoredResponse(modalContent)) {
            let storedResponses = await retrieveReturnStoredResponses()
            setResponses(storedResponses)
            doFilters(null, storedResponses)
            closeModal("closeDeleteStoredResponse")
        }
    }

    const updateStoredQuestion = async () => {
        if (await updateStoredResponse(modalContent)) {
            let storedResponses = await retrieveReturnStoredResponses()
            setResponses(storedResponses)
            doFilters(null, storedResponses)
            closeModal()
        }
    }

    const closeModal = (modalCloseButtonId = "closeStoredResponseModal") => {
        document.getElementById(modalCloseButtonId).click()
        setContentDisabled(false)
        setModalVisibility(false)
        setModalContent({
            id: "",
            response: "",
            visible: 0,
            created: "",
            modified: "",
            storedTypeId: "",
            idCreator: "",
            idModifier: ""
        })
    }

    const openModalCreate = () => {
        setContentDisabled(false)
        setCreateBtnDisplay(true)
        setDeleteBtnDisplay(false)
        setSaveBtnDisplay(false)

        setModalVisibility(true)
        setModalTitle("Crear Respuesta")
        setModalConfirmText("Crear")
        var today = new Date();
        if (userData.idRole === 1) {
            setModalContent({
                response: "",
                visible: 1,
                created: today,
                storedTypeId: storedResponsesTypes[0].id,
                idCreator: userData.id,
            })
        } else {
            setModalContent({
                response: "",
                visible: 0,
                created: today,
                storedTypeId: storedResponsesTypes[0].id,
                idCreator: userData.id,
            })
        }

    }

    const openModalModify = (id, response, visible, storedTypeId, created, modified, idModifier, idCreator) => {
        setContentDisabled(false)
        setSaveBtnDisplay(true)
        setCreateBtnDisplay(false)
        setDeleteBtnDisplay(false)
        setModalVisibility(true)
        setModalTitle("Modificar Respuesta")
        setModalConfirmText("Guardar")
        var today = new Date();
        setModalContent({
            id: id,
            response: response,
            visible: visible,
            storedTypeId: storedTypeId,
            created: created,
            modified: today,
            idCreator: idCreator,
            idModifier: userData.id
        })
        //SwitchChange();
    }

    const openModalDelete = (id, Response, visible, storedTypeId, created, modified, idModifier, idCreator) => {
        setSaveBtnDisplay(false)
        setCreateBtnDisplay(false)
        setDeleteBtnDisplay(true)
        setModalVisibility(true)
        setContentDisabled(true)
        setModalTitle("Eliminar Respuesta")
        setModalConfirmText("Eliminar")
        setModalContent({
            id: id,
            response: Response,
            visible: visible,
            storedTypeId: storedTypeId,
            created: created,
            modified: modified,
            idCreator: idCreator,
            idModifier: idModifier
        })
        //SwitchChange();
    }

    const SelectChange = e => {
        const label = e.label
        const value = e.value

        setModalContent({ ...modalContent, storedTypeId: value })
    }
    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value

        setModalContent({ ...modalContent, [name]: value })

    }

    const SwitchChange = () => {
        if (modalContent.visible === "0") {
            setModalContent({ ...modalContent, visible: 1 })
        } else {
            setModalContent({ ...modalContent, visible: 0 })
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (res.idRole === 3) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        userContext.retrieveUserData().then(async res => {
            if (res.idRole === 3) {
                history.push("/main-screen/cases")
            } else {
                let storedResponses = await retrieveReturnStoredResponses()
                setResponses(storedResponses)
                setFilteredResponses(storedResponses)
                let tmpStoredResponsesTypes = await retrieveStoredResponsesTypes()

                setStoredResponsesTypes(tmpStoredResponsesTypes.map(storedResponseType => {
                    storedResponseType.value = storedResponseType.id
                    storedResponseType.label = storedResponseType.Name
                    return storedResponseType
                }))

                let tmpUserData = await userContext.retrieveUserData()
                setUserData(tmpUserData)
            }
        })
    }, [])
    //#endregion

    return (
        <>
            {userData.idRole === 3 ?
                history.push('/main-screen/cases') :
                <StoredResponsesScreenUI
                    userData={userData}
                    SwitchChange={SwitchChange}
                    storedResponsesTypes={storedResponsesTypes}
                    StoredQuestions={StoredQuestions}
                    openModalCreate={openModalCreate}
                    openModalModify={openModalModify}
                    openModalDelete={openModalDelete}
                    modalContent={modalContent}
                    modalTitle={modalTitle}
                    modalConfirmText={modalConfirmText}
                    modalVisibility={modalVisibility}
                    contentDisabled={contentDisabled}
                    closeModal={closeModal}
                    btnsDisplay={{
                        createBtn: createBtnDisplay,
                        saveBtn: saveBtnDisplay,
                        deleteBtn: deleteBtnDisplay,
                    }}
                    handleInputChange={handleInputChange}
                    actions={{
                        create: createStoredQuestion,
                        update: updateStoredQuestion,
                        delete: deleteStoredQuestion
                    }}
                    filterResponses={filterResponses}
                    filteredResponses={filteredResponses}
                    SelectChange={SelectChange}
                    cbfilterObj={cbfilterObj}
                    views={{
                        type: views,
                        funcKanban: kanbanFunction,
                        funcList: listFunction
                    }}
                    doFilters={doFilters}
                />}
        </>
    )


}

export { StoredResponsesScreen }