import React, { useReducer } from 'react'
import { FileContext } from "./FileContext"
import FileReducer from "./FileReducer"

const FileState = (props) => {

    //#region 'VARIABLES'
    const initialState = {
        fileData: []
    }

    const files = []
    //#endregion

    //#region 'USE REDUCER'
    const [state, dispatch] = useReducer(FileReducer, initialState)
    //#endregion

    //#region 'FUNCTIONS'
    const fillArrayFiles = (fileData) => {
        files.push(fileData)
        return files
    }

    const clearArrayFiles = () => {
        files.length = 0
    }


    //#endregion

    return <FileContext.Provider
        value={{
            files: files,
            fillFiles: fillArrayFiles,
            clearArrayFiles: clearArrayFiles,
        }}>
        {props.children}
    </FileContext.Provider>
}

export { FileState }