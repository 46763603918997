import { useState, useEffect } from 'react';
import { getCasesAssessments } from '../Services/CasesAssessments/getCasesAssessments'
import { getCasesAssessmentsByUser } from '../Services/CasesAssessments/getCasesAssessmentsByUser';
import { getCountAssessments } from '../Services/CasesAssessments/getCountAssessments';
import { postCasesAssessments } from '../Services/CasesAssessments/postCasesAssessments'
import { putCasesAssessments } from '../Services/CasesAssessments/putCasesAssessments'

const useCaseAssesssments = (idUser = undefined, idRole = undefined) => {

    const [caseAssessments, setCaseAssessments] = useState([])

    const getDataCaseAssements = async (id_cases) => {
        return await getCasesAssessments(id_cases)
    }

    const getDataCaseAssementsByUser = async (id_user, id_role) => {
        return await getCasesAssessmentsByUser(id_user, id_role)
    }

    const insertCaseAssessments = async (data) => {
        return await postCasesAssessments(data)
    }

    const updateCaseAssessments = async (data) => {
        return await putCasesAssessments(data)
    }

    const countCasesAssessment = async (idUser, idRole) => await getCountAssessments(idUser, idRole)

    useEffect(async () => {
        if (idUser && idRole) {
            setCaseAssessments(await getDataCaseAssementsByUser(idUser, idRole))
        }
    }, [])

    return { caseAssessments, setCaseAssessments, getDataCaseAssements, insertCaseAssessments, updateCaseAssessments, countCasesAssessment }
}

export { useCaseAssesssments }