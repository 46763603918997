import React, { useEffect, useState, useContext } from 'react'
import RouteCtrl from '../../routes/Routes'
import { CasesTypesScreenUI } from './CasesTypesScreenUI'
import { MessageService } from '../../Services/message_service'
import { useHistory } from 'react-router-dom'
import { useTeachers } from '../../Hooks/useTeachers'
import { ToastContainer } from 'react-toastify'
import { UserContext } from '../../Contexts/User/UserContext'
import useCasesTypes from '../../Hooks/useCasesTypes'
import { useShowView } from '../../Hooks/useShowView'

const CasesTypesScreen = () => {
    //#region 'VARIBALES'
    let history = useHistory()
    const cbfilterObj = [{ value: 1, name: 'Nombre' },]
    const obModalContent = {
        id: "",
        name: "",
        abbreviation: "",
        color: "#443F3B"
    }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'CUSTOMIZED USE STATE'
    const { retrieveCasesTypes, createCaseType, updateCaseType, removeCaseType } = useCasesTypes()
    const { teachers } = useTeachers()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //#endregion

    //#region 'USE STATE'
    const [modalVisibility, setModalVisibility] = useState(false)
    const [contentDisabled, setContentDisabled] = useState(false)
    const [casesTypes, setCasesTypes] = useState([])
    const [modalTitle, setModalTitle] = useState("")
    const [modalConfirmText, setModalConfirmText] = useState("")
    const [modalContent, setModalContent] = useState(obModalContent)
    const [saveBtnDisplay, setSaveBtnDisplay] = useState(false)
    const [createBtnDisplay, setCreateBtnDisplay] = useState(false)
    const [deleteBtnDisplay, setDeleteBtnDisplay] = useState(false)
    const [filteredCasesTypes, setFilteredCasesTypes] = useState([])
    //#endregion

    //#region 'FUCNTIONS'
    const filterCasesTypes = (e, updatedCasesTypes) => {
        try {
            let name = e.target.name
            let value = e.target.value

            if (name == "textFilter") {
                setFilteredCasesTypes(casesTypes.filter(caseType => caseType.Name.toLowerCase().includes(value.toLowerCase())))
            }
        } catch (e) {
            setFilteredCasesTypes(updatedCasesTypes)
        }
    }

    const openModalCreate = () => {
        setCreateBtnDisplay(true)
        setDeleteBtnDisplay(false)
        setSaveBtnDisplay(false)

        setModalVisibility(true)
        setModalTitle("Crear tipo de caso")
        setModalConfirmText("Crear")
    }

    const create = async () => {
        if (await createCaseType(modalContent)) {
            await getCases()
            document.getElementById("closeCaseTypeModal").click()
        }
    }

    const update = async () => {
        if (await updateCaseType(modalContent)) {
            await getCases()
            document.getElementById("closeCaseTypeModal").click()
        }
    }

    const deleteCaseType = async () => {
        if (await removeCaseType(modalContent.id)) {
            setModalContent({id: "",name: "",color: ""})
            await getCases()
            document.getElementById("closeDeleteCaseTypeModal").click()
        }
    }

    const openModalModify = (id, name, abbreviation, color) => {
        setSaveBtnDisplay(true)
        setCreateBtnDisplay(false)
        setDeleteBtnDisplay(false)
        setModalVisibility(true)
        setModalTitle("Modificar tipo de caso")
        setModalConfirmText("Guardar")
        setModalContent({id,name,abbreviation,color})
    }

    const openModalDelete = () => {
        setContentDisabled(true)
        setDeleteBtnDisplay(true)
        setSaveBtnDisplay(false)
        setCreateBtnDisplay(false)
        setModalVisibility(true)
        setModalTitle("Eliminar tipo de caso")
        setModalConfirmText("Borrar")
    }

    const deleteTypeCase = () => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open("DELETE", RouteCtrl.deleteCaseType)
        xhr.withCredentials = true
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify({ id: modalContent.id }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                messageService.printToast(res.scope, res.type, res.code)
                if (res.type == "success") {
                    // setCasesTypes(await retrieveCasesTypes())
                    setModalVisibility(false)
                    setModalContent({
                        id: "",
                        name: ""
                    })
                    setContentDisabled(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
        }
    }

    const closeModal = () => {
        setContentDisabled(false)
        setModalVisibility(false)
        setModalContent({
            id: "",
            name: "",
            abbreviation: "",
            color: "#443F3B"
        })
    }

    const handleInputChange = e => {
        const name = e.target.name
        const value = e.target.value
        setModalContent({ ...modalContent, [name]: value })
    }

    const getCases = async () => {
        let cases = await retrieveCasesTypes()
        setCasesTypes(cases)
        setFilteredCasesTypes(cases)
    }
    //#endregion

    //#region 'USE EFFECT AND USE LAYOUT EFFECT'    
    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        if (!await userContext.checkSession()) {
            history.push("/")
            window.location.reload()
        } else if ((await userContext.retrieveUserData()).idRole == 1) {
            await getCases()
        } else {
            history.push("/main-screen/cases")
        }
    }, [])
    //#endregion

    return (
        <>
            <ToastContainer />
            <CasesTypesScreenUI
                casesTypes={casesTypes}
                openModalCreate={openModalCreate}
                openModalModify={openModalModify}
                openModalDelete={openModalDelete}
                modalContent={modalContent}
                modalTitle={modalTitle}
                modalConfirmText={modalConfirmText}
                modalVisibility={modalVisibility}
                contentDisabled={contentDisabled}
                closeModal={closeModal}
                btnsDisplay={{
                    createBtn: createBtnDisplay,
                    saveBtn: saveBtnDisplay,
                    deleteBtn: deleteBtnDisplay,
                }}
                handleInputChange={handleInputChange}
                actions={{
                    delete: deleteTypeCase,
                }}
                filterCasesTypes={filterCasesTypes}
                filteredCasesTypes={filteredCasesTypes}
                cbfilterObj={cbfilterObj}
                create={create}
                update={update}
                deleteCaseType={deleteCaseType}
                teachers={teachers}
                views={{
                    type: views,
                    funcKanban: kanbanFunction,
                    funcList: listFunction
                  }}
            />
        </>
    )
}

export { CasesTypesScreen }