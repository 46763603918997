import React, { useReducer } from 'react'

import { UserContext } from './UserContext'
// import { UserReducer } from './UserReducer'
import UserReducer from "./UserReducer"

import { getUserData } from '../../Services/Users/getUserData'
import { getUserById } from './requests/getUserById'
import { getUserByCase } from './requests/getUserByCase'
import { checkToken } from './requests/checkToken'

const UserState = (props) => {
    const initialState = {
        userData: {}
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)

    const getData = async () => {
        let userData = await getUserData()
        dispatch({ type: "GET_USER", payload: userData })
        return userData
    }

    const retrieveUserData = async () => {
        if (Object.keys({}).length > 0) {
            return state
        } else {
            return getData()
        }
    }

    const retrieveUserById = async (idUser) => {
        return await getUserById(idUser)
    }

    const retrieveUserByCase = async (role, idCase) => {
        return await getUserByCase(role, idCase)
    }

    const checkSession = async () => {
        return await checkToken()
    }

    return (
        <UserContext.Provider
            value={{
                userData: state.userData,
                retrieveUserData,
                retrieveUserByCase,
                checkSession
            }}>
            {props.children}
        </UserContext.Provider>
    )
}
export { UserState }