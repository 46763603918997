import { MessageService } from '../../Services/message_service'
import RouterCtrl from '../../routes/Routes'

export const postDiagnosis = async (data, id) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.diagnosis)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ diagnosis: data, id: id }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                switch (res.type) {
                    case "success":
                        resolve(true)
                        break;

                    case "error":
                        resolve(false)
                        break;
                    default:
                        resolve(false)
                        break;
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
        }
    })
}