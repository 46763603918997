export default (state, action) => {
    const { payload, type } = action

    switch (type) {
        case "SET_VISIBILITY":
            return {
                ...state,
                visibility: payload
            }

        default:
            return state;
    }
}