//#region "IMPORTS"
import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCase } from '../../Hooks/useCase'
import useCasesTypes from '../../Hooks/useCasesTypes'
import useEditions from '../../Hooks/useEditions'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from '../../Contexts/User/UserContext'
import { SearchContext } from '../../Contexts/Search/SearchContext'
import { CasesScreenUI } from './CasesScreenUI'
import { useShowView } from '../../Hooks/useShowView'
import { CasesFilter } from '../Tools/GeneralFunctions/CasesFilter'
import { useShowNotificationUser } from '../../Hooks/useShowNotificationUser'
import { ElimiarDiacriticos } from '../Tools/GeneralFunctions/EliminarDiacriticos'
//#endregion

const CasesScreen = () => {

    //#region "VARIABLES"
    let history = useHistory()
    /*cbfilterObj -> forbidden : 1:ADMIN , 2:PROFESOR, 3:DOCTOR/ALUMNO */
    const cbfilterObj = [
        { value: '1', name: 'Código de caso', forbidden: [1, 2, 3] },
        { value: '2', name: 'Profesor', forbidden: [1] },
        { value: '3', name: 'Doctor', forbidden: [1, 2] },
        { value: '4', name: 'Nombre Invisalign', forbidden: [1, 2, 3] },
        { value: '5', name: 'ID Invisalign', forbidden: [1, 2, 3] }
    ]
    const cbStateFilter = [
        { value: 0, label: "Todos", target: { name: "stateFilter" } },
        { value: 1, label: "Abierto", target: { name: "stateFilter" } },
        { value: 2, label: "Asignado", target: { name: "stateFilter" } },
        { value: 3, label: "En Curso", target: { name: "stateFilter" } },
        { value: 4, label: "Cerrado", target: { name: "stateFilter" } },
        { value: 5, label: "Urgente", target: { name: "stateFilter" } },
        { value: 6, label: "En Espera", target: { name: "stateFilter" } },
    ]
    const DEFAULT_VALUE_DP = { value: -1, label: "Todos" }
    const obFilter = {
        valueDp: '1',
        textFilter: '',
        requestedRevision: false,
        stateFilter: { value: 0, label: "Todos" },
        caseTypeFilter: DEFAULT_VALUE_DP,
        caseEditionFilter: DEFAULT_VALUE_DP
    }
    //#endregion

    //#region "USE CONTEXT"
    const userContext = useContext(UserContext)
    const searchContext = useContext(SearchContext)
    //#endregion

    //#region "USE STATES"
    const { views, kanbanFunction, listFunction, setViews } = useShowView({ visibleList: false })
    const { retrieveAllCases, retrievesTeacherAssignCases, retrieveUserCases, getDataCountCasesOnHold } = useCase()
    const { casesTypesDp } = useCasesTypes({ onlySearch: true })
    const { casesEditionsCases } = useEditions({ onlySearchCases: true })
    const [userData, setUserData] = useState({})
    const [cases, setCases] = useState([])
    const [stateFilter, setFilter] = useState(obFilter)
    const { getDataShowNotificationUser, updateDataShowNotificationUser } = useShowNotificationUser()
    const [showNotification, setShowNotification] = useState({})

    //#endregion

    //#region "FUNCTIONS"
    const getCases = async (tmpUserData) => {
        switch (tmpUserData.idRole) {
            case 1:
                setCases(await retrieveAllCases())
                break;
            case 2:
                setCases(await retrievesTeacherAssignCases())
                break;
            default:
                setCases(await retrieveUserCases())
                break;
        }
    }

    const handleInputChange = (e) => {
        const name = e.target?.name || e.type
        const value = name === 'textFilter' ? e.target.value
            : e.target?.value || { value: e.value, label: e.label }
        setFilter({ ...stateFilter, [name]: value })
    }

    const onFilter = (values) => {
        switch (stateFilter.valueDp) {
            case '1':
                return CasesFilter(stateFilter.textFilter, values, values.Case_code.toString())
            case '2':
                return CasesFilter(ElimiarDiacriticos(stateFilter.textFilter), values, ElimiarDiacriticos(values.Teacher))
            case '3':
                return CasesFilter(ElimiarDiacriticos(stateFilter.textFilter), values, ElimiarDiacriticos(values.Student))
            case '4':
                return CasesFilter(stateFilter.textFilter, values, values.Patient_name_invisalign)
            case '5':
                let idPatient = values.Patient_ID_invisalign ? values.Patient_ID_invisalign.toString() : ''
                return CasesFilter(stateFilter.textFilter, values, idPatient)
            default:
                return values
        }
    }

    const filterByState = obj => {
        if (stateFilter.stateFilter.value === 0) {
            return obj
        } else if (stateFilter.stateFilter.value === 5) {
            return obj?.Priority !== null && obj?.id_states !== 4 ? obj : false
        } else if (obj?.id_states === 5 && stateFilter.stateFilter.value === 6) {
            return obj?.id_states === 5 ? obj : false
        } else {
            return obj?.id_states === stateFilter.stateFilter.value ? obj : false
        }
    }

    const filterByType = obj => {
        if (stateFilter.caseTypeFilter.value === DEFAULT_VALUE_DP.value) {
            return obj
        } else {
            return obj?.id_cases_type === stateFilter.caseTypeFilter.value ? obj : false
        }
    }

    const filterByEdition = obj => {
        if (stateFilter.caseEditionFilter.value === DEFAULT_VALUE_DP.value) {
            return obj
        } else {
            return obj?.id_editions === stateFilter.caseEditionFilter.value ? obj : false
        }
    }

    const doFilters = e => {
        let value = onFilter(e)
        value = filterByState(value)
        value = filterByType(value)
        value = filterByEdition(value)
        return value
    }

    const handleNoNotificationOnHold = () => {
        if (showNotification) {
            updateDataShowNotificationUser(userData.id, 0)
            document.getElementById("triggerModalCasesOnHold").click()
        }
    }
    //#endregion

    //#region "USE EFFECTS"
    useLayoutEffect(() => {
        if (Object.keys(searchContext.search).length > 0) {
            setFilter(searchContext.search)
        }
        if (Object.keys(searchContext.typeViews).length > 0) {
            setViews(searchContext.typeViews)
        }
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        if (!await userContext.checkSession()) {
            history.push("/")
            window.location.reload()
        }
        document.querySelector('.modal-backdrop')?.remove()
        let tmpUserData = await userContext.retrieveUserData()
        if (tmpUserData.idRole === 3) {
            let count_onHold = await getDataCountCasesOnHold(tmpUserData.id)
            let dataNotification = await getDataShowNotificationUser(tmpUserData.id)
            setShowNotification(dataNotification)
            if (count_onHold) {
                if (count_onHold > 0 && Boolean(dataNotification.show_notification)) {
                    document.getElementById("triggerModalCasesOnHold").click()
                }
            }
        }
        setUserData(tmpUserData)
        getCases(tmpUserData)

    }, [])
    //#endregion
    return (
        <CasesScreenUI
            userData={userData}
            cases={cases}
            filters={{
                dpFiledsFilters: cbfilterObj.filter(el => el.forbidden.includes(userData.idRole)),
                state: cbStateFilter,
                casesTypes: casesTypesDp,
                editions: casesEditionsCases,
                selFilter: stateFilter,
                defaultValueDP: DEFAULT_VALUE_DP,
                onchange: handleInputChange,
                doFilter: doFilters
            }}
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
            handleNoNotificationOnHold={handleNoNotificationOnHold}
        />
    )
}

export { CasesScreen }
