//#region 'IMPORTS'
import { useState, useEffect } from 'react';
import { deleteCasesPackDuration } from '../Services/CasesPacksDuration/deleteCasesPackDuration';
import { getCasesPacksDuration } from '../Services/CasesPacksDuration/getCasesPacksDuration';
import { getCheckCasesPackDuration } from '../Services/CasesPacksDuration/getCheckCasesPackDuration';
import { postCasesPacksDuration } from '../Services/CasesPacksDuration/postCasesPacksDuration';
import { putCasesPacksDuration } from '../Services/CasesPacksDuration/putCasesPacksDuration';
//#endregion

const useCasesPacksDuration = () => {

    //#region 'USE STATES'
    const [casesPacksDuration, setCasesPacksDuration] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getData = async () => {
        return await getCasesPacksDuration()
    }

    const fillSetState = async () => {
        const doc = []
        let result = await getData()
        if (Array.isArray(result)) {
            result.forEach(el => {
                doc.push({
                    value: el.id,
                    label: el.name,
                    id: el.id,
                    name: el.name,
                    duration: el.duration,
                    target: { name: "packDuration" }
                })
            })
        }
        setCasesPacksDuration(doc)
    }

    const insertCasesPacksDuration = async (data) => {
        return postCasesPacksDuration(data)
    }

    const updateCasesPacksDuration = async (data) => {
        return putCasesPacksDuration(data)
    }

    const deleteItemCasesPacksDuration = async (id) => {
        return deleteCasesPackDuration(id)
    }

    const checkAssignmentCasesPackDuration = async (id) => {
        return getCheckCasesPackDuration(id)
    }
    //#endregion

    //#region 'USE EFFECT'
    useEffect(() => {
        fillSetState()
    }, [])
    //#endregion

    return {
        casesPacksDuration,
        setCasesPacksDuration,
        getData,
        insertCasesPacksDuration,
        updateCasesPacksDuration,
        deleteItemCasesPacksDuration,
        checkAssignmentCasesPackDuration
    }

}

export { useCasesPacksDuration }