const ipWS = 'https://oifformacion.dyndns.org:';
const portWS = 4000;
const route = ipWS + portWS + '/api'


const RouterCtrl = {
    login: route + '/authenticate/login',
    register: route + '/authenticate/register',
    closeSession: route + '/authenticate/close',
    activate: route + '/authenticate/activate',
    recoverPassword: route + '/authenticate/recover-password',
    setPassword: route + '/authenticate/set-password',
    users: route + '/authenticate/get-users',
    user: route + '/authenticate/get-user-data',
    checkToken: route + '/authenticate/check-token',
    createUserAdmin: route + '/authenticate/create-admin',
    getRoles: route + '/roles',
    diagnosis: route + '/diagnosis',
    diagnosisCase: route + '/diagnosis/case',
    diagnosisOther: route + '/diagnosis/other',
    getCase: route + '/cases/case',
    closeCase: route + '/cases/close',
    setCaseOnHold: route + '/cases/case/on-hold',
    setCaseInProgress: route + '/cases/case/in-progress',
    getCaseData: route + '/cases/case/data',
    getAllCases: route + '/cases/all',
    getUserCases: route + '/cases/user',
    getTeacherAssignCases: route + '/cases/teacher',
    assignCase: route + '/cases/case/assign',
    assignNotify: route + '/cases/case/assign/notify',
    createCase: route + '/cases/create',
    deleteCase: route + '/cases/',
    revisionCaseActivate: route + '/cases/case/revision/activate',
    revisionCaseRequest: route + '/cases/case/revision',
    cancelRevisionCase: route + '/cases/case/revision/cancel',
    cancelRevisionRequestCase: route + '/cases/case/revision/cancel/request',
    updateCaseAdmin: route + '/cases/update/admin',
    updateCaseUser: route + '/cases/update',
    getCasesStates: route + '/casesStates',
    caseType: route + '/casesTypes',
    getAllCasesTypes: route + '/casesTypes/all',
    getCasesPriorities: route + '/casesPriorities',
    getCasesEditions: route + '/editions',
    getUserEditionsByUserId: route + '/editions/get-user-editions',
    getUserById: route + '/authenticate/get-user',
    getUserByCase: route + '/users/get-user-case',
    updateUser: route + '/users',
    deleteUser: route + '/users',
    updateOwnUser: route + '/users/update',
    getTeachers: route + '/authenticate/get-teachers',
    getTeachersStats: route + '/authenticate/get-teachers-stats',
    filterTeachersStats: route + '/authenticate/filter-teachers-stats',
    getStudents: route + '/authenticate/get-students',
    resendEmail: route + '/authenticate/resend',
    faqs: route + '/faqs',
    getFaqsList: route + '/faqs/list',
    getFaq: route + '/faqs/get',
    faqsTypes: route + '/faqsType',
    chats: route + '/chat',
    responses: route + '/responses',
    doubts: route + '/doubts',
    getDoubtsByCase: route + '/doubts/case',
    doubtsTypes: route + '/doubtsTypes',
    uploadFile: route + '/files',
    retrieveFile: route + '/files/retrieve',
    retrieveFilesInfo: route + '/files/retrieve/info',
    downloadFile: route + '/files/download',
    downloadPath: route + '/files/download-path',
    editions: route + '/editions',
    archiveEdition: route + '/editions/archive',
    unarchiveEdition: route + '/editions/unarchive',
    storedResponses: route + '/stored-responses',
    getStoredResponsesByType: route + '/stored-responses/by-type',
    storedResponsesTypes: route + '/stored-responses-types',
    userEditions: route + '/user-editions',
    casesTeachers: route + '/cases-teachers',
    casesupdatePriority: route + '/cases/update/priorities',
    responsesRating: route + '/responses/response-rating',
    countResponsesRating: route + '/responses/count-response-rating',
    ratings: route + '/responses/ratings',
    caseAssessments: route + '/cases/cases-assessments',
    countCaseAssessments: route + '/cases/count-cases-assessments',
    assessments: route + '/cases/assessments',
    reassignCaseRequest: route + '/cases/case/reassign',
    observations: route + '/observations',
    reasonWaiting: route + '/reason-waiting',
    casesReasonWaiting: route + '/cases/reason-waiting',
    casesReasonWaiting_insertGet: route + '/cases/reason-waiting/insert-get',
    casesReasonWaiting_updateGet: route + '/cases/reason-waiting/update-get',
    forceCasesReasonWaiting: route + '/cases/force-reason-waiting',
    countCasesOnHold: route + '/cases/count-on-hold',
    countCasesByUser: route + '/cases/count-case-user',
    showNotification: route + '/show-notification',
    lopd: route + '/lopd',
    lopdEditor: route + '/lopd/editor/',
    packs: route + '/packs',
    packs_activated: route + '/packs/activated',
    packs_duration: route + '/packs-duration',
    packs_duration_check: route + '/packs-duration/check-assignment',
    cases_users_packs: route + '/cases-users-packs',
    cases_distribution: route + '/cases-distribution',
    cases_distribution_teachers: route + '/cases-distribution/teachers',

    audioResponse : route + '/audio-response'
}

export default RouterCtrl;