import React, { useState, forwardRef, useImperativeHandle } from 'react'
import ButtonModal from './ButtonModal'
import { DisplayImageUser } from './DisplayImageUser'
import FullscreenImage from './FullscreenImage'
import noPhoto from '../../img/silueta.png'

const SimpleBootstrapModal = forwardRef((props, ref) => {
    const {
        id,
        title,
        imageUrl,
        message,
        footerButtons,
        headerButtons,
        children,
        closeFunction,
        modalClasses,
        fullScreen,
        closeButtonId,
        bodyButtons,
        bodyClose,
        footerVisibility,
        footerCancelButton = true,
        cancelButtonMessage = "Cancelar",
        bodyCSS,
        customCloseButton,
    } = props

    const [footerDisplay, setFooterDisplay] = useState(footerVisibility ?? true)
    const [fullscreenImageBlob, setFullscreenImageBlob] = useState("")
    useImperativeHandle(
        ref,
        () => ({
            showFooter() {
                setFooterDisplay(true)
            },
            hideFooter() {
                setFooterDisplay(false)
            }
        }),
    )

    const handleImge = (img) => {
        let urlCreator = window.URL || window.webkitURL
        if (Number.isInteger(img?.size) && img.size !== 68) {
            let imageUrl = urlCreator.createObjectURL(img)
            setFullscreenImageBlob(imageUrl)
        }else{
            setFullscreenImageBlob(noPhoto)
        }
    }

    return (
        <>
            <button id={"idOpenModal"} data-bs-toggle="modal" data-bs-target={"#" + id} className="none"/>
            {
                imageUrl === undefined ? null : <FullscreenImage blob={fullscreenImageBlob} previousModalButtonOpenerId={"idOpenModal"} />
            }
            <div className={`modal fade${modalClasses ? ` ${modalClasses}` : ""}`} id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered${fullScreen ? " modal-fullscreen" : ""}`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className='d-flex'>
                                <div onClick={() => handleImge(imageUrl)}>
                                    {
                                        imageUrl === undefined ? null : <DisplayImageUser userBlob={imageUrl} idDiplayBig={true} />
                                    }
                                </div>
                                <h4 className="modal-title" style={{ margin: 'auto' }} id="staticBackdropLabel">{title}</h4>
                            </div>
                            <div>
                                {headerButtons}
                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={closeFunction}></button>
                            </div>
                        </div>
                        <div className="modal-body" style={bodyCSS}>
                            {bodyButtons}
                            {bodyClose ? <ButtonModal id={closeButtonId} text={cancelButtonMessage} bsDimiss={"modal"} close={true} onClick={closeFunction} /> : null}
                            {message || children}
                        </div>
                        {footerDisplay ?
                            <div className="modal-footer">
                                {!customCloseButton ? <ButtonModal id={closeButtonId} text={cancelButtonMessage} bsDimiss={"modal"} close={true} onClick={closeFunction} style={{
                                    visibility: footerCancelButton ? "visible" : "hidden"
                                }} /> : customCloseButton}
                                {footerButtons}
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </>
    )
})

export default SimpleBootstrapModal