import React, { useReducer } from 'react'
import { SearchContext } from './SearchContext'
import SearchReducer from './SearchReducer'

const SearchState = (props) => {
    const initialState = {
        searchData: {},
        searchUser: {},
        typeViews : {},
    }

    const [state, dispatch] = useReducer(SearchReducer, initialState)
    const setSearchData = (searchData) => {
        dispatch({ type: "SET_SEARCH", payload: searchData })
    }
    const setSearchUser = (searchUser) => {
        dispatch({ type: "SET_SEARCH_USER", payload: searchUser })
    }
    const setTypeViews = (typeViews) => {
        dispatch({ type: "SET_TYPE_VIEWS", payload: typeViews })
    }

    return <SearchContext.Provider
        value={{
            search: state.searchData,
            setSearchData: setSearchData,
            searchUser: state.searchUser,
            setSearchUser: setSearchUser,
            typeViews: state.typeViews,
            setTypeViews : setTypeViews
        }}>
        {props.children}
    </SearchContext.Provider>
}
export { SearchState }