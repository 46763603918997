//#region 'IMPORTS'
import React, { useEffect, useState, useLayoutEffect, useContext } from 'react'
import { MessageService } from '../../Services/message_service'
import { StoredResponsesTypesScreenUI } from './StoredResponsesTypesScreenUI'
import { useStoredResponsesTypes } from '../../Hooks/useStoredResponsesTypes'
import { UserContext } from '../../Contexts/User/UserContext'
import { useHistory } from 'react-router-dom'
import { useShowView } from '../../Hooks/useShowView'
//#endregion

export const StoredResponsesTypesScreen = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const obFilter = {
        valueDp: '1',
        textFilter: ''
    }
    const obModalContent = { id: "", name: "" }
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'CUSTOMIZED USE STATES'
    const { createStoredResponseType, retrieveStoredResponsesTypes,
        updateStoredResponseType, removeStoredResponseType } = useStoredResponsesTypes()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //#endregion

    //#region 'USE STATES'
    const [title, setTitle] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [isCreating, setIsCreating] = useState(false)
    const [storedResponsesTypes, setStoredResponsesTypes] = useState([])
    const [modalContent, setModalContent] = useState(obModalContent)
    const [stateFilter, setFilter] = useState(obFilter);
    //#endregion


    //#region 'FUNCTIONS'
    const openModalModify = e => {
        setIsCreating(false)
        setButtonText("Guardar")
        setTitle("Modificar tipo de respuesta")
        setModalContent({ ...modalContent, id: e.id, name: e.Name })
    }

    const openModalCreate = () => {
        setIsCreating(true)
        setButtonText("Crear")
        setTitle("Crear tipo de respuesta")
        setModalContent({
            id: "",
            name: ""
        })
    }

    const handleInputChange = e => {
        let name = e.target.name
        let value = e.target.value

        setModalContent({ ...modalContent, [name]: value })
    }

    const handleCreate = async () => {
        if (modalContent.name) {
            let result = await createStoredResponseType(modalContent)
            if (result) {
                setStoredResponsesTypes(await retrieveStoredResponsesTypes())
                document.getElementById("closeStoredResponseTypeModal").click()
            }
        } else {
            let messageService = new MessageService()
            messageService.printToast("Generics", "error", 1)
        }
    }

    const handleUpdate = async () => {
        if (
            Number.isInteger(modalContent.id) &&
            modalContent.name
        ) {
            let result = await updateStoredResponseType(modalContent)
            if (result) {
                setStoredResponsesTypes(await retrieveStoredResponsesTypes())
                document.getElementById("closeStoredResponseTypeModal").click()
            }
        } else {
            let messageService = new MessageService()
            messageService.printToast("Generics", "error", 1)
        }
    }

    const handleDelete = async () => {
        if (await removeStoredResponseType(modalContent.id)) {
            setStoredResponsesTypes(await retrieveStoredResponsesTypes())
            document.getElementById("closeDeleteStoredResponseTypeModal").click()
            setModalContent({
                id: "",
                name: ""
            })
        }
    }

    const handleInputChangeFilter = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFilter({ ...stateFilter, [name]: value })
    }

    const onFilter = (values) => {
        switch (stateFilter.valueDp) {
            case '1':
                return casesFilter(values, values.Name)
            default:
                return values
        }
    }

    const casesFilter = (obj, item) => {
        if (!stateFilter.textFilter.trim()) {
            return obj
        } else if (item.toLowerCase().includes(stateFilter.textFilter.toLocaleLowerCase())) {
            return obj
        }
    }
    //#endregion

    //#region 'USE LAYOUT AND USE EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (res.idRole === 3) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        let tmpUserData = await userContext.retrieveUserData()
        if (
            !await userContext.checkSession() ||
            tmpUserData.idRole === 3
        ) {
            history.push("/main-screen/cases")
        } else {
            setStoredResponsesTypes(await retrieveStoredResponsesTypes())
        }
    }, [])
    //#endregion

    return (
        <StoredResponsesTypesScreenUI
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
            cbfilterObj={cbfilterObj}
            storedResponsesTypes={storedResponsesTypes}
            modalContent={modalContent}
            buttonText={buttonText}
            handleInputChange={handleInputChange}
            title={title}
            openModalCreate={openModalCreate}
            openModalModify={openModalModify}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            isCreating={isCreating}
            handleDelete={handleDelete}
            onFilter={onFilter}
            handleInputChangeFilter={handleInputChangeFilter}
        />
    )
}