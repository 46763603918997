//#region 'IMPORTS'
import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react'
import { ObservationsFormUI } from './ObservationsFormUI'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../../Contexts/User/UserContext';
import { useObservations } from '../../../Hooks/useObservations';
//#endregion

const ObservationsForm = ({ caseRecord, userData, observationData, getObservations, observationFile, updateFunction }) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const objState = { editorState: '' }
    const objSelected = {
        idStoredResponseType: "",
        idStoredResponse: "",
        response: "",
        question: ""
    }
    const objSelectedResponse = {
        idStoredResponseType: "",
        idStoredResponse: "",
        response: "",
        typeResponse: ""
    }
    //#endregion

    //#region 'USE REF'
    const customFileInputRef = useRef()
    //#endregion

    //#region "USE CONTEXT"
    const userContext = useContext(UserContext)
    const { insertObservations, updateObservations } = useObservations()
    const [txtTypeObservation, setTxtTypeObservation] = useState('')
    //#endregion

    //#region 'USE STATE'
    const [stateEditor, setstateEditor] = useState(objState)
    const [fullscreenImageBlob, setFullscreenImageBlob] = useState("")
    const [ob, setOb] = useState('')
    const [response, setResponse] = useState("")
    const [editForm, setEditForm] = useState(observationData.getters.current?.id ? false : true)
    const [selectedFaq, setSelectedFaq] = useState(objSelected)
    const [selectedStoredResponse, setSelectedStoredResponse] = useState(objSelectedResponse)
    //#endregion

    //#region 'FUNCTIONS'
    const onEditorStateChange = (editorState) => {
        setstateEditor({
            editorState: editorState,
        });
        const html = draftToHtml(convertToRaw(stateEditor.editorState.getCurrentContent()))
        setOb(html)
    }

    const propertiesEditor = () => {
        const contentBlock = htmlToDraft('');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    const resetState = () => {
        setTxtTypeObservation('')
        setOb('')
        setSelectedFaq(objSelected)
        setSelectedStoredResponse(objSelectedResponse)
        observationData.setters.current({})
        customFileInputRef.current.forceEmptyList()
        setEditForm(true)
    }

    const handleCreateObservation = async () => {
        if (ob !== '') {
            let res = await insertObservations(caseRecord.id, ob, txtTypeObservation)
            if (res) {
                await customFileInputRef.current.uploadInMemoryFiles(res)
                getObservations()
                document.getElementById("triggerCreatedObservationModal").click()
                resetState()
            }
        }
    }

    const handleUpdateObservation = async () => {
        if (observationData.getters.current.id && ob !== '') {
            let res = await updateObservations(ob, observationData.getters.current.id, caseRecord.id, txtTypeObservation)
            if (res) {
                getObservations()
                document.getElementById("triggerModifiedObservationModal").click()
                resetState()
            }
        }
    }

    const handleEditForm = () => {
        setEditForm(false)
    }

    const handleFaqResponse = () => {
        let msg = "Te añado un enlace que te ofrecerá más información sobre: "
        setOb((ob === '' ? '' : ob + "<br/>") + msg + `<a target="_blank" href="https://oifformacion.dyndns.org//main-screen/faq/${selectedFaq.idStoredResponse}">${selectedFaq.question}</a>`)
        UpdateStateEditor((ob === '' ? '' : ob + "<br/>") + msg + `<a target="_blank" href="https://oifformacion.dyndns.org//main-screen/faq/${selectedFaq.idStoredResponse}">${selectedFaq.question}</a>`)
        setSelectedFaq(objSelected)
    }

    const handleStoredResponse = () => {
        setSelectedStoredResponse(objSelectedResponse)
        setOb(ob + "<br/>" + selectedStoredResponse.response)
        UpdateStateEditor((ob === '' ? '' : ob + "<br/>") + selectedStoredResponse.response)
    }
    //#endregion

    //#region 'USE EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        propertiesEditor()
        if (observationData?.getters?.current?.id) {
            setstateEditor({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(observationData.getters.current.observation))) })
            setOb(observationData?.getters?.current?.observation)
            setTxtTypeObservation(observationData?.getters?.current?.type_observation)
        }
    }, [observationData?.getters?.current])
    //#endregion


    return <ObservationsFormUI
        caseRecord={caseRecord}
        userData={userData}
        currentData={observationData}
        stateEditor={stateEditor}
        onEditorStateChange={onEditorStateChange}
        handleCreate={handleCreateObservation}
        handleUpdate={handleUpdateObservation}
        fullscreenImageBlob={fullscreenImageBlob}
        setFullscreenImageBlob={setFullscreenImageBlob}
        observationFile={observationFile}
        inputTypeObservation={{
            get: txtTypeObservation,
            set: setTxtTypeObservation
        }}
        customFileInputRef={customFileInputRef}
        editForm={editForm}
        setEditForm={setEditForm}
        handleEditForm={handleEditForm}
        resetState={resetState}
        selectedFaq={{
            select: selectedFaq,
            setSelect: setSelectedFaq
        }}
        handleFaqResponse={handleFaqResponse}
        selectedStoredResponse={{
            select: selectedStoredResponse,
            setSelect: setSelectedStoredResponse
        }}
        handleStoredResponse={handleStoredResponse}
    />
}

export { ObservationsForm }