import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'
import download from 'downloadjs'

const retrieveCasePoll = (idCase, caseCode) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", `${RouterCtrl.retrieveFile}/case/poll`)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.responseType = "blob"
        xhr.withCredentials = true
        xhr.send(JSON.stringify({idCase}))

        xhr.onload = () => {
            try {
                if (xhr.response.size !== 68) {
                    let fileBlob = xhr.response
                    let file = new Blob([fileBlob], { type: "application/pdf" });
        
                    download(file, `${caseCode}_${new Date().toISOString().substr(0, 10)}.pdf`)
                    resolve(true)
                } else {
                    let res = JSON.parse(xhr.response)
                    messageService.printToast(res.scope, res.type, res.code)
                }
            } catch (e) {
                messageService.printToast("Files", "error", 3)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }

    })
}
export { retrieveCasePoll }