import React, { useContext, useEffect, useState, useLayoutEffect } from 'react'
import { EditionsScreenUI } from './EditionsScreenUI'
import { useHistory } from 'react-router-dom'
import useEditions from '../../Hooks/useEditions'
import { UserContext } from '../../Contexts/User/UserContext'
import { useShowView } from '../../Hooks/useShowView'


const EditionsScreen = () => {
    let history = useHistory()

    const userContext = useContext(UserContext)
    const [editions, setEditions] = useState([])
    const [focusedEdition, setFocusedEdition] = useState({})
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //const [userData, setUserData] = useState({})

    //#region FILTER
    const [filteredEditions, setFilteredEditions] = useState([])
    const [archivedCheck, setArchivedCheck] = useState(false)
    //#endregion

    const {
        getEditions,
        createEditions,
        updateEditions,
        deleteEditions,
        hideEdition,
        showEdition
    } = useEditions()

    const [contentDisabled, setContentDisabled] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalConfirmText, setModalConfirmText] = useState("")
    const [modalContent, setModalContent] = useState({
        id: "",
        name: "",
        startDate: "",
        endDate: "",
        limitCases: ""
    });
    const [saveBtnDisplay, setSaveBtnDisplay] = useState(false)
    const [createBtnDisplay, setCreateBtnDisplay] = useState(false)
    const [deleteBtnDisplay, setDeleteBtnDisplay] = useState(false)

    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (res.idRole != 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        let tmpUserData = await userContext.retrieveUserData()
        if (
            !await userContext.checkSession() ||
            tmpUserData.idRole != 1
        ) {
            history.push("/main-screen/cases")
        } else if (!Number.isInteger(tmpUserData.idRole)) {
            history.push("/")
            window.location.reload()
        } else {
            let currEditions = await getEditions()
            setEditions(currEditions)
            setFilteredEditions(currEditions.filter(edition => edition.Archived == 0))
        }
    }, [])

    const filterEditions = (e, updatedEditions) => {
        try {
            let name = e.target.name
            let value = e.target.value
            let tmpFilterList = []
            Object.assign(tmpFilterList, editions)
            if (name == "textFilter") {
                tmpFilterList = tmpFilterList.filter(editions => editions.Name.toLowerCase().includes(value.toLowerCase()))
            }
            if (e.target.name == "archived") {
                tmpFilterList = tmpFilterList.filter(edition => Boolean(edition.Archived) == value)
            }
            setFilteredEditions(tmpFilterList)
        } catch (e) {
            setFilteredEditions(updatedEditions)
        }
    }

    const createEdition = async () => {
        if (await createEditions(modalContent)) {
            document.getElementById("closeEditionModal").click()
            setEditions(await getEditions())
        }


    }

    const deleteEdition = async () => {
        if (await deleteEditions(modalContent.id)) {
            setEditions(getEditions())
            document.getElementById("closeEditionModal").click()
            setModalContent({
                id: "",
                name: "",
                startDate: "",
                endDate: "",
                limitCases: ""
            })
            setContentDisabled(false)
        }
    }

    const updateEdition = async () => {
        if (await updateEditions(modalContent)) {
            document.getElementById("closeEditionModal").click()

            setEditions(await getEditions())
            setModalContent({
                id: "",
                name: "",
                startDate: "",
                endDate: "",
                limitCases: ""
            })
            setContentDisabled(false)
        }

    }

    const closeModal = () => {
        setContentDisabled(false)
    }

    const openModalModify = (id, name, startDate, endDate, limitCases, edition) => {
        setFocusedEdition(edition)
        setSaveBtnDisplay(true)
        setCreateBtnDisplay(false)
        setDeleteBtnDisplay(false)

        setModalTitle("Modificar Edicion")
        setModalConfirmText("Guardar")
        setModalContent({
            id: id,
            name: name,
            startDate: startDate,
            endDate: endDate,
            limitCases: limitCases
        })
    }

    const openModalDelete = (id, name, startDate, endDate, limitCases) => {
        setContentDisabled(true)

        setDeleteBtnDisplay(true)
        setSaveBtnDisplay(false)
        setCreateBtnDisplay(false)

        setModalTitle("Eliminar Edicion")
        setModalConfirmText("Borrar")
        setModalContent({
            id: id,
            name: name,
            startDate: startDate,
            endDate: endDate,
            limitCases: limitCases
        })
    }


    const openModalCreate = () => {
        setModalContent({
            id: "",
            name: "",
            startDate: "",
            endDate: "",
            limitCases: ""
        })
        setCreateBtnDisplay(true)
        setDeleteBtnDisplay(false)
        setSaveBtnDisplay(false)
        setModalTitle("Crear Edicion")
        setModalConfirmText("Crear")
    }

    const handleInputChange = e => {
        let name = e.target.name
        let value = e.target.value

        setModalContent({ ...modalContent, [name]: value })
    }

    const archiveEdition = async () => {
        let result = await hideEdition(focusedEdition.id)
        if (result) {
            let currEditions = await getEditions()
            setEditions(currEditions)
            setFilteredEditions(currEditions.filter(edition => Boolean(edition.Archived) == archivedCheck))
            document.getElementById("closeArchiveEditionModal").click()
        }
    }

    const unarchiveEdition = async () => {
        let result = await showEdition(focusedEdition.id)
        if (result) {
            let currEditions = await getEditions()
            setEditions(currEditions)
            setFilteredEditions(currEditions.filter(edition => Boolean(edition.Archived) == archivedCheck))
            document.getElementById("closeUnarchiveEditionModal").click()
        }
    }

    const setArchivedFilter = () => {
        setArchivedCheck(!archivedCheck)
        filterEditions({ target: { name: "archived", value: !archivedCheck } })
    }

    return (
        <EditionsScreenUI
            editions={editions}
            openModalCreate={openModalCreate}
            openModalModify={openModalModify}
            openModalDelete={openModalDelete}
            modalContent={modalContent}
            modalTitle={modalTitle}
            modalConfirmText={modalConfirmText}
            contentDisabled={contentDisabled}
            closeModal={closeModal}
            btnsDisplay={{
                createBtn: createBtnDisplay,
                saveBtn: saveBtnDisplay,
                deleteBtn: deleteBtnDisplay,
            }}
            handleInputChange={handleInputChange}
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
            actions={{
                create: createEdition,
                update: updateEdition,
                delete: deleteEdition
            }}
            filteredEditions={filteredEditions}
            filterEditions={filterEditions}
            focusedEdition={focusedEdition}
            setFocusedEdition={setFocusedEdition}
            archiveEdition={archiveEdition}
            archivedCheck={archivedCheck}
            setArchivedFilter={setArchivedFilter}
            unarchiveEdition={unarchiveEdition}
        />
    )

}

export { EditionsScreen }