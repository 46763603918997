import { getObservationsByCas } from '../Services/Observations/getObservationsByCas'
import { postGetObservations } from '../Services/Observations/postGetObservations'
import { putGetObservations } from '../Services/Observations/putGetObservations'

const useObservations = () => {

    const getDataObservationsByCas = async (id_cases) => {
        return await getObservationsByCas(id_cases)
    }

    const insertObservations = async (caseId, observation, type_observation) => {
        return await postGetObservations(caseId, observation, type_observation)
    }

    const updateObservations = async (observation, id, caseId, type_observation) => {
        return await putGetObservations(observation, id, caseId, type_observation)
    }

    return { insertObservations, updateObservations, getDataObservationsByCas }
}
export { useObservations }