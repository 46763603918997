import { closeSession } from '../Services/Sessions/closeSession'

const useSession = () => {

    const finishSession = async () => {
        await closeSession()
        window.location.reload("/");
        return true
    }

    return {finishSession }
}

export { useSession }