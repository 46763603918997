import React from 'react'
import { CustomFileInput } from '../CustomFileInput/CustomFileInput'

export default ({
    idUser,
    idEntityOwner,
    downloader,
    uploader,
    files,
    nFiles,
    maxSizePerFile,
    name,
    id,
    formats,
    formName,
    entityId,
    deleter,
    cssClass,
    deletable,
    fileElemCSS,
    adminAllowed,
    idRole,
    displayFactor,
    imageDownloader,
    setFullscreenImageBlob
}) => {
    if (typeof files !== "undefined") {
        files = files.map(file => {
            if (!(file instanceof File)) {
                file.name = file.Name
                file.size = file.Size
                file.extension = file.Extension
                file.mimetype = file.Mimetype
            }
            return file
        })
    }

    return (
            <CustomFileInput
                {...{
                    idUser,
                    idEntityOwner,
                    downloader,
                    uploader,
                    deleter,
                    files,
                    nFiles,
                    maxSizePerFile,
                    name,
                    id,
                    formats,
                    formName,
                    entityId,
                    cssClass,
                    deletable,
                    adminAllowed,
                    idRole,
                    displayFactor,
                    fileElemCSS,
                    imageDownloader,
                    setFullscreenImageBlob
                }}
            />
    )
}