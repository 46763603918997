import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const downloadPath = (path, mimetype) => new Promise((resolve, reject) => {
    let messageService = new MessageService()

    let xhr = new XMLHttpRequest()
    xhr.open("POST", RouterCtrl.downloadPath)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.withCredentials = true
    xhr.responseType = "blob"
    xhr.send(JSON.stringify({ path }))

    xhr.onload = () => {
        try {
            let  fileBlob = new Blob([xhr.response], { type: mimetype })
            resolve(fileBlob)
        } catch (e) {
            messageService.printToast("Files", "error", 3)
            resolve(false)
        }
    }

    xhr.onerror = () => {
        messageService.printToast("Files", "error", 3)
        resolve(false)
    }
})