//#region 'IMPORTS'
import React from 'react'
import './Faqs.css'
import ButtonSave from '../../Tools/ButtonSave'
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal'
import ButtonModal from '../../Tools/ButtonModal'
import TextInputCustom from '../../Tools/TextInputCustom'
import CustomSelect from '../../Tools/CustomSelect'
import CustomFilter from '../../Tools/CustomFilter'
import FullscreenImage from '../../Tools/FullscreenImage'
import { Editor } from 'react-draft-wysiwyg';
import OIFDoubtAttachments from '../../Tools/OIF_tools/OIFDoubtAttachment/OIFDoubtAttachments'
import { ViewType } from '../../Tools/ViewTypes/ViewType'
import { TableList } from '../../Tools/Table/TableList'
import { ContentItem } from '../../Tools/ContentItem'
import { KanbanCard } from '../../Tools/Kanban/KanbanCard'
import { ItemKanban } from '../../Tools/Kanban/ItemKanban'
import { ViewData } from '../../Tools/ViewsData/ViewData'
import { ContentGeneral } from '../../Tools/ContentGeneral'
import { HeadTitle } from '../../Tools/HeadTitle/HeadTitle'
//#endregion

const FaqsUI = ({
  filteredFaqs,
  views,
  faqsTypes,
  disabled,
  modalTitle,
  openModal,
  modalContent,
  handleInputChange,
  handleSelectChange,
  filterFaqs,
  cbfilterObj,
  runAction,
  buttonText,
  modalRef,
  closeModal,
  action,
  userData,
  uploadAttachments,
  faqFiles,
  deleteAttachment,
  downloadFaqAttachment,
  handleDelete,
  filterFaqsType,
  downloadImage,
  fullscreenImageBlob,
  setFullscreenImageBlob,
  stateEditor,
  onEditorStateChange,
  customFileInputRef
}) => {

  //#region 'VARIABLES'
  const obFields = {
    type: "Tipo",
    question: "Pregunta",
    response: "Respuesta",
    visible: "Pública"
  }
  //#endregion
  if (faqsTypes.filter(faqType => {
    if (faqType.value === -1) {
      return true
    }
  }) === 0) {
    faqsTypes.map(faqType => {
      return faqType.target = { name: "typeFilter" }
    })
    faqsTypes.unshift({ value: -1, label: "Todos", target: { name: "typeFilter" } })
  }

  const removeTags = (str) => {
    if (str === null || str === '') {
      return false
    } else {
      str = str.toString();
    }
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  //#region 'STRUCTURE'

  //#region 'VIEW KANBAN'
  const ViewKanban = ({ item, index }) => (
    <KanbanCard
      index={index} title={item.Questions}
      classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
      dataTarget={"#faqsForm"} onclickHead={() => openModal(item, false, "Modificar pregunta frecuente", "update", "Guardar")}
      cardBody={
        <ContentItem itemBody={[
          <ItemKanban key={"faq_response_" + index} label={obFields.response} value={removeTags(item.Response)} classCol={"col-12"} />,
          <ItemKanban key={"faqType_" + index} label={obFields.type} value={item.faqs_types} classCol={"col-6"} />,
          <ItemKanban key={"faq_visible_" + index} label={obFields.visible} value={<input type="checkbox" disabled checked={Boolean(item.Visible.data[0])} />} classCol={"col-6"} />
        ]} />
      } />
  )
  //#endregion

  //#region 'VIEW LIST'
  const ContentList = ({ item, index }) => (
    <tr key={index}
      className="clickable"
      onClick={() => openModal(item, false, "Modificar preguntas frecuentes", "update", "Guardar")}
      data-bs-toggle="modal" data-bs-target="#faqsForm">
      <th scope="row">{index + 1}</th>
      <td style={{ width: 120, minWidth: 120, textAlign: 'center' }}>{item.faqs_types}</td>
      <td style={{ width: 250, minWidth: 250 }}>{item.Questions}</td>
      <td style={{ width: 'auto', minWidth: 'auto' }}>
        <div style={{ height: 100, overflow: 'scroll', background: "#fff", paddingLeft: 5, paddingRight: 5 }}>
          <Editor
            spellCheck
            toolbarHidden={true}
            readOnly={true}
            editorState={item?.responseHTML}
          />
        </div>
      </td>
    </tr>
  )

  const HeadList = () => (
    <tr>
      <th></th>
      <th style={{ textAlign: 'center' }}>{obFields.type}</th>
      <th>{obFields.question}</th>
      <th>{obFields.response}</th>
    </tr>
  )

  const ViewList = () => (
    <TableList headList={<HeadList />} bodyList={ArrayMaping} />
  )
  //#endregion

  const ArrayMaping = filteredFaqs.map((faq, index) => (
    views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={faq} index={index} /> : <ContentList key={"key_ContentList"} item={faq} index={index} />
  ))
  //#endregion

  return ([
    <HeadTitle title={"Preguntas Frecuentes"} />,
    <ContentGeneral content={[
      <ViewType showCreateBtn={true} textButton={"Crear"} handleSubmit={() => openModal({ id: "", question: "", response: "", idFaqsTypes: "" },
        false, "Nueva pregunta frecuente", "create", "Crear")} dataTarget={"#faqsForm"}
        views={views.type} kanbanFunction={views.funcKanban} listFunction={views.funcList}
      />,

      <CustomFilter
        {...{
          options: cbfilterObj,
          onChange: filterFaqs,
          nameSelect: "valueDp",
          nameInput: "textFilter",
          cssClasses: "paddinglr0"
        }} />,
      <div>
        <label><b>Tipo</b></label>
        <CustomSelect
          data={faqsTypes}
          onChange={filterFaqsType}
          name="state"
          divClasses="marginlr0 field marginb05"
        />
      </div>,

      <ViewData
        viewType={views.type.kanban}
        kanban={<ContentItem itemBody={ArrayMaping} />}
        list={<ViewList />}
      />
    ]} />,
    <FullscreenImage
      blob={fullscreenImageBlob}
      previousModalButtonOpenerId="goBackFaqs"
    />
    ,
    <SimpleBootstrapModal
      id="deleteFaqs"
      title="Eliminar pregunta frecuente"
      closeButtonId="closeDeleteFaqs"
      message={`¿Está seguro de eliminar esta pregunta frecuente (${modalContent.question})?`}
      footerCancelButton={false}
      footerButtons={
        <>
          <ButtonModal
            id={"goBackFaqs"}
            text={"Atrás"}
            bsTarget={"#faqsForm"}
            close={true}
          />
          <ButtonSave
            textBtn={"Aceptar"}
            formGroup={false}
            handleSubmit={handleDelete}
          />
        </>
      }
    />,

    <SimpleBootstrapModal
      ref={modalRef}
      id={"faqsForm"}
      title={modalTitle}
      fullScreen={true}
      closeFunction={closeModal}
      closeButtonId="closeFaqsForm"
      footerButtons={[
        Number.isInteger(modalContent.id) ?
          <ButtonSave
            textBtn={"Eliminar"}
            formGroup={false}
            buttonClasses={"light-red"}
            dataTarget={"#deleteFaqs"}
            icoClasses={"fas fa-trash-alt"}
          />
          :
          null,
        < ButtonModal text={buttonText} onClick={runAction} />
      ]}
    >
      <div className="row">
        <div className="col-12 col-sm-8">
          <TextInputCustom
            textLabel={"Pregunta"}
            nameInput={"question"}
            typeInput={"text"}
            value={modalContent.question}
            required={true}
            disabled={disabled}
            evOnChange={handleInputChange}
            style={{ marginLeft: 0 }}
          />
          <CustomSelect
            data={faqsTypes.filter(e => e.value != -1)}
            nameSelect={"idFaqsType"}
            label={"*Tipo de pregunta frecuente"}
            value={faqsTypes.map(faqsType => {
              if (faqsType.value == modalContent.idFaqsType) {
                return faqsType
              }
            })}
            disabled={disabled}
            onChange={handleSelectChange}
            style={{ marginLeft: 0 }}
          />
          <div>
            <label htmlFor="visibility" className="marginr05">
              Pública
            </label>
            <input
              id="visibility"
              name="visibility"
              type="checkbox"
              className="middle"
              checked={modalContent.visibility}
              disabled={disabled}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <h5>Adjuntos de preguntas frecuentes</h5>
          <OIFDoubtAttachments
            fileInputRef={customFileInputRef}
            files={faqFiles}
            formName="faqDocuments"
            id={"faqAttachments"}
            nFiles={5}
            cssClass={"margin0"}
            formats={".pdf, .docx, .doc, .pptx, .ppt, .png, .jpg, .jpeg"}
            idUser={userData.id}
            idEntityOwner={modalContent.idCreator}
            entityId={modalContent.id}
            uploader={uploadAttachments}
            deleter={deleteAttachment}
            downloader={downloadFaqAttachment}
            imageDownloader={downloadImage}
            deletable={true}
            fileElemCSS={"width100"}
            setFullscreenImageBlob={setFullscreenImageBlob}
            readOnly={!disabled}
          />
        </div>
        <div style={{ paddingLeft: 0, paddingRight: 0, marginTop: 20 }}>
          <div style={{
            display: "flex",
            alignItems: "baseline",
            gap: "0.5rem",
            paddingLeft: 15,
            paddingTop: 15,
            paddingBottom: 15,
            background: 'black',
            color: 'white',
            marginBottom: 10
          }}>
            <h4 className="modal-title" style={{ fontWeight: 'normal', alignSelf: 'self-start' }}>Respuesta</h4>
          </div>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Editor
              spellCheck
              editorState={stateEditor.editorState}
              toolbarClassName="rdw-storybook-toolbar"
              wrapperClassName="rdw-storybook-wrapper"
              editorClassName="rdw-storybook-editor"
              onEditorStateChange={onEditorStateChange}
            />
          </div>
        </div>
      </div>
    </SimpleBootstrapModal>
  ])
}

export default FaqsUI