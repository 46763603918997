export default (state, action) => {
    const { payload, type } = action

    switch (type) {
        case "GET_USER":
            return {
                ...state, 
                userData: payload
            }
        default:
            return state
    }

}