import React from 'react'
import IconTextResponsiveCSS from './IconTextResponsive.module.css'

export default ({ msg, icoClasses }) => {

    const renderMsg = () => {
        if (msg && icoClasses) {
            return <div className={IconTextResponsiveCSS.textDiv}
                style={{ marginLeft: "0.5rem" }}>{msg}</div>
        } else if (msg) {
            return <div className={IconTextResponsiveCSS.textDiv}>{msg}</div>
        }
    }
    return (
        <div>
            {icoClasses ? <i className={`${IconTextResponsiveCSS.ico} ${icoClasses}`}></i> : null}
            {renderMsg()}
        </div>
    )
}