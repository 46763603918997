import React, { useReducer, useRef } from 'react'

import DoubtsReducer from './DoubtsReducer'
import { DoubtsContext} from './DoubtsContext'

export const DoubtsState = (props) => {
    const caseTab = useRef()
    const doubtsTab = useRef()
    const formTab = useRef()
    const attachmentsTab = useRef()
    const userProfileTab = useRef()

    const initialState = {
        doubtsFormEdition: true,
        responseFormEdition: true,
        isCreating: false,
    }

    const [state, dispatch] = useReducer(DoubtsReducer, initialState)

    const enableReponseEdition = () => {
        dispatch({type: "ENABLE_RESPONSE_EDITION"})
    }

    const disableResponseEdition = () => {
        dispatch({type: "DISABLE_RESPONSE_EDITION"})
    }

    const enableDoubtEdition = () => {
        dispatch({type: "ENABLE_DOUBT_EDITION"})
    }

    const disableDoubtEdition = () => {
        dispatch({type: "DISABLE_DOUBT_EDITION"})
    }

    const enableCreateDoubt = () => {
        dispatch({type: "ENABLE_DOUBT_CREATION"})
    }

    const disableCreateDoubt = () => {
        dispatch({type: "DISABLE_DOUBT_CREATION"})
    }

    return (
        <DoubtsContext.Provider
        value={{
            data: state,
            editionResponse: {
                enable: enableReponseEdition,
                disable: disableResponseEdition
            },
            editionDoubt: {
                enable: enableDoubtEdition,
                disable: disableDoubtEdition
            },
            isCreating: {
                enable: enableCreateDoubt,
                disable: disableCreateDoubt
            },
            refs: {
                case: caseTab,
                doubts: doubtsTab,
                form: formTab,
                attachments: attachmentsTab,
                userProfile: userProfileTab
            }
        }}>
            {props.children}
        </DoubtsContext.Provider>
    )
}