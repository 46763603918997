import { MessageService } from '../message_service'
import RouterlCtrl from '../../routes/Routes'

const getOtherDiagnosisCase = async (id) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open("GET", `${RouterlCtrl.diagnosisOther}/${id}`)
        xhr.withCredentials = true
        xhr.send()


        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (res.type !== "error") {
                    resolve(res)
                } else {
                    messageService.printToast("Generics", "error", 0)
                    resolve([])
                }
            } catch(e) {
                messageService.printToast("Generics", "error", 0)
                resolve([])
            }
        }

        xhr.onerror = () => { 
            messageService.printToast("Generics", "error", 0)
            resolve([])
        }
    })
}

export{ getOtherDiagnosisCase }