import React, { useState } from 'react'
import CustomFilter from '../Tools/CustomFilter'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import Doubts from './Doubts/Doubts'
import { DoubtsForm } from './DoubtsForm/DoubtsForm'
import { Observations } from './Observations/Observations'
import { ObservationsForm } from './ObservationsForm/ObservationsForm'

const DoubtsScreenUI = ({ doubts, changeAction, doubtsTypes, getDoubts, openModalModify,
    focusedDoubt, setFocusedDoubt, resetEditDoubt, actionType, setActionType, filterDoubts,
    filteredDoubts, setFilteredDoubts, cbfilterObj, uploadAttachments, deleteAttachment, downloadAttachment,
    userData, doubtFiles, caseRecord, doubtFormDisabled, setDoubtFormDisabled, setDoubtFiles, views,
    observations, currentObservation, observationFile, assignReasonWaiting, propertiesForm, time_out }) => {

    const [doubtsIndex, setDoubtsIndex] = useState(0)
    const [observationIndex, setObservationIndex] = useState(0)

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-9">
                    <CustomFilter options={cbfilterObj} onChange={filterDoubts}
                        nameSelect={"valueDp"} nameInput={"textFilter"} />
                </div>
                <div className="col-12 col-md-3" style={{ padding: "8px 0 8px 0" }}>
                    <ViewType views={views.type} kanbanFunction={views.funcKanban} listFunction={views.funcList} />
                </div>
            </div>
            <p className="margin0" style={{ fontSize: "0.9rem", fontStyle: "italic" }}>{doubts.length} dudas</p>
            <Doubts
                setDoubtsIndex={setDoubtsIndex}
                doubts={filteredDoubts}
                openModalModify={openModalModify}
                filterDoubts={filterDoubts}
                views={views.type}
            />

            <p className="margin0" style={{ fontSize: "0.9rem", fontStyle: "italic" }}>{observations.data.length} observaciones</p>
            <Observations observations={observations} views={views}
                setters={{
                    index: setObservationIndex,
                    openModal: currentObservation.openModal
                }}
            />

            <DoubtsForm
                doubtsIndex={doubtsIndex}
                setDoubtsIndex={setDoubtsIndex}
                changeAction={changeAction}
                doubtsTypes={doubtsTypes}
                getDoubts={getDoubts}
                focusedDoubt={focusedDoubt}
                setFocusedDoubt={setFocusedDoubt}
                resetEditDoubt={resetEditDoubt}
                actionType={actionType}
                setActionType={setActionType}
                filterDoubts={filterDoubts}
                uploadAttachments={uploadAttachments}
                userData={userData}
                doubtFiles={doubtFiles}
                deleteAttachment={deleteAttachment}
                downloadAttachment={downloadAttachment}
                caseRecord={caseRecord}
                doubtFormDisabled={doubtFormDisabled}
                setDoubtFormDisabled={setDoubtFormDisabled}
                setDoubtFiles={setDoubtFiles}
                doubts={doubts}
                assignReasonWaiting={assignReasonWaiting}
                propertiesForm={propertiesForm}
                time_out={time_out}
            />

            <ObservationsForm
                caseRecord={caseRecord}
                userData={userData}
                getObservations={observations.getFunction}
                updateFunction={observations.updateFunction}
                observationFile={observationFile}
                observationData={{
                    getters: {
                        index: observationIndex,
                        current: currentObservation.get
                    },
                    setters: {
                        index: setObservationIndex,
                        current: currentObservation.set
                    }
                }}
            />
        </>

    )

}

export default DoubtsScreenUI