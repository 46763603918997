import { useState, useEffect } from "react";
import GetCasesPriorities from "../Services/CasesPriorities/GetCasesPriorities";


export default function usePriorities() {

    const [priorities, setPriorities] = useState([])
    const [defaultPriorities, setdefaultPriorities] = useState({})

    useEffect(() => {
        GetCasesPriorities().then(item => {
            const doc = []
            item.forEach(element => {
                doc.push({ value: element.id, label: element.Name })
                if (element.id === 2) {
                    setdefaultPriorities({ value: element.id, label: element.Name })
                }
            });
            setPriorities(doc)
        })
    }, [])

    return { priorities, defaultPriorities }
}