//#region 'IMPORTS'
import React from 'react'
import TextInputCustom from '../Tools/TextInputCustom'
import ButtonSave from '../Tools/ButtonSave'
import ButtonModal from '../Tools/ButtonModal'
import SimpleBootstrapModal from '../Tools/SimpleBootstrapModal'
import CustomFilter from '../Tools/CustomFilter'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import { ViewData } from '../Tools/ViewsData/ViewData'
import { CheckFormatDate } from '../Tools/GeneralFunctions/CheckFormatDate'
import { TableList } from '../Tools/Table/TableList'
import { ContentItem } from '../Tools/ContentItem'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import { KanbanCard } from '../Tools/Kanban/KanbanCard'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

export const StoredResponsesTypesScreenUI = ({
    views,
    cbfilterObj,
    storedResponsesTypes,
    title,
    modalContent,
    handleInputChange,
    openModalCreate,
    handleCreate,
    handleUpdate,
    openModalModify,
    buttonText,
    isCreating,
    handleDelete,
    onFilter,
    handleInputChangeFilter
}) => {

    //#region 'VARIABLES'
    const obFields = {
        type: "Nombre",
        created: "Creación"
    }
    //#endregion

    //#region 'STRUCTURE'

    //#region 'VIEW KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Name}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            dataTarget={"#storedResponseTypeModal"} onclickHead={() => openModalModify(item)}
            cardBody={
                <ContentItem itemBody={[
                    <ItemKanban key={"createdResponseType_" + index} label={obFields.created} value={CheckFormatDate(item.Created)} classCol={"col"} />,
                ]} />
            } />
    )
    //#endregion

    //#region 'VIEW LIST'
    const ContentList = ({ item, index }) => (
        <tr key={index}
            className="clickable"
            onClick={() => openModalModify(item)}
            data-bs-toggle="modal"
            data-bs-target="#storedResponseTypeModal"
        >
            <th scope="row">{index + 1}</th>
            <td>{item.Name}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{CheckFormatDate(item.Created)}</td>
        </tr>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.type}</th>
            <th style={{ textAlign: 'center' }}>{obFields.created}</th>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion


    const ArrayMaping = storedResponsesTypes.filter(onFilter).map((storedResponsesType, index) => (
        views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={storedResponsesType} index={index} /> : <ContentList key={"key_ContentList"} item={storedResponsesType} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={"Tipos de respuestas"} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} handleSubmit={openModalCreate} dataTarget={"#storedResponseTypeModal"}
                textButton={"Crear"} views={views.type} kanbanFunction={views.funcKanban} listFunction={views.funcList}
            />,
            <CustomFilter
                {...{
                    options: cbfilterObj,
                    onChange: handleInputChangeFilter,
                    nameSelect: "valueDp",
                    nameInput: "textFilter",
                    cssClasses: "paddinglr0"
                }}
            />,
            <ViewData
                viewType={views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
            />,
        ]} />,

        <SimpleBootstrapModal
            id="deleteStoredResponseTypeModal"
            title="Eliminar tipo de respuesta"
            closeButtonId="closeDeleteStoredResponseTypeModal"
            footerCancelButton={false}
            message={`¿Está seguro que desea borrar esta respuesta guardada (${modalContent.name})?`}
            footerButtons={
                <>
                    <ButtonModal id={"goBackStoredResponse"} text={"Atrás"} bsTarget={"#storedResponseTypeModal"} close={true} />
                    <ButtonSave
                        textBtn={"Aceptar"}
                        formGroup={false}
                        handleSubmit={handleDelete}
                    />
                </>
            }
        />,

        <SimpleBootstrapModal
            id="storedResponseTypeModal"
            title={title}
            closeButtonId="closeStoredResponseTypeModal"
            footerButtons={
                isCreating ?
                    <ButtonModal
                        text={buttonText}
                        onClick={handleCreate}
                    />
                    :
                    <>
                        <ButtonSave
                            textBtn={"Eliminar"}
                            cssClasses={"margin0 inline-block"}
                            buttonClasses={"light-red"}
                            dataTarget={"#deleteStoredResponseTypeModal"}
                            icoClasses={"fas fa-trash-alt"}
                        />
                        <ButtonModal
                            text={buttonText}
                            onClick={handleUpdate}
                        />
                    </>

            }
        >
            <TextInputCustom
                textLabel={"Nombre de tipo de respuesta"}
                nameInput={"name"}
                typeInput={"text"}
                required={true}
                evOnChange={handleInputChange}
                value={modalContent.name}
                cssClassesGroup={"marginlr0 marginb05"}
                cssClassesInput={"field"}
            />
        </SimpleBootstrapModal>,
    ])
}