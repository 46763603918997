import React from 'react'
import SimpleBootstrapModal from '../../../Tools/SimpleBootstrapModal'
import FaqElement from '../../../Tools/FaqElement/FaqElement'
import { HeadTitle } from '../../../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../../../Tools/ContentGeneral'

const FaqsPublicScreenUI = ({ faqsTypes, faqs, selectedFaq, retrieveAttachments,
    downloadImage, downloadFile, imagePopUp, displayImagePopUp }) => {

    return ([
        <HeadTitle title={"Preguntas frecuentes"} />,
        <ContentGeneral content={[
            faqsTypes.map((faqsType, faqTypeIndex) => {
                let faqNum = 1
                let faqList = faqs.length > 0 && faqs.filter(faq => faq.id_faqs_types === faqsType.id && faq.Visible)
                if (faqList.length > 0) {
                    return (
                        <div key={faqTypeIndex} style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
                            <h5 style={{ color: "#2c82c9" }}>{faqsType.Name}</h5>
                            <div>
                                {faqList.map((faq, index) =>
                                    <FaqElement
                                        key={index}
                                        number={faqNum++}
                                        question={faq.Questions}
                                        response={faq.Response}
                                        id={faq.id}
                                        selectedFaq={selectedFaq}
                                        attachmentsRetriever={retrieveAttachments}
                                        imageDownloader={downloadImage}
                                        downloader={downloadFile}
                                        displayFactor={1700}
                                        displayImagePopUp={displayImagePopUp}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
            })
        ]} />,
        <button id="imageModalButton" data-bs-toggle="modal" data-bs-target="#imageModal" className="none"></button>,
        <SimpleBootstrapModal fullScreen={true} id="imageModal" bodyCSS={{ display: "flex", justifyContent: "center", alignItems: "center" }} cancelButtonMessage="Cerrar">
            <img src={imagePopUp} className="imageOrientation" />
        </SimpleBootstrapModal>
    ])
}

export { FaqsPublicScreenUI }