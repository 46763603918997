import { getCasesReasonWaiting } from '../Services/CasesReasonWaiting/getCasesReasonWaiting'
import { postCasesReasonWaiting } from '../Services/CasesReasonWaiting/postCasesReasonWaiting'
import { putCasesReasonWaiting } from '../Services/CasesReasonWaiting/putCasesReasonWaiting'
import { putForceCasesReasonWaiting } from '../Services/CasesReasonWaiting/putForceCasesReasonWaiting'

const useCasesReasonWaiting = () => {

    const getDataCasesReasonWaiting = async (id_cases) => {
        return await getCasesReasonWaiting(id_cases) || []
    }

    const insertDataCasesReasonWaiting = async (id_cases, reason_waiting, reason_waiting_other, id_students) => {
        return await postCasesReasonWaiting(id_cases, reason_waiting, reason_waiting_other, id_students)
    }

    const updateDataCasesReasonWaiting = async (id_cases, reason_waiting) => {
        return await putCasesReasonWaiting(id_cases, reason_waiting)
    }

    const updateForceDataCasesReasonWaiting = async (id_cases) => {
        return await putForceCasesReasonWaiting(id_cases)
    }

    return { getDataCasesReasonWaiting, insertDataCasesReasonWaiting, updateDataCasesReasonWaiting, updateForceDataCasesReasonWaiting }
}

export { useCasesReasonWaiting }