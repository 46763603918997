import { useState, useEffect } from 'react'
import { getReasonWaiting } from '../Services/ReasonWaiting/getReasonWaiting'

const useReasonWaiting = () => {
    const [reasonWaiting, setReasonWaiting] = useState([])

    const getDataReasonWaiting = async () => {
        setReasonWaiting(await getReasonWaiting())
    }

    useEffect(() => {
        getDataReasonWaiting()
    }, [])

    return { reasonWaiting }

}

export { useReasonWaiting }