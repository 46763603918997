import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { UserContext } from '../../../Contexts/User/UserContext'
import { useHistory } from 'react-router-dom'
import { FaqScreenUI } from './FaqScreenUI'

import { useFaqs } from '../../../Hooks/useFaqs'
import { useFiles } from '../../../Hooks/useFiles'

import download from 'downloadjs'

const FaqScreen = ({ match }) => {
    const {
        retireveSpecificFaq
    } = useFaqs()

    const {
        getFilesInfo,
        downloadFileByPath
    } = useFiles()

    const userContext = useContext(UserContext)
    let history = useHistory()
    const [faq, setFaq] = useState({})

    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        let tmpFaqId = Number.parseInt(match.params.id)
        if (Number.isInteger(tmpFaqId)) {
            let faq = await retireveSpecificFaq(tmpFaqId)
            if (faq != null) {
                setFaq(faq)
            } else {
                // history.push("/main-screen/faqs")
            }
        } else {
            history.push("/main-screen/faqs")
        }
    }, [])

    const retrieveAttachments = (idFaq) => {
        return getFilesInfo("faq", idFaq)
    }

    const downloadImage = async (path, mimetype) => {
        return await downloadFileByPath(path, mimetype)
    }

    const downloadFile = async (idFile, name, mimetype, extension, path) => {
        let result = await downloadFileByPath(path, mimetype)
        download(result, `${name}`)
    }

    return <FaqScreenUI
        {...{
            faq,
            retrieveAttachments,
            downloadImage,
            downloadFile
        }}
    />
}

export { FaqScreen }