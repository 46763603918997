import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const postCasesPacks = async (data) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open('POST', RouterCtrl.packs)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ data }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                messageService.printToast(res.scope, res.type, res.code)
                if (res.type === 'success') {
                    resolve(res.insertId)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast('Generics', 'error', 0)
                resolve(false)
            }
        }
        xhr.onerror = () => {
            messageService.printToast('Generics', 'error', 0)
            resolve(false)
        }
    })
}