import React from 'react'
import IconTextResponsive from './IconTextResponsive/IconTextResponsive'

const ButtonSave = ({
    id,
    buttonId,
    textBtn,
    handleSubmit,
    dataTarget = null,
    cssClasses,
    buttonClasses,
    icoClasses,
    style,
    buttonStyles,
    formGroup = true,
    buttonPrimaryClass,
    withContent = true
}) => {

    const styles = {
        background: "#5BC5F1",
        color: "#fff",
        border: 0,
        fontWeight: 450,
    }

    return (
        withContent ?
            <div id={id} className={`${formGroup ? "form-group" : ""}${cssClasses ? ` ${cssClasses}` : ""}`} style={style}>
                <button
                    id={buttonId}
                    data-bs-toggle={dataTarget ? "modal" : null}
                    data-bs-target={dataTarget}
                    type="button"
                    className={`btn ${buttonPrimaryClass || "btn-primary"} btn-block ${buttonClasses}`}
                    style={buttonStyles || styles}
                    onClick={handleSubmit}>
                    {
                        icoClasses ?
                            <IconTextResponsive
                                msg={textBtn}
                                icoClasses={icoClasses}
                            />
                            : textBtn
                    }
                </button>
            </div>
            :
            <button
                id={buttonId}
                data-bs-toggle={dataTarget ? "modal" : null}
                data-bs-target={dataTarget}
                type="button"
                className={`btn ${buttonPrimaryClass || "btn-primary"} btn-block ${buttonClasses}`}
                style={buttonStyles || styles}
                onClick={handleSubmit}>
                {
                    icoClasses ?
                        <IconTextResponsive
                            msg={textBtn}
                            icoClasses={icoClasses}
                        />
                        : textBtn
                }
            </button>

    )
}
export default ButtonSave