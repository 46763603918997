import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const putCasesPacksDuration = async (data) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open('PUT',RouterCtrl.packs_duration)
        xhr.withCredentials = true
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.send(JSON.stringify(data))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                messageService.printToast(res.scope, res.type, res.code)
                switch (res.type) {
                    case 'success':
                        resolve(true)
                        break;
                    default:
                        resolve(false)
                        break;
                }

            } catch (e) {
                messageService.printToast('Generics', 'error', 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast('Generics', 'error', 0)
            resolve(false)
        }
    })
}

export { putCasesPacksDuration }