import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const postFile = (formData, form, entityId) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()  
        xhr.open("POST", `${RouterCtrl.uploadFile}/${form}/upload${entityId ? `/${entityId}` : ""}`)
        xhr.withCredentials = true
        xhr.send(formData)

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                messageService.printToast(res.scope, res.type, res.code)
                if (res.type === "success") {
                    resolve(true)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    })
}

export { postFile }