import { MessageService } from "../message_service";
import RouteCtrl from '../../routes/Routes'

export const getStoredResponsesTypes = async () => {
    return new Promise((resolve, reject) => {
        let  messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("GET", RouteCtrl.storedResponsesTypes)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (Array.isArray(res)) {
                    resolve(res)
                } else {
                    //messageService.printToast(res.scope, res.type, res.code)
                    resolve([])
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve([])
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve([])
        }
    })
}