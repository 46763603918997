import React from 'react'
import { ActivationScreenUI } from './ActivationScreenUI'

const ActivationScreen = () => {

    return (
        <ActivationScreenUI />
        )
    }

export { ActivationScreen }