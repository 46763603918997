import { MessageService } from "../message_service"
import RouterCtrl from "../../routes/Routes"

const selectCase = (id) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", `${RouterCtrl.getCase}/${id}`)
        xhr.withCredentials = true
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.send(JSON.stringify({id: id}))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (res.type !== "error") {
                    resolve(res)
                } else {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve([])
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve([])
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
                resolve([])
        }

    })
}

export { selectCase }