import { useState } from 'react'
import { getStoredResponsesTypes } from '../Services/StoredResponsesTypes/getStoredResponsesTypes'
import { postStoredResponseType } from '../Services/StoredResponsesTypes/postStoredResponseType'
import { putStoredResponseType } from '../Services/StoredResponsesTypes/putStoredResponseType'
import { deleteStoredResponseType } from '../Services/StoredResponsesTypes/deleteStoredResponseType'
import { useEffect } from 'react'

export const useStoredResponsesTypes = () => {
    const [storedResponsesTypes, setStoredResponsesTypes] = useState([])

    const retrieveStoredResponsesTypes = () => getStoredResponsesTypes()

    const createStoredResponseType = name => postStoredResponseType(name)

    const updateStoredResponseType = name => putStoredResponseType(name)

    const removeStoredResponseType = id => deleteStoredResponseType(id)

    const getDataStoredResponse = async () => {
        const doc = []
        let result = await getStoredResponsesTypes()
        result.forEach(element => {
            doc.push({
                value:element.id,
                label: element.Name
            })
        });
        setStoredResponsesTypes(doc)
    }

    useEffect(() => {
      getDataStoredResponse()
    }, [])
    
    return {
        storedResponsesTypes,
        setStoredResponsesTypes,
        retrieveStoredResponsesTypes,
        createStoredResponseType,
        updateStoredResponseType,
        removeStoredResponseType
        
    }
}