//#region 'IMPORTS'
import React from 'react'
import ButtonModal from '../Tools/ButtonModal'
import SimpleBootstrapModal from '../Tools/SimpleBootstrapModal'
import TextInputCustom from '../Tools/TextInputCustom'
import ButtonSave from '../Tools/ButtonSave'
import CustomFilter from '../Tools/CustomFilter'
import { CustomToggleFilter } from '../Tools/CustomToggleFilter/CustomToggleFilter'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import { ViewData } from '../Tools/ViewsData/ViewData'
import { ContentItem } from '../Tools/ContentItem'
import { KanbanCard } from '../Tools/Kanban/KanbanCard'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import { CheckFormatDate } from '../Tools/GeneralFunctions/CheckFormatDate'
import moment from 'moment/moment'
import { TableList } from '../Tools/Table/TableList'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

const EditionsScreenUI = props => {
    //#region 'VARIABLES'
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]

    const obFields = {
        name: "Nombre",
        start_date: "Inicio",
        end_date: "Final",
        cases_limit: "Casos"
    }
    //#endregion

    //#region "STRUCTURE"
    const ContentList = ({ item, index }) => (
        <tr key={index}
            className="clickable"
            onClick={() => props.openModalModify(
                item.id,
                item.Name,
                item.Start_date.substr(0, 10),
                item.End_Date.substr(0, 10),
                item.Limit_cases,
                item
            )}
            data-bs-toggle="modal"
            data-bs-target="#editionModal"
        >
            <th scope="row">{index + 1}</th>
            <td>{item.Name}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{CheckFormatDate(item.Start_date)}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{CheckFormatDate(item.End_Date)}</td>
            <td style={{ width: 60, minWidth: 60, textAlign: 'right' }}>{item.Limit_cases}</td>
        </tr>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.start_date}</th>
            <th style={{ textAlign: 'center' }}>{obFields.end_date}</th>
            <th style={{ textAlign: 'center' }}>{obFields.cases_limit}</th>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )

    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Name}
            styleCard={{ backgroundColor: moment(item.End_Date).isAfter(moment(new Date())) ? "" : "rgb(177 178 180)" }}
            classHead={`ps-3 pe-3 pt-2 ${moment(item.End_Date).isAfter(moment(new Date())) ? "blackAndWhite" : "grey"} clickable`}
            dataTarget={"#editionModal"} onclickHead={() => props.openModalModify(
                item.id,
                item.Name,
                item.Start_date.substr(0, 10),
                item.End_Date.substr(0, 10),
                item.Limit_cases,
                item
            )}
            cardBody={
                <ContentItem itemBody={[
                    <ItemKanban key={"date_start_" + index} label={"Inicio"} value={CheckFormatDate(item.Start_date)} classCol={"col-6"} />,
                    <ItemKanban key={"end_date_" + index} label={"Final"} value={CheckFormatDate(item.End_Date)} classCol={"col-6"} />,
                    <ItemKanban key={"count_cases_" + index} label={"Casos"} value={item.Limit_cases} classCol={"col"} />
                ]} />
            } />
    )

    const ArrayMaping = props.filteredEditions.map((edition, index) => (
        props.views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={edition} index={index} /> : <ContentList key={"key_ContentList"} item={edition} index={index} />
    ))
    //#endregion
    return ([
        <HeadTitle title={"Ediciones"} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} textButton={"Crear"} handleSubmit={props.openModalCreate} dataTarget={"#editionModal"}
                views={props.views.type} kanbanFunction={props.views.funcKanban} listFunction={props.views.funcList}
            />,
            <CustomFilter keyValue={"searchDataEditions"} nameSelect={"valueDp"} nameInput={"textFilter"}
                options={cbfilterObj} onChange={props.filterEditions} cssClasses={"paddinglr0"} />,
            <CustomToggleFilter
                filterName={"archived"}
                text={"Archivadas"}
                checked={props.archivedCheck}
                event={props.setArchivedFilter}
            />,
            <ViewData
                viewType={props.views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
            />
        ]} />,
        <SimpleBootstrapModal
            id={"archiveEditionModal"}
            title={`Archivar Edición - ${props.focusedEdition.Name ? props.focusedEdition.Name.toUpperCase() : ""}`}
            message={`¿Quieres archivar la edición?`}
            closeButtonId="closeArchiveEditionModal"
            footerButtons={<ButtonModal onClick={props.archiveEdition} text={"Sí"} />}
        />,

        <SimpleBootstrapModal
            id={"unarchiveEditionModal"}
            title={`Desarchivar Edición - ${props.focusedEdition.Name ? props.focusedEdition.Name.toUpperCase() : ""}`}
            message={`¿Quieres desarchivar la edición?`}
            closeButtonId="closeUnarchiveEditionModal"
            footerButtons={<ButtonModal onClick={props.unarchiveEdition} text={"Sí"} />}
        />,

        <SimpleBootstrapModal
            id={"deleteEditionModal"}
            title={`Eliminar Edición - ${props.focusedEdition.Name ? props.focusedEdition.Name.toUpperCase() : ""}`}
            message={`¿Quieres eliminar la edición?`}
            closeButtonId="closeDeleteEditionModal"
            footerCancelButton={false}
            footerButtons={[
                <ButtonModal
                    id={"goBackEditions"}
                    text={"Atrás"}
                    bsTarget={"#editionModal"}
                    close={true}
                />,
                <ButtonSave
                    textBtn={"Aceptar"}
                    formGroup={false}
                    handleSubmit={props.actions.delete}
                />
            ]}
        />,

        <SimpleBootstrapModal
            id={"editionModal"}
            title={props.modalTitle}
            closeButtonId={"closeEditionModal"}
            footerButtons={[
                <ButtonSave
                    textBtn={"Eliminar"}
                    formGroup={false}
                    icoClasses={"fas fa-trash-alt"}
                    buttonClasses={"light-red"}
                    dataTarget="#deleteEditionModal"
                />,
                props.focusedEdition.Archived === 0 && (
                    <ButtonSave
                        textBtn={"Archivar"}
                        formGroup={false}
                        icoClasses={"fas fa-folder-plus"}
                        dataTarget="#archiveEditionModal"
                    />),

                props.focusedEdition.Archived === 1 && (
                    <ButtonSave
                        textBtn={"Desarchivar"}
                        formGroup={false}
                        icoClasses={"fas fa-folder-minus"}
                        dataTarget="#unarchiveEditionModal"
                    />),

                props.btnsDisplay.saveBtn && (<ButtonModal onClick={props.actions.update} text={props.modalConfirmText} />),
                props.btnsDisplay.deleteBtn && (<button type="button" className="btn btn-danger"
                    onClick={props.actions.delete}>{props.modalConfirmText}</button>),
                props.btnsDisplay.createBtn && (<ButtonModal onClick={props.actions.create} text={props.modalConfirmText} />)
            ]}
        >
            <form action="">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <TextInputCustom
                            nameInput="name"
                            required={true}
                            textLabel={"Nombre"}
                            evOnChange={props.handleInputChange}
                            value={props.modalContent.name}
                            disabled={props.contentDisabled}
                            cssClassesGroup={"marginlr0 marginb05"}
                            cssClassesInput={"field"}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <TextInputCustom
                            nameInput="limitCases"
                            required={true}
                            textLabel={"Limite de Casos"}
                            evOnChange={props.handleInputChange}
                            value={props.modalContent.limitCases}
                            disabled={props.contentDisabled}
                            typeInput="number"
                            cssClassesGroup={"marginlr0 marginb05"}
                            cssClassesInput={"field"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <TextInputCustom
                            textLabel={"Fecha inicio"}
                            required={true}
                            nameInput="startDate"
                            evOnChange={props.handleInputChange}
                            value={props.modalContent.startDate}
                            disabled={props.contentDisabled}
                            typeInput="date"
                            cssClassesGroup={"marginlr0 marginb05"}
                            cssClassesInput={"field"}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <TextInputCustom
                            textLabel={"Fecha final"}
                            required={true}
                            nameInput="endDate"
                            evOnChange={props.handleInputChange}
                            value={props.modalContent.endDate}
                            disabled={props.contentDisabled}
                            typeInput="date"
                            cssClassesGroup={"marginlr0 marginb05"}
                            cssClassesInput={"field"}
                        />
                    </div>
                </div>
            </form>
        </SimpleBootstrapModal>,
    ])
}

export { EditionsScreenUI }