import { useState, useEffect } from "react";
import { getStoredResponses } from '../Services/StoredResponses/getStoredResponses'
import { getStoredResponsesByType } from "../Services/StoredResponses/getStoredResponsesByType";
import { postStoredResponse } from '../Services/StoredResponses/postStoredResponse'
import { putStoredResponse } from '../Services/StoredResponses/putStoredResponse'
import { deleteStoredResponse } from '../Services/StoredResponses/deleteStoredResponse'

export const useStoredResponses = () => {

    const [storedResponses, setStoredResponse] = useState([])

    /* const getDataStoredResponses = async () => {
        let doc = []
        let result = await getStoredResponses()
        result.forEach(element => {
            doc.push({
                value: element.id,
                label: element.Response
            })
        });
        setStoredResponse(doc)
    } */

    const retrieveReturnStoredResponses = () => getStoredResponses()

    const removeStoredResponse = async (data) => {
        return deleteStoredResponse(data)
    }

    const createStoredResponse = async (data) => {
        return postStoredResponse(data)
    }


    const updateStoredResponse = async (data) => {
        return putStoredResponse(data)
    }

    const retrieveStoredResponsesByType = async (storedResponseTypeId) => {
        let res = await getStoredResponsesByType(storedResponseTypeId)
        return res.map(storedResponse => {
            return { value: storedResponse.id, label: storedResponse.Response }
        })
    }

    /* useEffect(() => {
        getDataStoredResponses()
    }, []) */


    return {
        retrieveStoredResponsesByType,
        storedResponses,
        setStoredResponse,
        createStoredResponse,
        updateStoredResponse,
        removeStoredResponse,
        retrieveReturnStoredResponses
    }
}