import React, { useRef } from 'react'
import DropdownItem from './DropdownDesktopItem/DropdownDesktopItem'
import DropdownCSS from './DropdownDesktop.module.css'

export default ({ mainLink, subLinks, style }) => {

    const mainLinkRef = useRef()
    return (
        <div
            /* onMouseOver={typeof mainLinkRef.current != "undefined" ?
                () => mainLinkRef.current.enter() : null}
            onMouseLeave={typeof mainLinkRef.current != "undefined" ?
                () => mainLinkRef.current.leave() : null} */
            className={DropdownCSS.dropdown}>
            {/* {mainLink} */}
            <DropdownItem
                ref={mainLinkRef}
                cssClass={mainLink.cssClass}
                text={mainLink.text}
                path={mainLink.path}
                main={mainLink.main}
                style={style}
            />
            <div className={DropdownCSS.subMenu}>
                <div className={DropdownCSS.subItem}>
                    {subLinks.map((subLink, index) => (
                        <div key={index} className="sub-item">
                            {subLink}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}