//#region "IMPORTS"
import React, { useContext } from 'react'
import { useHistory } from "react-router-dom"
import CustomFilter from "../Tools/CustomFilter"
import CustomSelect from '../Tools/CustomSelect'
import { headerColors } from '../../constants'
import SimpleBootstrapModal from '../Tools/SimpleBootstrapModal'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import { ContentItem } from '../Tools/ContentItem'
import { SearchContext } from '../../Contexts/Search/SearchContext'
import { ViewData } from '../Tools/ViewsData/ViewData'
import ButtonSave from '../Tools/ButtonSave'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import ButtonModal from '../Tools/ButtonModal'
import { TableList } from '../Tools/Table/TableList'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
import star_full from '../../img/star_full.png'
//#endregion

const CasesScreenUI = ({ cases, userData, filters, views, handleNoNotificationOnHold }) => {
    //#region "VARIABLES"
    let history = useHistory()
    const FONT_SIZE = 13
    const objFields = {
        patientInvisalign: "Nombre",
        patientIdInvisalign: "ID",
        case_code: "Referencia",
        state: "Estado",
        created_doubts: "Creado",
        Student: "Doctor",
        Edition: "Edición",
        Case_type: "Tipo",
        Teacher: "Profesor",
        casesCount: "NC",
        Doubts_number: "ND",
        Responses_number: "NR",
        case_assessment: "VC"
    }
    //#endregion

    //#region "USE CONTEXT"
    const searchContext = useContext(SearchContext)
    //#endregion

    //#region "FUNCTIONS"
    const colorSelector = (caseRecord) => {
        if (caseRecord.Answer_date_limit_exceeded > 0 && caseRecord.id_states !== 4) {
            return "importantHeader";
        } else if (caseRecord.id_priorities !== 0 || caseRecord.id_states === 4) {
            return headerColors[caseRecord.id_states]
        } else if (caseRecord.id_priorities !== 0 || caseRecord.id_states === 5) {
            return headerColors[caseRecord.id_states]
        } else {
            return "importantHeader";
        }
    }

    const onclickItem = (item) => {
        searchContext.setSearchData(filters.selFilter)
        searchContext.setTypeViews(views.type)
        history.push({ pathname: "/main-screen/case", state: item })
    }

    const handleCreateCase = () => {
        searchContext.setSearchData(filters.selFilter)
        searchContext.setTypeViews(views.type)
        history.push("/main-screen/case")
    }
    //#endregion

    //#region "STRUCTURES"
    const ImgIcon = ({ img, styImg }) => <img src={img} alt="iconWarnningObservations" className='icon' style={styImg ? styImg : { width: 30 }} />
    //#region "SEARCH"
    const ItemSearch = ({ allow = true, label, visible = true, ctrlSelect: {
        data, value, name, divClasses, evOnChange, defaultValue
    } }) => (
        visible ?
            <div className={`col-12 ${allow ? "col-sm-6" : "col-sm-4"}`}>
                <label><b>{label}</b></label>
                <CustomSelect
                    data={data}
                    value={value}
                    name={name}
                    divClasses={divClasses}
                    onChange={evOnChange}
                    defaultValue={defaultValue}
                />
            </div> : null
    )

    const ControlsSearch = () => ([
        <ContentItem itemBody={[
            <ItemSearch key={"keyCasesState"} label={"Estado"}
                allow={userData.idRole === 3}
                ctrlSelect={{
                    data: filters.state,
                    value: filters.selFilter.stateFilter,
                    name: "state",
                    divClasses: "marginlr0 field marginb05",
                    evOnChange: filters.onchange,
                    defaultValue: filters.defaultValueDP
                }}
            />,
            <ItemSearch key={"keyTypeCases"} label={"Tipo"}
                allow={userData.idRole === 3}
                visible={userData.idRole !== 3}
                ctrlSelect={{
                    data: filters.casesTypes,
                    value: filters.selFilter.caseTypeFilter,
                    name: "type",
                    divClasses: "marginlr0 field marginb05",
                    evOnChange: filters.onchange,
                    defaultValue: filters.defaultValueDP
                }}
            />,
            <ItemSearch key={"KeyEditionCases"} label={"Edición"}
                allow={userData.idRole === 3}
                ctrlSelect={{
                    data: filters.editions,
                    value: filters.selFilter.caseEditionFilter,
                    name: "edition",
                    divClasses: "marginlr0 field marginb05",
                    evOnChange: filters.onchange,
                    defaultValue: filters.defaultValueDP
                }}
            />
        ]} />
    ])
    //#endregion

    //#region "VIEWS"

    //#region "VIEW KANBAN"
    const ViewKanbanCases = ({ item }) => (
        <div className="col-xs-6 col-md-6 col-lg-3" key={item.id}>
            <div className="card mt-2" style={{ borderTopLeftRadius: 10 }}>
                <div className={item.id_states !== 4 ? "blackAndWhite" : "closedHeader"}>
                    <div className="clickable"
                        onClick={() => onclickItem(item)}>
                        <div className="d-flex justify-content-between" style={{ borderWidth: 2, borderColor: "red" }}>
                            <div style={{ width: "90%" }}>
                                <div className="row ps-3">
                                    <h5 className="card-title d-inline-block text-truncate pt-2" style={{ fontWeight: "bolder" }}>
                                        {item.Patient_name_invisalign} / {item.Patient_ID_invisalign}
                                    </h5>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className="ps-3">
                                        <h6 className='text-truncate'>{item.Case_code.toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <p className='text-truncate ps-3 mb-1' style={{ textAlign: 'right' }}>{item.State}</p>
                                    </div>
                                </div>
                            </div>
                            {item.id_states !== 4 && (<div style={{ width: "8px" }} className={colorSelector(item)}></div>)}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ContentItem
                        itemBody={[
                            <ItemKanban key={"keyItemCreatedCase"} classCol={"col-6"} label={objFields.created_doubts} value={item.id_states !== 4 ? item.created_doubts : 0} />,
                            <ItemKanban key={"keyItemDoctorCases"} classCol={"col-6"} label={objFields.Student} value={item.Student?.toLocaleUpperCase()} />
                        ]}
                    />
                    <ContentItem
                        itemBody={[
                            <ItemKanban key={"keyItemTeacherCase"} classCol={"col-6"} label={objFields.Teacher} value={item.Teacher?.toLocaleUpperCase()} />,
                            <ItemKanban key={"keyItemEditionsCases"} classCol={"col-6"} label={objFields.Edition} value={item.Edition} />
                        ]}
                    />
                    <div className="row">
                        <div className="col-6">
                            <p className="card-text mb-0" style={{ fontSize: FONT_SIZE }}><strong>{objFields.Case_type}</strong></p>
                            <p className="card-text mb-1" style={{
                                fontSize: FONT_SIZE,
                                color: "white",
                                fontWeight: "bolder",
                                display: "inline-block",
                                padding: "0.3rem 2rem",
                                borderRadius: "0.25rem",
                                backgroundColor: item.Case_type_color
                            }}>{item.Case_type_abbreaviation}</p>
                        </div>
                        <div className="col-6">
                            <div>
                                <p className="card-text mb-1 inline-block" style={{ fontSize: FONT_SIZE }}><strong>{objFields.casesCount}</strong><br />{item.casesCount}</p>
                                <p className="card-text mb-1 inline-block" style={{ fontSize: FONT_SIZE, marginLeft: "0.5rem" }}><strong>{objFields.Doubts_number}</strong><br />{item.Doubts_number}</p>
                                <p className="card-text mb-1 inline-block" style={{ fontSize: FONT_SIZE, marginLeft: "0.5rem" }}><strong>{objFields.Responses_number}</strong><br />{item.Responses_number}</p>
                                <p className="card-text mb-1 inline-block" style={{ fontSize: FONT_SIZE, marginLeft: "0.5rem" }}><strong>{objFields.case_assessment}</strong><br />{item.id_case_assessment ? <ImgIcon img={star_full} styImg={{ width: 15 }} /> : '-'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
    //#endregion

    //#region "VIEW LIST"
    const HeadList = () => (
        <tr style={{ textAlign: "center" }}>
            <th></th>
            <th style={{ width: 120, minWidth: 120 }}>{objFields.patientInvisalign}</th>
            <th style={{ width: 120, minWidth: 120 }}>{objFields.patientIdInvisalign}</th>
            <th style={{ width: 120, minWidth: 120 }}>{objFields.case_code}</th>
            <th style={{ width: 100, minWidth: 100 }}>{objFields.state}</th>
            <th style={{ width: 50, minWidth: 50 }}>{objFields.created_doubts}</th>
            <th style={{ width: 180, minWidth: 180 }}>{objFields.Student}</th>
            <th style={{ width: 180, minWidth: 180 }}>{objFields.Edition}</th>
            <th style={{ width: 160, minWidth: 160 }}>{objFields.Case_type}</th>
            <th style={{ width: 180, minWidth: 180 }}>{objFields.Teacher}</th>
            <th style={{ width: 40, minWidth: 40 }}>{objFields.casesCount}</th>
            <th style={{ width: 40, minWidth: 40 }}>{objFields.Doubts_number}</th>
            <th style={{ width: 40, minWidth: 40 }}>{objFields.Responses_number}</th>
            <th style={{ width: 40, minWidth: 40 }}>{objFields.case_assessment}</th>
        </tr>
    )

    const StyleTd = {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }

    const ListContentCases = ({ item, index }) => (
        <tr key={item.id}
            style={{ textAlign: "center" }}
            className="clickable"
            onClick={() => onclickItem(item)}>
            <th className={`numeric ${colorSelector(item)}`}>{index + 1}</th>
            <td style={StyleTd}>{item.Patient_name_invisalign}</td>
            <td style={StyleTd}>{item.Patient_ID_invisalign}</td>
            <td style={StyleTd}>{item.Case_code}</td>
            <td style={StyleTd}>{item.State}</td>
            <td style={StyleTd}>{item.id_states !== 4 ? item.created_doubts : 0}</td>
            <td style={StyleTd}>{item.Student}</td>
            <td style={StyleTd}>{item.Edition}</td>
            <td style={StyleTd}>{item.Case_type}</td>
            <td style={StyleTd}>{item.Teacher}</td>
            <td style={StyleTd}>{item.casesCount}</td>
            <td style={StyleTd}>{item.Doubts_number}</td>
            <td style={StyleTd}>{item.Responses_number}</td>
            <td style={StyleTd}>{item.id_case_assessment ? <ImgIcon img={star_full} styImg={{ width: 15 }} /> : '-'}</td>
        </tr>
    )

    const ViewListCases = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion
    const ArrayMaping = cases.filter(filters.doFilter).map((cas, index) => (
        views.type.kanban ? <ViewKanbanCases item={cas} /> : <ListContentCases index={index} item={cas} />
    ))
    //#endregion

    //#region 'MODALS'
    const ModalShowIfCaseIsOnHold = <SimpleBootstrapModal
        id="casesOnHold"
        title="Aviso"
        closeButtonId={"closeIfCaseIsOnHold"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal text={"No volver a mostrar"} onClick={handleNoNotificationOnHold} />,
            <ButtonModal id={"closeIfCaseIsOnHold"} text={"Aceptar"} bsDimiss={"modal"} close={true} />
        ]}
    >
        <p className="text-center">Tiene casos en espera</p>
    </SimpleBootstrapModal>


    const ModalWelcome = () => (
        <SimpleBootstrapModal>
            <p>¡Bienvenido Alumni!</p>
            <p>Tu registro se ha activado correctamente, en los próximos días se habilitará tu cuenta y podrás empezar a crear tus casos!</p>
        </SimpleBootstrapModal>
    )
    //#endregion

    //#endregion

    return ([
        ModalShowIfCaseIsOnHold,
        <ButtonSave buttonId="triggerModalCasesOnHold" dataTarget="#casesOnHold" cssClasses="none" />,
        <ModalWelcome />,
        <HeadTitle title={"Listado de casos"} />,
        <ContentGeneral
            content={[
                <ViewType
                    showCreateBtn={userData.idRole === 2 ? false : true}
                    handleSubmit={handleCreateCase}
                    textButton={"Caso"}
                    views={views.type}
                    kanbanFunction={views.funcKanban} listFunction={views.funcList}
                />,
                <CustomFilter nameSelect={"valueDp"} nameInput={"textFilter"}
                    defaultValue={0} onChange={filters.onchange}
                    valueSelect={filters.selFilter.valueDp}
                    valueInput={filters.selFilter.textFilter}
                    options={filters.dpFiledsFilters}
                />,
                <ControlsSearch />,
                <ViewData viewType={views.type.kanban}
                    kanban={<ContentItem itemBody={ArrayMaping} />}
                    list={<ViewListCases />} />
            ]}
        />
    ])

}

export { CasesScreenUI }
