import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { LopdUI } from './LopdUI'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useLOPD } from '../../Hooks/useLOPD';
import { UserContext } from '../../Contexts/User/UserContext';
import { useHistory } from 'react-router-dom'

const Lopd = (props) => {
    let history = useHistory()
    const idLopd = props.location.state?.idLopd
    const userContext = useContext(UserContext)
    const objState = { editorState: '' }
    const [stateEditor, setstateEditor] = useState(objState)
    const [text, setText] = useState('')
    const [editLopd, setEditLopd] = useState(true)
    const { getDataLOPDById, insertLOPD, updateLOPD } = useLOPD()
    const [data, setData] = useState([])

    const onEditorStateChange = (editorState) => {
        setstateEditor({
            editorState: editorState,
        });
        const html = draftToHtml(convertToRaw(stateEditor.editorState.getCurrentContent()))
        setText(html)
    }

    const propertiesEditor = () => {
        const contentBlock = htmlToDraft('');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    const handleSave = async () => {
        if (!data?.id) {
            if (text !== '', data.name !== '') {
                let res = await insertLOPD(data.name, text)
                if (res) {
                    setData(await getDataLOPDById(res))
                    setEditLopd(true)
                }
            }
        }
    }

    const handleUpdate = async () => {
        if (data?.id) {
            if (text !== '', data.name !== '') {
                let res = await updateLOPD(data.name, text, data.id)
                if (res) {
                    setEditLopd(true)
                    setData(await getDataLOPDById(data.id))
                }
            }
        }
    }

    const handleCancel = async () => {
        setEditLopd(true)
        setData(await getDataLOPDById(idLopd))
    }

    const getLOPD = async () => {
        let result = await getDataLOPDById(idLopd)
        if (result) {
            setstateEditor({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(result?.content))) })
            setText(result?.content)
        }
        setData(result)
    }

    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        propertiesEditor()
        if (idLopd) {
            getLOPD()
        }
    }, [])

    return <LopdUI
        data={data}
        setData={setData}
        editor={{
            value: stateEditor?.editorState,
            onchange: onEditorStateChange
        }}
        editLopd={{
            value: editLopd,
            set: setEditLopd
        }}
        handles={{
            save: handleSave,
            update: handleUpdate,
            cancel: handleCancel
        }}
    />
}

export { Lopd }