import React from 'react'
import IconTextResponsive from '../IconTextResponsive/IconTextResponsive'

export default ({
    textBtn,
    handleSubmit,
    buttonClasses,
    dataTarget = null,
    icoClasses
}) => {

    const styles = {
        // fontWeight: "bold",
        border: "1px solid rgb(91, 197, 241)",
        color: "rgb(91, 197, 241)",
        fontWeight: 450,
        background:'white'
    }

    return (
        <button
            type="button"
            data-bs-toggle={dataTarget ? "modal" : null}
            data-bs-target={dataTarget}
            className={`btn btn-outline-primary${buttonClasses ? ` ${buttonClasses}` : null}`}
            style={styles}
            onClick={handleSubmit}
        >
        <IconTextResponsive 
            msg={textBtn}
            icoClasses={icoClasses}
        />
        </button>
    )
}