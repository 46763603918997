import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const getStudents = () => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()

        xhr.open("GET", RouterCtrl.getStudents)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (Array.isArray(res)) {
                    resolve(res)
                } else if (res.type === 'error') {
                    //messageService.printToast(res.scope, res.type, res.code)
                    resolve([])
                } else {
                    //messageService.printToast("Generics", "error", 0)
                    resolve([])
                }
            } catch (e) {
                //messageService.printToast("Generics", "error", 0)
                resolve([])
            }
        }

        xhr.onerror = () => {
            //messageService.printToast("Generics", "error", 0)
            resolve([])
        }
    })
}

export { getStudents }