import { getUserEditions } from '../Services/UserEditions/getUserEditions'
import { postUserEdition } from '../Services/UserEditions/postUserEdition'
import { putUserEdition } from '../Services/UserEditions/putUserEdition'
import { deleteUserEdition } from '../Services/UserEditions/deleteUserEdition'

export const useUserEditions = () => {

    const retrieveUserEditions = (id) => getUserEditions(id)

    const createUserEdition = (idUser, idEdition, limitCases) => postUserEdition(idUser, idEdition, limitCases)

    const updateUserEdition = (idUser, idEdition, limitCases) => putUserEdition(idUser, idEdition, limitCases)

    const removeUserEdition = (idUser, idEdition) => deleteUserEdition(idUser, idEdition)

    return {
        retrieveUserEditions,
        createUserEdition,
        updateUserEdition,
        removeUserEdition
    }
}