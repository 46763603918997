//#region 'IMPORTS'
import React from 'react'
import ButtonSave from '../../Tools/ButtonSave'
import CustomSelect from '../../Tools/CustomSelect'
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal'
import ButtonModal from '../../Tools/ButtonModal'
import OIFCaseAttachments from '../../Tools/OIF_tools/OIFCaseAttachments'
import OIFDoubtAttachments from '../../Tools/OIF_tools/OIFDoubtAttachment/OIFDoubtAttachments'
import FullscreenImage from '../../Tools/FullscreenImage'
import Rating from 'react-rating'
import { Editor } from 'react-draft-wysiwyg';
import star_empty from '../../../img/star_empty.png'
import star_full from '../../../img/star_full.png'
import { ModalRequestRevision } from '../../Tools/Modals/ModalRequestRevision'
import { AudioRecord } from '../../Tools/AudioRecorder/AudioRecord'
import TextInputCustom from '../../Tools/TextInputCustom'
//#endregion

const DoubtsFormUI = ({ doubt, doubtsIndex, doubtsTypes, handleSelectTypeChange,
    handleInputChange, validators, handleCreate, handleUpdate, submitWithErrors,
    formObj, focusedDoubt, actionType, uploadAttachments, userData, doubtFiles,
    deleteAttachment, downloadAttachment, handleDelete, caseRecord, handleResponseInputChange,
    response, openStoredResponsesModal, storedResponsesTypes, selectingStoredResponseType,
    storedResponses, selectedStoredResponse, selectingStoredResponse,
    loadStoredResponse, closeDoubtModal, sendResponse, doubtResponse, responseFiles,
    deleteResponseAttachment, downloadResponseAttachment, uploadResponseAttachments,
    faqsTypes, filteredFaqs, selectingFaqType, selectingFaq, selectedFaq, savingFaqResponse,
    responseFaqLoader, doubtsContext, selectedStoredResponseType, cancelEdition,
    addNewDoubt, customFileInputRef, downloadImage, fullscreenImageBlob,
    setFullscreenImageBlob, stateEditor, onEditorStateChange, stateEditorFaq,
    doubts, NextDoubts, sendUpdateResponse, userRating, setUserRating,
    handleResponseRating, ClosedModal, assignReasonWaiting, propertiesForm, time_out, setDoubtResponse, audios, getAudioByResponse, setAudios, countAudio
}) => {
    const requiredFieldMsg = "Este campo es obligatorio"

    const updateFeatureToggler = () => {
        if (userData.idRole === 1 || userData.idRole === 2) {
            return sendUpdateResponse
        } else {
            return handleUpdate
        }
    }

    const editFeatureToggler = () => {
        if (userData.idRole === 1 || userData.idRole === 2) {
            doubtsContext.editionResponse.disable()
        } else {
            doubtsContext.editionDoubt.disable()
        }
    }

    const renderButtons = () => {
        let buttons = []
        if (!time_out && !Number.isInteger(focusedDoubt.id) &&
            doubtsContext.data.isCreating) {
            buttons.push(<ButtonModal
                key={buttons.length}
                text={"Guardar"}
                onClick={handleCreate}
                cssClasses={"marginr1"}
                icoClasses={"fas fa-save"}
            />)
        }

        if (!time_out && (Number.isInteger(focusedDoubt.id) &&
            !doubtsContext.data.doubtsFormEdition) ||
            !doubtsContext.data.responseFormEdition) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Guardar"}
                handleSubmit={updateFeatureToggler()}
                cssClasses={"marginr1 inline-block"}
                icoClasses={"fas fa-save"}
                formGroup={false}
            />)
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Cancelar"}
                handleSubmit={cancelEdition}
                buttonClasses={"light-red"}
                cssClasses={"marginr1 inline-block"}
                icoClasses={"fas fa-times"}
                formGroup={false}
            />)
        }

        if (!time_out && Number.isInteger(focusedDoubt.id) &&
            !doubtsContext.data.isCreating &&
            doubtsContext.data.doubtsFormEdition &&
            doubtsContext.data.responseFormEdition &&
            userData.idRole !== 3 && (caseRecord.stateId === 2 || caseRecord.stateId === 3)) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Responder"}
                handleSubmit={editFeatureToggler}
                cssClasses={"marginr1 inline-block"}
                formGroup={false}
                icoClasses={"fas fa-pencil-alt"}
            />)
        }

        if (!time_out && Number.isInteger(focusedDoubt.id) &&
            !doubtsContext.data.isCreating &&
            doubtsContext.data.doubtsFormEdition &&
            doubtsContext.data.responseFormEdition &&
            !(doubtResponse?.Response || doubtResponse?.id_faqs || caseRecord.stateId === 2 || caseRecord.stateId === 3 || caseRecord.stateId === 4) &&
            (userData.id === caseRecord.userId || userData.idRole !== 2)) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Editar"}
                handleSubmit={editFeatureToggler}
                cssClasses={"marginr1 inline-block"}
                formGroup={false}
                icoClasses={"fas fa-pencil-alt"}
            />)
        }

        if (!time_out && Number.isInteger(focusedDoubt.id) &&
            !doubtsContext.data.isCreating &&
            doubtsContext.data.doubtsFormEdition &&
            doubtsContext.data.responseFormEdition &&
            !(doubtResponse?.Response || doubtResponse?.id_faqs || caseRecord.stateId === 2 || caseRecord.stateId === 3) &&
            userData.idRole === 3) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Añadir otra duda"}
                cssClasses={"marginr1 inline-block"}
                handleSubmit={addNewDoubt}
                formGroup={false}
                icoClasses={"fas fa-plus"}
            />)
        }

        if (!time_out && !response && Number.isInteger(focusedDoubt.id) &&
            userData.idRole === 3 && caseRecord.Revision_accepted === 0) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Eliminar"}
                cssClasses={"marginb1 marginlauto inline-block"}
                buttonClasses={"light-red"}
                dataTarget={"#deleteDoubt"}
                icoClasses={"fas fa-trash-alt"}
            />)
        }
        if (!time_out && Number.isInteger(focusedDoubt.id) &&
            doubts.filter(d => d.response === false).length > 0 &&
            doubts.length - 1 !== doubtsIndex && !doubtsContext.data.isCreating &&
            doubtsContext.data.doubtsFormEdition) {
            buttons.push(<ButtonSave
                key={buttons.length}
                textBtn={"Siguiente Duda"}
                formGroup={false}
                cssClasses={"marginr1 inline-block"}
                handleSubmit={NextDoubts}
            />)
        }

        return buttons
    }
    const ImgIcon = ({ img, styImg }) => <img src={img} alt="iconWarnningObservations" className='icon' style={styImg ? styImg : { width: 30 }} />

    const ShowUserRating = ({ allowShow }) => (
        allowShow && ([
            <Rating
                stop={5}
                step={1}
                initialRating={userRating.rating}
                readonly={userRating.id}
                emptySymbol={<ImgIcon img={star_empty} styImg={{ width: 15 }} />}
                fullSymbol={<ImgIcon img={star_full} styImg={{ width: 15 }} />}
            />,
            <ButtonSave
                textBtn={"Ver valoración"}
                dataTarget={"#responseRating"}
                formGroup={false}
                cssClasses={"inline-block"}
                buttonPrimaryClass={"btn-link"}
                style={{ marginLeft: 10 }}
                buttonStyles={{ border: 0, fontWeight: 450, color: 'black', textDecoration: 'none', fontSize: 12 }}
            />
        ])
    )

    const ModalRevisionModal = () => (
        <SimpleBootstrapModal
            id={"revisionModal"}
            title={"Solicitar revisión"}
            message={"¿Estás seguro de solicitar la revisión del caso?"}
            footerCancelButton={false}
            footerButtons={[
                <ButtonModal id={"closeRevisionModal"} text={"No"} bsDimiss={"modal"} close={true} />,
                <ButtonModal text={"Sí"} onClick={""/* handleRequestRevision */} />
            ]}
        />
    )


    {/* <SimpleBootstrapModal id="audioRecord" modalClasses={"padding0"}
            title="Audio"
            fullScreen
            closeButtonId={"closeAudioRecord"}
            customCloseButton={<ButtonModal id={"closeAudioRecord"} text={"Cancelar"} bsTarget="#doubtForm" close={true} />}>
            <AudioRecord doubtResponse={doubtResponse} setDoubtResponse={setDoubtResponse} id_doubts={focusedDoubt.id} id_users={userData.id} audios={audios} setAudios={setAudios} getAudios={getAudioByResponse} caseRecord={caseRecord} userData={userData} />
        </SimpleBootstrapModal>*/}

    return ([
        <ButtonSave buttonId="triggerCreatedDoubtModal" dataTarget="#doubtCreated" cssClasses="none" />,
        <FullscreenImage blob={fullscreenImageBlob} previousModalButtonOpenerId="closeStoredResponses" />,
        <ModalRequestRevision assignReasonWaiting={assignReasonWaiting} caseData={caseRecord} propertiesForm={propertiesForm} />,
        <SimpleBootstrapModal
            id={"doubtCreated"}
            title={"Duda creada"}
            closeButtonId={"closeDoubtCreated"}
            cancelButtonMessage="Aceptar"
            footerButtons={[
                !caseRecord.teacherId && (<ButtonModal text="Solicitar revisión" key={"keyRevisionModal"} bsTarget={"#requestRevisionModal"} onClick={() => ClosedModal()} />),
                <ButtonModal text="Crear otra" key={"keyDoubtCreated"} bsTarget={"#doubtForm"} onClick={() => ClosedModal()} />
            ]}>
            <p className="text-center">¡Duda creada correctamente!</p>
            <p className="text-center">{!caseRecord.teacherId ? " Puedes crear otra duda o solicitar la revisión del caso." : "Puedes crear otra duda."}</p>
        </SimpleBootstrapModal>,

        <SimpleBootstrapModal
            id={"deleteDoubt"}
            closeButtonId={"closeDeleteDoubt"}
            title={"Eliminar duda"}
            message={`¿Desea eliminar la duda (${typeof focusedDoubt.Doubt != "undefined" && focusedDoubt.Doubt.length > 100 ? `${focusedDoubt.Doubt.substr(0, 97)}...` : focusedDoubt.Doubt})?`}
            footerCancelButton={false}
            footerButtons={[
                <ButtonModal id={"closeDeleteDoubtModal"} key={"keyCloseDeleteDoubtModal"} text={"Atrás"} bsTarget={"#doubtForm"} close={true} />,
                <ButtonModal text={"Sí"} key={"keyConfirmDeleteDoubtModal"} onClick={handleDelete} />
            ]}
        />,

        <SimpleBootstrapModal
            id="faqsList"
            modalClasses={"padding0"}
            title="Lista de preguntas frecuentes"
            fullScreen={true}
            customCloseButton={<ButtonModal id={"closeFaqsList"} text={"Cancelar"} bsTarget="#doubtForm" close={true} />}
        >
            <CustomSelect
                data={faqsTypes}
                nameSelect={"faqType"}
                label={"Tipo:"}
                onChange={selectingFaqType}
                value={faqsTypes?.forEach(faqType => {
                    if (faqType.value === selectedFaq.idStoredResponseType) {
                        return faqType
                    }
                })}
            />
            <CustomSelect
                data={filteredFaqs}
                nameSelect={"faqs"}
                label={"Preguntas frecuentes:"}
                onChange={selectingFaq}
                value={filteredFaqs.forEach(filteredFaq => {
                    if (filteredFaq.value === selectedFaq.idStoredResponse) {
                        return filteredFaq
                    }
                })}
            />
            <div className="form-group">
                <label htmlFor="faqResponse">Respuesta: </label>
                <Editor
                    spellCheck
                    toolbarHidden={true}
                    readOnly={true}
                    editorState={stateEditorFaq.editorState}
                />
            </div>
            {
                typeof selectedFaq?.response != "undefined" && selectedFaq.response ?
                    <ButtonSave
                        textBtn={"Seleccionar"}
                        dataTarget={"#doubtForm"}
                        handleSubmit={savingFaqResponse}
                        cssClasses={""}
                    /> :
                    null
            }
        </SimpleBootstrapModal>,



        <SimpleBootstrapModal
            id={"storedResponses"}
            modalClasses={"padding0"}
            title="Respuestas guardadas"
            fullScreen={true}
            customCloseButton={<ButtonModal id={"closeStoredResponses"} text={"Cancelar"} bsTarget="#doubtForm" close={true} />}
        >
            <CustomSelect
                data={storedResponsesTypes}
                nameSelect={"storedResponseType"}
                label={"Tipo:"}
                value={storedResponsesTypes.forEach(storedResponseType => {
                    if (storedResponseType.value === selectedStoredResponseType?.value) {
                        return storedResponseType
                    }
                })}
                onChange={selectingStoredResponseType}
            />

            <CustomSelect
                data={storedResponses}
                nameSelect={"storedResponse"}
                label={"Respuesta:"}
                value={storedResponses.forEach(storedResponse => {
                    if (storedResponse.value === selectedStoredResponse?.value) {
                        return storedResponse
                    }
                })}
                onChange={selectingStoredResponse}
            />

            {
                typeof selectedStoredResponse?.label !== "undefined" && selectedStoredResponse.label ?
                    <ButtonSave
                        textBtn={"Seleccionar"}
                        dataTarget={"#doubtForm"}
                        handleSubmit={loadStoredResponse}
                        cssClasses={""}
                    />
                    : null
            }
        </SimpleBootstrapModal>,

        <SimpleBootstrapModal
            id={"responseRating"}
            modalClasses={"padding0"}
            closeButtonId={"closeResponseRating"}
            closeFunction={() => setUserRating({ id_response: 0, rating: 0, observation: '' })}
            title="Valorar respuesta"
            fullScreen={false}

            footerCancelButton={false}
            footerButtons={
                (userRating.id === undefined || userRating.id === 0) && doubtResponse?.Response !== undefined ?
                    [
                        <ButtonModal id={"closeResponseRating"} key={"keyCloseStoredResponses"} text={"Cancelar"} bsTarget="#doubtForm" close={true} onClick={() => setUserRating({})} />,
                        <ButtonModal key={"keyConfirmResponseRating"} text={"Guardar"} onClick={handleResponseRating} />
                    ]
                    : <ButtonModal id={"closeResponseRating"} key={"keyCloseStoredResponses"} text={"Aceptar"} bsTarget="#doubtForm" close={true} />
            }
        >
            <div className="text-center mt-2">
                <Rating
                    stop={5}
                    step={1}
                    initialRating={userRating.rating}
                    readonly={userRating.id}
                    emptySymbol={<ImgIcon img={star_empty} />}
                    fullSymbol={<ImgIcon img={star_full} />}
                    onChange={(rate) => setUserRating({ ...userRating, rating: rate })}
                />
            </div>
            <div className="form-group mt-2">
                <label>Observaciones</label>
                <textarea className="form-control" aria-label="With textarea"
                    disabled={userRating.id}
                    rows={10}
                    value={userRating.observation}
                    onChange={(txt) => setUserRating({ ...userRating, observation: txt.target.value })} />
            </div>
        </SimpleBootstrapModal>,

        <SimpleBootstrapModal
            id={"doubtForm"}
            modalClasses={"padding0"}
            fullScreen={true}
            closeButtonId={"closeDoubtForm"}
            closeFunction={closeDoubtModal}
            bodyClose={true}
            footerVisibility={false}
            cancelButtonMessage="Atrás"
            title={"Duda " + (doubtsContext.data.isCreating ? '' : doubtsIndex + 1)}
            bodyButtons={[renderButtons()]}
        >
            <div className="row mt-3">
                <hr />
                <div className="col-12">
                    <div className="row">
                        <div className='col-12 col-sm-6'>
                            <label>Doctor: </label>
                            <TextInputCustom
                                cssClassesGroup={"marginlr0"}
                                value={caseRecord.doctor}
                                disabled={"disabled"}
                            />
                        </div>
                        <div className='col-12 col-sm-6'>
                            <label>Paciente: </label>
                            <TextInputCustom
                                cssClassesGroup={"marginlr0"}
                                value={caseRecord.patientName}
                                disabled={"disabled"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <div className="col-12">
                                <CustomSelect
                                    data={doubtsTypes}
                                    nameSelect={"doubtType"}
                                    label={"*Tipo de duda:"}
                                    onChange={handleSelectTypeChange}
                                    divClasses="marginlr0"
                                    value={
                                        doubtsTypes.map(doubtType => {
                                            if (doubtType.value === focusedDoubt.value ||
                                                doubtType.value === focusedDoubt.id_doubts_types) {
                                                return doubtType
                                            }
                                        })
                                    }
                                    requiredValidation={{
                                        condition: validators.caseType && !validators.caseType.validFlags.required
                                            && !validators.caseType.properties.pristine ||
                                            validators.caseType && !validators.caseType.validFlags.required &&
                                            submitWithErrors,
                                        msg: requiredFieldMsg
                                    }}
                                    disabled={doubtsContext.data.doubtsFormEdition}
                                />
                            </div>
                            <div className="col-12">
                                <div className="form-group marginlr0">
                                    <label>*Duda</label>
                                    <textarea
                                        name="doubt"
                                        style={{ maxHeight: 350, height: 150 }}
                                        className='m-0 w-100'
                                        onChange={handleInputChange}
                                        value={typeof formObj != "undefined" &&
                                            formObj.doubt ? formObj.doubt : ""}
                                        disabled={doubtsContext.data.doubtsFormEdition}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <h5>Adjuntos de la pregunta</h5>
                            <OIFDoubtAttachments
                                fileInputRef={customFileInputRef}
                                files={doubtFiles}
                                formName="doubtDocuments"
                                id={"doubtsAttachments"}
                                nFiles={5}
                                cssClass={"margin0"}
                                formats={".pdf, .docx, .doc, .pptx, .ppt, .png, .jpg, .jpeg"}
                                idUser={userData.id}
                                idEntityOwner={focusedDoubt.id_users}
                                entityId={focusedDoubt.id}
                                uploader={uploadAttachments}
                                deleter={deleteAttachment}
                                downloader={downloadAttachment}
                                imageDownloader={downloadImage}
                                deletable={true}
                                fileElemCSS={"width100"}
                                setFullscreenImageBlob={setFullscreenImageBlob}
                                readOnly={!doubtsContext.data.doubtsFormEdition}
                            />
                        </div>
                    </div>
                </div>
            </div >

            {caseRecord.stateId !== 1 ?
                <div className="row mt-2">
                    <div style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "0.5rem",
                        paddingLeft: 15,
                        paddingTop: 15,
                        paddingBottom: 15,
                        background: 'black',
                        color: 'white',
                        marginBottom: 10
                    }}>
                        <h4 className="modal-title" style={{ fontWeight: 'normal', alignSelf: 'self-start' }}>Respuesta</h4>
                    </div>
                    <div className="col-12">
                        <ShowUserRating allowShow={userData.idRole === 3 && userRating.id > 0} />

                        {(userData.idRole === 3 || (userData.idRole === 2 || userData.idRole === 1) && doubtsContext.data.responseFormEdition) && (
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <pre id="responseTag">{responseFaqLoader() || <i>Pregunta pendiente de responder.</i>}</pre>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <h5>Adjuntos de la respuesta</h5>
                                    <OIFCaseAttachments
                                        files={responseFiles}
                                        formName="responseDocuments"
                                        id={"responseAttachments"}
                                        nFiles={5}
                                        cssClass={"margin0"}
                                        formats={".pdf, .docx, .doc, .pptx, .ppt, .png, .jpg, .jpeg"}
                                        idUser={userData.idRole === 2 || userData.idRole === 1 ? caseRecord.teacherId : userData.id}
                                        idEntityOwner={caseRecord.teacherId}
                                        entityId={doubtResponse?.id}
                                        uploader={uploadResponseAttachments}
                                        deleter={deleteResponseAttachment}
                                        downloader={downloadResponseAttachment}
                                        imageDownloader={downloadImage}
                                        deletable={true}
                                        setFullscreenImageBlob={setFullscreenImageBlob}
                                    />
                                </div>
                            </div>
                        )}
                        {
                            (userData.idRole === 3 &&
                                (userRating.id === undefined || userRating.id === 0) && doubtResponse?.Response !== undefined) ?
                                <>
                                    <ButtonSave
                                        textBtn={"Valorar respuesta"}
                                        dataTarget={"#responseRating"}
                                        formGroup={false}
                                        cssClasses={"marginr1 inline-block"}
                                        style={{ marginLeft: 10 }}
                                    />
                                </>
                                : null
                        }
                        {(((userRating.id === undefined || userRating.id === 0) && doubtResponse?.Response !== undefined) && countAudio > 0) && (
                            <ButtonSave
                                textBtn={`Ver audios ${countAudio}`}
                                dataTarget={"#audioRecord"}
                                cssClasses={"inline-block"}
                                icoClasses="fas fa-microphone"
                            />
                        )}
                        {
                            (userData.idRole === 1 || userData.idRole === 2) && !doubtsContext.data.responseFormEdition && focusedDoubt.id ?
                                <>
                                    <ButtonSave
                                        textBtn={`Grabar audio ${countAudio}`}
                                        dataTarget={"#audioRecord"}
                                        cssClasses={"inline-block"}
                                        icoClasses="fas fa-microphone"
                                    />
                                    <ButtonSave
                                        textBtn={"Preguntas frecuentes"}
                                        dataTarget={"#faqsList"}
                                        cssClasses={"inline-block"}
                                        handleSubmit={openStoredResponsesModal}
                                    />
                                    <ButtonSave
                                        textBtn={"Respuestas guardadas"}
                                        dataTarget={"#storedResponses"}
                                        cssClasses={"inline-block"}
                                        handleSubmit={openStoredResponsesModal}
                                    />

                                    <hr style={{ marginTop: 0 }} />
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <Editor
                                                spellCheck
                                                editorState={stateEditor.editorState}
                                                toolbarClassName="rdw-storybook-toolbar"
                                                wrapperClassName="rdw-storybook-wrapper"
                                                editorClassName="rdw-storybook-editor"
                                                onEditorStateChange={onEditorStateChange}
                                            />
                                        </div>
                                        {
                                            focusedDoubt.id && (
                                                <div className="col-12 col-sm-6">
                                                    <h5>Adjuntos de la respuesta</h5>
                                                    <OIFCaseAttachments
                                                        files={responseFiles}
                                                        formName="responseDocuments"
                                                        id={"responseAttachments"}
                                                        nFiles={5}
                                                        cssClass={"margin0"}
                                                        formats={".pdf, .docx, .doc, .pptx, .ppt, .png, .jpg, .jpeg"}
                                                        idUser={userData.idRole === 2 || userData.idRole === 1 ? caseRecord.teacherId : userData.id}
                                                        idEntityOwner={caseRecord.teacherId}
                                                        entityId={doubtResponse?.id}
                                                        uploader={uploadResponseAttachments}
                                                        deleter={deleteResponseAttachment}
                                                        downloader={downloadResponseAttachment}
                                                        imageDownloader={downloadImage}
                                                        deletable={true}
                                                        setFullscreenImageBlob={setFullscreenImageBlob}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </> : null
                        }
                    </div>

                </div>
                : null}
        </SimpleBootstrapModal>
    ])
}

export { DoubtsFormUI }