//#region 'IMPORTS'
import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import DoubtsScreenUI from "./DoubtsScreenUI"
import { useDoubtsTypes } from '../../Hooks/useDoubtsTypes'
import { UserContext } from '../../Contexts/User/UserContext'
import { useFiles } from '../../Hooks/useFiles'
import { useHistory } from 'react-router-dom'
import { useShowView } from '../../Hooks/useShowView'
//#endregion

const DoubtsScreen = ({ doubts, getDoubts, observations, caseRecord, assignReasonWaiting, propertiesForm, time_out }) => {
    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Buscar duda' },
        { value: 2, name: 'Buscar observación' },
    ]
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'CUSTOMIZED USE STATE'
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { uploadFiles, getFilesInfo, removeFile, download, downloadFileByPath } = useFiles()
    const { retrieveDoubtsTypes } = useDoubtsTypes()
    //#endregion

    //#region 'USE STATE'
    const [focusedDoubt, setFocusedDoubt] = useState({})
    const [currentObservations, setCurrentObservations] = useState({})

    const [doubtFiles, setDoubtFiles] = useState([])
    const [observationFiles, setObservationFiles] = useState([])

    const [doubtsTypes, setDoubtsTypes] = useState([])
    const [action, setAction] = useState(false)
    const [actionType, setActionType] = useState("none")
    const [doubtFormDisabled, setDoubtFormDisabled] = useState(false)
    const [textFilter, setTextFilter] = useState("")
    const [filteredDoubts, setFilteredDoubts] = useState(doubts)
    //#endregion



    const changeAction = () => {
        setAction(!action)
    }

    const newDoubt = () => {
        setActionType("create")
    }

    const openModalModify = (doubt) => {
        setActionType("edit")
        setFocusedDoubt(doubt)
        changeAction()
    }

    const openModalObservationModify = async (observation) => {
        setCurrentObservations(observation)
    }

    const resetEditDoubt = () => {
        setFocusedDoubt({})
    }

    //#region FILTER
    
    //#endregion


    const filterDoubts = (e, updatedDoubts) => {
        try {
            let name = e.target.name
            let value = e.target.value
            if (name === "textFilter") {
                setTextFilter(value)
                setFilteredDoubts(doubts.filter(doubt => doubt.Doubt.toLowerCase().includes(value.toLowerCase())))
            }
        } catch (e) {
            setFilteredDoubts(updatedDoubts.filter(doubt => doubt.Doubt.toLowerCase().includes(textFilter.toLowerCase())))
        }
    }

    //#endregion
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        let userData = await userContext.retrieveUserData()
        if (!Number.isInteger(userData.idRole)) {
            history.push("/")
            window.location.reload()
        } else {
            let doubtsTypesTmp = await retrieveDoubtsTypes()
            doubtsTypesTmp = doubtsTypesTmp.map(caseType => {
                return { label: caseType.Type, value: caseType.id }
            })

            setDoubtsTypes(doubtsTypesTmp)
        }
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        let userData = await userContext.retrieveUserData()
        if (!Number.isInteger(userData.idRole)) {
            history.push("/")
            window.location.reload()
        } else {
            if (Number.isInteger(focusedDoubt.id)) {
                const dataFileDoubt = await getFilesInfo("doubt", focusedDoubt.id)
                setDoubtFiles(dataFileDoubt)
            } else {
                setDoubtFiles([])
            }
            if (Number.isInteger(currentObservations.id)) {
                const dataFileObservation = await getFilesInfo("observation", currentObservations.id)
                setObservationFiles(dataFileObservation)
            } else {
                setObservationFiles([])
            }
        }
    }, [focusedDoubt, currentObservations])

    const uploadAttachments = async (files, formName, entityId) => {
        await uploadFiles(files, formName, entityId)
        setDoubtFiles(await getFilesInfo("doubt", Number.isInteger(focusedDoubt.id) ? focusedDoubt.id : entityId))
    }

    const uploadObservationAttachments = async (files, formName, entityId) => {
        await uploadFiles(files, formName, entityId, caseRecord.id)
        setObservationFiles(await getFilesInfo("observation", Number.isInteger(currentObservations.id) ? currentObservations.id : entityId))
    }

    const deleteAttachment = async (idFile) => {
        if (await removeFile("doubt", idFile)) {
            setDoubtFiles(await getFilesInfo("doubt", focusedDoubt.id))
        }
    }

    const deleteObservationAttachment = async (idFile) => {
        if (await removeFile("observation", idFile)) {
            setObservationFiles(await getFilesInfo("observation", currentObservations.id))
        }
    }

    const downloadAttachment = async (idFile, name, mimetype, extension) => {
        download("doubt", idFile, name, mimetype, extension)
    }

    const downloadObservationAttachment = async (idFile, name, mimetype, extension) => {
        download("observation", idFile, name, mimetype, extension)
    }


    const downloadImageObservation = async (path, mimetype) => {
        return await downloadFileByPath(path, mimetype)
    }

    return <DoubtsScreenUI
        doubts={doubts}
        getDoubts={getDoubts}
        action={action}
        newDoubt={newDoubt}
        actionType={actionType}
        setActionType={setActionType}
        changeAction={changeAction}
        doubtsTypes={doubtsTypes}
        openModalModify={openModalModify}
        focusedDoubt={focusedDoubt}
        setFocusedDoubt={setFocusedDoubt}
        resetEditDoubt={resetEditDoubt}
        filterDoubts={filterDoubts}
        filteredDoubts={filteredDoubts}
        setFilteredDoubts={setFilteredDoubts}
        cbfilterObj={cbfilterObj}
        uploadAttachments={uploadAttachments}
        deleteAttachment={deleteAttachment}
        downloadAttachment={downloadAttachment}
        userData={userContext.userData}
        doubtFiles={doubtFiles}
        caseRecord={caseRecord}
        doubtFormDisabled={doubtFormDisabled}
        setDoubtFormDisabled={setDoubtFormDisabled}
        setDoubtFiles={setDoubtFiles}
        observationFile={{
            get: observationFiles,
            set: setObservationFiles,
            upload: uploadObservationAttachments,
            delete: deleteObservationAttachment,
            download: downloadObservationAttachment,
            downloadImage: downloadImageObservation
        }}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        observations={observations}
        currentObservation={{
            set: setCurrentObservations,
            get: currentObservations,
            openModal: openModalObservationModify
        }}
        assignReasonWaiting={assignReasonWaiting}
        propertiesForm={propertiesForm}
        time_out={time_out}
    />
}

export default DoubtsScreen