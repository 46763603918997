import { useState, useEffect } from 'react';
import { getCountResponseRating } from '../Services/ResponseRating/getCountResponseRating';
import { getResponseRating } from '../Services/ResponseRating/getResponseRating'
import { getResponseRatingByUser } from '../Services/ResponseRating/getResponseRatingByUser'
import { postResponseRating } from '../Services/ResponseRating/postResponseRating'

const useResponseRating = (idUser = undefined, idRole = undefined) => {

    const [responseRating, setResponseRating] = useState([])

    const getDataResponseRating = async (id_response) => {
        return await getResponseRating(id_response)
    }

    const getDataResponseRatingByUser = async (user_id, id_role) => {
        return await getResponseRatingByUser(user_id, id_role)
    }

    const postInsertResponseRating = async (data) => {
        return await postResponseRating(data)
    }

    const setDataResponseRating = async () => {
        setResponseRating(await getDataResponseRatingByUser(idUser, idRole))
    }

    const countResponsesRating = async (idUser, idRole) => await getCountResponseRating(idUser, idRole)

    useEffect(() => {
        if(idUser && idRole){
            setDataResponseRating()
        }
    }, [])


    return { responseRating, getDataResponseRating, postInsertResponseRating, getDataResponseRatingByUser, countResponsesRating }

}

export { useResponseRating }