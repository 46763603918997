import React, { useState } from 'react';
import { useFaqs } from '../../../Hooks/useFaqs';
import { useFaqsTypes } from '../../../Hooks/useFaqsTypes'
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import CustomSelect from '../CustomSelect';
import SimpleBootstrapModal from '../SimpleBootstrapModal';
import ButtonModal from '../ButtonModal';

const ModalFaqsForm = ({ bsTarget, selected, handleSelect }) => {

    //#region 'VARIABLES'
    const objState = { editorState: '' }
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { faqs } = useFaqs()
    const { faqsTypes } = useFaqsTypes()
    //#endregion

    //#region 'USE STATES'
    const [filteredFaqs, setFilteredFaqs] = useState(faqs)
    const [stateEditorFaq, setstateEditorFaq] = useState(objState)
    //#endregion

    //#region 'FUNCTIONS'
    const UpdateStateEditorFaq = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditorFaq({ editorState: editorState });
    }

    const selectingFaqType = e => {
        selected.setSelect({ ...selected.select, idStoredResponseType: e.value })
        setFilteredFaqs(faqs.filter(faq => { return faq.type === e.value }))
    }

    const selectingFaq = e => {
        selected.setSelect({
            ...selected.select, idStoredResponse: e.value,
            response: e.response, question: e.label
        })
        UpdateStateEditorFaq(e.response)
    }

    //#endregion

    return (
        <SimpleBootstrapModal
            id="faqsListModal"
            modalClasses={"padding0"}
            title="Lista de preguntas frecuentes"
            fullScreen={true}
            customCloseButton={[
                <ButtonModal id={"closeFaqsList"} text={"Cancelar"} bsTarget={bsTarget} close={true} />,
                typeof selected.select?.response !== "undefined" && selected.select.response ?
                    <ButtonModal text={"Seleccionar"} bsTarget={bsTarget} onClick={handleSelect} /> : null
            ]}>
            <CustomSelect
                data={faqsTypes}
                nameSelect={"faqType"}
                label={"Tipo:"}
                onChange={selectingFaqType}
                value={faqsTypes?.forEach(faqType => {
                    if (faqType.value === selected.select?.idStoredResponseType) {
                        return faqType
                    }
                })}
            />
            <CustomSelect
                data={filteredFaqs}
                nameSelect={"faqs"}
                label={"Preguntas frecuentes:"}
                onChange={selectingFaq}
                value={filteredFaqs.forEach(filteredFaq => {
                    if (filteredFaq.value === selected.select?.idStoredResponse) {
                        return filteredFaq
                    }
                })}
            />
            <div className="form-group">
                <label htmlFor="faqResponse">Respuesta: </label>
                <Editor spellCheck toolbarHidden={true} readOnly={true} editorState={stateEditorFaq.editorState} />
            </div>
        </SimpleBootstrapModal>
    )
}

export { ModalFaqsForm }