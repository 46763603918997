//#region 'IMPORTS'
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react'
import { UserContext } from '../../../Contexts/User/UserContext'
import { DoubtsContext } from '../../../Contexts/Doubts/DoubtsContext'
import { DoubtsFormUI } from './DoubtsFormUI'
import { useStoredResponsesTypes } from '../../../Hooks/useStoredResponsesTypes'
import { useStoredResponses } from '../../../Hooks/useStoredResponses'
import { useDoubts } from '../../../Hooks/useDoubts'
import { useResponse } from '../../../Hooks/useResponse'
import { useFiles } from '../../../Hooks/useFiles'
import { useFaqs } from '../../../Hooks/useFaqs'
import { useFaqsTypes } from '../../../Hooks/useFaqsTypes'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useResponseRating } from '../../../Hooks/useResponseRating'
import { FileContext } from '../../../Contexts/Files/FileContext'
import { useAudioResponse } from '../../../Hooks/useAudioResponse'
//#endregion

const DoubtsForm = ({ changeAction, doubtsIndex, setDoubtsIndex, doubtsTypes,
    caseRecord, getDoubts, focusedDoubt, setFocusedDoubt, actionType,
    filterDoubts, uploadAttachments, userData, doubtFiles, deleteAttachment,
    downloadAttachment, setDoubtFiles, doubts, assignReasonWaiting,
    propertiesForm, time_out }) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const objState = { editorState: '' }
    const objSelectedFaq = {
        idStoredResponseType: "",
        idStoredResponse: "",
        response: "",
        question: ""
    }
    const objValidators = {
        doubtType: { validFlags: { required: false }, properties: { pristine: true }, flags: { required: true } },
        doubt: { validFlags: { required: false, maxLen: false }, properties: { pristine: true }, flags: { required: true, maxLen: 30000 } }
    }
    const objForm = {
        id: typeof focusedDoubt != "undefined" ? focusedDoubt.id : "",
        doubtType: typeof focusedDoubt != "undefined" ? focusedDoubt.id_doubts_types : "",
        doubt: typeof focusedDoubt != "undefined" ? focusedDoubt.Doubt : ""
    }
    const objResponseRating = { id_response: 0, rating: 0, observation: '' }
    //#endregion

    //#region 'USE REF'
    const customFileInputRef = useRef()
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    const doubtsContext = useContext(DoubtsContext)
    const fileContext = useContext(FileContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { faqsTypes } = useFaqsTypes()
    const { faqs } = useFaqs()
    const { uploadFiles, getFilesInfo, removeFile, download, downloadFileByPath } = useFiles()
    const { createResponse, retrieveResponse, updateResponse } = useResponse()
    const { retrieveStoredResponsesByType } = useStoredResponses()
    const { retrieveStoredResponsesTypes, } = useStoredResponsesTypes()
    const { createDoubt, updateDoubt, removeDoubt } = useDoubts()
    const [countAudio, setCountAudio] = useState(0)
    const { getAudioResponse } = useAudioResponse()
    //#endregion

    //#region 'USE STATES'
    const [fullscreenImageBlob, setFullscreenImageBlob] = useState("")
    const [storedResponsesTypes, setStoredResponsesTypes] = useState([])
    const [doubtResponse, setDoubtResponse] = useState({})
    const [responseFiles, setResponseFiles] = useState([])
    const [response, setResponse] = useState("")
    const [submitWithErrors, setSubmitWithErrors] = useState(false)
    const [selectedStoredResponseType, setSelectedStoredResponseType] = useState()
    const [filteredFaqs, setFilteredFaqs] = useState(faqs)
    const [selectedFaq, setSelectedFaq] = useState(objSelectedFaq)
    const [storedResponses, setStoredResponses] = useState([])
    const [selectedStoredResponse, setSelectedStoredResponse] = useState()
    const [validators, setValidators] = useState(objValidators)
    const [formObj, setFormObj] = useState(objForm)
    const [stateEditor, setstateEditor] = useState(objState)
    const [stateEditorFaq, setstateEditorFaq] = useState(objState)
    const { getDataResponseRating, postInsertResponseRating } = useResponseRating()
    const [userRating, setUserRating] = useState(objResponseRating)

    const [audios, setAudios] = useState([])
    //#endregion

    //#region 'FUNCTIONS'


    const getAudioByResponse = async (id) => {
        try {
            if (id) {
                let data = await getAudioResponse(id)
                console.log(data, data?.length)
                setCountAudio(data?.length)
                setAudios(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const onEditorStateChange = (editorState) => {
        setstateEditor({
            editorState: editorState,
        });
        const html = draftToHtml(convertToRaw(stateEditor.editorState.getCurrentContent()))
        setDoubtResponse({ ...doubtResponse, Response: html })
        setResponse(html)
    };

    const propertiesEditor = () => {
        const contentBlock = htmlToDraft('');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    const getDataUserRating = async (idResponse) => {
        let responseRating = await getDataResponseRating(idResponse)
        if (responseRating) {
            if (responseRating.id > 0) {
                setUserRating(responseRating)
            } else {
                setUserRating({ ...userRating, id_response: idResponse })
            }
        } else {
            setUserRating({ ...userRating, id_response: idResponse })
        }
    }

    const formatStoredResponsesTypes = values => values.map(value => { return { value: value.id, label: value.Name } })

    if (focusedDoubt !== "undefined" && focusedDoubt.id !== formObj.id) {
        setFormObj(objForm)
    }

    const openStoredResponsesModal = async () => {
        retrieveStoredResponsesTypes()
    }

    const validate = (field, value) => {
        var flags = {}

        if (field.flags.required) {
            let requiredFlag = false
            if (value || Number.isInteger(Number.parseInt(value))) {
                requiredFlag = true
            }
            flags.required = requiredFlag
        }

        if (field.flags.maxLen) {
            let maxLenFlag = false
            if (field.flags.maxLen >= value.length) {
                maxLenFlag = true
            }
            flags.maxLen = maxLenFlag
        }

        return flags
    }

    const handleSelectTypeChange = (e) => {
        setFocusedDoubt(e)
        let tmpValidators = validators
        let validationRes = validate(tmpValidators.doubtType, e.value)
        tmpValidators.doubtType.validFlags = validationRes
        tmpValidators.doubtType.properties.pristine = false
        setValidators(tmpValidators)
        setFormObj({ ...formObj, doubtType: e.value })
    }

    const handleInputChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        let tmpValidators = validators
        let validationRes = validate(tmpValidators[name], value)
        tmpValidators[name].validFlags = validationRes
        tmpValidators[name].properties.pristine = false
        setFormObj({ ...formObj, [name]: value })
    }

    const validateFields = () => {
        let isValid = true
        for (const key in formObj) {
            if (key === "doubt" && !Boolean(formObj[key])) {
                isValid = false
            }

            if (key === "doubtType" &&
                !Boolean(Number.isInteger(Number.parseInt(formObj[key])))) {
                isValid = false
            }
        }

        return isValid
    }

    const validateValidators = () => {
        let isValid = true
        for (const key in validators) {
            for (const subKey in validators[key]) {
                if (!validators[key][subKey]) {
                    isValid = false
                }
            }
        }
        return isValid
    }

    const handleCreate = async () => {
        if (validateFields() && validateValidators()) {
            let result = await createDoubt(caseRecord.id, formObj.doubtType, formObj.doubt)
            if (Number.isInteger(result)) {
                await customFileInputRef.current.uploadInMemoryFiles(result)
                doubtsContext.isCreating.enable()
                doubtsContext.editionDoubt.disable()
                document.getElementById("triggerCreatedDoubtModal").click()
                let doubtsList = await getDoubts()
                let doubt = (doubtsList.filter(doubt => doubt.id === result))[0]
                setFocusedDoubt(doubt)
                filterDoubts(undefined, doubtsList)
                changeAction()
                closeDoubtModal()
            }
        } else {
            toast("No has rellenado todos los campos", { type: 'warning', autoClose: 3000 })
            setSubmitWithErrors(true)
        }
    }

    const handleDelete = async () => {
        if (await removeDoubt(focusedDoubt.id)) {
            filterDoubts(undefined, await getDoubts())
            document.getElementById("closeDeleteDoubt").click()
            closeDoubtModal()
        }
    }

    const handleUpdate = async () => {
        if (validateFields && validateValidators()) {
            let result = await updateDoubt(formObj.id, formObj.doubtType, formObj.doubt)
            if (result) {
                doubtsContext.editionDoubt.enable()
                filterDoubts(undefined, await getDoubts())
            } else {
                setSubmitWithErrors(true)
            }
        }
    }

    const resetFormData = () => {
        setFormObj({ id: "", doubtType: "", doubt: "" })
        setFocusedDoubt({})
    }

    const selectingFaqType = e => {
        setSelectedFaq({ ...selectedFaq, idStoredResponseType: e.value })
        setFilteredFaqs(faqs.filter(faq => {
            return faq.type === e.value
        }))
    }

    const selectingFaq = e => {
        setSelectedFaq({
            ...selectedFaq, idStoredResponse: e.value,
            response: e.response,
            question: e.label
        })
        UpdateStateEditorFaq(e.response)
    }

    const savingFaqResponse = async () => {
        let msg = "Te añado un enlace que te ofrecerá más información sobre: "
        setResponse(response + "<br/>" + msg + `<a target="_blank" href="https://oifformacion.dyndns.org/main-screen/faq/${selectedFaq.idStoredResponse}">${selectedFaq.question}</a>`)
        UpdateStateEditor((response === undefined ? '' : response) + "<br/>" + msg + `<a target="_blank" href="https://oifformacion.dyndns.org/main-screen/faq/${selectedFaq.idStoredResponse}">${selectedFaq.question}</a>`)
    }

    const selectingStoredResponseType = async e => {
        setStoredResponses(await retrieveStoredResponsesByType(e.value))
        setSelectedStoredResponseType(e)
    }

    const selectingStoredResponse = e => {
        setSelectedStoredResponse(e)
    }

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }

    const UpdateStateEditorFaq = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditorFaq({ editorState: editorState });
    }

    const loadStoredResponse = () => {
        setResponse(response + "<br/>" + selectedStoredResponse.label)
        UpdateStateEditor((response === undefined ? '' : response) + "<br/>" + selectedStoredResponse.label)
        setSelectedStoredResponseType()
        setSelectedStoredResponse()
    }

    const handleResponseInputChange = e => {
        setResponse(e.target.value)
    }

    const closeDoubtModal = () => {
        setUserRating(objResponseRating)
        cancelEdition()
        doubtsContext.editionDoubt.enable()
        doubtsContext.editionResponse.enable()
        doubtsContext.isCreating.disable()
        setFocusedDoubt({})
        setResponse("")
        setDoubtFiles([])
        setResponseFiles([])
        resetFormData()
        customFileInputRef.current.forceEmptyList()
    }

    const ClosedModal = () => {
        setUserRating(objResponseRating)
        doubtsContext.isCreating.enable()
        doubtsContext.editionDoubt.disable()
        setFocusedDoubt({})
        setResponse("")
        setDoubtFiles([])
        setResponseFiles([])
        customFileInputRef.current.forceEmptyList()
    }

    const sendResponse = async () => {
        if (await createResponse(focusedDoubt.id, response, null)) {
            let doubts = await getDoubts()
            filterDoubts(undefined, doubts)
            doubtsContext.editionResponse.enable()
            setDoubtResponse(await retrieveResponse(focusedDoubt.id))
        }
    }

    const sendUpdateResponse = async () => {
        if (doubtResponse.id) {
            let res = await updateResponse(doubtResponse.id, focusedDoubt.id, response, doubtResponse.id_faqs)
            if (!res) {
                let doubts = await getDoubts()
                filterDoubts(undefined, doubts)
                doubtsContext.editionResponse.enable()
                setDoubtResponse(await retrieveResponse(focusedDoubt.id))
            }
        } else {
            sendResponse()
        }
    }

    const uploadResponseAttachments = async (files, formName, entityId) => {
        if (await uploadFiles(files, formName, entityId, focusedDoubt.id)) {
            let responseId = doubtResponse.id
            if (!Number.isInteger(doubtResponse.id)) {
                let responseData = await retrieveResponse(focusedDoubt.id)
                setDoubtResponse(responseData)
                responseId = responseData.id
            }
            setResponseFiles(await getFilesInfo("response", responseId))
        }
    }

    const deleteResponseAttachment = async (idFile) => {
        if (await removeFile("response", idFile)) {
            setResponseFiles(await getFilesInfo("response", doubtResponse.id))
        }
    }

    const downloadResponseAttachment = async (idFile, name, mimetype, extension) => {
        download("response", idFile, name, mimetype, extension)
    }

    const responseFaqLoader = () => {
        if (Number.isInteger(doubtResponse.id)) {
            return <Editor
                spellCheck
                toolbarHidden={true}
                readOnly={true}
                editorState={stateEditor.editorState} />

        }
    }

    const cancelEdition = async () => {
        doubtsContext.editionDoubt.enable()
        setDoubtResponse({})
        setResponse("")
        filterDoubts(undefined, await getDoubts())
        let doubts = await getDoubts()
        filterDoubts(undefined, doubts)
        doubtsContext.editionResponse.enable()
    }

    const addNewDoubt = () => {
        setFocusedDoubt({})
        setFormObj({
            id: "",
            doubtType: "",
            doubt: ""
        })

        doubtsContext.isCreating.enable()
        doubtsContext.editionDoubt.disable()
    }

    const downloadImage = async (path, mimetype) => {
        return await downloadFileByPath(path, mimetype)
    }

    const NextDoubts = () => {
        setUserRating(objResponseRating)
        //setFocusedDoubt({})
        setDoubtFiles([])
        setResponseFiles([])
        customFileInputRef.current.forceEmptyList()
        if (doubts !== undefined || doubts.length > 0) {
            let filterData = doubts.filter(d => d.response === false)
            if (filterData.length > 0) {
                let index = doubtsIndex
                index += 1
                if (doubts.length >= index) {
                    let values = doubts[index]
                    if (values !== undefined) {
                        setDoubtsIndex(index)
                        if (filterData.filter(el => el.id === values.id).length > 0) {
                            setFocusedDoubt(values)
                        } else {
                            getDataUserRating(values?.response?.id)
                        }
                    }
                }
            }
        }
    }

    const handleResponseRating = async () => {
        if (userRating.rating > 0 && userRating.id_response > 0) {
            let res = postInsertResponseRating(userRating)
            if (res) {
                setUserRating(objResponseRating)
                document.getElementById("closeResponseRating").click()
            }
        }
    }

    //#region 'USE LAYOUT EFFECT AND USE EFFECT '
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        propertiesEditor()
        let userData = await userContext.retrieveUserData()
        if (!Number.isInteger(userData.idRole)) {
            history.push("/")
            window.location.reload()
        } else {
            if (focusedDoubt.id) {
                let responseData = await retrieveResponse(focusedDoubt.id)
                setDoubtResponse(responseData)
                getDataUserRating(responseData.id)
                setResponse(responseData.Response)
                setstateEditor({ editorState: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(responseData.Response ? responseData.Response : ''))) })
                if (responseData.id) {
                    setResponseFiles(await getFilesInfo("response", responseData.id))
                    console.log("RESPONSE ID",responseData?.id)
                    getAudioByResponse(responseData.id)
                }
                if (userContext.userData.idRole === 1 || userContext.userData.idRole === 2) {
                    setStoredResponsesTypes(formatStoredResponsesTypes(await retrieveStoredResponsesTypes()))
                }
            } else {
                setDoubtResponse({})
                setResponse("")
            }
        }
    }, [focusedDoubt])
    //#endregion


    return (
        <DoubtsFormUI
            doubtsIndex={doubtsIndex}
            formObj={formObj}
            setFormObj={setFormObj}
            doubtsTypes={doubtsTypes}
            validators={validators}
            handleSelectTypeChange={handleSelectTypeChange}
            handleInputChange={handleInputChange}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            submitWithErrors={submitWithErrors}
            focusedDoubt={focusedDoubt}
            actionType={actionType}
            resetFormData={resetFormData}
            uploadAttachments={uploadAttachments}
            userData={userData}
            doubtFiles={doubtFiles}
            deleteAttachment={deleteAttachment}
            downloadAttachment={downloadAttachment}
            handleDelete={handleDelete}
            caseRecord={caseRecord}
            response={response}
            handleResponseInputChange={handleResponseInputChange}
            openStoredResponsesModal={openStoredResponsesModal}
            storedResponsesTypes={storedResponsesTypes}
            selectingStoredResponseType={selectingStoredResponseType}
            storedResponses={storedResponses}
            selectedStoredResponse={selectedStoredResponse}
            selectingStoredResponse={selectingStoredResponse}
            loadStoredResponse={loadStoredResponse}
            closeDoubtModal={closeDoubtModal}
            sendResponse={sendResponse}
            doubtResponse={doubtResponse}
            responseFiles={responseFiles}
            deleteResponseAttachment={deleteResponseAttachment}
            downloadResponseAttachment={downloadResponseAttachment}
            uploadResponseAttachments={uploadResponseAttachments}
            filteredFaqs={filteredFaqs}
            faqsTypes={faqsTypes}
            selectedStoredResponseType={selectedStoredResponseType}
            selectingFaqType={selectingFaqType}
            selectingFaq={selectingFaq}
            selectedFaq={selectedFaq}
            savingFaqResponse={savingFaqResponse}
            responseFaqLoader={responseFaqLoader}
            doubtsContext={doubtsContext}
            cancelEdition={cancelEdition}
            addNewDoubt={addNewDoubt}
            customFileInputRef={customFileInputRef}
            downloadImage={downloadImage}
            fullscreenImageBlob={fullscreenImageBlob}
            setFullscreenImageBlob={setFullscreenImageBlob}
            stateEditor={stateEditor}
            onEditorStateChange={onEditorStateChange}
            stateEditorFaq={stateEditorFaq}
            doubts={doubts}
            NextDoubts={NextDoubts}
            sendUpdateResponse={sendUpdateResponse}
            userRating={userRating}
            setUserRating={setUserRating}
            handleResponseRating={handleResponseRating}
            ClosedModal={ClosedModal}
            assignReasonWaiting={assignReasonWaiting}
            propertiesForm={propertiesForm}
            time_out={time_out}
            setDoubtResponse={setDoubtResponse}
            audios={audios}
            setAudios={setAudios}
            getAudioByResponse={getAudioByResponse}
            countAudio={countAudio}
        />
    )

}

export { DoubtsForm }