import React from 'react'
import { ErrorText } from './ErrorText'

export default function TextInputCustom({
    textLabel,
    nameInput,
    typeInput,
    evOnChange,
    required,
    errorValidate = false,
    textError,
    pattern,
    title,
    value,
    disabled,
    placeholder,
    requiredValidation,
    formatValidation,
    lengthValidation,
    cssClassesGroup,
    cssClassesInput,
    minimumValue,
    maxValue,
    style,
    classLabel,
    classContentInput
}) {

    return (
        <div className={`form-group${cssClassesGroup ? " " + cssClassesGroup : ""}`} style={style}>
            {textLabel ?
                <label className={classLabel} htmlFor={nameInput}>{required ? "*" : ""}{textLabel}</label>
                : null
            }
            <div className={classContentInput}>
                <input
                    type={typeInput}
                    name={nameInput}
                    id={nameInput}
                    className={errorValidate ? `form-control is-invalid m-0${cssClassesInput ? " " + cssClassesInput : ""}` :
                        `form-control${cssClassesInput ? " " + cssClassesInput : ""}`}
                    placeholder={placeholder}
                    required={required}
                    pattern={pattern}
                    title={title}
                    onChange={evOnChange}
                    value={value || ""}
                    disabled={disabled}
                    min={minimumValue}
                    max={maxValue}
                />
            </div>
            {errorValidate ? <p className="text-danger error-validation" style={{ fontSize: 12 }}>{textError}</p> : null}
            {typeof requiredValidation != "undefined" && requiredValidation.condition ?
                <ErrorText msg={requiredValidation.msg} /> :
                null
            }
            {typeof formatValidation != "undefined" && formatValidation.condition ?
                <ErrorText msg={formatValidation.msg} /> :
                null
            }
            {typeof lengthValidation != "undefined" && lengthValidation.condition ?
                <ErrorText msg={lengthValidation.msg} /> :
                null
            }
        </div>
    )
}