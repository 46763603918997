import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import DropdownMobileCSS from './DropdownMobile.module.css'

export default forwardRef((props, ref) => {
    const dropdownRef = useRef()

    const divStyles = {
        fontSize: "1rem"
    }

    const iconStyles = {
        marginTop: "0.5rem !important",
        marginLeft: "0.5rem !important",
        color: "white",
        fontSize: "1rem"
    }

    const mainIconStyles = {
        margin: 0,
        display: "flex",
    }

    const subMenuDiv = {
        marginTop: "0.1rem",
        marginBottom: "0.1rem"
    }

    const hideShow = () => {
        let classes = dropdownRef.current.className
        if (classes.includes("activeMobileDropdown")) {
            dropdownRef.current.className = DropdownMobileCSS.mobileDropdown
        } else {
            dropdownRef.current.className = `${DropdownMobileCSS.mobileDropdown} ${DropdownMobileCSS.activeMobileDropdown}`
        }
    }

    const hide = () => {
        dropdownRef.current.className = DropdownMobileCSS.mobileDropdown
    }

    const clickOption = () => {
        props.action()
        hide()
    }

    useImperativeHandle(ref, () => ({
        hideSubMenu() { hide() }
    }))

    return (
        <div className="nav-link" style={divStyles}>
            <div onClick={hideShow} style={mainIconStyles}>
                <div>
                    {props.mainLink}
                </div>
                <div>
                    <div style={{
                        display: "inline-block",
                        marginTop: "0.3rem",
                        marginLeft: "0.5rem"
                    }}>
                        <i style={iconStyles} className="fas fa-chevron-down"></i>
                    </div>
                </div>
            </div>
            <div
                ref={dropdownRef}
                className={DropdownMobileCSS.mobileDropdown}>
                {typeof props.subLinks != "undefined" &&
                    props.subLinks.map((subLink, index) => (
                        <div key={index} style={subMenuDiv} className={DropdownMobileCSS.mobileDropdownOption}
                            onClick={clickOption}>
                            {subLink}
                        </div>
                    ))}
            </div>
        </div>
    )
})