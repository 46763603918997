import { useState, useEffect } from 'react'
import { getFaq } from '../Services/Faqs/getFaq'
import { getFaqsList } from '../Services/Faqs/getFaqsList'
import { postFaq } from '../Services/Faqs/postFaq'
import { putFaq } from '../Services/Faqs/putFaq'
import { deleteFaq } from '../Services/Faqs/deleteFaq'
import getOneFaq from '../Services/Faqs/getOneFaq'

const useFaqs = () => {

    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        getDataFaqs()
    }, [])

    const getDataFaqs = async () => {
        const doc = []
        let result = await getFaq()
        result.forEach(element => {
            if (element.Visible.data[0] === 1) {
                doc.push({
                    value: element.id,
                    label: element.Questions,
                    type: element.id_faqs_types,
                    response: element.Response
                })
            }
        })
        setFaqs(doc)
    }

    const retrieveFaqs = async () => {
        return await getFaq()
    }

    const retrieveFaqsList = async () => {
        return await getFaqsList()
    }

    const createFaq = async (data) => {
        return await postFaq(data)
    }

    const updateFaq = async (data) => {
        return await putFaq(data)
    }

    const removeFaq = async (id) => {
        return await deleteFaq(id)
    }

    const retireveSpecificFaq = (id) => getOneFaq(id)

    return {
        faqs,
        retrieveFaqs,
        retrieveFaqsList,
        createFaq,
        updateFaq,
        removeFaq,
        retireveSpecificFaq
    }
}

export { useFaqs }