import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const getResponse = (idDoubt) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("GET", RouterCtrl.responses)
        xhr.setRequestHeader("doubt", idDoubt)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
            
                if (res) {
                    resolve(res)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}