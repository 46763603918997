import React from 'react'
import './App.css';
import { ToastContainer } from 'react-toastify'
//import { BrowserRouter as Router, Link, Route, Switch, Redirect } from 'react-router-dom'

import { UserState } from './Contexts/User/UserState'
import { LoadingState } from './Contexts/Loading/LoadingState'

// Session related imports
import { LoginScreen } from './Components/LoginScreen/LoginScreen'

// Assets
import MainScreen from './Components/MainScreen/MainScreen'
import { SearchState } from './Contexts/Search/SearchState';
import { FileState } from './Contexts/Files/FileState';
import { BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';


function App() {
  return (
    <UserState>
      <LoadingState>
        <SearchState>
          <FileState>
            <Router>
              <Switch>
                <Route exact path="/" component={LoginScreen} />
                <Route path="/login" component={LoginScreen} />
                <Route path="/login/reset-password/:token" component={LoginScreen} />
                <Route path="/activate/:token" component={LoginScreen} />
                <Route path="/main-screen" component={MainScreen} />
                <Route path="/exit" element={<Redirect to="/login" />} />
              </Switch>
              <ToastContainer />
            </Router>
          </FileState>
        </SearchState>
      </LoadingState>
    </UserState>
  )
}




export default App;
