import RouteCtrl from "../../routes/Routes"
import { MessageService } from "../message_service"

const resetPassword = async (email) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouteCtrl.recoverPassword)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.send(JSON.stringify({ email: email }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (typeof res === "object") {
                    if (res.type === "success") {
                        messageService.printToast(res.scope, res.type, res.code)
                        resolve(res)
                    } else if (res.type === "error") {
                        messageService.printToast(res.scope, res.type, res.code)
                        resolve(false)
                    } else {
                        messageService.printToast("Generics", "error", 9)
                        resolve(false)
                    }
                } else {
                    messageService.printToast("Generics", "error", 9)
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    })
}

export { resetPassword }