import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const getCaseData = (id) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", `${RouterCtrl.getCaseData}/${id}`)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (typeof res.type === "undefined") {
                    resolve(res)
                } else {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}

export { getCaseData }