import React, { useState, useEffect } from 'react'
import Rating from 'react-rating'
import star_empty from '../../img/star_empty.png'
import star_full from '../../img/star_full.png'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
import TextInputCustom from '../Tools/TextInputCustom';
import ButtonBack from '../Tools/ButtonBack';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import { ContentGeneral } from '../Tools/ContentGeneral';

const ResponsesRatingsUI = ({ id_role, responseRating, nameUser, search, handleSearch }) => {

    //#region VARIABLE
    let history = useHistory()
    const divStyles = {
        display: "flex",
        color: 'black',
        fontWeight: '500',
    }

    const FONT_SIZE = 14
    //#endregion

    //#region STATES
    const [selectedItem, setSelectedItem] = useState(null)
    //#endregion

    //#region FUNCTIONS

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }

    const getResponse = () => {
        if (responseRating.length > 0) {
            responseRating.map((parent) => {
                parent.dataRating.map(child => {
                    child.responseHtml = UpdateStateEditor(child.Response ? child.Response : '')
                })
            })
        }
    }

    const onFilter = (item) => {
        if (!search.searchCode) {
            return item
        } else if (item.case_code.includes(search.searchCode)) {
            return item
        }
    }

    const backScreen = (e) => {
        e.preventDefault();
        history.push("/main-screen/users")
    }

    //#endregion

    useEffect(() => {
        getResponse()
    }, [responseRating])


    //#region STRUCTURE

    const ContentComp = ({ itemComp }) => (
        <div className="row">
            {itemComp}
        </div>
    )

    const ItemBodyComp = ({ label, value }) => (
        <div className="col-6">
            <p className="card-text mb-0" style={{ fontSize: FONT_SIZE }}><strong>{label} </strong></p>
            <p className="card-text mb-1 text-truncate" style={{ fontSize: FONT_SIZE }}>{value}</p>
        </div>
    )

    const ImgIcon = ({ img }) => <img src={img} className='icon' style={{ width: 18, opacity: img === star_empty ? '40%' : 100 }} />

    const ChildCompBody = ({ childItem }) => (
        <div style={{ backgroundColor: "#dfdfdf", marginTop: "0.5rem", paddingLeft: 20 }}>
            <div className='row'>
                {id_role === 3 && (<ItemBodyComp label={"Profesor"} value={childItem.teacher} />)}
                {(id_role === 2 || id_role === 1) && (<ItemBodyComp label={"Doctor"} value={childItem.modifier} />)}
                <ItemBodyComp label={"Fecha Respuesta"} value={moment(childItem.response_created).format('DD/MM/YYYY')} />
            </div>
            <ItemBodyComp label={"Observaciones"} value={childItem.observation} />
            <p className="card-text mb-0" style={{ fontSize: FONT_SIZE }}><strong>Respuesta</strong></p>
            <Editor
                spellCheck
                toolbarHidden={true}
                readOnly={true}
                editorState={childItem.responseHtml} />
        </div>
    )

    const ChildComponent = ({ childItem, index }) => (
        <div key={index} style={{ marginBottom: "0.5rem" }}>
            <hr style={{ marginBottom: "0.25rem" }} />
            <div style={divStyles}>
                {selectedItem !== childItem.id ?
                    <div onClick={() => setSelectedItem(childItem.id)} className={'clickable'}>
                        <span className={{ fontWeight: 'bolder' }}>
                            <svg className="svg-inline--fa fa-chevron-down fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                        </span>
                    </div> : null}
                {selectedItem === childItem.id ?
                    <div onClick={() => setSelectedItem(null)}
                        className={'clickable '}>
                        <span className={{ fontWeight: 'bolder' }}>
                            <svg className="svg-inline--fa fa-chevron-up fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                        </span>
                    </div> : null}
                &nbsp;<span className={selectedItem !== childItem.id ? 'text-truncate' : null}>{childItem.Doubt}</span>
            </div>
            <div style={{ paddingLeft: 20, alignItems: 'end' }} className='row'>
                <Rating
                    stop={5}
                    step={1}
                    initialRating={childItem.rating}
                    readonly={true}
                    emptySymbol={<ImgIcon img={star_empty} />}
                    fullSymbol={<ImgIcon img={star_full} />}
                />
            </div>
            {selectedItem === childItem.id ?
                <ChildCompBody childItem={childItem} />
                : null}
        </div>
    )

    const ChildArrayMapping = (data) => data.map((childItem, index) => (
        <ChildComponent childItem={childItem} index={index} />
    ))

    const ParentComponent = ({ item, index }) => (
        <div key={index} style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
            <h5 style={{ color: "#2c82c9" }}>{item.case_code}</h5>
            {ChildArrayMapping(item.dataRating)}
        </div>
    )

    const ParentArrayMapping = () => responseRating.filter(el => onFilter(el)).map((item, index) => (
        <ParentComponent item={item} index={index} />
    ))

    const ItemNothingElement = () => (
        <div style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
            <div className="card-body">
                <p className="card-text text-center">Aún no se ha registrado ninguna valoración.</p>
            </div>
        </div>
    )

    //#endregion
    return ([
        <HeadTitle title={"Reseñas"} />,
        <ContentGeneral content={[
            <ButtonBack onclickBack={backScreen} />,
            <TextInputCustom nameInput={"searchCode"} typeInput={"search"} textLabel={"Código del caso"}
                evOnChange={handleSearch} value={search.searchCode} placeholder={"Buscar..."}
                style={{ margin: 0 }} />,
            <br />,
            id_role === 3 && (<span><strong>Doctor: </strong> {nameUser}</span>),
            (id_role === 2 || id_role === 1) && (<span><strong>Profesor: </strong> {nameUser}</span>),
            <ContentComp itemComp={responseRating.length > 0 ?
                <ParentArrayMapping />
                : <ItemNothingElement />} />
        ]} />
    ])
}

export { ResponsesRatingsUI }