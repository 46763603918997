import React, { useState, useEffect } from 'react'
import CustomFileInputCSS from './CustomFileInput.module.css'
import loadingImg from '../../../../../img/loading.gif'

export default ({
    ico,
    file,
    onRemove,
    downloader,
    deleter,
    idUser,
    idEntityOwner,
    width,
    deletable,
    cssClasses,
    displayFactor = 335,
    adminAllowed,
    idRole,
    entityId,
    imageDownloader,
    setFullscreenImageBlob,
    readOnly
}) => {
    const [image, setImage] = useState("")

    useEffect(async () => {
        setImage(await imageDownloader(file.Path, file.mimetype))
    }, [])

    const mimetypes = [
        "image/png",
        "image/jpeg"
    ]

    let screenWidth = window.screen.width
    const displayChars = Math.round(13 * screenWidth / displayFactor)
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const download = async () => {
        //let download = await downloader(file.id, file.name, file.mimetype, file.Extension, file.Path)
        await downloader(file.id, file.name, file.mimetype, file.Extension, file.Path)
    }

    const deleteElem = async (e) => {
        if (Number.isInteger(entityId)) {
            //let deleted = await deleter(file.id)
            await deleter(file.id)
        } else {
            onRemove(file.id)
        }
    }

    const displayImage = () => {
        let urlCreator = window.URL || window.webkitURL
        if (image) {
            let imageUrl = urlCreator.createObjectURL(image)
            return <img src={imageUrl} className={CustomFileInputCSS.img + " clickable"} onClick={() => setFullscreenImageBlob(imageUrl)} />
        }
        return <img src={loadingImg} className={CustomFileInputCSS.img} />
    }

    return (
        <div className={`${cssClasses ? ` ${cssClasses}` : ""} ${CustomFileInputCSS.line}`} style={{ width: width || "100%" }}>
            <div style={{marginRight:10}}>
                {Number.isInteger(entityId) && (
                    <div className={CustomFileInputCSS.inlineBlock} onClick={download} >
                        <i className={`fas fa-file-download fa-lg clickable  ${CustomFileInputCSS.iconHeight}`}></i>
                    </div>
                )}
                {readOnly && (idUser === idEntityOwner || adminAllowed && idRole === 1 && deletable === true) || !Number.isInteger(entityId) && (
                    <div className={CustomFileInputCSS.inlineBlock} onClick={deleteElem}>
                        <i className={`fas fa-trash-alt fa-lg clickable marginl05 ${CustomFileInputCSS.iconHeight}`}></i>
                    </div>
                )}
            </div>
            <div className={CustomFileInputCSS.inlineBlock}>
                {mimetypes.indexOf(file.mimetype) !== -1 ? displayImage() : <img src={ico} className={CustomFileInputCSS.img} />}
                <div className={CustomFileInputCSS.inlineBlock}>
                    <p title={file.name} className={`${CustomFileInputCSS.noMargin} ${CustomFileInputCSS.optionFontSize}`}>
                        {file.name.length > displayChars ? `${file.name.substr(0, displayChars)}...` : file.name}
                    </p>
                    <p className={CustomFileInputCSS.optionFontSize}> {formatBytes(file.size)} </p>
                </div>
            </div>
        </div>
    )
}