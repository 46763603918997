import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useHistory } from 'react-router-dom'
import ButtonBack from '../Tools/ButtonBack'
import ButtonSave from '../Tools/ButtonSave'
import { ContentGeneral } from '../Tools/ContentGeneral'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import TextInputCustom from '../Tools/TextInputCustom'

const LopdUI = ({ data, setData, editor, editLopd, handles }) => {

    let history = useHistory()

    return ([
        <HeadTitle title={"LOPD"} />,
        <ContentGeneral content={[
            <div className="flex margintb1" style={{ gap: "0.5rem", flexWrap: "wrap" }}>
                <ButtonBack onclickBack={() => history.push("/main-screen/lopd")} classMarginLeft={"m0"} />
                {!data?.id || !editLopd.value && (<ButtonSave
                    buttonId={"saveLopd"} cssClasses={"margin0"}
                    textBtn={"Guardar"} icoClasses={"fas fa-save"}
                    handleSubmit={!data.id ? handles.save : handles.update} />)}
                {data?.id && editLopd.value && (<ButtonSave
                    buttonId={"EditLopd"} cssClasses={"margin0"}
                    textBtn={"Editar"} icoClasses={"fas fa-pencil-alt"}
                    handleSubmit={() => editLopd.set(false)} />)}
                {data?.id && !editLopd.value && (<ButtonSave
                    buttonId={"cancelLopd"} cssClasses={"margin0"}
                    textBtn={"Cancelar"} icoClasses={"fas fa-times"}
                    buttonClasses={"light-red"} handleSubmit={handles.cancel} />)}
            </div>,
            <TextInputCustom
                nameInput={"nameLopd"} textLabel={"Nombre"}
                cssClassesGroup={"marginlr0"} disabled={data?.id && editLopd.value}
                evOnChange={(e) => setData({...data, name:e.target.value})}
                value={data.name}
            />,
            <Editor
                spellCheck
                toolbarHidden={data.id && editLopd.value}
                readOnly={data.id && editLopd.value}
                editorState={editor.value}
                toolbarClassName="rdw-storybook-toolbar"
                wrapperClassName="rdw-storybook-wrapper"
                editorClassName="rdw-storybook-editor"
                editorStyle={{ background: "white", paddingLeft: 20, paddingRight: 20 }}
                onEditorStateChange={editor.onchange}
            />
        ]} />
    ])
}

export { LopdUI }