import { getCaseTeacher } from '../Services/CasesTeachers/getCaseTeacher'
import { postCaseTeacher } from '../Services/CasesTeachers/postCaseTeacher'
import { deleteCaseTeacher } from '../Services/CasesTeachers/deleteCaseTeacher'

const useCasesTeachers = () => {

    const retrieveCaseTeacher = idTeacher => getCaseTeacher(idTeacher)

    const assignTeacher = (idTeacher, idCasesType) => postCaseTeacher(idTeacher, idCasesType)

    const unassignTeacher = (idTeacher, idCasesType) => deleteCaseTeacher(idTeacher, idCasesType)

    return {
        retrieveCaseTeacher,
        assignTeacher,
        unassignTeacher
    }
}

export { useCasesTeachers }