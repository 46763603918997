//#region 'IMPORTS'
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../Contexts/User/UserContext';
import { useCase } from '../../../Hooks/useCase';
import { useCasesDistribution } from '../../../Hooks/useCasesDistribution';
import useCasesTypes from '../../../Hooks/useCasesTypes';
import useEditions from '../../../Hooks/useEditions';
import { useShowView } from '../../../Hooks/useShowView';
import { useTeachers } from '../../../Hooks/useTeachers';
import { CasesFilter } from '../../Tools/GeneralFunctions/CasesFilter';
import { ElimiarDiacriticos } from '../../Tools/GeneralFunctions/EliminarDiacriticos';
import { CasesDistributionByTeachersUI } from './CasesDistributionByTeachersUI';
//#endregion

const CasesDistributionByTeachers = (props) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const { id_teacher, teacher_name } = props.location.state
    const cbfilterObj = [
        { value: '1', name: 'Código del caso' },
        { value: '2', name: 'Doctor' },
    ]
    const DEFAULT_VALUE_DP = { value: -1, label: "Todos" }
    const obFilter = {
        valueDp: '1',
        textFilter: '',
        caseTypeFilter: DEFAULT_VALUE_DP,
        caseEditionFilter: DEFAULT_VALUE_DP
    }
    //#endregion

    //#region 'USE REF'

    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { getDataByTeachers } = useCasesDistribution()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { teachersReassign } = useTeachers()
    const { reassignCase } = useCase()
    const { casesTypesDp } = useCasesTypes({ onlySearch: true })
    const { casesEditionsCases } = useEditions({ onlySearchCases: true })
    //#endregion

    //#region 'USE STATES'
    const [casesTeachers, setCasesTeachers] = useState([])
    const [filter, setFilter] = useState(obFilter)
    const [selteacherReassign, setSelTeacherReassign] = useState({})
    const [selItem, setSelItem] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getCasesTeacher = async () => {
        if (id_teacher) {
            setCasesTeachers(await getDataByTeachers(id_teacher))
        }
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = name === 'textFilter' ? e.target.value
            : e.target?.value || { value: e.value, label: e.label }
        setFilter({ ...filter, [name]: value })
    }

    const selectTeachersAssign = e => {
        setSelTeacherReassign(e)
    }

    const onFilter = (values) => {
        switch (filter) {
            case '1':
                return CasesFilter(filter.textFilter, values, values.Case_code)
            case '2':
                return CasesFilter(filter.textFilter, values, ElimiarDiacriticos(values.student_name))
            default:
                return values
        }
    }

    const filterByType = obj => {
        if (filter?.caseTypeFilter?.value === DEFAULT_VALUE_DP.value) {
            return obj
        } else {
            return obj?.id_cases_types === filter?.caseTypeFilter?.value ? obj : false
        }
    }

    const filterByEdition = obj => {
        if (filter?.caseEditionFilter?.value === DEFAULT_VALUE_DP.value) {
            return obj
        } else {
            return obj?.id_editions === filter?.caseEditionFilter?.value ? obj : false
        }
    }

    const doFilters = e => {
        let value = onFilter(e)
        value = filterByType(value)
        value = filterByEdition(value)
        return value
    }

    const getSelItem = (item) => {
        setSelItem(item)
    }

    const handleReassignCase = async () => {
        if (await reassignCase(selItem.id, selteacherReassign.value)) {
            document.getElementById("closeReassignCaseModal").click()
            setSelTeacherReassign({})
            getCasesTeacher()
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (res.idRole !== 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        getCasesTeacher()
    }, [])

    //#endregion

    //#region 'STRUCTURE'

    //#endregion

    return <CasesDistributionByTeachersUI
        casesTeachers={{
            teacher_name: teacher_name,
            data: casesTeachers
        }}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        filters={{
            selFilter: filter,
            dpFieldsFilters: cbfilterObj,
            onChange: handleInputChange,
            onFilter: doFilters,
            casesTypes: casesTypesDp,
            editions: casesEditionsCases,
        }}
        teachersReassign={{
            data: teachersReassign,
            selected: selteacherReassign,
            change: selectTeachersAssign
        }}
        getSelItem={getSelItem}
        handleReassignCase={handleReassignCase}
    />
}

export { CasesDistributionByTeachers }