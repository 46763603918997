import { MessageService } from "../message_service";
import RouteCtrl from '../../routes/Routes'

export const delEditions = async data => {
    // console.log(data)
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open("DELETE", RouteCtrl.editions)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ id: data }))
        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                messageService.printToast(res.scope, res.type, res.code)
                switch (res.type) {
                    case "success":
                        resolve(true)
                        break;

                    case "error":
                        resolve(false)
                        break;
                    default:
                        resolve(false)
                        break;
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }

        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}