//#region 'IMPORTS'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSession } from '../Hooks/useSession'
import logo_transparente from '../img/Logo_OIF_blanco_v2.png'
import { UserContext } from '../Contexts/User/UserContext'
import { useHistory } from 'react-router-dom'
import DropdownDesktop from './Tools/DropdownDesktop/DropdownDesktop'
import DropdownMobile from './Tools/DropdownMobile/DropdownMobile'
//#endregion
/**
 * AGREGAR UN ITEM AL MENÚ
 * 1 En region VARIABLE --* lo ideal es que los items que se registran sea el nombre del módulo.*--
 *  -> objMenuItems -> agregar un item que tendrá el nombre que se mostrará.
 *  -> objPathMenu -> agregar un item que tendrá la ruta de acceso a la pantalla.
 * 
 * 2 Se ha de agregar los items en las REGIONES STRUCTURE -> MOBILE > ItemsMenuMobile y DESKTOP > ItemsMenuDesktop
 * 
 */

const Navigation = () => {

    //#region VARIABLE
    const CLASSNAME_MOBILE_CONTENT = "nav-item w-100"
    const CLASSNAME_MOBILE_LINK_DROPDOWN = "responsiveLink paddingb0 nav-link text-light pl-4 mobileDropdownSize black"
    const CLASSNAME_MOBILE_LINK_ONLY = "responsiveLink nav-link text-light pl-4"
    const CLASSNAME_DESKTOP_CONTENT = "menuLink"
    const CLASSNAME_DESKTOP_ITEMS = "submenuLink"

    const objMenuItems = {
        Title: "OIF",
        Cases: {
            title: "Casos",
            list: "Listado de casos",
            type_cases: "Tipos de casos",
            stats: "Estadística",
            distribution: "Repartición de casos"
        },
        Faqs: {
            title: "Preguntas frecuentes",
            list: "Preguntas frecuentes",
            type_faqs: "Tipos de preguntas frecuentes",
            public_page: "Página pública"
        },
        Response: {
            title: "Respuestas",
            list: "Respuestas",
            type_response: "Tipos de respuestas"
        },
        Editions: {
            title: "Ediciones",
            list: "Ediciones"
        },
        Packs: {
            title: "Packs",
            list: "Packs de Casos"
        },
        Packs_duration: {
            title: "Duración del pack",
            list: "Duración del pack"
        },
        User: "Usuarios",
        Profile: "Perfil",
        consent: {
            title: "Consentimiento",
            lopd: "LOPD",
            consent_info: "Consentimiento informado"
        },
        Logout: "Salir",
        test: {
            title: "Test",
            test: "TEST"
        },
        TextBtnExit: "X"

    }
    const objPathMenu = {
        main: "/main-screen/cases",
        cases: "/main-screen/cases",
        type_cases: "/main-screen/cases-types",
        teacher_stats: "/main-screen/teacher-stats",
        cases_distribution: "/main-screen/cases-distribution",
        faqs: "/main-screen/faqs-list",
        type_faqs: "/main-screen/faqs-types",
        public_page: "/main-screen/faqs",
        response: "/main-screen/stored-responses",
        type_response: "/main-screen/stored-responses-types",
        editions: "/main-screen/editions",
        packs: "/main-screen/cases-pack",
        packs_duration: "/main-screen/cases-pack-duration",
        users: "/main-screen/users",
        profile: "/main-screen/profile",
        lopd: "/main-screen/lopd",
        test: "/main-screen/test",
        login: "/login",
    }
    const userContext = useContext(UserContext)
    const { finishSession } = useSession()
    const [isLogged, setIsLogged] = useState(false)
    const sidebarRef = useRef()
    const dotRef = useRef()
    const outOfMenuRef = useRef()
    const casesSubMenu = useRef()
    let history = useHistory()
    //#endregion

    //#region FUNCTIONS

    const getDataUseEffect = async () => {
        if (!await userContext.checkSession()) {
            history.push("/")
            setIsLogged(false)
        } else {
            setIsLogged(true)
        }
    }

    useEffect(() => {
        getDataUseEffect()
    }, [isLogged])

    const closeAll = () => {
        if (casesSubMenu?.current?.hideSubMenu) {
            casesSubMenu.current.hideSubMenu()
        }
        displaySidebar()
    }

    const displaySidebar = () => {
        let currentClasses = sidebarRef.current.classList.value
        let dotClasses = dotRef.current.classList.value
        //let outOfMenuClasses = outOfMenuRef.current.classList.value

        if (!currentClasses.includes('margin0')) {
            sidebarRef.current.classList += " margin0"
            dotRef.current.classList += " dotScale"
            outOfMenuRef.current.classList = " outOfMenu block"
        } else {
            sidebarRef.current.classList = currentClasses.replaceAll(" margin0", "")
            dotRef.current.classList = dotClasses.replaceAll(" dotScale", "")
            outOfMenuRef.current.classList = " outOfMenu none"
        }
    }
    //#endregion

    //#region STRUCTURE
    //#region MOBILE
    const ContentItemDpMenuMobile = ({ items, title }) => (
        <li className={CLASSNAME_MOBILE_CONTENT}>
            <DropdownMobile
                ref={casesSubMenu}
                action={displaySidebar}
                mainLink={<span className="responsiveLink paddingb0 nav-link-size text-light pl-4" to={objPathMenu.main}>{title}</span>}
                subLinks={items}
            />
        </li>
    )

    const ContentItemOnlyMenuMobile = ({ path, title }) => (
        <li className={CLASSNAME_MOBILE_CONTENT}>
            <Link
                className={CLASSNAME_MOBILE_LINK_ONLY} to={path}
                onClick={displaySidebar}
            >
                {title}
            </Link>
        </li>
    )

    const obItemsMenuByRoles = {
        1: {
            mobile: [
                <ContentItemDpMenuMobile key={"key_mobile_cases_menu_items"} title={objMenuItems.Cases.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.cases}>{objMenuItems.Cases.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.type_cases}>{objMenuItems.Cases.type_cases}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.cases_distribution}>{objMenuItems.Cases.distribution}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.teacher_stats}>{objMenuItems.Cases.stats}</Link>,
                ]} />,
                <ContentItemDpMenuMobile key={"key_mobile_faqs_menu_items"} title={objMenuItems.Faqs.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.public_page}>{objMenuItems.Faqs.public_page}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.faqs}>{objMenuItems.Faqs.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.type_faqs}>{objMenuItems.Faqs.type_faqs}</Link>,
                ]} />,
                <ContentItemDpMenuMobile key={"key_mobile_response_menu_items"} title={objMenuItems.Response.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.response}>{objMenuItems.Response.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.type_response}>{objMenuItems.Response.type_response}</Link>
                ]} />,
                <ContentItemOnlyMenuMobile key={"key_mobile_editions_menu_items"} path={objPathMenu.editions} title={objMenuItems.Editions.title} />,
                <ContentItemDpMenuMobile key={"key_mobile_packs_menu_items"} title={objMenuItems.Packs.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.packs_duration}>{objMenuItems.Packs_duration.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.packs}>{objMenuItems.Packs.list}</Link>,
                ]} />,
                <ContentItemOnlyMenuMobile key={"key_mobile_consent_menu_items"} path={objPathMenu.lopd} title={objMenuItems.consent.lopd} />,
                <ContentItemOnlyMenuMobile key={"key_mobile_user_menu_items"} path={objPathMenu.users} title={objMenuItems.User} />,
            ],
            desktop: [
                <DropdownDesktop key={"key_desktop_cases_menu_items"} mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Cases.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.cases}>{objMenuItems.Cases.list}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.type_cases}>{objMenuItems.Cases.type_cases}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.cases_distribution}>{objMenuItems.Cases.distribution}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.teacher_stats}>{objMenuItems.Cases.stats}</Link>,
                    ]}
                />,
                <DropdownDesktop key={"key_desktop_faqs_menu_items"} mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Faqs.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.public_page}>{objMenuItems.Faqs.public_page}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.faqs}>{objMenuItems.Faqs.list}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.type_faqs}>{objMenuItems.Faqs.type_faqs}</Link>
                    ]}
                />,
                <DropdownDesktop key={"key_desktop_response_menu_items"} mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Response.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.response}>{objMenuItems.Response.list}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.type_response}>{objMenuItems.Response.type_response}</Link>,
                    ]}
                />,
                <Link key={"key_desktop_packs_menu_items"} className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.editions}>{objMenuItems.Editions.list}</Link>,
                <DropdownDesktop mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Packs.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.packs_duration}>{objMenuItems.Packs_duration.title}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.packs}>{objMenuItems.Packs.list}</Link>,
                    ]}
                />,
                <Link key={"key_desktop_consent_menu_items"} className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.lopd}>{objMenuItems.consent.lopd}</Link>,
                <Link key={"key_desktop_user_menu_items"} className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.users}>{objMenuItems.User}</Link>,
            ]
        },
        2: {
            mobile: [
                <ContentItemOnlyMenuMobile path={objPathMenu.cases} title={objMenuItems.Cases.title} />,
                <ContentItemDpMenuMobile title={objMenuItems.Faqs.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.faqs}>{objMenuItems.Faqs.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.type_faqs}>{objMenuItems.Faqs.type_faqs}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.public_page}>{objMenuItems.Faqs.public_page}</Link>
                ]} />,
                <ContentItemDpMenuMobile title={objMenuItems.Response.title} items={[
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.response}>{objMenuItems.Response.list}</Link>,
                    <Link className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.type_response}>{objMenuItems.Response.type_response}</Link>
                ]} />,
            ],
            desktop: [
                <Link key={"key_desktop_cases_menu_items"} className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.cases}>{objMenuItems.Cases.title}</Link>,
                <DropdownDesktop key={"key_desktop_faqs_menu_items"} mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Faqs.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.public_page}>{objMenuItems.Faqs.public_page}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.faqs}>{objMenuItems.Faqs.list}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.type_faqs}>{objMenuItems.Faqs.type_faqs}</Link>
                    ]}
                />,
                <DropdownDesktop key={"key_desktop_response_menu_items"} mainLink={{
                    cssClass: CLASSNAME_DESKTOP_CONTENT,
                    path: '#',
                    text: objMenuItems.Response.title,
                    main: true
                }}
                    subLinks={[
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.response}>{objMenuItems.Response.list}</Link>,
                        <Link className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.type_response}>{objMenuItems.Response.type_response}</Link>,
                    ]}
                />,
            ]
        },
        3: {
            mobile: [
                <ContentItemOnlyMenuMobile path={objPathMenu.cases} title={objMenuItems.Cases.title} />,
            ],
            desktop: [
                <Link key={"key_desktop_cases_menu_items"} className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.cases}>{objMenuItems.Cases.title}</Link>
            ]
        }
    }

    const ItemsMenuMobile = () => (
        <ul className="navbar-nav d-flex flex-column mt-5 w-100 ">
            {isLogged && obItemsMenuByRoles[userContext.userData.idRole]?.mobile}
            <ContentItemDpMenuMobile title={userContext.userData.name + ' ' + userContext.userData.lastname} items={[
                <Link key={"key_profile_itemmenu_mobile"} className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.profile}>{objMenuItems.Profile}</Link>,
                <Link key={"key_login_itemmenu_mobile"} className={CLASSNAME_MOBILE_LINK_DROPDOWN} to={objPathMenu.login} onClick={finishSession} >{objMenuItems.Logout}</Link>
            ]} />
        </ul>
    )

    const MenuMobile = () => (
        <div className="hide" ref={sidebarRef}>
            <div className="navbar navbar-expand d-flex flex-column align-item-start" id="sidebar">
                <div href="#" className="navbar-brand text-light mt-5">
                    <Link className="responsiveLink" to={objPathMenu.main}>
                        <div className="display-5 font-weight-bold">
                            {objMenuItems.Title}
                        </div>
                    </Link>
                </div>
                <ItemsMenuMobile />
            </div>
            <div className="dot clickable" ref={dotRef} onClick={closeAll}>
                <p>{objMenuItems.TextBtnExit}</p>
            </div>
            <div ref={outOfMenuRef} className="outOfMenu none" onClick={closeAll}></div>
        </div>
    )
    //#endregion

    //#region DESKTOP
    const ItemsMenuDesktop = () => (
        <div className={`${userContext.userData.idRole === 1 ? 'menuOptions_admin' : 'menuOptions'}`} style={{ zIndex: 99 }}>
            {isLogged && obItemsMenuByRoles[userContext.userData.idRole]?.desktop}
            <DropdownDesktop key={"key_Profile_login"} style={{ PointerEvent: 'none' }} mainLink={{
                cssClass: CLASSNAME_DESKTOP_CONTENT,
                path: '#',
                text: userContext.userData.name + ' ' + userContext.userData.lastname,
                main: true,
            }}
                subLinks={[
                    <Link key={"key_profile_itemmenu_desktop"} className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.profile}>{objMenuItems.Profile}</Link>,
                    <Link key={"key_login_itemmenu_desktop"} className={CLASSNAME_DESKTOP_ITEMS} to={objPathMenu.login} onClick={finishSession}>{objMenuItems.Logout}</Link>
                ]}
            />
        </div>
    )
    const MenuDesktop = () => (
        <nav className=' bg-dark' translate="no">
            {isLogged && (
                <div className={`${userContext.userData.idRole === 1 ? 'bars_admin' : 'bars'} clickable`} style={{ color: 'white' }} onClick={displaySidebar}>
                    <i className="fas fa-bars"></i>
                </div>
            )}
            <div className={`${userContext.userData.idRole === 1 ? 'logoWrapper_admin' : 'logoWrapper'}`}>
                <Link className={CLASSNAME_DESKTOP_CONTENT} to={objPathMenu.main}><img src={logo_transparente} alt="Menú logo" /></Link>
            </div>
            <ItemsMenuDesktop />
        </nav>
    )
    //#endregion

    const NavigationMenu = () => (
        <>
            {isLogged ? <MenuMobile /> : null}
            <MenuDesktop />
        </>
    )
    //#endregion

    return <NavigationMenu />

}

export default Navigation