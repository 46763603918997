import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react'
import CustomFileInputCSS from './CustomFileInput.module.css'
import { ErrorText } from '../../../ErrorText'
import FileElem from './FileElem'
import { MessageService } from "../../../../../Services/message_service"

import pngIco from '../../../../../img/attachments/img/png_ico.png'
import jpgIco from '../../../../../img/attachments/img/jpg_ico.png'
import jpegIco from '../../../../../img/attachments/img/jpeg_ico.png'
import docIco from '../../../../../img/attachments/doc/doc_ico.png'
import docxIco from '../../../../../img/attachments/doc/docx_ico.png'
import odtIco from '../../../../../img/attachments/doc/odt_ico.png'
import pptIco from '../../../../../img/attachments/presentation/ppt_ico.png'
import pptxIco from '../../../../../img/attachments/presentation/pptx_ico.png'
import odpIco from '../../../../../img/attachments/presentation/odp_ico.png'
import pdfIco from '../../../../../img/attachments/no_group/pdf_ico.png'
import { FileContext } from '../../../../../Contexts/Files/FileContext'
import { toast } from 'react-toastify'

export default forwardRef((props, ref) => {
    // const customFileInputRef = useRef()

    const {
        nFiles = 5,
        maxSizePerFile,
        name,
        id,
        cssClass,
        formats,
        files,
        idUser,
        idEntityOwner,
        uploader,
        downloader,
        deleter,
        inputLabel = "Archivos",
        limitLabel = "Límite de 5 archivos",
        formName,
        entityId,
        render = true,
        deletable,
        fileElemCSS,
        adminAllowed,
        displayFactor,
        idRole,
        formGroup = true,
        imageDownloader,
        setFullscreenImageBlob,
        readOnly
    } = props

    let messageService = new MessageService()

    const fileContext = useContext(FileContext)

    const [fileList, setFileList] = useState(entityId ? files : (fileContext?.files || files))
    const [numberOfFiles, setNumberOfFiles] = useState(null)
    const [maxFileSize, setMaxFileSize] = useState(null)
    const [countFiles, setCountFiles] = useState(0)

    const [validators, setValidators] = useState({
        pristine: true,
        sizeFlag: false,
        numberFilesFlag: false
    })

    useImperativeHandle(ref, () => ({
        uploadInMemoryFiles: async (newEntityId) => {
            if (fileList.length > 0) {
                let files = fileList.map(file => file.Browser_File)
                uploader(files, formName, newEntityId)
            }
        },
        forceEmptyList: () => {
            setFileList([])
            setCountFiles(0)
            fileContext.clearArrayFiles()
        },
    }))

    useEffect(() => {
        setNumberOfFiles(nFiles)
        setMaxFileSize(maxSizePerFile)
    }, [])

    useEffect(() => {
        if (fileContext?.files.length > 0) {
            setFileList(fileContext.files)
        } else {
            setFileList(files)
        }
    }, [files])

    const handleChange = e => {

        let tmpValidators = {}
        Object.assign(tmpValidators, validators)

        let files = Array.from(e.target?.files)

        let sizeFlag = validateSize(files)
        let numberFilesFlag = files.length <= numberOfFiles

        tmpValidators.pristine = false
        tmpValidators.sizeFlag = sizeFlag
        tmpValidators.numberFilesFlag = numberFilesFlag
        setValidators(tmpValidators)
        if (sizeFlag && numberFilesFlag) {
            for (let index = 0; index < files.length; index++) {
                if (files[index].size >= maxFileSize) {
                    files.splice(index, 1)
                }
            }
            if (Number.isInteger(entityId)) {
                uploader(files, formName, entityId)
            } else {
                if (fileContext?.files?.length < 5) {
                    messageService.printToast("Generics", "warning", 0)
                    files = files.map((file) => {
                        return {
                            "name": file.name,
                            "Mimetype": file.type,
                            "size": file.size,
                            "Browser_File": file,
                            "id": countFiles
                        }
                    })
                    setCountFiles(countFiles + 1)
                    let arrFiles = fileContext.fillFiles(files[0])
                    setFileList(arrFiles)
                } else {
                    toast.warning("Se ha superado el limite de adjuntos.")
                }
            }
        }
        e.target.value = ""

    }

    const onRemove = (index) => {
        let tmpFiles = []
        Object.assign(tmpFiles, fileList)
        tmpFiles.splice(index, 1)
        fileContext.files.splice(index, 1)
        setFileList(tmpFiles)
    }

    const validateSize = (files) => {
        let fileSizeFlag = true
        let maxUploadSize = maxFileSize * numberOfFiles
        let totalSize = 0
        files.map(file => {
            if (fileSizeFlag &&
                file.size > maxFileSize) {
                fileSizeFlag = false
            }
            totalSize += file.size
        })

        let totalSizeFlag = totalSize > maxUploadSize ? false : true
        return totalSizeFlag && fileSizeFlag
    }

    const returnImage = ext => {
        switch (ext) {
            case "png":
                return pngIco

            case "jpg":
                return jpgIco

            case "jpeg":
                return jpegIco

            case "doc":
                return docIco

            case "docx":
                return docxIco

            case "odt":
                return odtIco

            case "ppt":
                return pptIco

            case "pptx":
                return pptxIco

            case "odp":
                return odpIco

            case "pdf":
                return pdfIco
        }
    }

    return (
        <>
            {readOnly ?
                <div className={`${formGroup ? "form-group" : ""}${cssClass ? ` ${cssClass}` : ""}`}>
                    <label
                        htmlFor={id}
                        className="clickable"
                    ><i className="fas fa-upload"></i> {inputLabel} </label>
                    <p className={CustomFileInputCSS.warning}>{limitLabel}</p>
                    <input type="file"
                        name={name}
                        id={id}
                        className="none"
                        accept={formats}
                        onChange={handleChange}
                        multiple
                    />
                    {!validators.pristine && !validators.sizeFlag ?
                        <ErrorText msg={`El tamaño máximo permitido por archivo es de ${maxFileSize / 1000000} MB`} /> :
                        null
                    }
                    {!validators.pristine && !validators.numberFilesFlag ?
                        <ErrorText msg={`El número máximo de archivos es de ${numberOfFiles}`} /> :
                        null
                    }
                </div> : null}
            {render ?
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem"
                }}>
                    {typeof fileList != "undefined" ? fileList?.map((file, index) => {
                        const ico = returnImage(file?.name.substr(file?.name.lastIndexOf(".") + 1))
                        return (
                            <FileElem
                                key={index}
                                ico={ico}
                                file={file}
                                downloader={downloader}
                                deleter={deleter}
                                onRemove={onRemove}
                                idUser={idUser}
                                idEntityOwner={idEntityOwner}
                                deletable={deletable}
                                cssClasses={fileElemCSS}
                                adminAllowed={adminAllowed}
                                displayFactor={displayFactor}
                                idRole={idRole}
                                entityId={entityId}
                                imageDownloader={imageDownloader}
                                setFullscreenImageBlob={setFullscreenImageBlob}
                                readOnly={readOnly}
                            />
                        )
                    }) : null}
                </div> : null}
        </>
    )

})