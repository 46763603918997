import { useState, useEffect } from 'react';
import { getCasesDistribution } from '../Services/CasesDistribution/getCasesDistribution';
import { getCasesDistributionByTeachers } from '../Services/CasesDistribution/getCasesDistributionByTeachers';

const useCasesDistribution = () => {

    //#region 'USE STATES'
    const [casesDistribution, setCasesDistribution] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getData = async () => {
        return await getCasesDistribution()
    }

    const getDataByTeachers = async (id_teacher) => {
        return await getCasesDistributionByTeachers(id_teacher)
    }

    const postData = async () => {
        return await true
    }

    const putData = async () => {
        return await true
    }

    const fillSetState = async () => {
        setCasesDistribution(await getData())
    }
    //#endregion

    //#region 'USE EFFECT'
    useEffect(() => {
        fillSetState()
    }, [])
    //#endregion

    return { casesDistribution, setCasesDistribution, getData, getDataByTeachers, postData, putData }

}

export { useCasesDistribution }