import { getDoubtsByCase } from '../Services/Doubts/getDoubtsByCase'
import { postDoubt } from '../Services/Doubts/postDoubt'
import { putDoubt } from '../Services/Doubts/putDoubt'
import { deleteDoubt } from '../Services/Doubts/deleteDoubt'

const useDoubts = () => {

    const retrieveDoubts = async (idCase) => {
        return await getDoubtsByCase(idCase)
    }

    const createDoubt = async (idCase, idDoubtType, doubt) => {
        return await postDoubt(idCase, idDoubtType, doubt)
    }

    const updateDoubt = async (id, idDoubtType, doubt) => {
        return await putDoubt(id, idDoubtType, doubt)
    }

    const removeDoubt = async (id) => {
        return await deleteDoubt(id)
    }

    return {
        retrieveDoubts,
        createDoubt,
        updateDoubt,
        removeDoubt
    }
}

export { useDoubts }