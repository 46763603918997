import { getResponse } from '../Services/Responses/getResponse'
import { postResponse } from '../Services/Responses/postResponse'
import { putResponse } from '../Services/Responses/putResponse'

const useResponse = () => {

    const createResponse = (idDoubt, response, idFaq) => {
        return postResponse(idDoubt, response, idFaq)
    }

    const retrieveResponse = (idDoubt) => {
        return getResponse(idDoubt)
    }

    const updateResponse = (idResponse, idDoubt, response, idFaq) => {
        return putResponse(idResponse, idDoubt, response, idFaq)
    }

    return { createResponse, retrieveResponse, updateResponse }
}

export { useResponse }