import React from 'react'
import Switch from '@mui/material/Switch';
import ButtonModal from '../Tools/ButtonModal'
import SimpleBootstrapModal from '../Tools/SimpleBootstrapModal'
import ButtonSave from '../Tools/ButtonSave'
import CustomFilter from '../Tools/CustomFilter'
import CustomSelect from '../Tools/CustomSelect'
import TextInputCustom from '../Tools/TextInputCustom'
import { ViewType } from '../Tools/ViewTypes/ViewType';
import { ViewData } from '../Tools/ViewsData/ViewData';
import { ContentItem } from '../Tools/ContentItem';
import { TableList } from '../Tools/Table/TableList';
import { KanbanCard } from '../Tools/Kanban/KanbanCard';
import { ItemKanban } from '../Tools/Kanban/ItemKanban';
import { CheckFormatDate } from '../Tools/GeneralFunctions/CheckFormatDate';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import { ContentGeneral } from '../Tools/ContentGeneral';

const StoredResponsesScreenUI = props => {

    const obFields = {
        type: "Tipo",
        response: "Respuesta",
        created: "Creación",
        visible: "Visible"
    }

    if (props.storedResponsesTypes
        .filter(caseType => caseType.value === -1) === 0) {
        props.storedResponsesTypes.map(caseType => {
            return caseType.target = { name: "typeFilter" }
        })
        props.storedResponsesTypes.unshift({ value: -1, label: "Todos", target: { name: "typeFilter" } })
    }

    //#region 'STRUCTURES'

    //#region 'VIEWS KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={props.storedResponsesTypes.find(e => e.value === item.id_chats_responses_recorded_types)?.label || ''}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite`}
            dataTarget={"#modal"} onclickHead={() => props.openModalModify(
                item.id,
                item.Response,
                item.Visible.data[0],
                item.id_chats_responses_recorded_types,
                item.Created,
                item.Modified,
                item.id_modifier,
                item.id_creator
            )}
            cardBody={
                <ContentItem
                    itemBody={[
                        <ItemKanban classCol={"col-12"} label={obFields.response} value={item.Response} />,
                        <ItemKanban classCol={"col-6"} label={obFields.created} value={CheckFormatDate(item.Created)} />,
                        <div className="col-12 col-sm-6">
                            <p className="card-text mb-1" style={{ fontSize: 13 }}>
                                <strong>Visible</strong><br />
                                <Switch disabled checked={Boolean(item.Visible.data[0])} />
                            </p>
                        </div>
                    ]}
                />
            }
        />
    )
    //#endregion

    //#region 'VIEWS LIST'
    const ContentList = ({ item, index }) => (
        <tr key={index}
            onClick={() => props.openModalModify(
                item.id,
                item.Response,
                item.Visible.data[0],
                item.id_chats_responses_recorded_types,
                item.Created,
                item.Modified,
                item.id_modifier,
                item.id_creator
            )} data-bs-toggle="modal" data-bs-target="#modal">
            <th scope="row">{index + 1}</th>
            <td style={{ width: 200, minWidth: 200, textAlign: 'center' }}>{item.Name}</td>
            <td>{item.Response}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{CheckFormatDate(item.Created)}</td>
            <td style={{ width: 60, minWidth: 60, textAlign: 'center' }}><Switch disabled checked={Boolean(item.Visible.data[0])} /></td>
        </tr>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>{obFields.type}</th>
            <th>{obFields.response}</th>
            <th style={{ textAlign: 'center' }}>{obFields.created}</th>
            <th style={{ textAlign: 'center' }}>{obFields.visible}</th>
        </tr>
    )

    const ViewList = () => <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    //#endregion

    const ArrayMaping = props.filteredResponses.map((response, index) => (
        props.views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={response} index={index} /> : <ContentList key={"key_ContentList"} item={response} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={"Respuestas"} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} textButton={"Crear"} handleSubmit={props.openModalCreate} dataTarget={"#modal"}
                views={props.views.type} kanbanFunction={props.views.funcKanban} listFunction={props.views.funcList}
            />,
            <CustomFilter
                {...{
                    options: props.cbfilterObj,
                    onChange: props.doFilters,
                    nameSelect: "valueDp",
                    nameInput: "textFilter",
                    cssClasses: "paddinglr0"
                }}
            />,

            <div>
                <label><b>Tipo</b></label>
                <CustomSelect
                    data={props.storedResponsesTypes}
                    name="type"
                    divClasses="marginlr0 field marginb05"
                    onChange={props.doFilters}
                />
            </div>,

            <ViewData viewType={props.views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
            />

        ]} />,

        <SimpleBootstrapModal
            id="deleteStoredResponse"
            title="Eliminar respuesta"
            message={`¿Está seguro que desea borrar esta respuesta guardada (${props.modalContent.response})?`}
            closeButtonId="closeDeleteStoredResponse"
            footerCancelButton={false}
            footerButtons={
                <>
                    <ButtonModal id={"goBackStoredResponse"} text={"Atrás"} bsTarget={"#modal"} close={true} />
                    <ButtonSave
                        textBtn={"Aceptar"}
                        formGroup={false}
                        handleSubmit={props.actions.delete}
                    />
                </>
            }
        />,

        <SimpleBootstrapModal
            id={"modal"}
            closeButtonId="closeStoredResponseModal"
            title={props.modalTitle}
            footerButtons={
                <>
                    {
                        Number.isInteger(props.modalContent.id) ?
                            <ButtonSave
                                textBtn={"Eliminar"}
                                cssClasses={"margin0 inline-block"}
                                buttonClasses={"light-red"}
                                dataTarget={"#deleteStoredResponse"}
                                icoClasses={"fas fa-trash-alt"}
                            />
                            : null
                    }
                    {props.btnsDisplay.saveBtn ? <ButtonModal onClick={props.actions.update} text={props.modalConfirmText} /> : ""}
                    {props.btnsDisplay.deleteBtn ? <button type="button" className="btn btn-danger"
                        onClick={props.actions.delete}>{props.modalConfirmText}</button> : ""}
                    {props.btnsDisplay.createBtn ? <ButtonModal onClick={props.actions.create} text={props.modalConfirmText} /> : ""}
                </>
            }
        >
            <form action="">
                <div className="row">
                    <div className="col-12">
                        <TextInputCustom
                            textLabel={"Respuesta"}
                            nameInput={"response"}
                            value={props.modalContent.response}
                            evOnChange={props.handleInputChange}
                            disabled={props.contentDisabled}
                            required={true}
                            cssClassesGroup={"marginlr0 marginb05"}
                            cssClassesInput={"field"}
                        />
                    </div>
                    <div className="col-12">
                        <CustomSelect
                            data={props.storedResponsesTypes}
                            nameSelect={"storedTypeId"}
                            label={"*Tipos de Respuestas"}
                            value={
                                props.storedResponsesTypes.map(option => {
                                    if (option.value === props.modalContent.storedTypeId) {
                                        return option
                                    }
                                })}
                            disabled={props.contentDisabled}
                            onChange={props.SelectChange}
                            divClasses={"marginlr0 field marginb05"}
                        />

                    </div>
                </div>
                <div className="row">
                    {props.userData.idRole === 1 &&
                        <div className="col-12">
                            <div className="field">
                                <label htmlFor="name">Visible </label>
                                <br />
                                <Switch
                                    id="sw"
                                    className="margin0"
                                    name="sw"
                                    onChange={props.SwitchChange}
                                    checked={Boolean(props.modalContent?.visible)}
                                    disabled={props.contentDisabled}
                                />
                            </div>
                        </div>
                    }
                </div>
            </form>
        </SimpleBootstrapModal>
    ])
}

export { StoredResponsesScreenUI }