import React, { useEffect } from "react"
import SimpleBootstrapModal from "./SimpleBootstrapModal"

export default({
    blob,
    previousModalButtonOpenerId
}) => {
    useEffect(() => {
        if (blob) document.getElementById("imageModalButton").click()
    }, [blob])

    const openPreviousModal = () => document.getElementById(previousModalButtonOpenerId).click()

    return (
        <>
            <button type="button" id="imageModalButton" data-bs-toggle="modal" data-bs-target="#imageModal" className="none"></button>
            <SimpleBootstrapModal 
                fullScreen={true} 
                id="imageModal" 
                bodyCSS={{display: "flex", justifyContent: "center", alignItems: "center"}} 
                cancelButtonMessage="Atrás" 
                closeFunction={openPreviousModal}>
                <img src={blob} alt={"imgFullScreen"} className="imageOrientation"/>
            </SimpleBootstrapModal>
        </>
    )
}