export default (state, action) => {
    const { payload, type } = action

    switch (type) {
        case "DISABLE_RESPONSE_EDITION":
            return {
                ...state,
                responseFormEdition: false
            }
            break
        case "ENABLE_RESPONSE_EDITION":
            return {
                ...state,
                responseFormEdition: true
            }
        case "DISABLE_DOUBT_EDITION":
            return {
                ...state,
                doubtsFormEdition: false
            }
            break
        case "ENABLE_DOUBT_EDITION":
            return {
                ...state,
                doubtsFormEdition: true
            }
            break
        case "DISABLE_DOUBT_CREATION":
            return {
                ...state,
                isCreating: false
            }
            break
        case "ENABLE_DOUBT_CREATION":
            return {
                ...state,
                isCreating: true
            }
            break
        default:
            return state
            break
    }
}