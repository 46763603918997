import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContentGeneral } from '../Tools/ContentGeneral';
import { ContentItem } from '../Tools/ContentItem';
import CustomFilter from '../Tools/CustomFilter';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import { ItemKanban } from '../Tools/Kanban/ItemKanban';
import { KanbanCard } from '../Tools/Kanban/KanbanCard';
import { TableList } from '../Tools/Table/TableList';
import { ViewData } from '../Tools/ViewsData/ViewData';
import { ViewType } from '../Tools/ViewTypes/ViewType';

const CasesDistributionUI = ({ views, casesDistribution, filters }) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const obFields = {
        title: "Repartición de casos",
        teacher_name: "Profesor",
        count_cases_assigned: "Casos asignados"
    }
    //#endregion


    //#region 'FUNCTIONS'
    const handleViewCasesByTeachers = (item) => {
        history.push({
            pathname: "/main-screen/cases-distribution-teacher",
            state: {
                id_teacher: item.id_teacher_assigned,
                teacher_name: item.teacher_name
            }
        })
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'

    //#endregion


    //#region 'STRUCTURE'
    //#region 'KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.teacher_name.toLocaleUpperCase()}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            onclickHead={() => handleViewCasesByTeachers(item)}
            cardBody={[
                <ContentItem key={"keyCI_" + index} itemBody={[
                    <ItemKanban classCol={'col'} label={obFields.count_cases_assigned} value={item.count_cases_assigned} />,
                ]} />
            ]}
        />
    )
    //#endregion
    //#region 'LIST'
    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.teacher_name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.count_cases_assigned}</th>
        </tr>
    )

    const ContentList = ({ item, index }) => (
        <tr key={item.id} className='clickable' onClick={() => handleViewCasesByTeachers(item)}
            data-bs-toggle="modal" data-bs-target="#packsModal">
            <th scope="row">{index + 1}</th>
            <td>{item.teacher_name.toLocaleUpperCase()}</td>
            <td style={{ width: 180, minWidth: 180, textAlign: 'center' }}>{item.count_cases_assigned}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion
    const ArrayMaping = casesDistribution.data?.filter(filters.onFilter).map((item, index) => (
        views.type.kanban ? <ViewKanban item={item} index={index} /> : <ContentList item={item} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={obFields.title} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={false} views={views.type}
                kanbanFunction={views.funcKanban}
                listFunction={views.funcList}
            />,
            <CustomFilter keyValue={"searchCasesDistribution"} nameSelect={"valueDp"} nameInput={"textFilter"}
                options={filters.dpFieldsFilters}
                onChange={filters.onChange}
                valueSelect={filters.selFilter.valueDp}
                valueInput={filters.selFilter.textFilter}
                cssClasses={"paddinglr0"} />,
            < ViewData
                kanban={< ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
                viewType={views.type.kanban} />,
        ]} />
    ])
}

export { CasesDistributionUI }