import { useState, useEffect } from 'react';
import { deleteCasesPacks } from '../Services/CasesPacks/deleteCasesPacks';
import { getCasesPacks } from '../Services/CasesPacks/getCasesPacks';
import { postCasesPacks } from '../Services/CasesPacks/postCasesPacks';
import { putActivatedCasesPacks } from '../Services/CasesPacks/putActivatedCasesPacks';
import { putCasesPacks } from '../Services/CasesPacks/putCasesPacks';

const useCasesPacks = () => {

    //#region 'USE STATES'
    const [casesPacks, setCasesPacks] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getData = async () => {
        return await getCasesPacks()
    }

    const postData = async (data) => {
        return await postCasesPacks(data)
    }

    const putData = async (data) => {
        return await putCasesPacks(data)
    }

    const putActivatedData = async (id, active) => {
        return await putActivatedCasesPacks(id, active)
    }

    const deleteData = async (id) => {
        return await deleteCasesPacks(id)
    }

    const fillSetState = async () => {
        setCasesPacks(await getData())
    }
    //#endregion
    //#region 'USE EFFECT'
    useEffect(() => {
        fillSetState()
    }, [])
    //#endregion

    return { casesPacks, setCasesPacks, getData, postData, putData, deleteData, putActivatedData }

}

export { useCasesPacks }