import React from 'react';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import pwa_logo from '../../../img/pwa_logo.png'
import shareIcon from "../../../img/ic_iphone_share.png";

const PopUpIOS = () => {
    return (
        <PwaInstallPopupIOS delay={1}>
            <div style={{ padding: '15px 30px', backgroundColor: 'white', color: 'black' }}>
                <div className='row'>
                    <p>Para encontrar la aplicación OIF desde tu pantalla de inicio</p>
                    <div style={{ width: 100 }}>
                        <figure>
                            <img src={pwa_logo} style={{ width: 80, height: 80 }} alt="OIF" />
                            <figcaption style={{ textAlign: 'center' }}>OIF</figcaption>
                        </figure>
                    </div>
                    <div className='col-8'>
                        <ol>
                            <li>Abre OIF en Safari</li>
                            <li>Haga clic en el menú Compartir 
                                <span>
                                    <img className="small" src={shareIcon} />
                                </span>
                            </li>
                            <li>seleccione 'Add to Homescreen'</li>
                        </ol>
                    </div>
                </div>
            </div>
        </PwaInstallPopupIOS>
    );
}

export { PopUpIOS }