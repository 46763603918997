//#region 'IMPORTS'
import './Doubts.css'
import React from 'react'
import { CheckFormatDate } from '../../Tools/GeneralFunctions/CheckFormatDate'
import { TableList } from '../../Tools/Table/TableList'
import { KanbanCard } from '../../Tools/Kanban/KanbanCard'
import { ContentItem } from '../../Tools/ContentItem'
import { ItemKanban } from '../../Tools/Kanban/ItemKanban'
import { ViewData } from '../../Tools/ViewsData/ViewData'
//#endregion

const DoubtsUI = ({ setDoubtsIndex, doubts, openModalModify, views }) => {
    //#region 'VARIABLES'
    const objFields = {
        type: "Tipo",
        doubts: "Duda",
        created: "Creación",
        teacher: "Profesor",
        responseDate: "F. Respuesta"
    }
    //#endregion

    //#region 'STRUCTURE'

    //#region 'VIEWS'

    //#region 'VIEWS KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Type.toUpperCase()}
            classHead={`ps-3 pe-3 pt-2${item.response.Response || item.response.id_faqs ? " green" : " blackAndWhite"}`}
            onclickHead={() => { openModalModify(item); setDoubtsIndex(index) }}
            dataTarget={"#doubtForm"}
            cardBody={[
                <ContentItem itemBody={[
                    <ItemKanban label={objFields.doubts} value={`${item.Doubt.length > 28 ? `${item.Doubt.substr(0, 25)}...` : item.Doubt}`} />,
                    <ItemKanban classCol={"col-6"} label={objFields.created} value={CheckFormatDate(item.Created)} />,
                    <ItemKanban classCol={"col-6"} label={objFields.teacher} value={item.teacher_name} />
                ]} />
            ]}
        />
    )

    //#endregion

    //#region 'VIEW LIST'

    const HeadList = () => (
        <tr>
            <th></th>
            <th style={{ textAlign: 'center', width: 180, minWidth: 180 }}>{objFields.type}</th>
            <th style={{ textAlign: 'center', minWidth: 500 }}>{objFields.doubts}</th>
            <th style={{ textAlign: 'center', width: 120, minWidth: 120 }}>{objFields.created}</th>
            <th style={{ textAlign: 'center', width: 250, minWidth: 250 }}>{objFields.teacher}</th>
            <th style={{ textAlign: 'center', width: 120, minWidth: 120 }}>{objFields.responseDate}</th>
        </tr>
    )

    const ContentList = ({ item, index }) => (
        <tr key={index} onClick={() => { openModalModify(item); setDoubtsIndex(index) }}
            className="clickable" data-bs-toggle="modal" data-bs-target="#doubtForm">
            <th className={`${item.response.Response || item.response.id_faqs ? "green" : ""}`}>{index + 1}</th>
            <td className="text-center">{item.Type}</td>
            <td className='text-truncate text-right'>{item.Doubt}</td>
            <td className="text-center">{CheckFormatDate(item.Created)}</td>
            <td className="text-center">{item.teacher_name}</td>
            <td className="text-center">{CheckFormatDate(item.response.Created)}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMapping} />
    )
    //#endregion

    const ArrayMapping = doubts.map((doubt, index) => (
        views.kanban ? <ViewKanban item={doubt} index={index} /> : <ContentList item={doubt} index={index} />
    ))
    //#endregion

    //#endregion
    return ([
        typeof doubts !== "undefined" && doubts.length > 0 && (
            <ViewData viewType={views.kanban} kanban={<ContentItem itemBody={ArrayMapping} />} list={<ViewList />} />),
        typeof doubts !== "undefined" && doubts.length === 0 && (<p>Este caso no tiene dudas</p>)
    ])



}

export default DoubtsUI