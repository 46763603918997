import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const getUserData = () => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.user)
        xhr.withCredentials = true
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                resolve(res)
            } catch(e) {
                //messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            //messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}

export { getUserData }