import React from 'react'
import DoubtsUI from './DoubtsUI'

const Doubts = ({ setDoubtsIndex, doubts, openModalModify, views }) => {
    return (
        <DoubtsUI
            setDoubtsIndex={setDoubtsIndex}
            doubts={doubts}
            openModalModify={openModalModify}
            views={views}
        />
    )
}

export default Doubts;