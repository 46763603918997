import { useContext, useState, useEffect } from 'react'
import { getTeachers } from '../Services/Teacher/GetTeachers'
import { UserContext } from '../Contexts/User/UserContext'

const useTeachers = () => {
    const userContext = useContext(UserContext)
    const [teachers, setTeachers] = useState([])
    const [teachersReassign, setTeachersReassign] = useState([])

    const getDataTeacher = async () => {
        let userData = await userContext.retrieveUserData()
        if (userData.idRole === 1) {
            getTeachers().then(item => {
                console.log("🚀 ~ file: useTeachers.js:14 ~ getTeachers ~ item:", item)
                const doc = []
                item.forEach(element => {
                    doc.push({ value: element.id, label: `${element.Name} ${element.Lastname}` })
                })
                setTeachers([{ value: null, label: "*Sin profesor" }, ...doc])
                setTeachers(doc)
                setTeachersReassign(doc)
            })
        }
    }

    useEffect(() => {
        getDataTeacher()
    }, [])


    return { teachers, setTeachers, teachersReassign }
}

export { useTeachers }