import { postFile } from '../Services/Files/postFile'
import { retrieveFile } from '../Services/Files/retrieveFile'
import { retrieveCasePoll } from '../Services/Files/retrieveCasePoll'
import { retrieveFilesInfo } from '../Services/Files/retrieveFilesInfo'
import { deleteFile } from '../Services/Files/deleteFile'
import { downloadFile } from '../Services/Files/downloadFile'
import { downloadPath } from '../Services/Files/downloadPath'

const useFiles = () => {

    const formDataBuilder = (files, aux) => {
        let formData = new FormData()
        for (let index = 0; index < files.length; index++) {
            formData.append('userUpload', files[index])
        }

        if (Number.isInteger(aux)) {
            formData.append("aux", aux)
        }
        return formData
    }

    const uploadFiles = async (files, form, entityId, aux) => {
        let fromData = formDataBuilder(files, aux)
        let result = await postFile(fromData, form, entityId)
        return result
    }

    const getFile = async (fileName) => {
        return await retrieveFile(fileName)
    }

    const getCasePollFile = async (idCase, caseCode) => {
        return await retrieveCasePoll(idCase, caseCode)
    }

    const getFilesInfo = async (entity, idEntity) => {
        return await retrieveFilesInfo(entity, idEntity)
    }

    const removeFile = async (entity, idFile) => {
        return await deleteFile(entity, idFile)
    }

    const download = async (entity, idFile, name, mimetype, extension) => {
        return await downloadFile(entity, idFile, name, mimetype, extension)
    }


    const downloadFileByPath = (path, mimetype) => downloadPath(path, mimetype)
    return {
        uploadFiles,
        getFile,
        getCasePollFile,
        getFilesInfo,
        removeFile,
        download,
        downloadFileByPath
    }
}

export { useFiles }