import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Contexts/User/UserContext';
import { useLOPD } from '../../Hooks/useLOPD';
import { useShowView } from '../../Hooks/useShowView';
import { LopdScreenUI } from './LopdScreenUI';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

const LopdScreen = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const objState = { editorState: '' }
    //#endregion

    //#region 'USE REF'

    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { data } = useLOPD()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //#endregion

    //#region 'USE STATES'
    const [stateEditor, setstateEditor] = useState(objState)
    //#endregion

    //#region 'FUNCTIONS'
    const propertiesEditor = () => {
        const contentBlock = htmlToDraft('');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setstateEditor({ editorState: editorState });
    }
    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        propertiesEditor()
        if (data.length > 0) {
            data.forEach(item => {
                item.contentHTML = UpdateStateEditor(item?.content || '')
                return item
            })
        }
    }, [data])

    //#endregion


    //#region 'STRUCTURE'

    //#endregion

    return <LopdScreenUI
        data={data}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
    />
}

export { LopdScreen }