import { useState, useEffect } from 'react'
import { getFaqsTypes } from '../Services/FaqsTypes/getFaqsTypes'
import { postFaqsType } from '../Services/FaqsTypes/postFaqsType'
import { putFaqsType } from '../Services/FaqsTypes/putFaqsType'
import { deleteFaqsType } from '../Services/FaqsTypes/deleteFaqsType'

const useFaqsTypes = () => {

    const [faqsTypes, setFaqsTypes] = useState([])

    useEffect(() => {
        getFaqsTypes().then(item => {
            const doc = []
            item.forEach(element => {
                doc.push({ value: element.id, label: element.Name })
            })
            setFaqsTypes(doc)
        })
    }, [])

    const retrieveFaqsTypes = async () => {
        return await getFaqsTypes()
    }

    const createFaqsType = (name) => postFaqsType(name)

    const updateFaqsType = (id, name) => putFaqsType(id, name)

    const removeFaqsType = (id) => deleteFaqsType(id)

    return {
        faqsTypes,
        retrieveFaqsTypes,
        createFaqsType,
        updateFaqsType,
        removeFaqsType
    }
}

export { useFaqsTypes }