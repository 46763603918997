import { useState, useEffect } from 'react'
import { GetCasesTypes } from '../Services/CasesTypes/GetCasesTypes'
import { postCaseType } from '../Services/CasesTypes/postCaseType'
import { putCaseType } from '../Services/CasesTypes/putCaseType'
import { deleteCaseType } from '../Services/CasesTypes/deleteCaseType'
import { getAllCasesTypes } from '../Services/CasesTypes/getAllCasesTypes'

export default ({ onlyForm = false, onlySearch = false, together = false } = {}) => {

    const [casesTypes, setCasesTypes] = useState([])
    const [casesTypesDp, setCasesTypesDp] = useState([])

    useEffect(() => {
        if (onlyForm) {
            getFillCasesTypes()
        } else if (onlySearch) {
            getFillCasesTypesDp()
        } else if (together) {
            getFillCasesTypes()
            getFillCasesTypesDp()
        }
    }, [])

    const formatData = (item) => {
        const doc = []
        item.forEach(element => {
            doc.push({
                value: element.id,
                label: element.Name,
                target: { name: "caseTypeFilter" }
            })
        });
        return doc
    }

    const getFillCasesTypes = async () => {
        let result = await GetCasesTypes()
        result = formatData(result)
        setCasesTypes(result)
    }

    const getFillCasesTypesDp = async () => {
        let result = await GetCasesTypes()
        result = formatData(result)
        result.unshift({ value: -1, label: "Todos", target: { name: "caseTypeFilter" } })
        setCasesTypesDp(result)
    }

    const retrieveCasesTypes = () => getAllCasesTypes()

    const createCaseType = data => postCaseType(data)

    const updateCaseType = data => putCaseType(data)

    const removeCaseType = data => deleteCaseType(data)

    return {
        retrieveCasesTypes,
        createCaseType,
        updateCaseType,
        removeCaseType,
        casesTypes,
        casesTypesDp
    }
}
