//#region 'IMPORTS'
import React from 'react'
import TextInputCustom from '../Tools/TextInputCustom'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import ButtonModal from '../Tools/ButtonModal'
import { ContentItem } from '../Tools/ContentItem'
import { CheckTime } from '../Tools/GeneralFunctions/CheckTime'
import { ViewData } from '../Tools/ViewsData/ViewData'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import { KanbanCard } from '../Tools/Kanban/KanbanCard'
import { TableList } from '../Tools/Table/TableList'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

const TeacherStatisticsScreenUI = ({ views, teacherStats, totals, dateIni, dateEnd }) => {

    //#region VARIABLES
    const objLabelItems = {
        itemName: "Nombre",
        itemNumCases: "Casos asignados",
        itemNumResponseCases: "Casos resp.",
        itemNumResponseDoubts: "Dudas resp.",
        itemResponseTime: "Total días",
        itemAvarageResponseTime: "Media días",
        itemAverage: "Media",
        itemTotalAverage: "Media total"
    }
    //#endregion

    //#region STRUCTURE    

    //#region STRUCTURE KANBAN
    const ViewKanvan = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.NAME.toLocaleUpperCase()}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite`}
            cardBody={[
                <ContentItem key={"keyCI_RC_RD_" + index} itemBody={[
                    <ItemKanban key={"keyItemNumResponseCases"} classCol={"col-6"} label={objLabelItems.itemNumResponseCases} value={item.n_response_cases} />,
                    <ItemKanban key={"keyItemNumResponseDoubts"} classCol={"col-6"} label={objLabelItems.itemNumResponseDoubts} value={item.n_response_doubts} />
                ]} />,
                <ContentItem key={"keyCI_AV_RT_" + index} itemBody={[
                    <ItemKanban key={"keyItemAvarageResponseTime"} label={objLabelItems.itemAvarageResponseTime} value={CheckTime(item.avarage_response_time)} />
                ]} />
            ]}
        />
    )
    //#endregion

    //#region STRUCTURE LIST
    const HeadList = () => (
        <tr>
            <th></th>
            <th>{objLabelItems.itemName}</th>
            <th>{objLabelItems.itemNumResponseCases}</th>
            <th>{objLabelItems.itemNumResponseDoubts}</th>
            <th>{objLabelItems.itemAvarageResponseTime}</th>
        </tr >
    )

    const ContentList = ({ item, index }) => (
        <tr key={item.id}>
            <th>{index + 1}</th>
            <td>{item.NAME.toLocaleUpperCase()}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'right' }}>{item.n_response_cases}</td>
            <td style={{ width: 110, minWidth: 110, textAlign: 'right' }}>{item.n_response_doubts}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'right' }}>{CheckTime(item.avarage_response_time)}</td>
        </tr>
    )

    const FooterList = () => (
        <tr>
            <td></td>
            <td><strong>TOTALES</strong></td>
            <td style={{ textAlign: 'right' }}>{totals.response_cases}</td>
            <td style={{ textAlign: 'right' }}>{totals.response_doubts}</td>
            <td style={{ textAlign: 'right' }}>{totals.avarage_time}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} footerList={<FooterList />} />
    )
    //#endregion

    const ArrayMaping = teacherStats.data.map((stats, index) => (
        views.type.kanban ? <ViewKanvan key={"key_itemsTeacherStatsKanban_" + index} item={stats} index={index} />
            : <ContentList key={"key_itemsTeacherStatsList_" + index} item={stats} index={index} />
    ))

    //#endregion

    return ([
        <HeadTitle title={"Estadísticas"} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={false} views={views.type} kanbanFunction={views.funcKanban} listFunction={views.funcList} />,
            <ContentItem
                itemBody={[
                    <TextInputCustom
                        key={"keyInputDateIniSearch"}
                        typeInput={"date"}
                        textLabel={"Fecha inicio"}
                        nameInput={"dateIniSearch"}
                        style={{ margin: 0, marginTop: 5, fontWeight: 'bolder' }}
                        cssClassesGroup={"col-12 col-sm-5"}
                        value={dateIni.value}
                        maxValue={dateEnd.value}
                        pattern={"{2}-2}-{4}"}
                        evOnChange={(e) => dateIni.set(e.target.value)}
                    />,
                    <TextInputCustom
                        key={"keyInputDateEndSearch"}
                        typeInput={"date"}
                        textLabel={"Fecha final"}
                        nameInput={"dateFinalSearch"}
                        cssClassesGroup={"col-12 col-sm-5"}
                        style={{ margin: 0, marginTop: 5, fontWeight: 'bolder' }}
                        value={dateEnd.value}
                        pattern={"{2}-{2}-{4}"}
                        evOnChange={(e) => dateEnd.set(e.target.value)}
                    />,
                    <div key={"keyContentButtons"} className='col-12 col-sm-2 d-flex align-items-end'>
                        <ButtonModal text={"Filtrar"} cssClasses={"col btn-block me-2"} style={{ marginTop: 5 }} onClick={teacherStats.funcFilter} />
                        <ButtonModal text={"Limpiar"} cssClasses={"col btn-block"} style={{ marginTop: 5 }} onClick={teacherStats.funcResetVal} />
                    </div>
                ]}
            />,
            <ViewData viewType={views.type.kanban} kanban={<ContentItem itemBody={ArrayMaping} />} list={<ViewList />} />
        ]} />
    ])
}

export { TeacherStatisticsScreenUI }