import React, { useEffect } from 'react'
import { ObservacionesUI } from '../Observations/ObservationsUI'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';

const Observations = ({ observations, views, setters }) => {

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }

    useEffect(() => {
        let data = [...observations.data]
        data.forEach(item => {
            item.observationHTML = UpdateStateEditor(item.observation || '')
            return item
        });
        observations.set(data)
    }, [])


    return <ObservacionesUI observations={observations} views={views} setters={setters} />
}

export { Observations }