//#region 'IMPORTS'
import React, { useState } from 'react'
import FaqsTypesScreenCSS from './FaqsTypesScreen.module.css'
import ButtonSave from '../../Tools/ButtonSave'
import ButtonOutline from '../../Tools/ButtonOutline/ButtonOutline'
import CustomFilter from '../../Tools/CustomFilter'
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal'
import ButtonModal from '../../Tools/ButtonModal'
import TextInputCustom from '../../Tools/TextInputCustom'
import { helpers } from '../../../constants'
import { ViewData } from '../../Tools/ViewsData/ViewData'
import { ContentItem } from '../../Tools/ContentItem'
import { KanbanCard } from '../../Tools/Kanban/KanbanCard'
import { ItemKanban } from '../../Tools/Kanban/ItemKanban'
import { TableList } from '../../Tools/Table/TableList'
import { CheckFormatDate } from '../../Tools/GeneralFunctions/CheckFormatDate'
import { ViewType } from '../../Tools/ViewTypes/ViewType'
import { HeadTitle } from '../../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../../Tools/ContentGeneral'
//#endregion

const FaqsTypesScreenUI = props => {

    //#region 'VARIABLES'
    const obFields = {
        name: "Nombre",
        created: "Creación"
    }
    //#endregion

    //#region 'STRUCTURE'

    //#region 'VIEW KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Name}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            dataTarget={"#faqsTypeForm"} onclickHead={() => props.openModal.update(item.id, item.Name)}
            cardBody={
                <ContentItem itemBody={[
                    <ItemKanban key={"faqTypes_Created_" + index} label={obFields.created} value={CheckFormatDate(item.Created)} classCol={"col-12"} />,
                ]} />
            } />
    )
    //#endregion

    //#region 'VIEW LIST'
    const ContentList = ({ item, index }) => (
        <tr key={index}
            className="clickable"
            onClick={() => props.openModal.update(item.id, item.Name)}
            data-bs-toggle="modal"
            data-bs-target="#faqsTypeForm">
            <th scope="row">{index + 1}</th>
            <td>{item.Name}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{CheckFormatDate(item.Created)}</td>
        </tr>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.created}</th>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion

    const ArrayMaping = props.filteredFAQsTypes.map((faqType, index) => (
        props.views.type.kanban ? <ViewKanban key={"key_viewKanban_" + index} item={faqType} index={index} /> : <ContentList key={"key_ContentList"} item={faqType} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={"Tipos de preguntas frecuentes"} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} textButton={"Crear"} handleSubmit={props.openModal.create} dataTarget={"#faqsTypeForm"}
                views={props.views.type} kanbanFunction={props.views.funcKanban} listFunction={props.views.funcList}
            />,

            <CustomFilter
                {...{
                    options: props.cbfilterObj,
                    onChange: props.filterFAQsTypes,
                    nameSelect: "valueDp",
                    nameInput: "textFilter",
                    cssClasses: "paddinglr0"
                }}
            />,

            <ViewData
                viewType={props.views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
            />,

        ]} />,

        <SimpleBootstrapModal
            id="deletefaqsType"
            title="Eliminar tipo de FAQ"
            closeButtonId="closeDeletefaqsType"
            message={`¿Está seguro de eliminar este tipo de FAQ (${props.modalContent.name})?`}
            footerCancelButton={false}
            footerButtons={[
                <ButtonModal
                    id={"goBackFaqsTypes"}
                    text={"Atrás"}
                    bsTarget={"#faqsTypeForm"}
                    close={true}
                />,
                <ButtonSave
                    textBtn={"Aceptar"}
                    formGroup={false}
                    handleSubmit={props.actions.delete}
                />
            ]}
        >
        </SimpleBootstrapModal>,

        <SimpleBootstrapModal
            id="faqsTypeForm"
            title={props.modalTitle}
            closeButtonId={"closeFaqsTypeForm"}
            footerButtons={[
                Number.isInteger(props.modalContent.id) && (<ButtonSave
                    textBtn={"Eliminar"}
                    formGroup={false}
                    buttonClasses={"light-red"}
                    dataTarget={"#deletefaqsType"}
                    icoClasses={"fas fa-trash-alt"}
                />),
                props.btnsDisplay.update && (<ButtonSave
                    textBtn={props.modalConfirmText}
                    handleSubmit={props.actions.update}
                    formGroup={false}
                />),
                props.btnsDisplay.delete && (<ButtonSave
                    textBtn={props.modalConfirmText}
                    handleSubmit={props.actions.delete}
                    formGroup={false}
                />),
                props.btnsDisplay.create && (<ButtonSave
                    textBtn={props.modalConfirmText}
                    handleSubmit={props.actions.create}
                    formGroup={false}
                />),
            ]}
        >
            <form action="">
                <div className={"row"}>
                    <div className="col-sm">
                        <TextInputCustom
                            textLabel={"Nombre de tipo de FAQ"}
                            nameInput={"name"}
                            required={true}
                            value={props.modalContent.name}
                            disabled={props.contentDisabled}
                            evOnChange={props.handleInputChange}
                        />
                    </div>
                </div>
            </form>
        </SimpleBootstrapModal >
    ])
}

export { FaqsTypesScreenUI }