import React from 'react'
import Select from 'react-select'
import { ErrorText } from './ErrorText'

const CustomSelect = ({ label, nameSelect, closeMenu,
    disabled, multi = false, onChange, data, value, requiredValidation,
    divClasses, style, defaultValue, required = false }) => {

    return (
        <div className={`form-group${divClasses ? ` ${divClasses}` : ""}`} style={style}>
            {label ? <label htmlFor={label}>{required ? '*' : ''}{label}</label> : null}
            <Select
                name={nameSelect}
                closeMenuOnSelect={closeMenu}
                isDisabled={disabled}
                placeholder='Selecciona...'
                isMulti={multi}
                defaultValue={defaultValue || 'Selecciona...'}
                onChange={onChange}
                options={data}
                value={value}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            {typeof requiredValidation != "undefined" && requiredValidation.condition ?
                <ErrorText msg={requiredValidation.msg} /> :
                null
            }
        </div>
    )
}

export default CustomSelect;