import React, { useState, useEffect, useContext } from 'react'
import { useHistory, BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import Navigation from '../Navigation'

import { UserContext } from './../../Contexts/User/UserContext'
import { LoadingContext } from '../../Contexts/Loading/LoadingContext'

// Screens
import { CasesScreen } from '../CasesScreen/CasesScreen'
import { CasesTypesScreen } from '../CasesTypesScreen/CasesTypesScreen'
import DoubtsScreen from '../DoubtsScreen/DoubtsScreen'
import { EditionsScreen } from '../../Components/EditionsScreen/EditionsScreen'
import { UsersScreen } from '../UsersScreen/UsersScreen'
import { FaqsTypesScreen } from '../Faqs/FaqsTypesScreen/FaqsTypesScreen'
import { CasesForm } from '../CasesScreen/CasesForm/CasesForm'
import { ActivationScreen } from '../ActivationScreen/ActivationScreen'
import { ProfileScreen } from '../ProfileScreen/ProfileScreen'
import { FaqsScreen } from '../Faqs/FaqsScreen/FaqsScreen'
import { FaqsPublicScreen } from '../Faqs/FaqsScreen/FaqsPublicScreen/FaqsPublicScreen'
import { StoredResponsesScreen } from '../StoredResponsesScreen/StoredResponsesScreen'
import { StoredResponsesTypesScreen } from '../StoredResponsesTypesScreen/StoredResponsesTypesScreen'
import { FaqScreen } from '../Faqs/FaqScreen/FaqScreen'
import { DoubtsState } from '../../Contexts/Doubts/DoubtsState'
import { TeacherStatisticsScreen } from '../TeacherStatisticsScreen/TeacherStatisticsScreen'

// Loading screen
import LoadingScreen from '../Tools/LoadingScreen'
import loadingImg from '../../img/loading.gif'
import { ResponsesRatings } from '../ResponseRating/ResponsesRatings'
import { CasesAssessments } from '../CaseAssessments/CasesAssessments'
import { LopdScreen } from '../LOPD/LopdScreen'
import { CasesPack } from '../CasesPack/CasesPack'
import { Lopd } from '../LOPD/Lopd'
import { CasesPackDurationScreen } from '../CasesPackDuration/CasesPackDurationScreen'
import { CasesDistribution } from '../CasesDistribution/CasesDistribution'
import { CasesDistributionByTeachers } from '../CasesDistribution/CasesDsitributionByTeachers/CasesDistributionByTeachers'
import { TestForm } from '../Test/TestForm'


const MainScreen = () => {
    const history = useHistory()
    const userContext = useContext(UserContext)
    const loadingContext = useContext(LoadingContext)
    const [needsActivation, setNeedsActivation] = useState(true)

    useEffect(async () => {
        let enabled = (await userContext.retrieveUserData()).enabled
        setNeedsActivation(enabled)
        if (typeof enabled == "undefined") {
            history.push("/login")
        }
    }, [])


    return (
        <>
            {needsActivation ?
                <>
                    <LoadingScreen
                        loadingImg={loadingImg}
                        visible={loadingContext.visibility}
                    />
                    <Router>
                        <Navigation />
                        <div className="p-4 paddingt0">
                            <Switch>
                                <Route path="/main-screen/cases/" exact component={CasesScreen} />
                                <Route path="/main-screen/cases-types" component={CasesTypesScreen} />
                                <Route path="/main-screen/teacher-stats" component={TeacherStatisticsScreen} />
                                <Route path="/main-screen/ratings" component={ResponsesRatings} />
                                <Route path="/main-screen/cases-assessments" component={CasesAssessments} />
                                <Route path="/main-screen/faqs/:id?" component={FaqsPublicScreen} />
                                <Route path="/main-screen/faq/:id?" component={FaqScreen} />
                                <Route path="/main-screen/faqs-list" component={FaqsScreen} />
                                <Route path="/main-screen/faqs-types" component={FaqsTypesScreen} />
                                <Route path="/main-screen/doubts" component={DoubtsScreen} />
                                <Route path="/main-screen/users" component={UsersScreen} />
                                <Route path="/main-screen/editions" component={EditionsScreen} />
                                <Route path="/main-screen/cases-pack" component={CasesPack} />
                                <Route path="/main-screen/lopd" component={LopdScreen} />
                                <Route path="/main-screen/test" component={TestForm} />
                                <Route path="/main-screen/lopd-editor" component={Lopd} />
                                <Route path="/main-screen/profile" component={ProfileScreen} />
                                <Route path="/main-screen/stored-responses" component={StoredResponsesScreen} />
                                <Route path="/main-screen/stored-responses-types" component={StoredResponsesTypesScreen} />
                                <Route path="/main-screen/cases-pack-duration" component={CasesPackDurationScreen} />
                                <Route path="/main-screen/cases-distribution" component={CasesDistribution} />
                                <Route path="/main-screen/cases-distribution-teacher" component={CasesDistributionByTeachers} />
                                <DoubtsState>
                                    <Route path="/main-screen/case" exact component={CasesForm} />
                                </DoubtsState>
                            </Switch>
                        </div>
                    </Router>
                </> :
                <ActivationScreen />}
        </>
    )
}
export default MainScreen