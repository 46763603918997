import React, { useEffect, useState } from "react"
import MicRecorder from 'mic-recorder-to-mp3';
import { useAudioResponse } from "../../../Hooks/useAudioResponse";
const Mp3Recorder = new MicRecorder({ bitRate: 128 })

const AudioRecord = ({ doubtResponse, setDoubtResponse, id_doubts, id_users, audios, getAudios, setAudios, caseRecord, userData }) => {

    //#region 'VARIABLES'
    const widthHeightBtn = 200
    const sizeIcon = 98
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { insertAudioResponse, removeAudioResponse } = useAudioResponse()
    //#endregion

    //#region 'USE STATES'
    //const [response, setResponse] = useState({})
    const [blobURL, setBlobURL] = useState('');
    const [blobBase64, setBlobBase64] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [timer, setTimer] = useState(0)
    //const [audios, setAudios] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const convertBlobToBase64 = (blobData) => {
        let base64data = ''
        let reader = new window.FileReader()
        reader.readAsDataURL(blobData);
        reader.onloadend = function () {
            base64data = reader.result
            setBlobBase64(base64data)
        }
    }

    const formatTime = ({ timer }) => {
        const getSeconds = `0${(timer % 60)}`.slice(-2)
        const minutes = Math.floor(timer / 60)
        const getMinutes = `0${minutes % 60}`.slice(-2)
        return `${getMinutes} : ${getSeconds}`
    }

    const clearUseStates = () => {
        setBlobURL('');
        setBlobBase64('');
    }

    const startRecording = () => {
        if (isBlocked) {
            alert('Permission Denied');
        } else {
            Mp3Recorder.start().then(() => {
                clearUseStates();
                setIsRecording(true)
            }).catch((e) => { console.log(e); alert(e) })
        }
    }

    const stopRecording = () => {
        Mp3Recorder.stop().getMp3().then(([blob]) => {
            let blobData = new Blob(blob)
            const blobURL = URL.createObjectURL(blobData)
            convertBlobToBase64(blobData)
            setBlobURL(blobURL)
            setIsRecording(false);
        }).catch((e) => console.log(e));
    }

    const handleSave = async () => {
        try {
            let id_response = doubtResponse?.id
            const result = await insertAudioResponse({ id_response, blobBase64, id_doubts, id_users })
            if (result?.response) {
                setDoubtResponse(result.response)
                await getAudios(result.response.id)
            } else {
                await getAudios(audios[0]?.id_response)
            }
            clearUseStates()
        } catch (error) {
            console.error(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            const result = await removeAudioResponse(id)
            if (result) {
                await getAudios(audios[0]?.id_response)
            }
        } catch (error) {
            console.error(error)
        }
    }

    /*  const getAudioByResponse = async (id) => {
         try {
             if (id) {
                 let data = await getAudioResponse(id)
                 console.log(data)
                 setAudios(data)
             }
         } catch (error) {
             console.error(error)
         }
     } */

    const getAudio = async (item) => {
        if (!item?.blobURL) {
            const base64Response = await fetch(`${item.prefix_base64},${item.base64_blob}`);
            const blob = await base64Response.blob();
            const blobURL = URL.createObjectURL(blob)
            let data = []
            audios?.map(el => {
                if (el.id === item.id) {
                    el.blobURL = blobURL
                    el.visible = true
                } else {
                    el.blobURL = null
                    el.visible = false
                }
                data.push(el)
            })
            setAudios(data)
        } else {
            let data = []
            audios?.map(el => {
                if (el.id === item.id) {
                    el.blobURL = null
                    el.visible = false
                }
                data.push(el)
            })
            setAudios(data)
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useEffect(() => {
        const interval = setInterval(() => {
            if (isRecording) {
                setTimer(seconds => seconds + 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
            setTimer(0)
        };
    }, [isRecording])

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false },
            () => {
                console.log('Permission Granted');
                setIsBlocked(false);
            },
            () => {
                console.log('Permission Denied');
                setIsBlocked(true)
            },
        );

    }, [])

    //#endregion

    //#region 'STYLE'
    const styles = {
        timer: {
            textAlign: "center", fontSize: 86
        },
        audio: {
            width: 300,
            boxShadow: "5px 5px 20px rgba(0,0, 0, 0.4)",
            borderRadius: 90,
            transform: [{ scale: 1.5 }]
        },
        btnAudio: {
            width: widthHeightBtn,
            height: widthHeightBtn,
            borderRadius: widthHeightBtn,
            borderWidth: 5,
        },
        contentBtnAudio: {
            textAlign: "center"
        },
        contentCtrl: {
            marginTop: 50,
            alignItems: 'center',
            justifyContent: 'center',
            display: "flex"
        },
        btn: {
            borderRadius: 90,
            width: 40,
            height: 40,
            marginLeft: 20
        },
        contentListAudio: {
            marginTop: 20
        },
        contentItems: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            marginTop: 20
        }
    }
    //#endregion

    //#region 'STRUCTURE'
    const IconMicrophone = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width={sizeIcon} height={sizeIcon} fill="currentColor" class="bi bi-mic-fill" viewBox="0 0 16 16">
            <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
        </svg>
    )

    const IconStop = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width={sizeIcon} height={sizeIcon} fill="currentColor" class="bi bi-stop-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.5 5A1.5 1.5 0 0 0 5 6.5v3A1.5 1.5 0 0 0 6.5 11h3A1.5 1.5 0 0 0 11 9.5v-3A1.5 1.5 0 0 0 9.5 5h-3z" />
        </svg>
    )

    const IconSave = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
        </svg>
    )

    const IconDelete = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
        </svg>
    )
    //#endregion

    return (
        <div className="col-12">
            {
                !(caseRecord.stateId === 4 || userData.idRole === 3) && ([
                    <p style={styles.timer}>{formatTime({ timer })}</p>,
                    <div style={styles.contentBtnAudio}>
                        {!isRecording && (
                            <button className="btn-outline-primary" style={styles.btnAudio} disabled={isRecording}
                                onClick={(e) => startRecording()}>
                                <IconMicrophone />
                            </button>
                        )}
                        {isRecording && (
                            <button className="btn-outline-danger" style={styles.btnAudio} disabled={!isRecording}
                                onClick={(e) => stopRecording()}>
                                <IconStop />
                            </button>
                        )}
                    </div>
                ])}
            {blobURL && (
                <div style={styles.contentCtrl}>
                    <audio src={blobURL} controls style={styles.audio} preload='none' />
                    <button className="btn-outline-success" onClick={handleSave} style={styles.btn}>
                        <IconSave />
                    </button>
                </div>
            )}
            <div style={styles.contentListAudio}>
                {audios?.length > 0 && (<h3>Lista de audios</h3>)}
                {audios?.map((item, index) => (
                    <div className="row p-3">
                        <button className="btn btn-dark" onClick={() => getAudio(item)}>{`Audio ${index + 1}`}</button>
                        {item?.blobURL && item.visible && (
                            <div style={styles.contentItems}>
                                <audio src={item?.blobURL} style={styles.audio} preload='none' controls />
                                {!(caseRecord.stateId === 4 || userData.idRole === 3) && (
                                    <button className="btn-outline-danger" onClick={() => handleDelete(item.id)} style={styles.btn}>
                                        <IconDelete />
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div >
    )
}

export { AudioRecord }

