import React from 'react';

const ButtonBack = ({ onclickBack, classMarginLeft }) => {

    const styles = {
        border: 0,
        background: "#5BC5F1",
        color: "#fff",
        fontWeight: 450,
    }

    return (
        <button
            className={`btn btn-primary ${classMarginLeft || "me-3"}`}
            style={styles}
            onClick={onclickBack}>
            <i className="fas fa-xs fa-arrow-left" />
        </button>
    )
}

export default ButtonBack