import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const putStoredResponseType = name => new Promise((resolve, reject) => {
    let messageService = new MessageService()

    let xhr = new XMLHttpRequest()
    xhr.open("PUT", RouterCtrl.storedResponsesTypes)
    xhr.withCredentials = true
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    xhr.send(JSON.stringify(name))

    xhr.onload = () => {
        let res = JSON.parse(xhr.response)

        messageService.printToast(res.scope, res.type, res.code)
        if (res.type === "success") {
            resolve(true)
        } else {
            resolve(false)
        }
    }

    xhr.onerror = () => {
        messageService.printToast("Generics", "error", 0)
        resolve(false)
    }
})