import React from 'react'
import { ContentItem } from './ContentItem';

const CustomFilter = ({ keyValue, leftCol, nameSelect, onChange, valueSelect, defaultValue, options, rightCol, nameInput, valueInput }) => {

    return <ContentItem itemBody={[
        <div key={"divSel_" + keyValue} className={`col-12 col-sm-4 pt-2${leftCol ? ` ${leftCol}` : ""}`}>
            <select
                className="form-select"
                name={nameSelect}
                onChange={onChange}
                value={valueSelect}
                defaultValue={defaultValue}>
                {options.map(ob => {
                    return <option key={ob.value} value={ob.value}>{ob.name}</option>
                })}
            </select>
        </div>,
        <div key={"divInput_" + keyValue} className={`col-12 col-sm-8 pt-2${rightCol ? ` ${rightCol}` : ""}`}>
            <input
                type="search"
                name={nameInput}
                autoComplete="off"
                onChange={onChange}
                className="form-control"
                value={valueInput}
                placeholder="Buscar..." />
        </div>
    ]} />
}

export default CustomFilter;
