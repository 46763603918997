import React from 'react'
import ButtonOutline from '../ButtonOutline/ButtonOutline'

const ViewType = ({ showCreateBtn, dataTarget, textButton ,handleSubmit, views, listFunction, kanbanFunction }) => {

    return (
        <div className={showCreateBtn ? "flex flex-xbetween paddinglr0" : "flex flex-row-reverse"}>
            {
                showCreateBtn ?
                    <ButtonOutline
                        textBtn={textButton}
                        handleSubmit={handleSubmit}
                        cssClasses={"marginlr0 margin0"}
                        icoClasses={"fas fa-plus"}
                        dataTarget={dataTarget}
                    /> :
                    null
            }
            <div>
                <div
                    style={{ background: views.list ? "#5BC5F1" : "#fff", color: views.list ? "#fff" : "#5BC5F1", borderColor: "#5BC5F1" }}
                    className={views.list ? "btn btn-outline-primary active me-2" : "btn btn-outline-primary me-2"}
                    onClick={listFunction}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-table" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
                    </svg>
                </div>
                <div
                    className={views.kanban ? "btn btn-outline-primary active" : "btn btn-outline-primary"}
                    style={{ background: views.kanban ? "#5BC5F1" : "#fff", color: views.kanban ? "#fff" : "#5BC5F1", borderColor: "#5BC5F1" }}
                    onClick={kanbanFunction}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-kanban" viewBox="0 0 16 16">
                        <path d="M13.5 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h11zm-11-1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-11z" />
                        <path d="M6.5 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3zm-4 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3zm8 0a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V3z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export { ViewType }

