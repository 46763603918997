//#region "IMPORT"
import React from 'react'
import CustomFilter from '../Tools/CustomFilter'
import CustomSelect from '../Tools/CustomSelect'
import UserAdminForm from '../Tools/OIF_tools/UserAdminForm'
import { DisplayImageUser } from '../Tools/DisplayImageUser'
import { ViewType } from '../Tools/ViewTypes/ViewType'
import { ContentItem } from '../Tools/ContentItem'
import { ViewData } from '../Tools/ViewsData/ViewData'
import { CustomToggleFilter } from '../Tools/CustomToggleFilter/CustomToggleFilter'
import { ItemKanban } from '../Tools/Kanban/ItemKanban'
import { KanbanCard } from '../Tools/Kanban/KanbanCard'
import { TableList } from '../Tools/Table/TableList'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

const UsersScreenUI = ({ modalConf, openModal, users, roles, modalContent, views,
    casesEditions, handleFilters, teacherCasesTypes, casesTypes, setTeacherCasesTypes,
    setUsers, setFilteredUsers, prepareCreateUser, isCreating, filters
}) => {

    //#region VARIABLES
    const FONT_SIZE = 13
    const objroles = {
        1: "Administrador",
        2: "Profesor",
        3: "Doctor"
    }
    const objLabelItems = {
        itemRole: "Rol",
        itemInvisalign: "Invisalign",
        itemVat: "DNI",
        itemMail: "Email",
        itemPhone: "Teléfono",
        itemDescription: "Especialidad dental",
        itemValidated: "Validado",
        itemEnabled: "Habilitado",
        itemEditions: "Edición activa",
        itemPaused: "Pausado",
        itemName: "Nombre",
    }
    //#endregion

    //#region STRUCTURE

    //#region "SEARCH"
    const ItemSearch = ({ label, ctrlSelect: { dpData, nameDP, divClasses, handleFilters, value, defaultValue } }) => (
        <div className="col">
            <label><b>{label}</b></label>
            <CustomSelect
                data={dpData}
                name={nameDP}
                divClasses={divClasses}
                onChange={handleFilters}
                value={value}
                defaultValue={defaultValue}
            />
        </div>
    )

    const ControlsSearch = () => ([
        <ContentItem
            itemBody={[
                <ItemSearch
                    label={"Tipo de usuario"}
                    ctrlSelect={{
                        dpData: filters.roles,
                        nameDP: "role",
                        divClasses: "marginlr0 field marginb05",
                        handleFilters: filters.onchange,
                        value: filters.selFilter.roleFilter,
                        defaultValue: filters.defaultValueDP
                    }}
                />,
                <ItemSearch
                    label={"Edición"}
                    ctrlSelect={{
                        dpData: filters.editions,
                        nameDP: "edition",
                        divClasses: "marginlr0 field marginb05",
                        handleFilters: filters.onchange,
                        value: filters.selFilter.caseEditionFilter,
                        defaultValue: filters.defaultValueDP_Edition
                    }}
                />,
                <div>
                    <CustomToggleFilter text={"Todos"} filterName={"ckAllValues"} checked={filters.selFilter.ckAllValues} event={filters.onchange} />
                    <CustomToggleFilter text={"Validado"} filterName={"ckValidate"} checked={filters.selFilter.ckValidate} event={filters.onchange} />
                    <CustomToggleFilter text={"Habilitado"} filterName={"ckEnabled"} checked={filters.selFilter.ckEnabled} event={filters.onchange} />
                </div>
            ]}
        />
    ])
    //#endregion

    //#region VIEWS
    //#region KANBAN

    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={(item.Name + ' ' + item.Lastname).toLocaleUpperCase()}
            classHead={`ps-1 pe-2 pt-1 pb-1${item.Enabled ? " blackAndWhite" : " grey"}`}
            styleCard={{ background: "#bbb" }} styleTitle={{ marginBlockStart: 'auto', margin: 0, fontStyle: 'normal' }}
            onclickHead={() => openModal.update(item)} dataTarget={"#userModal"}
            imgUser={<DisplayImageUser userBlob={item.profileImageblob} />}
            cardBody={[
                <ContentItem key={"key_kc_ci_role_edition" + index} itemBody={[
                    <ItemKanban key={"keyItemRole"} classCol={"col-6"} label={objLabelItems.itemRole} value={objroles[item.id_Roles]} />,
                    <ItemKanban key={"keyItemEditions"} classCol={"col-6"} label={objLabelItems.itemEditions} value={item.lastEdition} />
                ]} />,
                <ContentItem key={"key_kc_ci_vat_mail" + index} itemBody={[
                    <ItemKanban key={"keyItemVat"} classCol={"col-6"} label={objLabelItems.itemVat} value={item.Vat} />,
                    <ItemKanban key={"keyItemMail"} classCol={"col-6"} label={objLabelItems.itemMail} value={item.Email} />
                ]} />,
                <ContentItem key={"key_kc_ci_phone_desc" + index} itemBody={[
                    <ItemKanban key={"keyItemPhone"} classCol={"col-6"} label={objLabelItems.itemPhone} value={item.Phone} />,
                    <ItemKanban key={"keyItemDescription"} classCol={"col-6"} label={objLabelItems.itemDescription} value={item.Description_notes} />
                ]} />,
                <div className="row">
                    <div className={`${item.id_Roles === 2 ? "col-4" : "col-6"}`}>
                        <p className="card-text mb-1" style={{ fontSize: FONT_SIZE }}><strong>Validado </strong><br /><span><input type="checkbox" checked={item.Validated} disabled /></span></p>
                    </div>
                    <div className={`${item.id_Roles === 2 ? "col-4" : "col-6"}`}>
                        <p className="card-text mb-1" style={{ fontSize: FONT_SIZE }}><strong>Habilitado </strong><br /><span><input type="checkbox" checked={item.Enabled} disabled /></span></p>
                    </div>
                    {item.id_Roles === 2 && <div className="col-4">
                        <p className="card-text mb-1" style={{ fontSize: FONT_SIZE }}><strong>Pausado </strong><br /><span><input type="checkbox" checked={!item.pause_assignment} disabled /></span></p>
                    </div>}
                </div>
            ]}
        />
    )
    //#endregion

    //#region LIST

    const HeadList = () => (
        <tr style={{ textAlign: "center" }}>
            <th scope="col"></th>
            <th scope="col" style={{ width: 180, minWidth: 180 }}>{objLabelItems.itemName}</th>
            <th scope="col" style={{ width: 100, minWidth: 100 }}>{objLabelItems.itemRole}</th>
            <th scope="col" style={{ width: 120, minWidth: 120 }}>{objLabelItems.itemEditions}</th>
            <th scope="col" style={{ width: 60, minWidth: 60 }}>{objLabelItems.itemVat}</th>
            <th scope="col" style={{ width: 100, minWidth: 100 }}>{objLabelItems.itemMail}</th>
            <th scope="col" style={{ width: 10, minWidth: 80 }}>{objLabelItems.itemPhone}</th>
            <th scope="col" style={{ width: 180, minWidth: 180 }}>{objLabelItems.itemDescription}</th>
            <th scope="col" style={{ width: 40, minWidth: 40 }}>{objLabelItems.itemValidated}</th>
            <th scope="col" style={{ width: 40, minWidth: 40 }}>{objLabelItems.itemEnabled}</th>
            <th scope="col" style={{ width: 40, minWidth: 40 }}>{objLabelItems.itemPaused}</th>
        </tr>
    )

    const ContentList = ({ item, index }) => (
        <tr key={index}
            style={{ textAlign: "center" }}
            className="clickable"
            onClick={() => openModal.update(item)}
            data-bs-toggle="modal"
            data-bs-target="#userModal">
            <th scope="row">{index + 1}</th>
            <td>{item.Name + ' ' + item.Lastname}</td>
            <td>{objroles[item.id_Roles]}</td>
            <td>{item.lastEdition}</td>
            <td>{item.Vat}</td>
            <td>{item.Email}</td>
            <td>{item.Phone}</td>
            <td>{item.Description_notes}</td>
            <td><input type="checkbox" checked={item.Validated} disabled /></td>
            <td><input type="checkbox" checked={item.Enabled} disabled /></td>            
            <td>{item.id_Roles === 2 && <input type="checkbox" checked={!item.pause_assignment} disabled />}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion
    const ArrayMaping = users.filter(filters.doFilter).map((user, index) => (
        views.type.kanban ? <ViewKanban item={user} index={index} /> : <ContentList item={user} index={index} />
    ))
    //#endregion

    //#endregion

    return ([
        <HeadTitle title={"Usuarios"} />,
        <ContentGeneral content={[
            <ViewType
                showCreateBtn={true} handleSubmit={prepareCreateUser} dataTarget={"#userModal"}
                textButton={"Crear"} views={views.type}
                kanbanFunction={views.funcKanban} listFunction={views.funcList}
            />,
            <CustomFilter {...{
                options: filters.dpFiledsFilters, onChange: filters.onchange,
                valueSelect: filters.selFilter.valueDp,
                valueInput: filters.selFilter.textFilter,
                nameSelect: "valueDp", nameInput: "textFilter", cssClasses: "paddinglr0"
            }} />,
            <ControlsSearch />,
            <ViewData viewType={views.type.kanban} kanban={<ContentItem itemBody={ArrayMaping} />} list={<ViewList />} />,
        ]} />,
        <UserAdminForm
            title={modalConf.title}
            imageUrl={modalConf.imageUrl}
            roles={roles}
            casesEditions={filters.editions}
            casesTypes={casesTypes}
            teacherCasesTypes={teacherCasesTypes}
            inputModalContent={modalContent}
            setUsers={setUsers}
            setFilteredUsers={setFilteredUsers}
            setTeacherCasesTypes={setTeacherCasesTypes}
            creating={isCreating}
            handleFilters={handleFilters}
        />
    ])
}

export { UsersScreenUI }