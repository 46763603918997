import React from 'react';
import { useCase } from '../../../Hooks/useCase';
import { useCasesReasonWaiting } from '../../../Hooks/useCasesReasonWaiting';
import ButtonModal from '../ButtonModal';
import SimpleBootstrapModal from '../SimpleBootstrapModal';

const ModalRequestRevision = ({ assignReasonWaiting, caseData, propertiesForm }) => {

    //#region 'USE STATES CUSTOMIZED'
    const { getCase, requestRevision } = useCase()
    const { updateDataCasesReasonWaiting } = useCasesReasonWaiting()
    //#endregion

    //#region 'FUNCTIONS'
    const handleRequestRevision = async () => {
        if (assignReasonWaiting.data.length > 0) {
            let res = await updateDataCasesReasonWaiting(caseData.id, assignReasonWaiting.data)
            if (res) {
                if (await requestRevision(caseData.id, caseData.userId)) {
                    document.getElementById("closeRequestRevisionModal").click()
                    assignReasonWaiting.set(res)
                    propertiesForm.set("revisionRequest", true)
                    propertiesForm.update(await getCase(caseData.id))
                }
            }
        } else {
            if (await requestRevision(caseData.id, caseData.userId)) {
                document.getElementById("closeRequestRevisionModal").click()
                propertiesForm.set("revisionRequest", true)
                propertiesForm.update(await getCase(caseData.id))
            }
        }
    }
    //#endregion

    return <SimpleBootstrapModal
        id={"requestRevisionModal"}
        title={"Solicitar revisión"}
        message={"¿Estás seguro de solicitar la revisión del caso?"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal id={"closeRequestRevisionModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal text={"Sí"} onClick={handleRequestRevision} />
        ]}
    />
}

export { ModalRequestRevision }