import { MessageService } from '../../Services/message_service'
import RouterCtrl from '../../routes/Routes'

const putPassword = (token, password) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.setPassword)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ token: token, password: password }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (res.type === "success") {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve(true)
                } else {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve(false)
                }

            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    })
}

export { putPassword }