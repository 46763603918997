import React from 'react';
import { useStoredResponses } from '../../../Hooks/useStoredResponses';
import { useStoredResponsesTypes } from '../../../Hooks/useStoredResponsesTypes';
import ButtonModal from '../ButtonModal';
import CustomSelect from '../CustomSelect';
import SimpleBootstrapModal from '../SimpleBootstrapModal';

const ModalStoredResponses = ({ bsTarget, selected, handleSelect }) => {

    //#region 'USE STATES CUSTOMIZED'
    const { storedResponsesTypes } = useStoredResponsesTypes()
    const { storedResponses, setStoredResponse, retrieveStoredResponsesByType } = useStoredResponses()
    //#endregion

    //#region 'USE STATES'

    //#endregion

    //#region 'FUNCTIONS'

    const selectingStoredResponseType = async e => {
        setStoredResponse(await retrieveStoredResponsesByType(e.value))
        selected.setSelect({ ...selected.select, idStoredResponseType: e.value, typeResponse: e.label })
    }

    const selectingStoredResponse = e => {
        selected.setSelect({ ...selected.select, idStoredResponse: e.value, response: e.label })
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'

    //#endregion


    //#region 'STRUCTURE'
    //#endregion

    return <SimpleBootstrapModal
        id={"storedResponsesModal"}
        modalClasses={"padding0"}
        title="Respuestas guardadas"
        fullScreen={true}
        customCloseButton={[
            <ButtonModal id={"closeStoredResponses"} text={"Cancelar"} bsTarget={bsTarget} close={true} />,
            typeof selected.select?.response !== "undefined" && selected.select?.response && (
                <ButtonModal text={"Seleccionar"} bsTarget={bsTarget} onClick={handleSelect} />)
        ]}
    >
        <CustomSelect
            data={storedResponsesTypes}
            nameSelect={"storedResponseType"}
            label={"Tipo:"}
            value={storedResponsesTypes.forEach(storedResponseType => {
                if (storedResponseType.value === selected.select?.idStoredResponseType) {
                    return storedResponseType
                }
            })}
            onChange={selectingStoredResponseType}
        />

        <CustomSelect
            data={storedResponses}
            nameSelect={"storedResponse"}
            label={"Respuesta:"}
            value={storedResponses.forEach(storedResponse => {
                if (storedResponse.value === selected.select?.idStoredResponse) {
                    return storedResponse
                }
            })}
            onChange={selectingStoredResponse}
        />
    </SimpleBootstrapModal>
}

export { ModalStoredResponses }