import React from 'react';
import { toast } from 'react-toastify';
import { useCasesPacksDuration } from '../../../Hooks/useCasesPacksDuration';
import ButtonModal from '../ButtonModal';
import SimpleBootstrapModal from '../SimpleBootstrapModal';
import TextInputCustom from '../TextInputCustom';

const ModalCasesPackDuration = ({ setData, selection }) => {

    //#region 'VARIABLES'
    const obFields = {
        title: { create: "Crear duración de casos", update: "Modificar duración de casos" },
        name: { name: "name", label: "Nombre" },
        duration: { name: "duration", label: "Duración (días)" },
    }
    //#endregion

    //#region 'USE CONTEXT'

    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { insertCasesPacksDuration, updateCasesPacksDuration,
        getData, deleteItemCasesPacksDuration, checkAssignmentCasesPackDuration } = useCasesPacksDuration()
    //#endregion

    //#region 'USE STATES'

    //#endregion

    //#region 'FUNCTIONS'
    const resetValues = async () => {
        selection.set({})
        setData(await getData())
        document.getElementById("closePackDurationModal").click()
    }

    const handleSave = async () => {
        const { name, duration } = selection.select
        if (name && duration) {
            if (name.trim() !== '' && duration > 0) {
                let result = await insertCasesPacksDuration(selection.select)
                if (result) {
                    resetValues()
                }
            }
        }
    }

    const handleUpdate = async () => {
        const { id, name, duration } = selection.select
        if (id && name && duration) {
            if (name.trim() !== '' && duration > 0) {
                let result = await updateCasesPacksDuration(selection.select)
                if (result) {
                    resetValues()
                }
            }
        }
    }


    const handleDelete = async () => {
        if (selection.select.id) {
            let check = await checkAssignmentCasesPackDuration(selection.select.id)
            if (check === 0) {
                let result = await deleteItemCasesPacksDuration(selection.select.id)
                if (result) {
                    resetValues()
                }
            } else {
                toast.warn("No se puede eliminar, porque ya está asignado.")
            }
        }
    }

    const handleChangeValue = (e) => {
        const name = e.target.name
        const value = e.value ? e : e.target.value
        selection.set({ ...selection.select, [name]: value })
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'

    //#endregion


    //#region 'STRUCTURE'
    //#endregion

    return <SimpleBootstrapModal
        id="packsDurationModal"
        modalClaseses={"padding0"}
        title={selection.select?.id ? obFields.title.update : obFields.title.create}
        closeButtonId={"closePackDurationModal"}
        footerButtons={[
            selection.select?.id ? <ButtonModal key={"keyDeletePackDurationModal"} text={"Eliminar"} onClick={handleDelete} cssClasses={"light-red"} /> : null,
            <ButtonModal key={"keySavePackModal"} text={"Guardar"} onClick={selection.select?.id ? handleUpdate : handleSave} />,
        ]}
    >
        <TextInputCustom
            nameInput={obFields.name.name}
            textLabel={obFields.name.label}
            required={true}
            evOnChange={handleChangeValue}
            value={selection.select?.name}
        />
        <TextInputCustom
            typeInput={"number"} minimumValue={0}
            nameInput={obFields.duration.name} required={true}
            textLabel={obFields.duration.label}
            evOnChange={handleChangeValue}
            value={selection.select?.duration}
        />
    </SimpleBootstrapModal>
}

export { ModalCasesPackDuration }