//#region 'IMPORTS'
import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonBack from '../../Tools/ButtonBack';
import ButtonModal from '../../Tools/ButtonModal';
import { ContentGeneral } from '../../Tools/ContentGeneral';
import { ContentItem } from '../../Tools/ContentItem';
import CustomFilter from '../../Tools/CustomFilter';
import CustomSelect from '../../Tools/CustomSelect';
import { HeadTitle } from '../../Tools/HeadTitle/HeadTitle';
import { ItemKanban } from '../../Tools/Kanban/ItemKanban';
import { KanbanCard } from '../../Tools/Kanban/KanbanCard';
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal';
import { TableList } from '../../Tools/Table/TableList';
import { ViewData } from '../../Tools/ViewsData/ViewData';
import { ViewType } from '../../Tools/ViewTypes/ViewType';
//#endregion

const CasesDistributionByTeachersUI = ({ casesTeachers, views, filters, teachersReassign, handleReassignCase, getSelItem }) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const obFields = {
        title: "Casos de " + casesTeachers.teacher_name,
        Case_code: "Código",
        student_name: "Doctor",
        edition: "Edición",
        cases_type: "Tipo",
        state: "Estado"
    }
    //#endregion

    //#region 'FUNCTIONS'

    const backScreen = (e) => {
        e.preventDefault();
        history.push("/main-screen/cases-distribution")
    }
    //#endregion

    //#region 'STRUCTURE'
    //#region 'MODAL'
    const ModalReassignCase = <SimpleBootstrapModal
        id={"reassignCase"}
        closeButtonId={"closeReassignCaseModal"}
        title={"Asignar caso"}
        fullScreen={false}
        footerCancelButton={true}
        footerButtons={<ButtonModal text={"Asignar"} key={"keyConfirmReassignCase"} onClick={handleReassignCase} />}
    >
        <div className="row">
            <div className="col">
                <CustomSelect
                    data={teachersReassign.data}
                    value={teachersReassign.selected}
                    nameSelect={"teacherReassignId"}
                    label={"Profesor:"}
                    onChange={teachersReassign.change}
                    divClasses={"field marginb05"}
                />
            </div>
        </div>
    </SimpleBootstrapModal>
    //#endregion
    //#region 'KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.Case_code}
            dataTarget={"reassignCase"}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            onclickHead={() => getSelItem(item)}
            cardBody={[
                <ContentItem key={"keyCI_" + index} itemBody={[
                    <ItemKanban classCol={'col-6'} label={obFields.student_name} value={item.student_name} />,
                    <ItemKanban classCol={'col-6'} label={obFields.edition} value={item.edition} />,
                ]} />,
                <ContentItem key={"keyCI_" + index} itemBody={[
                    <ItemKanban classCol={'col-6'} label={obFields.cases_type} value={item.cases_type} />,
                    <ItemKanban classCol={'col-6'} label={obFields.state} value={item.state} />,
                ]} />
            ]}
        />
    )
    //#endregion
    //#region 'LIST'
    const HeadList = () => (
        <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>{obFields.Case_code}</th>
            <th>{obFields.student_name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.edition}</th>
            <th style={{ textAlign: 'center' }}>{obFields.cases_type}</th>
            <th style={{ textAlign: 'center' }}>{obFields.state}</th>
        </tr>
    )

    const ContentList = ({ item, index }) => (
        <tr key={item.id} className='clickable' onClick={() => getSelItem(item)}
            data-bs-toggle="modal" data-bs-target="#reassignCase">
            <th scope="row">{index + 1}</th>
            <td style={{ width: 120, minWidth: 120, textAlign: 'center' }}>{item.Case_code}</td>
            <td>{item.student_name}</td>
            <td style={{ width: 250, minWidth: 250, textAlign: 'center' }}>{item.edition}</td>
            <td style={{ width: 250, minWidth: 250, textAlign: 'center' }}>{item.cases_type}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{item.state}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion
    const ArrayMaping = casesTeachers.data?.filter(filters.onFilter).map((item, index) => (
        views.type.kanban ? <ViewKanban item={item} index={index} /> : <ContentList item={item} index={index} />
    ))
    //#endregion

    return ([
        ModalReassignCase,
        <HeadTitle title={obFields.title} />,
        <ContentGeneral content={[
            <ContentItem
                itemBody={[
                    <div className='col-6'>
                        <ButtonBack onclickBack={backScreen} />
                    </div>,
                    <div className='col-6'>
                        <ViewType showCreateBtn={false} views={views.type}
                            kanbanFunction={views.funcKanban}
                            listFunction={views.funcList}
                        />
                    </div>
                ]}
            />,
            <CustomFilter keyValue={"searchCasesDistributionTeacher"} nameSelect={"valueDp"} nameInput={"textFilter"}
                options={filters.dpFieldsFilters}
                onChange={filters.onChange}
                valueSelect={filters.selFilter.valueDp}
                valueInput={filters.selFilter.textFilter}
                cssClasses={"paddinglr0"}
            />,
            <ContentItem itemBody={[
                <CustomSelect
                    label={"Tipo"}
                    data={filters.casesTypes}
                    value={filters.selFilter.caseTypeFilter}
                    name={"type"}
                    divClasses={"col-12 col-sm-6 marginlr0 field marginb05"}
                    onChange={filters.onChange}
                    defaultValue={filters.defaultValueDP}
                />,
                <CustomSelect
                    label={"Edición"}
                    data={filters.editions}
                    value={filters.selFilter.caseEditionFilter}
                    name={"edition"}
                    divClasses={"col-12 col-sm-6 marginlr0 field marginb05"}
                    onChange={filters.onChange}
                    defaultValue={filters.defaultValueDP}
                />,
            ]} />,
            <ViewData viewType={views.type.kanban}
                kanban={<ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />} />
        ]} />
    ])
}

export { CasesDistributionByTeachersUI }