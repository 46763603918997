import React from 'react';
import { useCasesPacks } from '../../../Hooks/useCasesPacks';
import { useCasesPacksDuration } from '../../../Hooks/useCasesPacksDuration';
import ButtonModal from '../ButtonModal';
import { ContentItem } from '../ContentItem';
import CustomSelect from '../CustomSelect';
import SimpleBootstrapModal from '../SimpleBootstrapModal';
import TextInputCustom from '../TextInputCustom';

const ModalCasesPacks = ({ setData, selection }) => {

    //#region 'VARIABLES'
    const obFields = {
        title: { create: "Crear packs de casos", update: "Modificar packs de casos" },
        packName: { name: "packName", label: "Nombre" },
        duration: { name: "packDuration", label: "Tiempo" },
        cases_limit: { name: "cases_limit", label: "Limite de casos" }
    }
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { getData, postData, putData, deleteData, putActivatedData } = useCasesPacks()
    const { casesPacksDuration } = useCasesPacksDuration()
    //#endregion

    //#region 'FUNCTIONS'
    const resetValues = async () => {
        selection.set({})
        setData(await getData())
        document.getElementById("closeEditionModal").click()
    }

    const handleSave = async () => {
        const { packName, packDuration, cases_limit } = selection.select
        if (packName && packDuration && cases_limit) {
            if (packName.trim() !== '' && packDuration.value && cases_limit > 0) {
                let result = await postData(selection.select)
                if (result) {
                    resetValues()
                }
            }
        }
    }

    const handleUpdate = async () => {
        const { id, packName, packDuration, cases_limit } = selection.select
        if (id && packName && packDuration && cases_limit) {
            if (packName.trim() !== '' && packDuration.value && cases_limit > 0) {
                let result = await putData(selection.select)
                if (result) {
                    resetValues()
                }
            }
        }
    }

    const handleDelete = async () => {
        //COMPROBAR SI ESTA ASIGNADO
        if (selection.select.id) {
            let result = await deleteData(selection.select.id)
            if (result) {
                resetValues()
            }
        }
    }

    const handleActivated = async () => {
        //COMPROBAR SI ESTA ASIGNADO
        if (selection.select.id) {
            let result = await putActivatedData(selection.select.id, selection.select.active ? 0 : 1)
            if (result) {
                resetValues()
            }
        }
    }

    const handleChangeValue = (e) => {
        const name = e.target.name
        const value = e.value ? e : e.target.value
        selection.set({ ...selection.select, [name]: value })
    }
    //#endregion

    return (
        <SimpleBootstrapModal
            id="packsModal"
            modalClaseses={"padding0"}
            title={selection.select?.id ? obFields.title.update : obFields.title.create}
            closeButtonId={"closeEditionModal"}
            footerButtons={[
                selection.select?.id ? <ButtonModal key={"keyDeletePackModal"} text={"Eliminar"} onClick={handleDelete} cssClasses={"light-red"} /> : null,
                selection.select?.id ? <ButtonModal key={"keyActivedPackModal"} text={selection.select.active ? "Archivar" : "Restaurar"} onClick={handleActivated} /> : null,
                <ButtonModal key={"keySavePackModal"} text={"Guardar"} onClick={selection.select?.id ? handleUpdate : handleSave} />,
            ]}
        >
            <TextInputCustom
                nameInput={obFields.packName.name} textLabel={obFields.packName.label}
                required={true} evOnChange={handleChangeValue}
                value={selection.select?.packName}
            />
            <ContentItem itemBody={[
                <div className="col-6">
                    <CustomSelect
                        data={casesPacksDuration}
                        nameSelect={obFields.duration.name}
                        label={obFields.duration.label}
                        required={true}
                        onChange={handleChangeValue}
                        value={selection.select?.packDuration}
                    />
                </div>,
                <div className="col-6">
                    <TextInputCustom
                        typeInput={"number"} minimumValue={0}
                        nameInput={obFields.cases_limit.name} required={true}
                        textLabel={obFields.cases_limit.label}
                        evOnChange={handleChangeValue}
                        pattern={"0-9"}
                        value={selection.select?.cases_limit}
                    />
                </div>
            ]} />
        </SimpleBootstrapModal>
    )
}

export { ModalCasesPacks }