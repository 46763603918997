import React from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Contexts/User/UserContext';
import { useCasesPacksDuration } from '../../Hooks/useCasesPacksDuration';
import { useShowView } from '../../Hooks/useShowView';
import { CasesFilter } from '../Tools/GeneralFunctions/CasesFilter';
import { CasesPackDurationScreenUI } from './CasesPackDurationScreenUI';

const CasesPackDurationScreen = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]
    const obFilter = {
        valueDp: 1,
        textFilter: '',
    }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'USE STATES CUSTOMIZED'
    const { casesPacksDuration, setCasesPacksDuration } = useCasesPacksDuration()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //#endregion

    //#region 'USE STATES'
    const [filter, setFilter] = useState(obFilter)
    const [selection, setSelection] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const getValues = (item) => {
        setSelection({
            id: item.id,
            name: item.name,
            duration: item.duration
        })
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFilter({ ...filter, [name]: value })
    }

    const onFilter = (values) => {
        switch (filter.valueDp) {
            case 1:
                return CasesFilter(filter.textFilter, values, values.label)
            default:
                return values
        }
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useLayoutEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        userContext.retrieveUserData().then(res => {
            if (res.idRole !== 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])
    //#endregion

    return <CasesPackDurationScreenUI
        casesPacksDuration={{
            data: casesPacksDuration,
            set: setCasesPacksDuration
        }}
        views={{
            type: views,
            funcKanban: kanbanFunction,
            funcList: listFunction
        }}
        filters={{
            selFilter: filter,
            dpFieldsFilters: cbfilterObj,
            onChange: handleInputChange,
            onFilter: onFilter
        }}
        selection={{
            select: selection,
            set: setSelection
        }}
        events={{
            evOnClickItem: getValues
        }}
    />
}

export { CasesPackDurationScreen }