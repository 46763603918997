import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const postLOPD = async (name, content) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.lopd)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.withCredentials = true
        xhr.send(JSON.stringify({ name, content }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                console.log(res)
                messageService.printToast(res.scope, res.type, res.code)
                if (res.type === "success") {
                    resolve(res.insertId)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}