import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Link } from 'react-router-dom'

export default forwardRef((props, ref, style) => {
    const chevUpRef = useRef()
    const chevDownRef = useRef()

    const spanStyles = {
        marginLeft: "0.25rem"
    }

    useImperativeHandle(ref, () => ({
        enter() {
            chevUpRef.current.style.display = "inline-block"
            chevDownRef.current.style.display = "none"
        },
        leave() {
            chevDownRef.current.style.display = "inline-block"
            chevUpRef.current.style.display = "none"
        }
    }))

    return (
        <Link style={style}
            className={props.cssClass} to={props.path}>{props.text}{props.main ?
                <>
                    <span style={spanStyles} ref={chevUpRef} className="none"
                    ><i className="fas fa-chevron-up"></i></span>

                    <span style={spanStyles} ref={chevDownRef}
                    ><i className="fas fa-chevron-down"></i></span>
                </> : null}
        </Link>
    )
})