//#region "IMPORT"
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { UserContext } from '../../Contexts/User/UserContext'
import { UsersScreenUI } from './UsersScreenUI'
import { useUsers } from '../../Hooks/useUsers'
import { useUserEditions } from '../../Hooks/userUserEditions'
import { useCasesTeachers } from '../../Hooks/useCasesTeachers'
import useCasesTypes from '../../Hooks/useCasesTypes'
import useEditions from '../../Hooks/useEditions'
import { useFiles } from '../../Hooks/useFiles'
import { useShowView } from '../../Hooks/useShowView'
import { ElimiarDiacriticos } from '../Tools/GeneralFunctions/EliminarDiacriticos'
import { CasesFilter } from '../Tools/GeneralFunctions/CasesFilter'
import { SearchContext } from '../../Contexts/Search/SearchContext'
import { useCase } from '../../Hooks/useCase'
import { useResponseRating } from '../../Hooks/useResponseRating'
import { useCaseAssesssments } from '../../Hooks/useCaseAssessments'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
//#endregion

const UsersScreen = () => {
    //#region "VARIABLES"
    let history = useHistory()
    const cbfilterObj = [
        { value: '1', name: 'Nombre', fieldName: "Name" },
        { value: '2', name: 'Teléfono', fieldName: "Phone" },
        { value: '3', name: 'DNI', fieldName: "Vat" },
        { value: '4', name: 'Email', fieldName: "Email" },
    ]

    const cbRoleFilter = [
        { type: "roleFilter", value: -1, label: "Todos" },
        { type: "roleFilter", value: 1, label: "Administrador" },
        { type: "roleFilter", value: 2, label: "Profesor" },
        { type: "roleFilter", value: 3, label: "Doctor" }
    ]

    const objModalContent = {
        id: "",
        name: "",
        lastname: "",
        vat: "",
        email: "",
        phone: "",
        password: "",
        profilePhoto: "",
        userInvisalign: "",
        passwordInvisalign: "",
        descriptionNotes: "",
        internalNotes: "",
        created: "",
        modified: "",
        validated: "",
        enabled: "",
        role: "",
        pause_assignment: "",
        userEditions: []
    }
    const DEFAULT_VALUE_DP = { value: -1, label: "Todos" }
    const DEFAULT_VALUE_DP_EDITIONS = { value: -3, label: "Todos" }
    const objFilter = {
        valueDp: '1',
        textFilter: '',
        roleFilter: DEFAULT_VALUE_DP,
        caseEditionFilter: DEFAULT_VALUE_DP_EDITIONS,
        ckValidate: false,
        ckEnabled: false,
        ckWithEdition: true,
        ckAllValues: true
    }
    //#endregion

    //#region "USE CONTEXT"
    const userContext = useContext(UserContext)
    const searchContext = useContext(SearchContext)
    //#endregion

    //#region "USE STATE"
    const { casesEditionsUsers } = useEditions({ onlySearch: true })
    const { casesTypes } = useCasesTypes({ onlyForm: true })
    const { retrieveCaseTeacher } = useCasesTeachers()
    const { retrieveUserEditions } = useUserEditions()
    const { countCasesByUser } = useCase()
    const { countCasesAssessment } = useCaseAssesssments()
    const { countResponsesRating } = useResponseRating()
    const { retrieveUsers, retrieveRoles } = useUsers()
    const { downloadFileByPath } = useFiles()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: false })
    const [modalVisibility, setModalVisibility] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [modalImage, setModalImage] = useState("")
    const [contentDisabled, setContentDisabled] = useState(false)
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [cbEditionFilter, setCbEditionFilter] = useState([]);
    const [teacherCasesTypes, setTeacherCasesTypes] = useState([])
    const [isCreating, setIsCreating] = useState(false)
    const [modalContent, setModalContent] = useState(objModalContent)
    const [filters, setFilters] = useState(objFilter)
    //#endregion

    //#region "FUNCTION"
    //#endregion

    //#region "USE EFFECT"
    useLayoutEffect(() => {
        if (Object.keys(searchContext.searchUser).length > 0) {
            setFilters(searchContext.searchUser)
        }
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            } else if (res.idRole !== 1) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        let tmpUserData = await userContext.retrieveUserData()
        if (!await userContext.checkSession() || tmpUserData.idRole !== 1) {
            history.push("/main-screen/cases")
        } else {
            let usersList = await retrieveUsers()
            await Promise.all(
                usersList.map(async user => {
                    if (user.Photo_Profile) {
                        const imageBlob = await downloadFileByPath(user.Photo_Profile)
                        user.profileImageblob = imageBlob
                    }
                    user.Editions = await retrieveUserEditions(user.id)
                    if (user.Editions.length > 0) {
                        user.lastEdition = user.Editions[0].Name
                    } else {
                        user.lastEdition = ''
                    }
                    return user
                })
            )

            setUsers(usersList)
            setRoles(await retrieveRoles())
        }
    }, [])

    const openModalUpdate = async (item) => {
        setIsCreating(false)
        if (item.id_Roles === 2 || item.id_Roles === 1) {
            setTeacherCasesTypes(await retrieveCaseTeacher(item.id))
        }
        setModalContent({
            id: item.id,
            name: item.Name,
            lastname: item.Lastname,
            vat: item.Vat,
            email: item.Email,
            phone: item.Phone,
            password: "",
            profilePhoto: item.Photo_Profile,
            userInvisalign: item.User_invisalign,
            passwordInvisalign: item.Password_invisalign,
            descriptionNotes: item.Description_notes,
            internalNotes: item.Internal_notes,
            created: item.Created,
            modified: item.Modified,
            validated: item.Validated,
            enabled: item.Enabled,
            role: item.id_Roles,
            pause_assignment: item.pause_assignment,
            userEditions: await retrieveUserEditions(item.id),
            count_case: await countCasesByUser(item.id, item.id_Roles) || 0,
            count_case_assessments: await countCasesAssessment(item.id, item.id_Roles) || 0,
            count_reponse_rating: /* await countResponsesRating(item.id, item.id_Roles)?.count_reponse_rating ||  */0,
        })
        setModalTitle(`${item.Name} ${item.Lastname}`)
        setModalImage(item.profileImageblob)
        setContentDisabled(false)
        setModalVisibility(true)
        searchContext.setSearchUser(filters)
    }

    const handleFilters = (e) => {
        const name = e.target?.name || e.type
        const type = e.target?.type || ''
        const value = type === "checkbox" ? e.target.checked : name === 'textFilter' ? e.target.value
            : e.target?.value || { value: e.value, label: e.label }

        if ((name == 'ckValidate' || name == 'ckEnabled')) {
            setFilters({ ...filters, [name]: value, ckAllValues: false })
        } else if (name == 'ckAllValues') {
            setFilters({ ...filters, [name]: value, ckValidate: false, ckEnabled: false })
        } else {
            setFilters({ ...filters, [name]: value })
        }
    }

    const prepareCreateUser = () => {
        searchContext.setSearchUser(filters)
        setModalTitle("Crear usuario")
        setModalContent(objModalContent)
        setIsCreating(true)
    }

    const onFilter = (values) => {
        switch (filters.valueDp) {
            case '1':
                let userName = values.Name + ' ' + values.Lastname
                return CasesFilter(ElimiarDiacriticos(filters.textFilter), values, ElimiarDiacriticos(userName))
            case '2':
                return CasesFilter(filters.textFilter, values, values.Phone.toString())
            case '3':
                return CasesFilter(filters.textFilter, values, values.Vat)
            case '4':
                return CasesFilter(filters.textFilter, values, values.Email)
            default:
                return values
        }
    }

    const filterRole = obj => {
        if (filters.roleFilter.value === DEFAULT_VALUE_DP.value) {
            return obj
        } else {
            return obj?.id_Roles === filters.roleFilter.value ? obj : false
        }
    }

    const filterEdition = obj => {
        switch (filters.caseEditionFilter.value) {
            case -1:
                return obj?.Editions === undefined || obj?.Editions?.length === 0 ? obj : false
            case -2:
                return obj?.Editions !== undefined || obj?.Editions?.length > 0 ? obj : false
            case -3:
                return obj
            default:
                return obj?.Editions ? obj?.Editions?.some(el => el.id_editions === filters.caseEditionFilter.value) ? obj : false : false
        }
    }

    const filterCheckBox = (typeFilter, obj, value) => {
        return filters.ckAllValues ? obj : value === typeFilter ? obj : false
    }

    const doFilter = (item) => {
        let value = onFilter(item)
        value = filterRole(value)
        value = filterEdition(value)
        value = filterCheckBox(filters.ckValidate, value, Boolean(value?.Validated))
        value = filterCheckBox(filters.ckEnabled, value, Boolean(value?.Enabled))
        //value = filterWithEdition(value)
        return value
    }

    return (
        <UsersScreenUI
            modalConf={{
                disabled: contentDisabled,
                visibility: modalVisibility,
                title: modalTitle,
                imageUrl: modalImage
            }}
            openModal={{
                update: openModalUpdate,
            }}
            users={users}
            roles={roles}
            modalContent={modalContent}
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
            filters={{
                dpFiledsFilters: cbfilterObj,
                roles: cbRoleFilter,
                editions: casesEditionsUsers,
                selFilter: filters,
                defaultValueDP: DEFAULT_VALUE_DP,
                defaultValueDP_Edition: DEFAULT_VALUE_DP_EDITIONS,
                onchange: handleFilters,
                doFilter: doFilter
            }}
            teacherCasesTypes={teacherCasesTypes}
            casesTypes={casesTypes}
            setTeacherCasesTypes={setTeacherCasesTypes}
            setUsers={setUsers}
            prepareCreateUser={prepareCreateUser}
            isCreating={isCreating}
            cbEditionFilter={cbEditionFilter}
        />
    )
}

export { UsersScreen }