import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

export const postGetObservations = (caseId, observation, type_observation) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.observations)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({
            id_cases: caseId,
            observation: observation,
            type_observation: type_observation
        }))
        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                messageService.printToast(res.scope, res.type, res.code)
                if (res.type === "success") {
                    resolve(res.insertId)
                } else {
                    resolve(false)
                }
            } catch (e) {
                resolve(false)
            }
        }

        xhr.onerror = () => {
            resolve(false)
        }
    })
}