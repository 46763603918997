import { useState } from 'react'

const useShowView = ({ visibleList }) => {

    const [views, setViews] = useState({ list: visibleList, kanban: !visibleList })

    const listFunction = () => setViews({ ...views, list: true, kanban: false })

    const kanbanFunction = () => setViews({ ...views, list: false, kanban: true })

    return { views, setViews, listFunction, kanbanFunction }
}

export { useShowView }