import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { FaqsTypesScreenUI } from './FaqsTypesScreenUI'
import { useFaqsTypes } from '../../../Hooks/useFaqsTypes'
import { UserContext } from '../../../Contexts/User/UserContext'
import { useHistory } from 'react-router-dom'
import { useShowView } from '../../../Hooks/useShowView'

const FaqsTypesScreen = () => {

    //#region 'VARIABLES'
    let history = useHistory()
    const cbfilterObj = [
        { value: 1, name: 'Nombre' },
    ]
    const obModalContent = { id: "", name: "" }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    //#endregion

    //#region 'CUSTOMIZED USE STATES'
    const { retrieveFaqsTypes, createFaqsType,
        updateFaqsType, removeFaqsType } = useFaqsTypes()
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    //#endregion

    //#region 'USE STATES'
    const [modalConfirmText, setModalConfirmText] = useState("")
    const [modalTitle, setModalTitle] = useState("")
    const [contentDisabled, setContentDisabled] = useState(false)
    const [modalContent, setModalContent] = useState(obModalContent)
    const [faqsTypes, setFaqsTypes] = useState([])
    const [updateBtnDisplay, setUpdateBtnDisplay] = useState(false)
    const [deleteBtnDisplay, setDeleteBtnDisplay] = useState(false)
    const [createBtnDisplay, setCreateBtnDisplay] = useState(false)
    const [filteredFAQsTypes, setFilteredFAQsTypes] = useState(faqsTypes)
    //#endregion

    //#region 'FUNCTIONS'
    const filterFAQsTypes = (e, updatedFAQsTypes) => {
        try {
            let name = e.target.name
            let value = e.target.value

            if (name === "textFilter") {
                setFilteredFAQsTypes(faqsTypes.filter(faqsType => faqsType.Name.toLowerCase().includes(value.toLowerCase())))
            }
        } catch (e) {
            setFilteredFAQsTypes(updatedFAQsTypes)
        }
    }

    const getFaqsTypes = async () => {
        let tmpFaqsTypes = await retrieveFaqsTypes()
        setFaqsTypes(tmpFaqsTypes)
        setFilteredFAQsTypes(tmpFaqsTypes)
    }


    const handleInputChange = e => {
        let name = e.target.name
        let value = e.target.value

        setModalContent({ ...modalContent, [name]: value })
    }

    const openModalUpdate = (id, name) => {
        setModalTitle("Modificar tipo de FAQS")
        setModalConfirmText("Guardar")
        setModalContent({ id: id, name: name })
        setUpdateBtnDisplay(true)
        setDeleteBtnDisplay(false)
        setCreateBtnDisplay(false)

    }

    const update = async () => {
        let result = await updateFaqsType(modalContent.id, modalContent.name)
        if (result) {
            getFaqsTypes()
            resetValues()
            document.getElementById("closeFaqsTypeForm").click()
        }
    }

    const openModalDelete = (id, name) => {
        setContentDisabled(true)
        setModalContent({ id: id, name: name })
        setUpdateBtnDisplay(false)
        setDeleteBtnDisplay(true)
        setCreateBtnDisplay(false)
        setModalTitle("Eliminar tipo de FAQS")
        setModalConfirmText("Eliminar")
    }

    const deleteFaqsType = async () => {
        let result = await removeFaqsType(modalContent.id)

        if (result) {
            document.getElementById("closeDeletefaqsType").click()
            getFaqsTypes()
            setContentDisabled(false)
        }
    }

    const resetValues = () => {
        setModalContent(obModalContent)
        setCreateBtnDisplay(false)
        setUpdateBtnDisplay(false)
        setDeleteBtnDisplay(false)
        setContentDisabled(false)
    }

    const openModalCreate = () => {
        setCreateBtnDisplay(true)
        setUpdateBtnDisplay(false)
        setDeleteBtnDisplay(false)
        setModalTitle("Crear tipo de FAQS")
        setModalConfirmText("Crear")
        setModalContent(obModalContent)
    }

    const create = async () => {
        let result = await createFaqsType(modalContent.name)
        if (result) {
            getFaqsTypes()
            resetValues()
            document.getElementById("closeFaqsTypeForm").click()
        }
    }
    //#endregion

    //#region 'USE EFFECT AND USE LAYOUT EFFECT'
    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (res.idRole === 3) {
                history.push("/main-screen/cases")
            }
        })
    }, [])

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        userContext.retrieveUserData().then(res => {
            if (res.idRole === 3) {
                history.push("/main-screen/cases")
            } else {
                getFaqsTypes()
            }
        })
    }, [])
    //#endregion

    return (
        <FaqsTypesScreenUI
            faqsTypes={faqsTypes}
            openModal={{
                create: openModalCreate,
                update: openModalUpdate,
                delete: openModalDelete
            }}
            btnsDisplay={{
                create: createBtnDisplay,
                update: updateBtnDisplay,
                delete: deleteBtnDisplay
            }}
            handleInputChange={handleInputChange}
            modalContent={modalContent}
            modalTitle={modalTitle}
            modalConfirmText={modalConfirmText}
            actions={{
                create,
                update,
                delete: deleteFaqsType
            }}
            contentDisabled={contentDisabled}
            filterFAQsTypes={filterFAQsTypes}
            filteredFAQsTypes={filteredFAQsTypes}
            cbfilterObj={cbfilterObj}
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
        />
    )
}

export { FaqsTypesScreen }