import { MessageService } from "../message_service";
import RouterCtrl from '../../routes/Routes'

const postDoubt = (idCase, idDoubtType, doubt) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.doubts)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.send(JSON.stringify({
            idCase: idCase,
            idDoubtType: idDoubtType,
            doubt: doubt
        }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (res.type !== "error") {
                    resolve(res.id)
                } else {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve(false)   
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    }
    )
}

export { postDoubt }