import React, { useState } from 'react';
import { CasesAssessmentsUI } from './CasesAssessmentsUI';
import { useHistory } from 'react-router-dom'
import { useCaseAssesssments } from '../../Hooks/useCaseAssessments';

const CasesAssessments = (props) => {
    useHistory()
    const idUser = props.location.state === undefined ? '' : props.location.state.idUser
    const idRole = props.location.state === undefined ? '' : props.location.state.typeUser
    const nameUser = props.location.state === undefined ? '' : props.location.state.nameUser

    const { caseAssessments } = useCaseAssesssments(idUser, idRole)
    const [search, setSearch] = useState("")

    const handleSearch = (e) => {
        const name = e.target.name
        const value = e.target.value
        setSearch({ ...search, [name]: value })
    }

    return <CasesAssessmentsUI
        id_role={idRole}
        caseAssessments={caseAssessments}
        nameUser={nameUser}
        search={search}
        handleSearch={handleSearch}
    />
}

export { CasesAssessments }