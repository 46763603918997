import React from 'react'

const CustomToggleFilter = ({ filterName, text, checked, event, styleCheck, value, disabled }) => {

    return (
        <div className="form-check form-check-inline form-switch" style={styleCheck || { marginRight: "0.5rem" }}>
            <input
                id={filterName}
                name={filterName}
                className="form-check-input"
                type="checkbox"
                value={value}
                checked={checked}
                onChange={event}
                disabled={disabled}
                style={{ marginTop: 5 }}
            />
            <label className="form-check-label" htmlFor={filterName}>
                {text}
            </label>
        </div>
    )
}

export { CustomToggleFilter }