import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'
import download from 'downloadjs'

const downloadFile = (entity, idEntity, name, mimetype, extension) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.downloadFile)
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.withCredentials = true
        xhr.responseType = "blob"
        xhr.send(JSON.stringify({ entity, idEntity }))

        xhr.onload = () => {
            try {
                let fileBlob = xhr.response
                if (fileBlob.size !== 68) {
                    let file = new Blob([fileBlob], { type: mimetype })
                    download(file, `${name}.${extension}`)
                    resolve(true)
                } else {
                    messageService.printToast("Files", "error", 3)
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Files", "error", 3)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Files", "error", 3)
            resolve(false)
        }
    })
}

export { downloadFile }