//#region 'IMPORTS'
import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CasesFormUI } from './CasesFormUI'
import { MessageService } from '../../../Services/message_service'
import { UserContext } from '../../../Contexts/User/UserContext'
import { LoadingContext } from '../../../Contexts/Loading/LoadingContext'
import useCasesTypes from '../../../Hooks/useCasesTypes'
import useEditions from '../../../Hooks/useEditions'
import usePriorities from '../../../Hooks/usePriorities'
import { useTeachers } from '../../../Hooks/useTeachers'
import { useStudents } from '../../../Hooks/useStudents'
import { useDiagnosis } from '../../../Hooks/useDiagnosis'
import { useFormObj } from '../../../Hooks/useFormObj'
import { useCase } from '../../../Hooks/useCase'
import { useDoubts } from '../../../Hooks/useDoubts'
import { useFiles } from '../../../Hooks/useFiles'
import { useResponse } from '../../../Hooks/useResponse'
import useStates from '../../../Hooks/useStates'
import { DoubtsContext } from '../../../Contexts/Doubts/DoubtsContext'
import { getUserEditionsByUserId } from '../../../Services/Editions/getUserEditionsByUserId'
import { useCaseAssesssments } from '../../../Hooks/useCaseAssessments'
import { useObservations } from '../../../Hooks/useObservations'
import { useReasonWaiting } from '../../../Hooks/useReasonWaiting'
import { useCasesReasonWaiting } from '../../../Hooks/useCasesReasonWaiting'
import { useShowNotificationUser } from '../../../Hooks/useShowNotificationUser'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { toast } from 'react-toastify'
import moment from 'moment'
//#endregion

const CasesForm = (props) => {

    //#region 'VARIABLES'
    let history = useHistory()
    const messageService = new MessageService()
    const objUserFeedback = { id: 0, id_cases: 0, internal_note: '' }
    let urlData = props.location.state
    let caseData = typeof urlData != "undefined" ? urlData : false
    const objDataInitial = {
        stateId: { validFlags: { required: true }, properties: { pristine: caseData.State ? false : true }, flags: { required: true } },
        priorityId: { validFlags: { required: true }, properties: { pristine: caseData.Priority ? false : true }, flags: { required: true } },
        userId: { validFlags: { required: true }, properties: { pristine: caseData.Student ? false : true }, flags: { required: true } },
        teacherId: { validFlags: { required: true }, properties: { pristine: caseData.Teacher ? false : true }, flags: { required: false } },
        editionId: { validFlags: { required: true }, properties: { pristine: caseData.Edition ? false : true }, flags: { required: true } },
        caseTypeId: { validFlags: { required: true }, properties: { pristine: caseData.Type ? false : true }, flags: { required: true } },
        patientId: { validFlags: { regex: true }, properties: { pristine: caseData.patientInvisalignId ? false : true }, flags: { regex: /^[0-9]+$/ } },
        patientName: { validFlags: { maxLen: true }, properties: { pristine: caseData.patientInvisalignName ? false : true }, flags: { maxLen: 50 } },
        objective: { validFlags: { required: false, maxLen: false }, properties: { pristine: caseData.objective ? false : true }, flags: { required: true, maxLen: 10000 } }
    }
    const objRevisionForm = {
        teacherId: null,
        notifyTeacher: true,
        notifyStudent: true
    }
    //#endregion

    //#region 'USE CONTEXT'
    const userContext = useContext(UserContext)
    const doubtsContext = useContext(DoubtsContext)
    const loadingContext = useContext(LoadingContext)
    //#endregion

    //#region 'CUSTOMIZED STATES'
    const { uploadFiles, getCasePollFile, getFilesInfo, removeFile, download, downloadFileByPath } = useFiles()
    const { retrieveResponse } = useResponse()
    const { teachers, setTeachers, teachersReassign } = useTeachers()
    const { students, setStudents } = useStudents()
    const { diagnosis, retrieveDiagnosis, createDiagnosis, retrieveDiagnosisCase, updateDiagnosis, retrieveOtherDiagnosis } = useDiagnosis()
    const { casesEditions } = useEditions({ onlyForm: true })
    const { retrieveDoubts } = useDoubts()
    const { getDataCaseAssements, insertCaseAssessments, updateCaseAssessments } = useCaseAssesssments()
    const { getDataObservationsByCas } = useObservations()
    const { getDataShowNotificationUser, insertDataShowNotificationUser, updateDataShowNotificationUser } = useShowNotificationUser()
    const { createCase, updateCase, updateCaseUser, getCase, requestRevision,
        reassignCase, cancelRevision, cancelRevisionRequest, activateRevision,
        assignTeacher, notifyAssignation, removeCase, getCaseInfo, finishCase, changeCaseStateInProgress } = useCase()
    const { reasonWaiting } = useReasonWaiting()
    const [form, createForm, setFormData, setMultiData,
        setDiagnosis, updateForm] = useFormObj()
    const { states } = useStates()
    const { priorities, defaultPriorities } = usePriorities()
    const { casesTypes } = useCasesTypes({ onlyForm: true })
    const { getDataCasesReasonWaiting, insertDataCasesReasonWaiting, updateDataCasesReasonWaiting, updateForceDataCasesReasonWaiting } = useCasesReasonWaiting()
    //#endregion

    //#region 'USE STATE'
    const [selteacherReassign, setSelTeacherReassign] = useState({})
    const [userEditions, setUserEditions] = useState([])
    const [caseDiagnosis, setCaseDiagnosis] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [caseCode, setCaseCode] = useState("")
    const [doubts, setDoubts] = useState([])
    const [userData, setUserData] = useState({})
    const [files, setFiles] = useState([])
    const [pollFile, setPollFile] = useState([])
    const [origTeacher, setOrigTeacher] = useState(null)
    const [pollDownloadLink, setPollDownloadLink] = useState("")
    const [selectedTab, setSelectedTab] = useState("caseTab")
    const [isNew, setIsNew] = useState(true)
    const [tabsAttributes, setTabsAttributes] = useState({})
    const [imgDoctor, setImgDoctor] = useState({})
    const [submitTryWithErrors, setSubmitTryWithErrors] = useState(false)
    const [userFeedback, setUserFeedback] = useState(objUserFeedback)
    const [validators, setValidators] = useState(objDataInitial)
    const [observations, setObservations] = useState([])
    const [assignReasonWaiting, setAssignReasonWaiting] = useState([])
    const [revisionForm, setRevisionForm] = useState(objRevisionForm)
    const [revisionFormTeacherRequired, setRevisionFormTeacherRequired] = useState(false)
    const [showNotification, setShowNotification] = useState({})
    const [selState, setSelState] = useState(null)
    const [selPriorities, setSelPriorities] = useState(null)
    const [selCasesEditions, setSelCaseEditions] = useState(null)
    const [selCasesType, setSelCasesTypes] = useState(null)
    const [selTeacher, setSelTeachers] = useState(null)
    const [selStudent, setSelStudents] = useState(null)
    const [otherOption, setOtherOption] = useState("")
    const [reasonWaitingCheck, setReasonWaitingCheck] = useState({})
    const [otherInformation, setOtherInformation] = useState('')
    const [checkAssignReasonWaiting, setCheckAssignReasonWaiting] = useState({})
    const [disableOtherInformation, setDisableOtherInformation] = useState(true)
    const [lopdCheck, setlopdCheck] = useState(false)
    const [finishProcess, setFinishProcess] = useState(false)
    const [time_out, setTime_out] = useState(false)
    //#endregion

    //#region Validations

    const validate = (field, value) => {
        var flags = {}

        if (field.flags.required) {
            let requiredFlag = false
            if (value) {
                requiredFlag = true
            }

            flags.required = requiredFlag
        }


        if (field.flags.regex) {
            let regexFlag = false

            if (value) {
                if (field.flags.regex.test(value)) {
                    regexFlag = true
                }
            } else {
                regexFlag = true
            }

            flags.regex = regexFlag
        }

        if (field.flags.maxLen) {
            let maxLenFlag = false
            if (field.flags.maxLen >= value.length) {
                maxLenFlag = true
            }

            flags.maxLen = maxLenFlag
        }

        return flags
    }

    const validateValidators = () => {
        let isValid = true
        for (const key in validators) {
            for (const subKey in validators[key]) {
                if (!validators[key][subKey]) {
                    isValid = false
                }
            }
        }
        return isValid
    }
    //#endregion

    const getDoubts = async () => {
        let result = await retrieveDoubts(caseData.id)

        let doubts = await Promise.all(result.map(async (doubt, index) => {
            let response = await retrieveResponse(doubt.id)
            doubt.response = response
            return doubt
        }))

        if (form.id) {
            updateForm(await getCase(form.id))
        }
        setDoubts(doubts)
        return result
    }

    const updateDataObservation = (data) => {
        setObservations(data)
    }

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }

    const generateObservationHtml = (data) => {
        data.forEach(item => {
            item.observationHTML = UpdateStateEditor(item.observation || '')
            return item
        });
        return data
    }

    const getObservations = async () => {
        let result = await getDataObservationsByCas(caseData.id)
        setObservations(generateObservationHtml(result))
        return result
    }

    const getCaseUser = async (role, caseId) => {
        // Only retrieve the current teacher if the user is a teacher
        let caseUser = await userContext.retrieveUserByCase(role, caseId)
        return [caseUser]
    }

    const setTeacher = async () => {
        let userId = (await userContext.retrieveUserData()).id
        let result = await assignTeacher(userId, form.id)
        if (result) {
            document.getElementById("closeAutoAssignModal").click()
            setFormData("teacherId", userId)
            updateForm(await getCase(form.id))
        }
    }

    const forceExit = () => {
        history.push("/")
        window.location.reload()
    }

    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                forceExit()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        loadingContext.setVisibility(true)
        if (!await userContext.checkSession()) {
            forceExit()
        } else {
            let tmpUserData = await userContext.retrieveUserData()
            setUserData(tmpUserData)
            if (typeof caseDiagnosis !== "undefined" && caseDiagnosis.length === 0) {
                let urlData = props.location.state
                let caseData = typeof urlData !== "undefined" ? (await getCase(urlData.id))[0] : {}
                let caseDataLength = Object.keys(caseData).length > 0
                setImgDoctor(await downloadFileByPath(caseData.Photo_Profile))
                if (Number.isInteger(caseData.id)) {
                    setlopdCheck(caseData.allow_lopd)
                    if (tmpUserData.idRole !== 3) {
                        let dataCasesAssessments = await getDataCaseAssements(caseData.id)
                        if (dataCasesAssessments) {
                            if (dataCasesAssessments.id) {
                                setUserFeedback(dataCasesAssessments)
                            } else {
                                setUserFeedback({ ...userFeedback, id_cases: caseData.id })
                            }
                        } else {
                            setUserFeedback({ ...userFeedback, id_cases: caseData.id })
                        }
                        let dataShowNotification = await getDataShowNotificationUser(caseData.id_users)
                        setShowNotification(dataShowNotification)
                    }
                    setFiles(await getFilesInfo("case", caseData.id))
                    setIsNew(false)
                    if (tmpUserData.idRole !== 3) {
                        setTeachers(await getCaseUser("teacher", caseData.id))
                        setStudents(await getCaseUser("student", caseData.id))
                    }
                    var diagnosisResult = await retrieveDiagnosisCase(caseData.id)
                    var caseResult = await getCase(caseData.id)
                    if (tmpUserData.idRole !== 2 || tmpUserData.idRole === 2) {
                        await getDoubts()
                        await getObservations()
                    }
                    loadingContext.setVisibility(false)
                } else if (tmpUserData.idRole !== 2) {
                    loadingContext.setVisibility(false)
                } else {
                    loadingContext.setVisibility(false)
                    history.push("/main-screen/cases")
                }

                setAssignReasonWaiting(await getDataCasesReasonWaiting(caseData.id))

                setDisabled(caseData.Case_code ? true : false)
                setCaseCode(caseData.Case_code ? caseData.Case_code : "")
                setCaseDiagnosis(diagnosisResult)
                setOrigTeacher(typeof caseResult != "undefined" ? caseResult[0].id_teacher_assigned : null)

                createForm([[
                    ["editable", caseData.id ? true : false],
                    ["id", caseData.id ? caseData.id : false],
                    ["revisionRequest", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Revision_request : false],
                    ["revisionAccepted", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Revision_accepted : false],
                    ["revisionCancelRequest", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Revision_cancel_request : false],
                    ["caseCode", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Case_code : ""],
                    ["stateId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_states : ""],
                    ["priorityId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_priorities == 0 ? true : false : ""],
                    ["editionId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_editions : ""],
                    ["caseTypeId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_cases_types : ""],
                    ["teacherId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_teacher_assigned : ""],
                    ["userId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].id_users : ""],
                    ["doctor", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].doctor : ""],
                    ["patientId", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Patient_ID_invisalign : ""],
                    ["patientName", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Patient_name_invisalign : ""],
                    ["objective", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Objetive : ""],
                    ["pollFilePath", typeof caseResult != "undefined" && caseResult.length ? caseResult[0].Poll_file_path : ""]
                ], [["diagnosis", "object", caseDataLength ? [diagnosisResult, retrieveDiagnosis] : retrieveDiagnosis]]])
            } else {
                loadingContext.setVisibility(false)
            }
        }
    }, [])

    useEffect(() => {
        setUserEditions(casesEditions)
        if (userData.idRole === 3) {
            if (!finishProcess) {
                if (casesEditions) {
                    if (casesEditions.length > 0) {
                        if (Object.keys(form).length > 0) {
                            if (form.id) {
                                if (moment().isAfter(casesEditions[0].endDate)) {
                                    console.log("CADUCADO")
                                    setTime_out(true)
                                } else if (casesEditions[0].consumed >= casesEditions[0].number) {
                                    console.log("CONSUMIDO")
                                } else {
                                    setFormData("editionId", form.editionId)
                                }
                            } else {
                                if (moment().isAfter(casesEditions[0].endDate)) {
                                    console.log("CADUCADO")
                                    document.getElementById("triggerNoEditionsModal").click()
                                } else if (casesEditions[0].consumed >= casesEditions[0].number) {
                                    console.log("CONSUMIDO")
                                    document.getElementById("triggerNoEditionsModal").click()
                                } else {
                                    setFormData("editionId", casesEditions[0].value)
                                }
                            }
                            setFinishProcess(true)
                        }
                    } else {
                        document.getElementById("triggerNoEditionsModal").click()
                    }
                }
            }
        }
    }, [casesEditions, userData, form])

    //#multiregion revisions
    //#region Activate revision

    const handleRevisionFormCheckChange = (e) => {
        const name = e.target.name
        const checked = e.target.checked
        setRevisionForm({ ...revisionForm, [name]: checked })
    }

    const handleRevisionFormInput = (e) => {
        setRevisionForm({ ...revisionForm, teacherId: e.value })
    }

    const submitRevisionForm = async () => {
        if (Number.isInteger(revisionForm.teacherId)) {
            setRevisionFormTeacherRequired(false)
            await activateRevision({ ...revisionForm, caseId: form.id })
            document.getElementById("closeActivateRevisionModal").click()
            updateForm(await getCase(form.id))
        } else {
            setRevisionFormTeacherRequired(true)
        }
    }
    //#endregion

    //#region notifications
    const notifiyTeacherChange = () => {
        notifyAssignation(form.id, form.teacherId)
        document.getElementById("closeAssignNotificationModal").click()
    }
    //#endregion
    //#endmultiregion

    const backScreen = (e) => {
        e.preventDefault();
        history.push("/main-screen/cases")
    }

    const selectChangeState = (e) => {
        setSelState(e)
        let tmpStateValidator = validators.stateId
        let validationRes = validate(tmpStateValidator, true)
        tmpStateValidator.valid = validationRes
        setValidators({ ...validators, stateId: tmpStateValidator })
        setFormData("stateId", e.value)
    }

    const selectChangePriorities = e => {
        let checked = e.target.checked
        setFormData("priorityId", checked)
    }

    const selectChangeEditions = (e) => {
        setSelCaseEditions(e)
        let tmpEditionValidator = validators.editionId
        let validationRes = validate(tmpEditionValidator, true)
        tmpEditionValidator.valid = validationRes
        setValidators({ ...validators, editionId: tmpEditionValidator })
        setFormData("editionId", e.value)
    }

    const selectChangeCasesTypes = (e) => {
        setSelCasesTypes(e)
        let tmpTypeValidator = validators.caseTypeId
        let validationRes = validate(tmpTypeValidator, true)
        tmpTypeValidator.valid = validationRes
        setValidators({ ...validators, caseTypeId: tmpTypeValidator })
        setFormData("caseTypeId", e.value)
    }

    const selectChangeTeachers = (e) => {
        setSelTeachers(e)
        let tmpTeacherValidator = validators.teacherId
        let validationRes = validate(tmpTeacherValidator, true)
        tmpTeacherValidator.valid = validationRes
        setValidators({ ...validators, teacherId: tmpTeacherValidator })
        setFormData("teacherId", e.value)
    }

    const selectTeachersAssign = e => {
        setSelTeacherReassign(e)
    }

    const selectChangeStudents = async (e) => {
        let userEditions = await getUserEditionsByUserId(e.value)
        let doc = []
        userEditions.map(element => {
            if (element.consumed_cases < element.Limit_cases) {
                doc.push({
                    value: element.id_editions,
                    label: element.Name,
                    number: element.Limit_cases,
                    consumed: element.consumed_cases || 0,
                    endDate: element.End_date,
                    target: { name: "caseEditionFilter" }
                })
            }
        })
        setUserEditions(doc)

        setSelStudents(e)
        let tmpStudentValidator = validators.userId
        let validationRes = validate(tmpStudentValidator, true)
        tmpStudentValidator.valid = validationRes
        setValidators({ ...validators, userId: tmpStudentValidator })
        setFormData("userId", e.value)
    }

    const createTabAttributes = () => {
        for (const tab in tabsAttributes) {
            switch (tab) {
                case "case":
                    setOriginalAttributes(doubtsContext.refs.case, tabsAttributes[tab])
                    break
                case "doubts":
                    setOriginalAttributes(doubtsContext.refs.doubts, tabsAttributes[tab])
                    break
                case "form":
                    setOriginalAttributes(doubtsContext.refs.form, tabsAttributes[tab])
                    break
                case "attachments":
                    setOriginalAttributes(doubtsContext.refs.attachments, tabsAttributes[tab])
                    break
            }
        }
    }

    const setOriginalAttributes = (ref, obj) => {
        if (ref.current !== undefined) {
            ref.current.attributes.removeNamedItem("data-bs-target")
            for (const key in obj) {
                ref.current.attributes.setNamedItem(obj[key])
            }
        }
    }

    const setEditingAttributes = (ref, obj) => {
        ref.current.attributes.setNamedItem(obj)
    }

    const changeDisabled = () => {
        let modalToggler = document.createAttribute("data-bs-toggle")
        modalToggler.value = "modal"
        let modalName = document.createAttribute("data-bs-target")
        modalName.value = "#isEditingModal"

        let tmpTabsAttributes = {}
        tmpTabsAttributes.case = {}
        tmpTabsAttributes.case.href = doubtsContext.refs.case.current.attributes.removeNamedItem("href")
        tmpTabsAttributes.case.toggle = doubtsContext.refs.case.current.attributes.removeNamedItem("data-bs-toggle")
        setEditingAttributes(doubtsContext.refs.case, modalToggler.cloneNode())
        setEditingAttributes(doubtsContext.refs.case, modalName.cloneNode())

        tmpTabsAttributes.doubts = {}
        tmpTabsAttributes.doubts.href = doubtsContext.refs.doubts.current.attributes.removeNamedItem("href")
        tmpTabsAttributes.doubts.toggle = doubtsContext.refs.doubts.current.attributes.removeNamedItem("data-bs-toggle")
        setEditingAttributes(doubtsContext.refs.doubts, modalToggler.cloneNode())
        setEditingAttributes(doubtsContext.refs.doubts, modalName.cloneNode())

        tmpTabsAttributes.form = {}
        //tmpTabsAttributes.form.href = doubtsContext.refs.form.current.attributes.removeNamedItem("href")
        //tmpTabsAttributes.form.toggle = doubtsContext.refs.form.current.attributes.removeNamedItem("data-bs-toggle")
        /* setEditingAttributes(doubtsContext.refs.form, modalToggler.cloneNode())
        setEditingAttributes(doubtsContext.refs.form, modalName.cloneNode()) */

        tmpTabsAttributes.attachments = {}
        if (doubtsContext.refs.attachments.current !== undefined) {
            tmpTabsAttributes.attachments.href = doubtsContext.refs.attachments.current.attributes.removeNamedItem("href")
            tmpTabsAttributes.attachments.toggle = doubtsContext.refs.attachments.current.attributes.removeNamedItem("data-bs-toggle")
            setEditingAttributes(doubtsContext.refs.attachments, modalToggler.cloneNode())
            setEditingAttributes(doubtsContext.refs.attachments, modalName.cloneNode())
        }

        setTabsAttributes(tmpTabsAttributes)
        setDisabled(!disabled)
    }

    const handleDiagnosis = e => {
        let name = e.target.name
        let value = e.target.value
        let checked = e.target.checked
        let tmpDiagnosis = form.diagnosis
        if (name === "otherDiagnosisCheck") {
            let length = Object.keys(tmpDiagnosis).length
            let currVal = tmpDiagnosis[length - 1].used
            tmpDiagnosis[length - 1].used = !currVal
            setFormData("diagnosis", tmpDiagnosis)
        } else {
            for (const index in tmpDiagnosis) {
                let id = parseInt(index) + 1
                if (id == value) {
                    let currVal = tmpDiagnosis[id - 1].value
                    tmpDiagnosis[id - 1].value = !currVal
                    setFormData("diagnosis", tmpDiagnosis)
                }
            }
        }

    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const type = e.target.type
        const value = type === "checkbox" ? e.target.checked : e.target.value

        if (name === "lopd") {
            setlopdCheck(value)
        } else if (name === "otherDiagnosis") {
            let length = Object.keys(form.diagnosis).length
            setDiagnosis(length - 1, value)
            setOtherOption(value)
        } else {
            setFormData(name, value)
            let tmpValidator = validators[name]
            let validationRes = validate(tmpValidator, value)
            tmpValidator.validFlags = validationRes
            tmpValidator.properties.pristine = false
            setValidators({ ...validators, [name]: tmpValidator })
        }
    }

    const validateFields = () => {
        let isValid = true
        for (const key in form) {
            if (form[key] !== "undefined" &&
                Boolean(form[key]) === false &&
                !Number.isInteger(form[key])) {
                if (key != "id" &&
                    key != "teacherId" &&
                    key != "userId" &&
                    key != "patientId" &&
                    key != "caseCode" &&
                    key != "editable" &&
                    key != "revisionRequest" &&
                    key != "revisionAccepted" &&
                    key != "revisionCancelRequest" &&
                    key != "stateId" &&
                    key != "priorityId" &&
                    key != "doctor" &&
                    key != "pollFilePath") {
                    setSubmitTryWithErrors(true)
                    isValid = false
                }
            }
        }

        return isValid
    }

    const handleSubmit = async (e) => {
        if (lopdCheck) {
            let isValid = validateFields()
            if (isValid) {
                let caseResult = await createCase(form, lopdCheck)
                if (Number.isInteger(caseResult)) {
                    setIsNew(false)
                    let caseNewData = await getCaseInfo(caseResult)
                    setMultiData([
                        { field: "userId", value: caseNewData.id_students },
                        { field: "doctor", value: caseNewData.doctor },
                        { field: "id", value: caseNewData.id },
                        { field: "caseCode", value: caseNewData.Case_code },
                        { field: "editable", value: true },
                    ])

                    setCaseCode(caseNewData.Case_code)
                    setDisabled(true)
                    let result = createDiagnosis(form.diagnosis, caseResult)

                    document.getElementById("triggerCreatedModal").click()

                    if (caseNewData) {
                        let diagnosis = await retrieveDiagnosisCase(caseNewData.id)
                        let diagnosisOther = await retrieveOtherDiagnosis(caseNewData.id)
                        if (diagnosisOther.length == 1) {
                            diagnosis.push({
                                id: "otherDiagnsosisCheck",
                                used: true,
                                value: diagnosisOther[0].text
                            })
                        }
                        caseNewData.diagnosis = diagnosis
                        setFormData("id", caseNewData.id)
                        setFormData("stateId", caseNewData.id_states)
                        history.push({ pathname: "/main-screen/case", state: caseNewData })
                    }
                }
            }
        } else {
            toast("No has aceptado la política de privacidad", { type: 'warning', autoClose: 3000 })
        }
    }

    const cancelEdition = async () => {
        createTabAttributes()
        setDisabled(true)
        updateForm(await getCase(form.id))
        let diagnosisUsed = await retrieveDiagnosisCase(caseData.id)
        diagnosisUsed = diagnosisUsed.map(diagnosis => {
            if (typeof diagnosis.text === "undefined") {
                return { id: diagnosis.id_diagnosis_option, value: true }
            } else {
                let usedFlag = Boolean(diagnosis.text)
                return {
                    id: "otherDiagnsosisCheck",
                    used: usedFlag,
                    value: usedFlag ? diagnosis.text : false
                }
            }
        })

        let res = form.diagnosis.map(diagnosis => {
            for (let index = 0; index < diagnosisUsed.length; index++) {
                if (diagnosis.id === diagnosisUsed[index].id) {
                    return diagnosisUsed[index]
                }
            }
            return { id: diagnosis.id, value: false }
        })
        setFormData("diagnosis", res)
    }

    const handleUpdate = async (e) => {
        let isValid = validateFields()
        let validValidators = validateValidators()
        if (isValid && validValidators) {
            let res = await updateCase(form)

            if (res) {
                createTabAttributes()
                setDisabled(true)
                updateForm(await getCase(form.id))
                updateDiagnosis(form.id, form.diagnosis)
                if (origTeacher != form.teacherId) {
                    document.getElementById("openAssignNotification").click()
                    setOrigTeacher(form.teacherId)
                }
            }
        } else {
            messageService.printToast("Generics", "error", 7)
        }
    }

    const handleUpdateUser = async () => {
        let isValid = validateFields()
        let validValidators = validateValidators()
        if (isValid && validValidators) {
            if (await updateCaseUser(form)) {
                createTabAttributes()
                updateForm(await getCase(form.id))
                updateDiagnosis(form.id, form.diagnosis)
                setDisabled(true)
            }
        } else {
            messageService.printToast("Generics", "error", 7)
        }
    }

    const handleRequestRevision = async () => {
        if (assignReasonWaiting.length > 0) {
            let res = await updateDataCasesReasonWaiting(caseData.id, assignReasonWaiting)
            if (res) {
                if (await requestRevision(form.id, form.userId)) {
                    document.getElementById("closeRevisionModal").click()
                    setAssignReasonWaiting(res)
                    setFormData("revisionRequest", true)
                    updateForm(await getCase(form.id))
                }
            }
        } else {
            if (await requestRevision(form.id, form.userId)) {
                document.getElementById("closeRevisionModal").click()
                setFormData("revisionRequest", true)
                updateForm(await getCase(form.id))
            }
        }
    }

    const handleRevisionCancel = async () => {
        if (await cancelRevision(form.id, caseData.id_students)) {
            document.getElementById("closeCancelModal").click()
            setFormData("revisionRequest", false)
            updateForm(await getCase(form.id))
        }
    }

    const handleRequestRevisionCancel = async () => {
        if (await cancelRevisionRequest(form.id)) {
            document.getElementById("closeRequestCancelRevision").click()
            setFormData("revisionCancelRequest", true)
            updateForm(await getCase(form.id))
        }
    }

    const handleDeleteCase = async () => {
        if (await removeCase(form.id)) {
            document.getElementById("closeDeleteModal").click()
            history.push("/main-screen/cases")
        }
    }

    const downloadPollFile = async () => {
        if (form.pollFilePath) {
            await getCasePollFile(form.id, form.caseCode)
            updateForm(await getCase(form.id))
        }
    }

    const uploadAttachments = async (files, formName, entityId) => {
        await uploadFiles(files, formName, entityId)
        setFiles(await getFilesInfo("case", caseData.id))
    }

    const downloadAttachment = async (idFile, name, mimetype, extension) => {
        download("case", idFile, name, mimetype, extension)
    }

    const deleteAttachment = async (idFile) => {
        if (await removeFile("case", idFile)) {
            setFiles(await getFilesInfo("case", caseData.id))
        }
    }

    const selectTab = tabName => { setSelectedTab(tabName) }

    const closeCase = async () => {
        if (await finishCase(form.id)) {
            document.getElementById("closeCloseCaseModal").click()
            history.push("/main-screen/cases")
        }
    }

    const processShowNotification = async () => {
        if (showNotification) {
            await updateDataShowNotificationUser(caseData.id_students, 1)
        } else {
            await insertDataShowNotificationUser(caseData.id_students, 1)
        }
    }

    const setCaseOnHold = async () => {
        if (caseData.id > 0 && reasonWaitingCheck || (!disableOtherInformation && otherInformation.trim() !== '')) {
            let res = await insertDataCasesReasonWaiting(caseData.id, reasonWaitingCheck, otherInformation, caseData.id_students)
            if (res) {
                processShowNotification()
                document.getElementById("closeReasonWaiting").click()
                updateForm(res)
                if (userData.idRole === 2 || userData.idRole === 1) {
                    history.push("/main-screen/cases")
                }
            }
        }
    }

    const setCaseInProgress = async () => {
        updateForceDataCasesReasonWaiting(caseData.id)
        if (await changeCaseStateInProgress(form.id)) {
            if (showNotification) {
                await updateDataShowNotificationUser(caseData.id_students, 0)
            }
            document.getElementById("closeInProgressModal").click()
            updateForm(await getCase(form.id))
        }
    }

    const handleFeedBack = async () => {
        if (userFeedback.id_cases > 0 && userFeedback.internal_note.trim() !== '') {
            if (userFeedback.id > 0) {
                await updateCaseAssessments(userFeedback)
            } else {
                await insertCaseAssessments(userFeedback)
            }
            let res = await getDataCaseAssements(userFeedback.id_cases)
            setUserFeedback(res)
            document.getElementById("closeCaseAssessments").click()
        }
    }

    const handleReassignCase = async () => {
        if (await reassignCase(form.id, selteacherReassign.value)) {
            document.getElementById("closeReassignCaseModal").click()
            updateForm(await getCase(form.id))
            setFormData("teacherId", selteacherReassign.value)
            setFormData("reassignCase", true)
            setSelTeacherReassign({})
        }
    }

    const handleChangeValue = (e) => {
        const name = e.target.name
        const type = e.target.type
        const value = type === 'checkbox' ? e.target.checked : e.target.value
        if (name === "ck_Otros,4" && !value) {
            setOtherInformation('')
            setDisableOtherInformation(true)
        }
        if (name === "ck_Otros,4" && value) {
            setDisableOtherInformation(false)
        }
        setReasonWaitingCheck({ ...reasonWaitingCheck, [name]: value })
    }

    const hanleChangeAssignReasonValue = index => e => {
        const name = e.target.name
        const value = e.target.checked
        let newArr = [...assignReasonWaiting]
        newArr[index].done = value ? 1 : 0
        setAssignReasonWaiting(newArr)
    }

    const handleAssignReasonWaiting = async () => {
        let res = await updateDataCasesReasonWaiting(caseData.id, assignReasonWaiting)
        if (res) {
            setAssignReasonWaiting(res)
            document.getElementById("closeDetailReasonWaiting").click()
        }
    }

    return (
        <CasesFormUI
            backScreen={backScreen}
            fields={{
                states: {
                    data: states,
                    selected: selState,
                    change: selectChangeState
                },
                priorities: {
                    data: priorities,
                    default: defaultPriorities,
                    selected: selPriorities,
                    change: selectChangePriorities
                },
                casesEditions: {
                    selected: selCasesEditions,
                    change: selectChangeEditions
                },
                casesTypes: {
                    data: casesTypes,
                    selected: selCasesType,
                    change: selectChangeCasesTypes
                },
                teachers: {
                    data: teachers,
                    selected: selTeacher,
                    change: selectChangeTeachers
                },
                students: {
                    data: students,
                    selected: selStudent,
                    change: selectChangeStudents
                },
                diagnosis: {
                    data: diagnosis,
                },
                teachersReassign: {
                    data: teachersReassign,
                    selected: selteacherReassign,
                    change: selectTeachersAssign
                }
            }}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleUpdate={handleUpdate}
            handleUpdateUser={handleUpdateUser}
            handleDiagnosis={handleDiagnosis}
            handleRequestRevision={handleRequestRevision}
            form={form}
            retrieveDiagnosisCase={retrieveDiagnosisCase}
            disabled={disabled}
            caseCode={caseCode}
            changeDisabled={changeDisabled}
            validators={validators}
            doubts={doubts}
            getDoubts={getDoubts}
            observations={{
                data: observations,
                set: setObservations,
                getFunction: getObservations,
                updateFunction: updateDataObservation,
            }}
            submitTryWithErrors={submitTryWithErrors}
            userData={userContext.userData}
            otherOption={otherOption}
            setTeacher={setTeacher}
            files={{
                data: files,
                setter: setFiles
            }}
            revisionForm={{
                data: revisionForm,
                handleCheck: handleRevisionFormCheckChange,
                handleInput: handleRevisionFormInput,
                submit: submitRevisionForm,
                teacherRequired: revisionFormTeacherRequired
            }}
            handleRevisionCancel={handleRevisionCancel}
            handleRequestRevisionCancel={handleRequestRevisionCancel}
            handleDeleteCase={handleDeleteCase}
            notifiyTeacherChange={notifiyTeacherChange}
            history={history}
            uploadFiles={uploadAttachments}
            pollFile={pollFile}
            setPollFile={setPollFile}
            pollDownloadLink={pollDownloadLink}
            downloadPollFile={downloadPollFile}
            deleteAttachment={deleteAttachment}
            downloadAttachment={downloadAttachment}
            isNew={isNew}
            selectTab={selectTab}
            selectedTab={selectedTab}
            closeCase={closeCase}
            userEditions={userEditions}
            cancelEdition={cancelEdition}
            setCaseOnHold={setCaseOnHold}
            setCaseInProgress={setCaseInProgress}
            userFeedback={userFeedback}
            setUserFeedback={setUserFeedback}
            handleFeedBack={handleFeedBack}
            imgDoctor={imgDoctor}
            handleReassignCase={handleReassignCase}
            hanleSelectTeachers={selectTeachersAssign}
            reasonWaitingCheck={reasonWaitingCheck}
            reasonWaiting={reasonWaiting}
            handleChangeValue={handleChangeValue}
            otherInformation={{
                value: otherInformation,
                set: setOtherInformation
            }}
            assignReasonWaiting={{
                data: assignReasonWaiting,
                set: setAssignReasonWaiting
            }}
            propertiesForm={{
                set: setFormData,
                update: updateForm
            }}
            checkAssignReasonWaiting={checkAssignReasonWaiting}
            hanleChangeAssignReasonValue={hanleChangeAssignReasonValue}
            handleAssignReasonWaiting={handleAssignReasonWaiting}
            disableOtherInformation={disableOtherInformation}
            lopdCheck={lopdCheck}
            time_out={time_out}
        />
    )
}

export { CasesForm }