import React from 'react'

const KanbanCard = ({ index, title, styleTitle, classHead, onclickHead, dataTarget, cardBody, styleCard, imgUser }) => {
    return (
        <div key={index} className="col-xs-6 col-md-6 col-lg-3">
            <div className="card mt-3" style={{borderTopLeftRadius:10}}>
                <div className={classHead} style={styleCard}>
                    <div className="d-flex clickable"
                        onClick={onclickHead}
                        key={index}
                        data-bs-toggle="modal"
                        data-bs-target={dataTarget}
                    >
                        {imgUser}
                        <h5 className="card-title text-truncate" style={styleTitle}>{title}</h5>
                    </div>
                </div>
                <div className="card-body">
                    {cardBody}
                </div>
            </div>
        </div>
    )
}

export { KanbanCard }