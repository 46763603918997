import React, { useRef, useContext, useState, useEffect } from 'react'
import RouteCtrl from '../../routes/Routes'
import { LoginScreenUI } from './LoginScreenUI'
import LoginScreenCSS from './LoginScreen.module.css'
import { MessageService } from '../../Services/message_service'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import { useFormObj } from '../../Hooks/useFormObj'
import { useUsers } from '../../Hooks/useUsers'
import { UserContext } from '../../Contexts/User/UserContext'
import { toast } from 'react-toastify'
import ValidateIdentification from '../Tools/GeneralFunctions/ValidateIdentification'

// Components

const LoginScreen = props => {
    const { recoverPassword, setPassword, resendEmail, validateUser } = useUsers()

    const userContext = useContext(UserContext)
    const emailRegex = /^(?:[\w\.\-]+)@(?:[\w\-]+)(?:(?:\.(?:\w){2,3})+)$/
    const passComplexRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&~€*-]).{8,}$/

    let history = useHistory()

    const [loginFormValues, setLoginFormValues] = useState({ vat: "", password: "" })
    const [showResendConf, setShowResendConf] = useState(false)

    // Reset password form
    const [resetPasswordSubmitTry, setResetPasswordSubmitTry] = useState(false)
    const [resetPasswordObj,
        setResetPasswordObj] = useState({
            password: "",
            confirmPassword: ""
        })

    const handleResetPassword = (e) => {
        let name = e.target.name
        let value = e.target.value

        let tmpValidators = resetPasswordValidators
        let validationRes = validateResetPasswordForm(tmpValidators[name], value)
        tmpValidators[name].validFlags = validationRes
        tmpValidators[name].properties.pristine = false
        setResetPasswordObj({ ...resetPasswordObj, [name]: value })
        switch (name) {
            case 'password':
                if (value === resetPasswordObj.confirmPassword) {
                    tmpValidators.sync = true
                } else {
                    tmpValidators.sync = false
                }
                break

            case 'confirmPassword':
                if (value === resetPasswordObj.password) {
                    tmpValidators.sync = true
                } else {
                    tmpValidators.sync = false
                }
                break
        }
        setResetPasswordValidators(tmpValidators)
    }

    const [resetPasswordValidators, setResetPasswordValidators] = useState({
        password: {
            validFlags: { required: false, regex: false },
            properties: { pristine: true },
            flags: { required: true, regex: passComplexRegex }
        }
        ,
        confirmPassword: {
            validFlags: { required: false, regex: false },
            properties: { pristine: true },
            flags: { required: true, regex: passComplexRegex }
        },
        sync: false
    }
    )

    const validateResetPasswordForm = (field, value) => {
        var flags = {}

        if (field.flags.required) {
            let requiredFlag = false
            if (value) {
                requiredFlag = true
            }

            flags.required = requiredFlag
        }

        if (field.flags.regex) {
            let regexFlag = false
            if (field.flags.regex.test(value)) {
                regexFlag = true
            }

            flags.regex = regexFlag
        }

        return flags
    }

    const validateValidators = () => {
        let flag = true

        for (const validator in resetPasswordValidators) {
            let validFlags = resetPasswordValidators[validator].validFlags
            for (const key in validFlags) {
                if (!validFlags[key]) {
                    flag = false
                }
            }
        }

        if (!resetPasswordValidators.sync) {
            flag = false
        }

        return flag
    }

    const submitResetPassword = () => {
        setResetPasswordSubmitTry(true)
        validateValidators()
        if (checkEmptyFields(resetPasswordObj) && validateValidators()) {
            let token = props.location.pathname.replaceAll("/login/reset-password/", "")
            setPassword(token, resetPasswordObj.password)
        }
    }

    // Recover password form
    const [recoverForm, createRecoverForm, updateRecoverForm] = useFormObj()
    createRecoverForm("email")

    const [registerFormValues, setRegisterFormValues] = useState({
        name: "",
        lastname: "",
        vat: "",
        email: "",
        phone: "",
        userInvisalign: "",
        passwordInvisalign: "",
        descriptionNotes: "",
        password: "",
        confirmPassword: "",
        files: [],
        lopd: false
    })

    // Tabs
    const loginTabRef = useRef()
    const registerTabRef = useRef()
    const recoverTabRef = useRef()

    const myTabContentRef = useRef()
    const activatePanelRef = useRef()
    const tabMenuRef = useRef()

    // Recover form
    const handleRecoverTab = (e) => {
        const loginTabCurrent = loginTabRef.current
        const registerTabCurrent = registerTabRef.current
        const recoverTabCurrent = recoverTabRef.current

        console.log("🚀 ~ file: LoginScreen.js:167 ~ handleRecoverTab ~ e.target.name:", e.target.name)
        if (e.target.name) {
            loginTabCurrent.className = "nav-link"
            registerTabCurrent.className = "nav-link"
        } else {
            recoverTabCurrent.className = "nav-link"
        }
    }

    const handleRecoverSubmit = e => {
        e.preventDefault()
        let email = recoverForm.email
        if (email && emailRegex.test(email)) {
            updateRecoverForm("email", "")
            document.getElementById("loginTab").click()
            recoverPassword(email)
        }
    }

    const handleRecoverInput = (e) => {
        updateRecoverForm("email", e.target.value)
    }

    // Change pass form


    // Validation booleans
    const [passwordsMatch, setPasswordsMatch] = useState(false)
    const [imageSizeIsValid, setImageSizeIsValid] = useState(false)
    const [vatIsValid, setVatIsValid] = useState(false)
    const [emailIsValid, setEmaiIsValid] = useState(false)
    const [passwordComplexityIsValid, setPasswordComplexityIsValid] = useState(false)
    const [lopdCheck, setlopdCheck] = useState(false)
    const [selectNif, setSelectNif] = useState({ value: 'ES', label: 'España' })

    useEffect(async () => {
        const token = props.match.params?.token
        token && validateUser(token)
    }, [])

    const handleLoginSubmit = async e => {
        let messageService = new MessageService()
        e.preventDefault()
        if (checkEmptyFields(loginFormValues)) {
            let xhr = new XMLHttpRequest()
            xhr.open("POST", RouteCtrl.login)
            xhr.setRequestHeader('Content-Type', 'application/json')
            xhr.withCredentials = true
            xhr.send(JSON.stringify(loginFormValues))

            xhr.onload = async () => {
                let res = JSON.parse(xhr.response)
                messageService.printToast("RegisterAndLogin", res.type, res.code)
                if (res.type === "success") {
                    if (await userContext.checkSession()) {
                        history.push("/main-screen/cases")
                    }
                } else if (res.type === "error" && res.code === 17) {
                    setShowResendConf(true)
                } else {
                    setShowResendConf(false)
                }
            }

            xhr.onerror = () => {
                messageService.printToast("RegisterAndLogin", "error", 9)
            }
        } else {
            messageService.printToast("RegisterAndLogin", "error", 0)
        }
    }

    const handleResendAccountConf = async () => {
        await resendEmail(loginFormValues.vat)
        setShowResendConf(false)
    }

    const showActivate = () => {
        let myTabContentChange = myTabContentRef.current
        let activatePanelChange = activatePanelRef.current
        let tabMenuChange = tabMenuRef.current

        tabMenuChange.className = LoginScreenCSS.none
        myTabContentChange.className = LoginScreenCSS.none
        activatePanelChange.className = `${LoginScreenCSS.active} padding1 ${LoginScreenCSS.block}`
    }

    const handleRegisterSubmit = async e => {
        let messageService = new MessageService()
        e.preventDefault()
        if (checkEmptyFields(registerFormValues)) {
            let values = {}
            Object.assign(values, registerFormValues)
            delete values.files
            let files = registerFormValues.files
            const data = formDataBuilder(values, files)
            if (registerFormValues.lopd) {
                fetch(RouteCtrl.register, {
                    method: "POST",
                    body: data
                })
                    .then(res => res.json())
                    .then(res => {
                        if (res.type === "success") {
                            showActivate()
                        }
                        messageService.printToast("RegisterAndLogin", res.type, res.code)
                    })
                    .catch(res => messageService.printToast("RegisterAndLogin", "error", 9))
            } else {
                toast.warning('No has aceptado la politica de privacidad')
            }
        } else {
            messageService.printToast("RegisterAndLogin", "error", 0)
        }
    }

    const formDataBuilder = (data, files) => {
        let formData = new FormData()

        for (let index = 0; index < files.length; index++) {
            formData.append('userDocuments', files[index])
        }

        for (const key in data) {
            formData.append(key, data[key])
        }

        return formData
    }

    const handleRegisterInputChange = e => {
        const name = e.target.name
        const type = e.target.type
        const value = type === "checkbox" ? e.target.checked : e.target.value
        if (type === "checkbox") {
            setlopdCheck(value)
        }

        if (name === "password") {
            if (passComplexRegex.test(value)) {
                setPasswordComplexityIsValid(false)
            } else {
                setPasswordComplexityIsValid(true)
            }
            if (value === registerFormValues.confirmPassword &&
                Boolean(registerFormValues.confirmPassword)) {
                setPasswordsMatch(false)
            } else if (Boolean(registerFormValues.confirmPassword)) {
                setPasswordsMatch(true)
            }
        } else if (name === "confirmPassword") {
            if (value === registerFormValues.password) {
                setPasswordsMatch(false)
            } else {
                setPasswordsMatch(true)
            }
        }

        if (name === "vat") {
            let isoCountry = selectNif.value
            if (isoCountry === 'ES') {
                ValidateIdentification[isoCountry].NIE(value) || ValidateIdentification[isoCountry].DNI(value) ? setVatIsValid(false) : setVatIsValid(true)
            } else if (isoCountry === 'PT') {
                console.log(ValidateIdentification[isoCountry].NIF(value))
                ValidateIdentification[isoCountry].NIF(value) ? setVatIsValid(false) : setVatIsValid(true)
            } else {
                setVatIsValid(true)
            }
        }

        if (name === "email") {
            if (emailRegex.test(value)) {
                setEmaiIsValid(false)
            } else {
                setEmaiIsValid(true)
            }
        }

        setRegisterFormValues({ ...registerFormValues, [name]: value })
    }

    const handleLoginInputChange = e => {
        const name = e.target.name
        const value = e.target.value
        setLoginFormValues({ ...loginFormValues, [name]: value })
    }

    const handleImage = e => {
        let name = e.target.name
        let files = e.target.files
        let currFiles = registerFormValues.files

        let newList = []
        if (name === "userDocuments") {
            newList = currFiles.filter(file => {
                if (typeof file.nameType !== "undefined") {
                    return !file.nameType.includes("userDocuments")
                }
                return true
            })

            for (let index = 0; index < files.length; index++) {
                if (name === "userDocuments") {
                    files[index].nameType = `${name}-${index}`
                    newList.push(files[index])
                }
            }
        } else {
            if (files.length > 0) {
                let uploadName = files[0].name
                let type = files[0].type
                let fileBlob = files[0].
                    slice(0, files[0].size, type);
                let fileName = `photoProfile_oif_app.${uploadName.
                    substr(uploadName.lastIndexOf(".") + 1,)}`
                let newFile = new File([fileBlob], fileName,
                    { type: type });
                newFile.nameType = "profilePhoto"
                newList = currFiles.filter(file => {
                    return !(file.nameType === "profilePhoto")
                })
                newList.unshift(newFile)
            }
        }
        setRegisterFormValues({ ...registerFormValues, ["files"]: newList })
    }

    const checkEmptyFields = values => {
        let okCount = 0
        for (const key in values) {
            if (
                Boolean(values[key]) &&
                typeof values[key] === 'string' &&
                values[key].trim() ||
                typeof values[key] === 'object' &&
                key === "files" ||
                key === "descriptionNotes" ||
                key === "lopd"
            ) {
                okCount++
            }
        }
        return okCount === Object.keys(values).length ? true : false
    }

    return (
        <LoginScreenUI
            tabRefs={{
                login: loginTabRef,
                register: registerTabRef,
                recover: recoverTabRef,
                myTabContent: myTabContentRef,
                activatePanel: activatePanelRef,
                tabMenu: tabMenuRef
            }}
            forms={{
                resetPassword: {
                    submit: submitResetPassword,
                    validators: resetPasswordValidators,
                    handle: handleResetPassword,
                    try: resetPasswordSubmitTry
                }
            }}
            recoverForm={recoverForm}
            updateRecoverForm={updateRecoverForm}
            handleResetPassword={handleResetPassword}
            handleRecoverTab={handleRecoverTab}
            handleRecoverSubmit={handleRecoverSubmit}
            handleRecoverInput={handleRecoverInput}
            handleLoginSubmit={handleLoginSubmit}
            handleLoginInputChange={handleLoginInputChange}
            handleRegisterSubmit={handleRegisterSubmit}
            handleRegisterInputChange={handleRegisterInputChange}
            handleImage={handleImage}
            token={props.match.params.token}
            validationBooleans={{
                passwordsMatch: passwordsMatch,
                imageSize: imageSizeIsValid,
                vat: vatIsValid,
                email: emailIsValid,
                passComplex: passwordComplexityIsValid
            }}
            registerValues={registerFormValues}
            loginValues={loginFormValues}
            showResendConf={showResendConf}
            handleResendAccountConf={handleResendAccountConf}
            resetPasswordObj={resetPasswordObj}
            lopdCheck={lopdCheck}
            selectNif={{
                value: selectNif,
                set: setSelectNif
            }}
        />
    )
}

export { LoginScreen }