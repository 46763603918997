//#region IMPORTS
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ProfileScreenUI } from './ProfileScreenUI'
import { UserContext } from '../../Contexts/User/UserContext'
import { useUsers } from '../../Hooks/useUsers'
import { useFiles } from '../../Hooks/useFiles'
import { MessageService } from '../../Services/message_service'
//#endregion

const ProfileScreen = () => {
    const history = useHistory()
    const userContext = useContext(UserContext)
    let messageService = new MessageService()
    const { updateOwnUser } = useUsers()
    const { uploadFiles, downloadFileByPath } = useFiles()
    const [userData, setUserData] = useState({})
    const [profileImage, setProfileImage] = useState()
    const [userTitle, setUserTitle] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [userForm, setUserForm] = useState({
        id: "",
        name: "",
        lastname: "",
        vat: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        userInvisalign: "",
        passwordInvisalign: "",
        descriptionNotes: ""
    })


    useLayoutEffect(() => {
        userContext.retrieveUserData().then(res => {
            if (!Number.isInteger(res.idRole)) {
                history.push("/")
                window.location.reload()
            }
        })
    }, [])

    useEffect(async () => {
        document.querySelector('.modal-backdrop')?.remove()
        if (!await userContext.checkSession()) {
            history.push("/")
            window.location.reload()
        }

        let tmpUserData = await userContext.retrieveUserData()
        setUserData(tmpUserData)
        let imageBlob = await downloadFileByPath(tmpUserData.profileImage)
        setUserTitle(tmpUserData.name + " " + tmpUserData.lastname)
        setProfileImage(imageBlob)
        setUserForm({
            id: tmpUserData.id,
            name: tmpUserData.name,
            lastname: tmpUserData.lastname,
            vat: tmpUserData.vat,
            email: tmpUserData.email,
            phone: tmpUserData.phone,
            password: "",
            confirmPassword: "",
            userInvisalign: tmpUserData.userInvisalign,
            passwordInvisalign: "",
            descriptionNotes: tmpUserData.descriptionNotes
        })
    }, [])

    const changeEdit = () => setDisabled(!disabled)

    const handleUpdateUser = async () => {
        let userFormCopy = {}
        Object.assign(userFormCopy, userForm)
        delete userFormCopy.vat

        let emptyFlag = true
        for (const field in userFormCopy) {
            if (!userFormCopy[field] &&
                field !== "password" &&
                field !== "confirmPassword" &&
                field !== "passwordInvisalign" &&
                field !== "descriptionNotes"
            ) {
                emptyFlag = false
            }
        }

        if (emptyFlag) {
            if (await updateOwnUser(userFormCopy)) {
                setUserTitle(userForm.name + " " + userForm.lastname)
                setDisabled(!disabled)
            }
        } else {
            messageService.printToast("Generics", "error", 1)
        }
    }

    const handleInputChange = e => {
        let name = e.target.name
        let value = e.target.value

        setUserForm({ ...userForm, [name]: value })
    }

    const handleImageChange = async e => {
        //let name = e.target.name
        let file = e.target.files[0]

        let result = await uploadFiles([file], "profilePhoto")
        if (result) {
            let tmpUserData = await userContext.retrieveUserData()
            let imageBlob = await downloadFileByPath(tmpUserData.profileImage)
            setProfileImage(imageBlob)
        }
    }

    /* const downloadImage = async (path, mimetype) => {
        return await downloadFileByPath(path, mimetype)
    } */

    const cancelChanges = async () => {
        let tmpUserData = userContext.userData
        setUserForm({
            id: tmpUserData.id,
            name: tmpUserData.name,
            lastname: tmpUserData.lastname,
            vat: tmpUserData.vat,
            email: tmpUserData.email,
            phone: tmpUserData.phone,
            password: "",
            userInvisalign: tmpUserData.userInvisalign,
            passwordInvisalign: "",
            descriptionNotes: tmpUserData.descriptionNotes
        })
    }

    return (
        <ProfileScreenUI
            disabled={disabled}
            userForm={userForm}
            changeEdit={changeEdit}
            handleImageChange={handleImageChange}
            handleInputChange={handleInputChange}
            cancelChanges={cancelChanges}
            handleUpdateUser={handleUpdateUser}
            profileImage={profileImage}
            userTitle={userTitle}
            userData={userData}
        />
    )
}

export { ProfileScreen }