//#region IMPORTS
import { resetPassword } from '../Services/Users/resetPassword'
import { putPassword } from '../Services/Users/putPassword'
import { resendConfEmail } from '../Services/Users/resendConfEmail'
import { putUser } from '../Services/Users/putUser'
import { getUsers } from '../Services/Users/getUsers'
import { getRoles } from '../Services/Users/getRoles'
import { putOwnUser } from '../Services/Users/putOwnUser'
import { activateUser } from '../Services/Users/activateUser'
import postUserAdmin from '../Services/Users/postUserAdmin'
import { useHistory } from 'react-router-dom'
import { deleteUserByAdmin } from '../Services/Users/deleteUserByAdmin'
//#endregion

const useUsers = props => {
    const history = useHistory()

    const retrieveUsers = () => getUsers()

    const retrieveRoles = () => getRoles()

    const recoverPassword = email => { resetPassword(email) }

    const setPassword = async (token, password) => {
        let result = await putPassword(token, password)
        if (result) {
            history.push("/")
        }
        return result
    }

    const resendEmail = async (vat) => {
        return await resendConfEmail(vat)
    }

    const updateUser = data => putUser(data)

    const updateOwnUser = data => putOwnUser(data)

    const validateUser = token => activateUser(token)

    const createUserAdmin = (data) => postUserAdmin(data)

    const removeUserByAdmin = idUser => deleteUserByAdmin(idUser)

    return {
        retrieveUsers, retrieveRoles, recoverPassword, setPassword, resendEmail,
        updateUser, updateOwnUser, validateUser, createUserAdmin, removeUserByAdmin
    }
}

export { useUsers }