import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonBack from '../Tools/ButtonBack';
import { ContentGeneral } from '../Tools/ContentGeneral';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import TextInputCustom from '../Tools/TextInputCustom';

const CasesAssessmentsUI = ({ id_role, nameUser, caseAssessments, search, handleSearch, doFilter }) => {

    //#region VARIABLE
    let history = useHistory()
    const divStyles = {
        display: "flex",
        color: 'black',
        fontWeight: '500',
    }

    const FONT_SIZE = 14
    //#endregion

    //#region STATES
    const [selectedItem, setSelectedItem] = useState(null)
    //#endregion

    const backScreen = (e) => {
        e.preventDefault();
        history.push("/main-screen/users")
    }

    const onFilter = (item) => {
        if (!search.searchCode) {
            return item
        } else if (item.Case_code.includes(search.searchCode)) {
            return item
        }
    }

    //#region STRUCTURE

    const ContentComp = ({ itemComp }) => (
        <div className="row">
            {itemComp}
        </div>
    )

    const ItemBodyComp = ({ label, value }) => (
        <div className="col-12">
            <p className="card-text mb-0" style={{ fontSize: FONT_SIZE }}><strong>{label} </strong></p>
            <p className="card-text mb-1" style={{ fontSize: FONT_SIZE }}>{value}</p>
        </div>
    )

    const ChildComponent = ({ item, index }) => (
        <div key={index} style={{ marginBottom: "0.5rem" }}>
            {selectedItem === item.id ?
                <div style={{ backgroundColor: "#dfdfdf", marginTop: "0.5rem", paddingLeft: 20 }}>
                    <div className='row'>
                        {id_role === 3 && (<ItemBodyComp label={"Profesor"} value={item.modifier} />)}
                        {(id_role === 2 || id_role === 1) && (<ItemBodyComp label={"Doctor"} value={item.doctor} />)}
                        <ItemBodyComp label={"Nota interna"} value={item.internal_note} />
                    </div>
                </div>
                : null}
        </div>
    )

    const ParentComponent = ({ item, index }) => (
        <div key={index} style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
            <div style={divStyles}>
                {selectedItem !== item.id ?
                    <div onClick={() => setSelectedItem(item.id)} className={'clickable'}>
                        <span className={{ fontWeight: 'bolder' }}>
                            <svg className="svg-inline--fa fa-chevron-down fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
                        </span>
                    </div> : null}
                {selectedItem === item.id ?
                    <div onClick={() => setSelectedItem(null)}
                        className={'clickable '}>
                        <span className={{ fontWeight: 'bolder' }}>
                            <svg className="svg-inline--fa fa-chevron-up fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                        </span>
                    </div> : null}
                &nbsp;<h5 style={{ color: "#2c82c9" }}>{item.Case_code}</h5>
            </div>
            <hr style={{ margin: 0 }} />
            <ChildComponent item={item} index={index} />
        </div>
    )

    const ParentArrayMapping = () => caseAssessments.filter(el => onFilter(el)).map((item, index) => (
        <ParentComponent item={item} index={index} />
    ))

    const ItemNothingElement = () => (
        <div style={{ borderRadius: "6px", padding: "1rem", marginTop: "1rem", backgroundColor: "#dfdfdf" }}>
            <div className="card-body">
                <p className="card-text text-center">Aún no se ha registrado ninguna valoración.</p>
            </div>
        </div>
    )
    //#endregion

    return ([
        <HeadTitle title={"Valoraciones"} />,
        <ContentGeneral content={[
            <ButtonBack onclickBack={backScreen} />,
            <TextInputCustom nameInput={"searchCode"} typeInput={"search"} textLabel={"Código del caso"}
                evOnChange={handleSearch} value={search.searchCode} placeholder={"Buscar..."}
                style={{ margin: 0 }} />,
            <br />,
            id_role === 3 && (<span><strong>Doctor:</strong> {nameUser}</span>),
            (id_role === 2 || id_role === 1 ) && (<span><strong>Profesor:</strong> {nameUser}</span>),
            <ContentComp itemComp={caseAssessments.length > 0 ?
                <ParentArrayMapping />
                : <ItemNothingElement />} />,
        ]} />
    ])
}

export { CasesAssessmentsUI }