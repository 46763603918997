//#region "IMPORT"
import React, { useState, useContext, useEffect } from 'react'
import DoubtsScreen from '../../DoubtsScreen/DoubtsScreen'
import CaseFormCSS from './CasesForm.module.css'
import ButtonBack from '../../Tools/ButtonBack'
import CustomSelect from '../../Tools/CustomSelect'
import TextInputCustom from '../../Tools/TextInputCustom'
import ButtonSave from '../../Tools/ButtonSave'
import ButtonOutline from '../../Tools/ButtonOutline/ButtonOutline'
import { ErrorText } from '../../Tools/ErrorText'
import SimpleBootstrapModal from '../../Tools/SimpleBootstrapModal'
import ButtonModal from '../../Tools/ButtonModal'
import { headerColors } from '../../../constants'
import { DoubtsContext } from '../../../Contexts/Doubts/DoubtsContext'
import { useHistory } from 'react-router-dom'
import imgUserPatient from '../../../img/userPatient2.png'
import { DisplayImageUser } from '../../Tools/DisplayImageUser'
import { CustomToggleFilter } from '../../Tools/CustomToggleFilter/CustomToggleFilter'
import { ContentItem } from '../../Tools/ContentItem'
import moment from 'moment'
import { ContentGeneral } from '../../Tools/ContentGeneral'
import { HeadTitle } from '../../Tools/HeadTitle/HeadTitle'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { useLOPD } from '../../../Hooks/useLOPD'
import { ModalLopd } from '../../Tools/Modals/ModalLopd'
//#endregion

const CasesFormUI = props => {

    //#region "VARIABLES"
    const BG_TAB_NO_ACTIVE = '#e3e3e3'
    const PADDING_TAB = ".35rem"
    let history = useHistory()
    const requiredFieldMsg = "Este campo es obligatorio"
    const charLimit10000 = "Este campo no puede superar los 10000 caracteres"
    const charLimit50 = "Este campo no puede superar los 50 caracteres"
    const numericField = "Este campo debe ser numérico"
    const { diagnosis } = props.form
    //#endregion

    //#region "USE CONTEXT"
    const doubtsContext = useContext(DoubtsContext)
    //#endregion

    //#region "USE STATE"
    const [caseDiagnosis, setCaseDiagnosis] = useState(diagnosis)
    const { data, setData } = useLOPD()
    //#endregion

    if (caseDiagnosis != diagnosis) { setCaseDiagnosis(diagnosis) }

    const currState = props.fields.states.data.filter(option => {
        if (option.value == props.form.stateId) {
            return option
        }
    })[0]

    const createButtonFeatureToggler = () => {
        if (props.selectedTab === "doubtsTab") {
            doubtsContext.editionDoubt.disable()
            doubtsContext.isCreating.enable()
        } else {
            history.push("/main-screen/case")
            window.location.reload()
        }
    }

    const displayEdition = () => {
        if (props.form.id) {
            return props.userEditions.map(option => {
                if (option.value === props.form.editionId) {
                    return option
                }
            })
        } else if (props.userData.idRole === 3) {
            return props.userEditions.filter(option => {
                return new Date(option.endDate) > new Date()
            })[0]
        }
    }

    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }

    const colorSelector = (caseRecord) => {
        if (caseRecord.Answer_date_limit_exceeded > 0 && caseRecord.stateId !== 4) {
            return "importantHeader";
        } else if (caseRecord.id_priorities !== 0 || caseRecord.stateId === 4) {
            return headerColors[caseRecord.stateId]
        } else if (caseRecord.id_priorities !== 0 || caseRecord.stateId === 5) {
            return headerColors[caseRecord.stateId]
        } else {
            return "importantHeader";
        }
    }

    //#region STRUCTURE

    //#region MODALS

    const ModalCaseCreated = <SimpleBootstrapModal
        id="caseCreated"
        title="Caso creado"
        cancelButtonMessage="Aceptar">
        <p className="text-center">¡Caso creado correctamente!</p>
        <p className="text-center">Ya puedes empezar a crear tus dudas</p>
    </SimpleBootstrapModal>


    const ModalNoEditionsAvailable = <SimpleBootstrapModal
        id="noEditionsAvailable"
        title="Aviso"
        cancelButtonMessage="Aceptar"
        closeFunction={() => history.push("/main-screen/cases")}
    >
        <p className="text-center">No tienes ninguna edición activa</p>
        <p className="text-center">Hablar con la administración de OIF</p>
    </SimpleBootstrapModal>


    const ModalOnHoldCase = <SimpleBootstrapModal
        id="onHoldCase"
        title="Aviso"
        closeButtonId={"closeOnHoldCaseModal"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal key={"Key_onHoldCase_no"} id={"closeOnHoldCaseModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal key={"key_onHoldCase_si"} text={"Sí"} bsTarget={"#reasonWaiting"} onClick={() => {
                document.getElementById("closeOnHoldCaseModal").click()
            }} />
        ]}
    >
        <p>¿Quieres dejar el caso en espera?</p>
        <p>Si hay la solicitud de revisión se cancelará para que el alumno pueda cumplir con los motivos de espera.</p>
    </SimpleBootstrapModal>


    const ModalAssignReasonWaiting = <SimpleBootstrapModal
        id="detailReasonWaiting"
        title="Detalles"
        closeButtonId={"closeDetailReasonWaiting"}
        cancelButtonMessage="Aceptar">
        <ContentItem itemBody={
            props.assignReasonWaiting.data.map((item, index) => (
                <CustomToggleFilter
                    key={index}
                    text={item.id_reason_waiting === 4 ? item.reason_waiting_other : item.name}
                    filterName={item.name + ',' + item.id_reason_waiting}
                    checked={!item.done}
                    disabledr={true}
                />
            ))
        } />
    </SimpleBootstrapModal>


    const ModalReasonWaiting = <SimpleBootstrapModal
        id="reasonWaiting"
        title="Aviso"
        closeButtonId={"closeReasonWaiting"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal id={"closeReasonWaiting"} text={"Cancelar"} bsDimiss={"modal"} close={true} />,
            <ButtonModal text={"Guardar"} onClick={props.setCaseOnHold} />
        ]}
    >
        <p>Añadir motivo para establecer el caso en espera</p>
        <ContentItem itemBody={
            props.reasonWaiting.map((item) => (
                <CustomToggleFilter text={item.name} filterName={"ck_" + item.name + ',' + item.id}
                    checked={Object.keys(props.reasonWaitingCheck).forEach(i => {
                        if (i === "ck_" + item.name) {
                            return props.reasonWaitingCheck
                        } else {
                            return false
                        }
                    })}
                    event={props.handleChangeValue} />
            ))
        } />
        <TextInputCustom
            nameInput={"otherInformation"}
            disabled={props.disableOtherInformation}
            evOnChange={(e) => props.otherInformation.set(e.target.value)}
            value={props.otherInformation.value} />
    </SimpleBootstrapModal>

    const ModalInProgressCase = <SimpleBootstrapModal
        id="inProgressCase"
        title="Aviso"
        closeButtonId={"closeInProgressModal"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal key={"key_inProgressCase_no"} id={"closeInProgressModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal key={"key_inProgressCase_si"} text={"Sí"} onClick={props.setCaseInProgress} />
        ]}
    >
        <p>¿Quieres poner el caso en curso?</p>
        <p>Se confirmaran los motivos de espera que esten pendientes</p>
    </SimpleBootstrapModal>


    const ModalIsEditingModal = <SimpleBootstrapModal
        id="isEditingModal"
        title="Aviso"
        cancelButtonMessage="Aceptar"
        message="Por favor, guarda los cambios primero."
    />


    const ModalAssignNotification = <SimpleBootstrapModal
        id={"assignNotification"}
        title={"Notificar asignación"}
        message={"¿Desea notificar la asignación al nuevo profesor?"}
        footerButtons={[
            <ButtonModal id={"closeAssignNotificationModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal text={"Sí"} onClick={props.notifiyTeacherChange} />
        ]}
    >
    </SimpleBootstrapModal>


    const ModalActivateRevisionModal = <SimpleBootstrapModal
        id={"activateRevisionModal"}
        title={"Activar Revisión"}
        closeButtonId={"closeActivateRevisionModal"}
        footerButtons={<ButtonModal text={"Activar"} onClick={props.revisionForm.submit} />}
    >
        <CustomSelect
            data={props.fields.teachers.data}
            nameSelect={"teacherId"}
            label={"Profesor:"}
            onChange={props.revisionForm.handleInput}
            requiredValidation={{
                condition: props.revisionForm.teacherRequired,
                msg: requiredFieldMsg
            }}
            divClasses={"field marginb05"}
        />
        <div className="form-group marginb0 flex">
            <label>Notificar: </label><br />
        </div>
        <div className="form-group marginb0 flex gap05">
            <div>
                <label
                    className="marginr05"
                >Profesor</label>
                <input type="checkbox"
                    name="notifyTeacher"
                    id="notifyTeacher"
                    className="middle"
                    checked={props.revisionForm.data.notifyTeacher}
                    onChange={props.revisionForm.handleCheck}
                />
            </div>
            <div>
                <label
                    htmlFor="notifyStudent"
                    className="marginr05"
                >Doctor</label>
                <input type="checkbox"
                    name="notifyStudent"
                    id="notifyStudent"
                    className="middle"
                    checked={props.revisionForm.data.notifyStudent}
                    onChange={props.revisionForm.handleCheck}
                />
            </div>
        </div>
    </SimpleBootstrapModal>


    const ModalNeedDoubtsFirst = <SimpleBootstrapModal
        id={"needDoubtsFirst"}
        title={"Solicitar revisión"}
        message={"Antes de solicitar la revisión del caso, recuerda indicarnos tus dudas."}
        cancelButtonMessage="Aceptar"
    />


    const ModalRevisionModal = <SimpleBootstrapModal
        id={"revisionModal"}
        title={"Solicitar revisión"}
        message={"¿Estás seguro de solicitar la revisión del caso?"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal id={"closeRevisionModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal text={"Sí"} onClick={props.handleRequestRevision} />
        ]}
    />


    const ModalRequestCancelRevision = <SimpleBootstrapModal
        id={"requestCancelRevision"}
        title={"Solicitar cancelación de revisión"}
        message={"¿Está seguro de solicitar la cancelación de la revisión?"}
        footerButtons={[
            <ButtonModal text={"Sí"} onClick={props.handleRequestRevisionCancel} />
        ]}
    />


    const ModalCancelRevision = <SimpleBootstrapModal
        id={"cancelRevision"}
        title={"Cancelar solicitud de revisión del caso"}
        message={[
            <span key={"key_question_cancelRevision"}>¿Quieres cancelar la solictud de revisión del caso?</span>,
            <br key={"key_br_cancelRevision"} />,
            <span key={"key_msg_cancelRevision"}>Al cancelar la solicitud podrás modificar las dudas pendientes de responder por el profesor. Una vez modificadas las dudas deberás de volver a solicitar la revisión del caso y el tiempo de respuesta volverá a ser de 7 días.</span>
        ]}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal key={"key_cancelRevision_no"} id={"closeCancelModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal key={"key_cancelRevision_si"} text={"Sí"} onClick={props.handleRevisionCancel} />
        ]}
    />

    const ModalAutoAssignModal = <SimpleBootstrapModal
        id={"autoAssignModal"}
        title={"Autoasignar caso"}
        closeButtonId={"closeAutoAssignModal"}
        message={"¿Está seguro de asignarse el caso?"}
        footerCancelButton={false}
        footerButtons={[
            <ButtonModal key={"key_autoAssignModal_no"} id={"closeAutoAssignModal"} text={"No"} bsDimiss={"modal"} close={true} />,
            <ButtonModal key={"key_autoAssignModal_si"} text={"Sí"} onClick={props.setTeacher} />
        ]}
    />


    const ModalDeleteCase = <SimpleBootstrapModal
        id={"deleteCase"}
        title={"Eliminar caso"}
        closeButtonId={"closeDeleteModal"}
        message={"¿Está seguro de eliminar el caso?"}
        footerButtons={
            <ButtonModal text={"Aceptar"} onClick={props.handleDeleteCase} />
        }
    />

    const ModalCaseAssessments = <SimpleBootstrapModal
        id={"caseAssessments"}
        title={"Valorar caso"}
        closeButtonId={"closeCaseAssessments"}
        fullScreen={false}
        footerCancelButton={true}
        footerButtons={[
            <ButtonModal id={"closeCaseAssessments"} key={"keyCloseCaseAssessments"} text={"Guardar"} onClick={props.handleFeedBack} />
        ]}>
        <div className="form-group mt-2">
            <label>Notas internas</label>
            <textarea className="form-control" aria-label="With textarea"
                disabled={false}
                rows={10}
                value={props.userFeedback.internal_note}
                onChange={(txt) => props.setUserFeedback({ ...props.userFeedback, internal_note: txt.target.value })}
            />
        </div>
    </SimpleBootstrapModal>

    const ModalCloseCase = ({ allowShow }) => (
        allowShow ?
            <SimpleBootstrapModal
                id={"closeCase"}
                title={"Cerrar caso"}
                closeButtonId="closeCloseCaseModal"
                message={"¿Está seguro de cerrar el caso?"}
                footerButtons={
                    <ButtonModal
                        text={"Sí"}
                        onClick={props.closeCase}
                    />
                }
            /> : null
    )

    const ModalReassignCase = <SimpleBootstrapModal
        id={"reassignCase"}
        closeButtonId={"closeReassignCaseModal"}
        title={"Reasignar caso"}
        fullScreen={false}
        footerCancelButton={true}
        footerButtons={<ButtonModal text={"Asignar"} key={"keyConfirmReassignCase"} onClick={props.handleReassignCase} />}
    >
        <div className="row">
            <div className="col-12">
                <div className="form-group field marginb05">
                    <label>Doctor actual:</label>
                    <p>{props.fields.teachers.data?.find(e => e.value === props.form.teacherId)?.label || ''}</p>
                </div>
            </div>
            <div className="col-12">
                <CustomSelect
                    data={props.fields.teachersReassign.data}
                    value={props.fields.teachersReassign.selected}
                    nameSelect={"teacherReassignId"}
                    label={"Profesor:"}
                    onChange={props.fields.teachersReassign.change}
                    divClasses={"field marginb05"}
                />
            </div>
        </div>
    </SimpleBootstrapModal>

    const IconReasonWaiting = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-exclamation-octagon-fill" style={{ color: '#0d6efd', marginBottom: 10, pointerEvents: 'none' }} viewBox="0 0 16 16">
        <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </svg>

    //#endregion

    const HeadCasesForm = () => (
        <div className={props.form.stateId !== 4 ? "blackAndWhite" : "closedHeader"}>
            <div className='d-flex'>
                <div style={{ alignSelf: 'center' }}>
                    <img src={imgUserPatient} style={{ width: "40px", marginRight: "5px", marginLeft: "5px" }} />
                </div>
                <div className='d-flex justify-content-between w-100' >
                    <div style={{ width: "90%" }}>
                        {props.form.patientName ? <div className="row ps-3">
                            <h4 className="card-title d-inline-block text-truncate pt-2" style={{ fontWeight: "bolder" }}>{props.form.patientName} / {props.form.patientId}</h4>
                        </div> : <></>}
                        <div className={props.form.patientName ? 'd-flex justify-content-between' : 'd-flex justify-content-between mt-2'}>
                            <div className="ps-3">
                                <h6>{props.caseCode.toUpperCase() || "NUEVO CASO"}</h6>
                            </div>
                            <div>
                                <p className='ps-3 mb-1' style={{ textAlign: 'right' }}>{typeof currState != "undefined" ? currState.label : "No disponible"}</p>
                            </div>
                        </div>
                    </div>
                    {props.form.selected != 4 ?
                        <div style={{ width: "15px" }} className={colorSelector(props.form)}></div> : <></>}
                </div>
            </div>
        </div>
    )

    const ButtonBar = () => (
        <div className="flex margintb1" style={{ gap: "0.5rem", flexWrap: "wrap" }}>
            <ButtonBack onclickBack={props.backScreen} classMarginLeft={"m0"} />
            {props.time_out || props.caseCode && ([
                props.caseCode && props.userData.idRole !== 2 && props.form.stateId !== 2 && props.form.stateId !== 3 &&
                props.selectedTab === "doubtsTab" && (
                    <ButtonOutline textBtn={"Dudas"} icoClasses={"fas fa-plus"}
                        dataTarget={"#doubtForm"} handleSubmit={createButtonFeatureToggler} />
                ),

                props.caseCode && props.userData.idRole !== 3 &&
                (props.form.stateId !== 1) && props.selectedTab === "doubtsTab" && (
                    <ButtonOutline textBtn={"Observaciones"} icoClasses={"fas fa-plus"}
                        dataTarget={"#observationForm"} handleSubmit={createButtonFeatureToggler}
                    />
                ),

                props.caseCode && !props.disabled && ([
                    <ButtonSave textBtn={"Guardar"} cssClasses={"margin0 marginl05"}
                        handleSubmit={props.userData.idRole === 1 ? props.handleUpdate : props.userData.idRole === 3 ? props.handleUpdateUser : null}
                        icoClasses={"fas fa-save"} />,

                    <ButtonSave textBtn={"Cancelar"} cssClasses={"margin0"}
                        buttonClasses={"light-red"} handleSubmit={props.cancelEdition}
                        icoClasses={"fas fa-times"} />
                ]),

                props.disabled && props.selectedTab === "caseTab" &&
                props.userData.idRole !== 2 && props.form.stateId in [1, 5] && (
                    <ButtonSave textBtn={"Editar"} cssClasses={"margin0"}
                        handleSubmit={props.changeDisabled} icoClasses={"fas fa-pencil-alt"}
                    />
                ),

                props.userData.idRole === 1 && props.form.revisionRequest === 1 &&
                props.form.revisionAccepted === 0 && (
                    <ButtonSave textBtn={"Activar revisión"} cssClasses={"margin0"}
                        dataTarget={"#activateRevisionModal"} icoClasses={"fas fa-user"}
                    />),

                props.userData.idRole !== 3 && props.form.stateId !== 4 &&
                typeof props.form.teacherId !== "undefined" &&
                typeof props.userData.id !== "undefined" &&
                props.form.teacherId !== props.userData.id && (
                    <ButtonSave textBtn={"Autoasignar"} dataTarget={"#autoAssignModal"}
                        cssClasses={"margin0"} icoClasses={"fas fa-id-badge"}
                    />),

                (props.userData.idRole === 1 && props.form.teacherId !== null &&
                    props.form.stateId !== 4) && (
                    <ButtonSave textBtn={"Reasignar"} cssClasses={"margin0"}
                        dataTarget={"#reassignCase"} />),

                props.userData.idRole !== 2 && props.form.teacherId !== null &&
                (props.form.stateId === 1 || props.form.stateId === 2 || props.form.stateId === 5) 
                && props.form.revisionRequest === 1 &&
                props.form.revisionAccepted === 1 && (
                    <ButtonSave textBtn={"Cancelar revisión"}
                        dataTarget={"#cancelRevision"} cssClasses={"margin0"}
                        icoClasses={"fas fa-user-times"} />),

                Boolean(props.doubts.length) &&
                props.userData.idRole !== 2 &&
                props.form.revisionRequest === 0 &&
                props.form.revisionAccepted === 0 && (
                    <div className="form-group margin0">
                        <ButtonSave
                            textBtn={"Solicitar revisión"}
                            dataTarget={props.doubts.length > 0 ?
                                "#revisionModal" :
                                "#needDoubtsFirst"
                            }
                            cssClasses={"margin0"}
                            icoClasses={"fas fa-user"}
                        />
                    </div>),

                props.disabled &&
                props.userData.idRole !== 3 &&
                (props.selectedTab === "caseTab" || props.selectedTab === 'doubtsTab') &&
                (props.form.stateId === 2 || props.form.stateId === 3) && (
                    <ButtonSave textBtn={"En espera"} cssClasses={"margin0"}
                        dataTarget="#onHoldCase" icoClasses={"fas fa-pause"}
                        buttonStyles={{ background: "rgb(125 51 143)", color: "#fff", border: 0, fontWeight: 450 }}
                    />),

                props.disabled && props.userData.idRole !== 3 &&
                (props.selectedTab === "caseTab" || props.selectedTab === 'doubtsTab') &&
                props.form.stateId === 5 && props.form.stateId !== 4 && (
                    <ButtonSave
                        textBtn={"Poner en curso"}
                        cssClasses={"margin0"}
                        dataTarget="#inProgressCase"
                        icoClasses={"fas fa-play"}
                        buttonStyles={{
                            background: "rgb(255 155 85)",
                            color: "#fff",
                            border: 0,
                            fontWeight: 450
                        }}
                    />),

                Number.isInteger(props.form.id) &&
                !Boolean(props.doubts.length) &&
                props.form.revisionRequest === 0 &&
                props.form.revisionAccepted === 0 &&
                props.userData.idRole !== 2 &&
                props.form.stateId !== 4 &&
                props.caseCode && (
                    <ButtonSave textBtn={"Eliminar"}
                        cssClasses={"margin0"} buttonClasses={"light-red"}
                        dataTarget={"#deleteCase"} icoClasses={"fas fa-trash-alt"} />),

                props.disabled && props.form.stateId !== 4 &&
                (props.userData.idRole === 1 || props.userData.idRole === 2) && (
                    <ButtonSave textBtn={"Cerrar"} cssClasses={"margin0"}
                        dataTarget={"#closeCase"} icoClasses={"fas fa-times"}
                        buttonClasses={"buttonClose"} />),

                props.userData.idRole !== 3 && props.form.stateId === 4 && (
                    <ButtonSave textBtn={"Valoración caso"} cssClasses={"margin0"} dataTarget={"#caseAssessments"} icoClasses={props.userFeedback?.id > 0 ? "fas fa-exclamation-circle" : null} />
                )
            ])}
        </div>
    )

    const BannerOnHold = () => (
        <div className='col-12 d-flex justify-content-center align-self-center text-center' style={{ background: '#d1d1d1', padding: 10, alignItems: 'center' }}>
            <div> {IconReasonWaiting} </div>
            <div> <p style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>{` El caso está en espera por diversos motivos. `}</p> </div>
            <ButtonSave textBtn={"Detalles"} icoClasses={"fa fa-bars"} formGroup={false} dataTarget={"#detailReasonWaiting"} />
        </div>
    )

    //#region TAB VIEWS 
    const viewTabProfile = () => (
        <div className="tab-pane fade" id="userProfile">
            <div className="row">
                <div className="row col-12 col-md-6">
                    <p>Nombre</p>
                    <div>
                        <TextInputCustom
                            cssClassesGroup={"marginlr0"}
                            value={props.fields?.students?.data[0]?.label}
                            disabled={"disabled"}
                        />
                    </div>
                    <p>Usuario Invisalign</p>
                    <TextInputCustom
                        cssClassesGroup={"marginlr0"}
                        value={props.fields?.students?.data[0]?.User_invisalign}
                        disabled={"disabled"}
                    />
                    <p>Contraseña Invisalign</p>
                    <TextInputCustom
                        cssClassesGroup={"marginlr0"}
                        value={props.fields?.students?.data[0]?.Password_invisalign}
                        disabled={"disabled"}
                    />
                </div>
                <div className="row col-md-4 col-xl-2" style={{ display: 'flex', justifyContent: 'center', marginTop: 0, marginBottom: 0, marginLeft: 'auto', marginRight: 'auto' }}>
                    <DisplayImageUser userBlob={props.imgDoctor} styImg={{ height: 'auto', minWidth: 300 }} />
                </div>
            </div>
        </div>
    )

    const ViewTabDoubtsAndObservations = () => (
        <div className="tab-pane fade" id="doubts">
            {props.caseCode && (
                <div className="row">
                    {props.userData.idRole !== 2 || props.userData.idRole === 2 ?
                        <DoubtsScreen
                            doubts={props.doubts}
                            getDoubts={props.getDoubts}
                            observations={props.observations}
                            caseRecord={props.form}
                            assignReasonWaiting={props.assignReasonWaiting}
                            propertiesForm={props.propertiesForm}
                            time_out={props.time_out}
                        /> : <p>El doctor aún no ha solicitado la revisión o no ha sido aceptada.</p>
                    }
                </div>
            )}
        </div>
    )

    const TabsCasesForm = props.caseCode && ([
        <ul className="nav nav-tabs nav-fill" style={{ border: 0 }}>
            <li className="nav-item">
                <a className="nav-link active font-size1-1"
                    style={{
                        padding: PADDING_TAB, border: 1, borderBottom: props.selectedTab === 'caseTab' ? 0 : 1,
                        borderColor: 'black', borderStyle: 'solid', color: 'black',
                        fontWeight: props.selectedTab === 'caseTab' ? 'bold' : 'normal',
                        borderBottomStyle: props.selectedTab === 'caseTab' ? 'initial' : 'solid',
                        background: props.selectedTab === 'caseTab' ? null : BG_TAB_NO_ACTIVE
                    }}
                    data-bs-toggle="tab" href="#case" id="caseTab" ref={doubtsContext.refs.case}
                    onClick={(e) => props.selectTab(e.target.id)}>
                    Caso
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link font-size1-1"
                    style={{
                        padding: PADDING_TAB, border: 1, borderBottom: props.selectedTab === 'doubtsTab' ? 0 : 1,
                        borderColor: 'black', borderStyle: 'solid', color: 'black',
                        borderBottomStyle: props.selectedTab === 'doubtsTab' ? 'initial' : 'solid',
                        fontWeight: props.selectedTab === 'doubtsTab' ? 'bold' : 'normal',
                        background: props.selectedTab === 'doubtsTab' ? null : BG_TAB_NO_ACTIVE
                    }}
                    data-bs-toggle="tab" href="#doubts" id="doubtsTab" ref={doubtsContext.refs.doubts}
                    onClick={(e) => props.selectTab(e.target.id)}>
                    {`Dudas `} {props.form.stateId === 5 ? IconReasonWaiting : null}
                </a>
            </li>
            {
                props.userData.idRole !== 3 && (
                    <li className="nav-item">
                        <a className="nav-link font-size1-1"
                            style={{
                                padding: PADDING_TAB, color: 'black', border: 1, borderBottom: props.selectedTab === 'userProfileTab' ? 0 : 1,
                                borderColor: 'black', borderStyle: 'solid', fontWeight: props.selectedTab === 'userProfileTab' ? 'bold' : 'normal',
                                borderBottomStyle: props.selectedTab === 'userProfileTab' ? 'initial' : 'solid',
                                background: props.selectedTab === 'userProfileTab' ? null : BG_TAB_NO_ACTIVE
                            }}
                            data-bs-toggle="tab" href="#userProfile" id="userProfileTab"
                            ref={doubtsContext.refs.userProfile}
                            onClick={(e) => props.selectTab(e.target.id)}>
                            Perfil
                        </a>
                    </li>)
            }
        </ul>
    ])

    //#endregion
    //#endregion

    //#region 'USE EFFECT'
    useEffect(() => {
        if (data?.id) {
            setData({ ...data, contentHtml: UpdateStateEditor(data.content || '') })
        }
    }, [data.id])
    //#endregion

    return ([
        <ButtonSave buttonId="triggerCreatedModal" dataTarget="#caseCreated" cssClasses="none" />,
        <ButtonSave buttonId="triggerNoEditionsModal" dataTarget="#noEditionsAvailable" cssClasses="none" />,
        <span id="openAssignNotification" className="none" data-bs-target="#assignNotification" data-bs-toggle="modal" />,
        <ModalLopd idLopd={2} dataTarget={"modalLopdCases"} />,
        ModalCaseCreated,
        ModalNoEditionsAvailable,
        ModalOnHoldCase,
        ModalInProgressCase,
        ModalIsEditingModal,
        ModalAssignNotification,
        ModalActivateRevisionModal,
        ModalNeedDoubtsFirst,
        ModalRevisionModal,
        ModalCancelRevision,
        ModalAutoAssignModal,
        ModalRequestCancelRevision,
        ModalDeleteCase,
        ModalCaseAssessments,
        ModalReasonWaiting,
        ModalAssignReasonWaiting,
        <ModalCloseCase allowShow={props.disabled && props.userData.idRole === 1 || props.userData.idRole === 2} />,
        ModalReassignCase,
        <HeadTitle title={""} />,
        <ContentGeneral key={"key_cg_casesFormUI"} content={[
            <HeadCasesForm />,
            <ButtonBar />,
            props.form.stateId === 5 && (<BannerOnHold />),
            TabsCasesForm,
            <div className="card-body " style={{ border: 1, borderTop: 0, borderColor: "black", borderStyle: "solid", background: '#fff' }}>
                <div id="myTabContent" className="tab-content">
                    <div className="tab-pane fade show active" id="case">
                        <fieldset>
                            <div className="row">
                                {props.userData.idRole === 1 && ([
                                    <div className="col-12 col-md-6">
                                        <CustomSelect
                                            data={props.fields.states.data}
                                            nameSelect={"stateId"}
                                            label={"*Estado"}
                                            value={props.fields.states.data.map(option => {
                                                if (option.value == props.form.stateId) {
                                                    return option
                                                }
                                            })}
                                            requiredValidation={{
                                                condition: !props.validators.stateId.validFlags.required && props.submitTryWithErrors,
                                                msg: requiredFieldMsg
                                            }}
                                            disabled={props.disabled}
                                            onChange={props.fields.states.change}
                                            divClasses={"marginlr0 field marginb05"}
                                        />
                                    </div>,
                                    <div className="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                        <input name="priorityId" className="middle marginl05" type="checkbox"
                                            checked={props.form.priorityId}
                                            onChange={props.fields.priorities.change}
                                            disabled={props.disabled}
                                            style={{ marginRight: 10 }}
                                        />
                                        <label>Urgente</label>
                                    </div>
                                ])}
                            </div>
                            <div className="row">
                                {
                                    props.userData.idRole === 1 || props.userData.idRole === 2 ?
                                        <>
                                            <div className="col-xs-12">
                                                <CustomSelect
                                                    data={props.fields.teachers.data}
                                                    nameSelect={"teacherId"}
                                                    label={"Solicitar profesor"}
                                                    value={
                                                        props.fields.teachers.data.map((option, index) => {
                                                            if (option.value === props.form.teacherId) {
                                                                return option
                                                            }
                                                        })
                                                    }
                                                    disabled={props.disabled}
                                                    onChange={props.fields.teachers.change}
                                                    divClasses={"marginlr0 field marginb05"}
                                                />
                                            </div>
                                            <div className="col-xs-12">
                                                <CustomSelect
                                                    data={props.fields.students.data}
                                                    nameSelect={"userId"}
                                                    label={"*Doctor"}
                                                    value={props.fields.students.data.map(option => {
                                                        if (option.value == props.form.userId) {
                                                            return option
                                                        }
                                                    })}
                                                    requiredValidation={{
                                                        condition: !props.validators.userId.validFlags.required && props.submitTryWithErrors,
                                                        msg: requiredFieldMsg
                                                    }}
                                                    disabled={props.disabled}
                                                    onChange={props.fields.students.change}
                                                    divClasses={"marginlr0 field marginb05"}
                                                />
                                            </div>
                                            <hr />
                                        </>
                                        : null
                                }
                            </div>
                            <CustomSelect
                                data={props.isNew ?
                                    props.userEditions.filter(option => {
                                        return moment(option.endDate).isAfter(new Date())
                                    })
                                    : props.userEditions}
                                nameSelect={"editionId"}
                                label={"*Edición"}
                                value={displayEdition()}
                                disabled={!props.isNew || props.isNew && props.userData.idRole === 3}/* */
                                onChange={props.fields.casesEditions.change}
                                divClasses={"marginlr0 field marginb05"}
                            />

                            <CustomSelect
                                data={props.fields.casesTypes.data}
                                nameSelect={"caseTypeId"}
                                label={"*Tipos de casos"}
                                value={
                                    props.fields.casesTypes.data.map(option => {
                                        if (Number.isInteger(props.form.caseTypeId) &&
                                            props.form.caseTypeId.toString().length > 0 &&
                                            option.value == props.form.caseTypeId) {
                                            return option
                                        }
                                    })}
                                requiredValidation={{
                                    condition: !props.validators.caseTypeId.validFlags.required && props.submitTryWithErrors,
                                    msg: requiredFieldMsg
                                }}
                                disabled={props.disabled}
                                onChange={props.fields.casesTypes.change}
                                divClasses={"marginlr0 field marginb05"}
                            />

                            <TextInputCustom
                                textLabel={"ID Paciente Invisalign"}
                                nameInput={"patientId"}
                                typeInput={"number"}
                                required={false}
                                pattern={/^[0-9]+$/}
                                value={props.form.patientId || ""}
                                disabled={props.disabled}
                                formatValidation={{
                                    condition: !props.validators.patientId.validFlags.regex &&
                                        !props.validators.patientId.properties.pristine,
                                    msg: numericField
                                }}
                                evOnChange={props.handleInputChange}
                                cssClassesGroup={"marginlr0 marginb05"}
                                cssClassesInput={"field"}
                            />

                            <TextInputCustom
                                textLabel={"*Nombre Paciente Invisalign"}
                                nameInput={"patientName"}
                                typeInput={"text"}
                                required={false}
                                value={props.form.patientName ? props.form.patientName : ""}
                                disabled={props.disabled}
                                lengthValidation={{
                                    condition: !props.validators.patientName.validFlags.maxLen &&
                                        !props.validators.patientName.properties.pristine,
                                    msg: charLimit50

                                }}
                                evOnChange={props.handleInputChange}
                                cssClassesGroup={"marginlr0 marginb05"}
                                cssClassesInput={"field"}
                            />

                            <div className="form-group marginlr0">
                                <label>*Objetivo</label>
                                <textarea
                                    name="objective"
                                    className="m-0 w-100 field"
                                    onChange={props.handleInputChange}
                                    value={props.form.objective ? props.form.objective : ""}
                                    disabled={props.disabled}
                                ></textarea>
                                {!props.validators.objective.validFlags.required &&
                                    (props.submitTryWithErrors || !props.validators.objective.properties.pristine) ?
                                    <ErrorText msg={requiredFieldMsg} /> :
                                    null
                                }
                                {!props.validators.objective.validFlags.maxLen &&
                                    !props.validators.objective.properties.pristine ?
                                    <ErrorText msg={charLimit10000} /> :
                                    null
                                }
                            </div>

                            <div className="row form-group marginlr0">
                                <label className="paddinglr0">Diagnóstico</label>
                                <hr className={CaseFormCSS.separator}></hr>
                                {/* <div style={{ border: .5, borderRadius: 10, borderStyle: 'solid', padding:5 }}> */}
                                {props.fields.diagnosis.data.map((elem, index) => {
                                    return (
                                        <div key={index} className="flex-xleft align-items gy-1 col-6 paddinglr0">
                                            <input
                                                className="marginr025"
                                                id={`diagnosis${elem.value}`}
                                                name="diagnosis"
                                                value={elem.value}
                                                type="checkbox"
                                                onChange={props.handleDiagnosis}
                                                disabled={props.disabled}
                                                checked={diagnosis ?
                                                    diagnosis.map((diag, index) => {
                                                        if (elem.value === diag.id &&
                                                            diag.value === true) {
                                                            return diagnosis[index].value
                                                        }
                                                    })[elem.value - 1] :
                                                    false}
                                            />
                                            <label
                                                htmlFor={`diagnosis${elem.value}`}>{elem.label}
                                            </label>
                                        </div>
                                    )
                                })}
                                <div className="row">
                                    <div className="col-12 paddinglr0">
                                        <input
                                            name="otherDiagnosisCheck"
                                            type="checkbox"
                                            id="otherDiagnsosisCheck"
                                            value="otherDiagnsosisCheck"
                                            onChange={props.handleDiagnosis}
                                            disabled={props.disabled}
                                            checked={diagnosis ?
                                                diagnosis.map((diag, index) => {
                                                    if (diagnosis.length - 1 === index &&
                                                        diagnosis[index].id === "otherDiagnsosisCheck") {
                                                        return diagnosis[index].used
                                                    }
                                                })[diagnosis.length - 1] : null}
                                        />
                                        <label htmlFor="otherDiagnsosisCheck"
                                            className={CaseFormCSS.customInput}>Otro</label>
                                        {
                                            typeof diagnosis != "undefined" &&
                                                diagnosis[diagnosis.length - 1].id === "otherDiagnsosisCheck" &&
                                                diagnosis[diagnosis.length - 1].used === true ?
                                                <TextInputCustom
                                                    cssClassesGroup={`${CaseFormCSS.customGroup} marginb05`}
                                                    cssClassesInput={`${CaseFormCSS.customInput} field`}
                                                    nameInput={"otherDiagnosis"}
                                                    typeInput={"text"}
                                                    required={false}
                                                    disabled={props.disabled}
                                                    value={typeof diagnosis != "undefined" ?
                                                        diagnosis.map((diag, index) => {
                                                            if (diagnosis.length - 1 === index &&
                                                                diagnosis[index].id === "otherDiagnsosisCheck") {
                                                                return diagnosis[index].value
                                                            }
                                                        })[diagnosis.length - 1] : null}
                                                    evOnChange={props.handleInputChange}
                                                /> :
                                                null}
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="d-flex">
                                    <CustomToggleFilter filterName={"lopd"}
                                        styleCheck={{ margin: 0 }}
                                        value={props.lopdCheck}
                                        event={props.handleInputChange}
                                        disabled={!props.isNew}
                                        checked={props.lopdCheck} style={{ margin: 0 }} />
                                    <button type="button" className="btn btn-link text-justify"
                                        data-bs-toggle="modal" data-bs-target="#modalLopdCases"
                                        style={{ fontSize: 14, padding: 0 }}
                                    >
                                        * Marcando esta casilla se acepta la politica de privacidad
                                    </button>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            {
                                !props.caseCode ?
                                    <ButtonSave
                                        textBtn={"Guardar"}
                                        handleSubmit={props.handleSubmit}
                                        cssClasses={"marginlr0"}
                                    /> :
                                    !props.disabled ?
                                        <ButtonSave
                                            textBtn={"Guardar"}
                                            cssClasses={"marginlr0"}
                                            handleSubmit={props.userData.idRole === 1 ? props.handleUpdate :
                                                props.userData.idRole === 3 ? props.handleUpdateUser : null}
                                            icoClasses={"fas fa-save"}
                                        />
                                        : null
                            }
                        </fieldset>
                    </div>
                    {ViewTabDoubtsAndObservations()}
                    {viewTabProfile()}
                </div>
            </div>

        ]} />
    ])
};

export { CasesFormUI }