//#region IMPORTS
import React from 'react'
import TextInputCustom from '../Tools/TextInputCustom'
import ButtonSave from '../Tools/ButtonSave'
import { ErrorText } from '../Tools/ErrorText'
import { DisplayImageUser } from '../Tools/DisplayImageUser'
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle'
import { ContentGeneral } from '../Tools/ContentGeneral'
//#endregion

const ProfileScreenUI = ({ userForm, disabled, handleImageChange, changeEdit, handleInputChange,
    cancelChanges, handleUpdateUser, profileImage, userTitle, userData }) => {

    //#region 'VARIABLES'
    const passComplexRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&~€*-]).{8,}$/
    const passComplexMsg = `La contraseña debe:
    - Ser como mínimo de 8 caracteres
    - Al menos un carácter en mayúscula
    - Al menos un carácter en minúscula
    - Al menos un carácter especial (#, ?, !, @, $, %, ^, &, ~, €, *, -)`
    //#endregion

    //#region 'STRUCTURES'
    const HeadProfile = () => (
        <div className="d-flex blackAndWhite p-2" style={{ borderRadius: 0 }}>
            <div className="d-flex align-items-center">
                <div className='text-center clickable' style={{ alignSelf: 'baseline', background: 'white', color: 'black', borderRadius: 25, width: 25 }}>
                    <label htmlFor="profilePicture">
                        <i className="fas fa-paperclip clickable"></i>
                    </label>
                    <input id="profilePicture" accept=".png,.jpg,.jpeg"
                        className="none" type="file" onChange={handleImageChange}
                    />
                </div>
                <div>
                    <DisplayImageUser userBlob={profileImage} styImg={{ width: 90, marginRight: 20 }} />
                </div>
                <div style={{ marginLeft: 5 }}>
                    <h4>{userTitle}</h4>
                </div>
            </div>
        </div>
    )

    //#endregion

    return ([
        <HeadTitle title={"Perfil"} />,
        <ContentGeneral content={[
            <div className="width80 margin-auto" style={{ background: "white" }}>
                <HeadProfile />
                <form className='p-4'>
                    <div className="row">
                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Nombre"}
                                nameInput={"name"}
                                typeInput={"text"}
                                required={true}
                                value={userForm.name}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                        </div>

                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Apellidos"}
                                nameInput={"lastname"}
                                typeInput={"text"}
                                required={true}
                                value={userForm.lastname}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Email"}
                                nameInput={"email"}
                                typeInput={"text"}
                                required={true}
                                value={userForm.email}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                        </div>
                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Teléfono"}
                                nameInput={"phone"}
                                typeInput={"text"}
                                required={true}
                                value={userForm.phone}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Contraseña"}
                                nameInput={"password"}
                                typeInput={"password"}
                                required={true}
                                placeholder={"************"}
                                value={userForm.password}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                            {userForm.password && !passComplexRegex.test(userForm.password) && (
                                <ErrorText msg={passComplexMsg} />
                            )}
                        </div>
                        <div className="col-sm col-md-6">
                            <TextInputCustom
                                textLabel={"Confirmar contraseña"}
                                nameInput={"confirmPassword"}
                                typeInput={"password"}
                                required={true}
                                placeholder={"************"}
                                value={userForm.confirmPassword}
                                disabled={disabled}
                                evOnChange={handleInputChange}
                                cssClassesGroup={"marginlr0"}
                            />
                            {userForm.password !== userForm.confirmPassword && (
                                <ErrorText msg={"Las contraseñas no coinciden"} />
                            )}
                            {userForm.confirmPassword && !passComplexRegex.test(userForm.confirmPassword) && (
                                <ErrorText msg={passComplexMsg} />
                            )}
                        </div>
                    </div>

                    {userData.idRole === 3 && (
                        <div className="row">
                            <div className="col-sm col-md-6">
                                <TextInputCustom required
                                    textLabel={"Usuario Invisalign"}
                                    nameInput={"userInvisalign"}
                                    typeInput={"text"}
                                    value={userForm.userInvisalign}
                                    disabled={disabled}
                                    evOnChange={handleInputChange}
                                    cssClassesGroup={"marginlr0"}
                                />
                            </div>
                            <div className="col-sm col-md-6">
                                <TextInputCustom required
                                    textLabel={"Contraseña Invisalign"}
                                    nameInput={"passwordInvisalign"}
                                    typeInput={"password"}
                                    placeholder={"************"}
                                    disabled={disabled}
                                    value={userForm.passwordInvisalign}
                                    evOnChange={handleInputChange}
                                    cssClassesGroup={"marginlr0"}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <label>Especialidad dental</label>
                        <textarea
                            name="descriptionNotes"
                            maxLength="500"
                            className='m-0 w-100'
                            disabled={disabled}
                            onChange={handleInputChange}
                            value={userForm.descriptionNotes}>
                        </textarea>
                    </div>
                    <div className="margint1">
                        <ButtonSave
                            textBtn={disabled ? "Editar" : "Cancelar"}
                            handleSubmit={disabled ? changeEdit : () => {
                                changeEdit()
                                cancelChanges()
                            }}
                            cssClasses={"inline-block marginlr0"}
                        />

                        {!disabled && (
                            <ButtonSave
                                textBtn={"Guardar"}
                                cssClasses={"inline-block"}
                                handleSubmit={handleUpdateUser}
                            />)
                        }
                    </div>
                </form>
            </div>
        ]} />
    ])
}

export { ProfileScreenUI }