import { getAudioResponseByIdResponse } from "../Services/AudioResponse/getAudioResponseByIdResponse"
import { postAudioResponse } from "../Services/AudioResponse/postAudioResponse"
import { deleteAudioResponse } from "../Services/AudioResponse/deleteAudioResponse"

const useAudioResponse = () => {

    const getAudioResponse = async (id_response) => {
        let data = await getAudioResponseByIdResponse(id_response)
        data.forEach(element => {
            element.visible = false
        });
        return data
    }

    const insertAudioResponse = async (data) => {
        return await postAudioResponse(data)
    }

    const removeAudioResponse = async (id) => {
        return await deleteAudioResponse(id)
    }

    return {
        getAudioResponse,
        insertAudioResponse,
        removeAudioResponse
    }
}

export { useAudioResponse }