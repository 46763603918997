import { MessageService } from '../../Services/message_service'
import RouterCtrl from '../../routes/Routes'

export const deleteUserByAdmin = (idUser) => new Promise((resolve, reject) => {
    let messageService = new MessageService()

    let xhr = new XMLHttpRequest()
    xhr.open("DELETE", RouterCtrl.deleteUser)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
    xhr.withCredentials = true
    xhr.send(JSON.stringify({idUser}))

    xhr.onload = () => {
        try {
            let res = JSON.parse(xhr.response)
            messageService.printToast(res.scope, res.type, res.code)
            if(res.type === "success") {
                resolve(true)
            } else {
                resolve(false)
            }
        } catch (e) {
            messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    }

    xhr.onerror = () => {
        messageService.printToast("Generics", "error", 0)
        resolve(false)
    }
})