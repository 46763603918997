import { useState, useEffect } from 'react'
import { getDiagnosis } from '../Services/Diagnosis/getDiagnosis'
import { postDiagnosis } from '../Services/Diagnosis/postDiagnosis'
import { getDiagnosisCase } from '../Services/Diagnosis/getDiagnosisCase'
import { getOtherDiagnosisCase } from '../Services/Diagnosis/getOtherDiagnosisCase'
import { putDiagnosis } from '../Services/Diagnosis/putDiagnosis'

export const useDiagnosis = () => {
    const [diagnosis, setDiagnosis] = useState([])
    const [diagnosisObj, setDiagnosisObj] = useState([])


    useEffect(() => {
        getDiagnosis().then(item => {
            const doc = []
            item.forEach(element => {
                doc.push({ value: element.id, label: element.Name })
            })
            setDiagnosis(doc)

            let obj = doc.map(elem => {
                return { id: elem.value, value: false }
            })
            obj[obj.length] = {id: "otherDiagnsosisCheck", used: false, value: ""}

            setDiagnosisObj(obj)
        })

    }, [])
    const retrieveDiagnosis = async () => {
        let item = await getDiagnosis()
        if (item) {
            const doc = []
            item.forEach(element => {
                doc.push({ value: element.id, label: element.Name })
            })
            setDiagnosis(doc)

            let obj = doc.map(elem => {
                return { id: elem.value, value: false }
            })
            obj[obj.length] = { id: "otherDiagnsosisCheck", used: false, value: "" }
            return obj
        } else {
            return []
        }
    }

    const createDiagnosis = async (data, id) => {
        return await postDiagnosis(data, id)
    }

    const updateDiagnosis = async (idCase, diagnosis) => {
        return await putDiagnosis(idCase, diagnosis)
    }

    const retrieveDiagnosisCase = async (id) => {
        let otherDiagnosis = await getOtherDiagnosisCase(id)
        let diagnosis = await getDiagnosisCase(id)

        if (typeof otherDiagnosis[0] != "undefined") {
            diagnosis.push(otherDiagnosis[0])
        }

        return diagnosis
    }

    const retrieveOtherDiagnosis = async (id) => {
        return await getOtherDiagnosisCase(id)
    }

    return { diagnosis, diagnosisObj, retrieveDiagnosis, createDiagnosis, retrieveDiagnosisCase, retrieveOtherDiagnosis, updateDiagnosis }
}