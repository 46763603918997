import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const getResponseRatingByUser = (id_user, id_role) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("GET", RouterCtrl.ratings)
        xhr.setRequestHeader("data", JSON.stringify({ id_user, id_role }))
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                if (res) {
                    resolve(res)
                } else {
                    resolve(false)
                }
            } catch (e) {
                resolve(false)
            }
        }

        xhr.onerror = () => {
            //messageService.printToast("Generics", "error", 0)
            resolve(false)
        }
    })
}

export { getResponseRatingByUser }