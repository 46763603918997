import React, { useContext, useState, useEffect, useRef, useLayoutEffect } from 'react'
import FaqsUI from './FaqsScreenUI'
import { useHistory } from 'react-router-dom'
import { useFaqs } from '../../../Hooks/useFaqs'
import { useFaqsTypes } from '../../../Hooks/useFaqsTypes'
import { UserContext } from '../../../Contexts/User/UserContext'
import { MessageService } from '../../../Services/message_service'
import { useFiles } from '../../../Hooks/useFiles'
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useShowView } from '../../../Hooks/useShowView'

const FaqsScreen = () => {

  //#region 'VARIABLES'
  let messageService = new MessageService()
  let history = useHistory()
  const cbfilterObj = [
    { value: 1, name: 'Pregunta' },
    { value: 2, name: 'Respuesta' }
  ]
  const obFilter = {
    valueDp: '1',
    textFilter: '',
    typeFilter: -1
  }
  const obModalContent = {
    id: "",
    idCreator: "",
    question: "",
    response: "",
    position: "",
    visibility: "",
    idFaqsType: ""
  }
  const objState = {
    editorState: '',
  }
  //#endregion

  //#region 'USE REF'
  const customFileInputRef = useRef()
  const modalRef = useRef()
  //#endregion

  //#region 'USE CONTEXT'
  const userContext = useContext(UserContext)
  //#endregion

  //#region 'CUSTOMIZED USE STATE'
  const { uploadFiles, getFilesInfo, removeFile,
    download, downloadFileByPath } = useFiles()
  const { createFaq, updateFaq, retrieveFaqs, removeFaq } = useFaqs()
  const { faqsTypes } = useFaqsTypes()
  const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
  //#endregion

  //#region 'USE STATES'
  const [fullscreenImageBlob, setFullscreenImageBlob] = useState("")
  const [faqFiles, setFaqFiles] = useState([])
  const [filter, setFilter] = useState(obFilter)
  const [modalContent, setModalContent] = useState(obModalContent)
  const [action, setAction] = useState("create")
  const [buttonText, setButtonText] = useState("Crear")
  const [modalTitle, setModalTitle] = useState("Faq")
  const [disabled, setDisabled] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [filteredFaqs, setFilteredFaqs] = useState([])
  const [stateEditor, setstateEditor] = useState(objState)
  //#endregion

  //#region 'FUNCTIONS'
  const onEditorStateChange = (editorState) => {
    setstateEditor({
      editorState: editorState,
    });
    const html = draftToHtml(convertToRaw(stateEditor.editorState.getCurrentContent()))
    setModalContent({ ...modalContent, response: html })
  };

  const propertiesEditor = () => {
    const contentBlock = htmlToDraft('');
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setstateEditor({ editorState: editorState });
  }

  const handleSelectChange = e => {
    setModalContent({ ...modalContent, idFaqsType: e.value })
  }

  const handleInputChange = e => {
    let name = e.target.name
    let value = e.target.value

    if (name !== "visibility") {
      setModalContent({ ...modalContent, [name]: value })
    } else {
      setModalContent({ ...modalContent, [name]: e.target.checked })
    }
  }

  const UpdateStateEditor = (html) => {
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    //setstateEditor({ editorState: editorState });
    return editorState
  }

  const openModal = async (faq, disableValue, modalTitleValue, method, buttonText) => {
    setFaqFiles([])
    setAction(method)
    setButtonText(buttonText)
    setstateEditor({ editorState: UpdateStateEditor(faq.Response || "") });
    setModalContent({
      id: faq.id || "",
      idCreator: faq.id_creator || "",
      question: faq.Questions || "",
      response: faq.Response || "",
      position: faq.faqs_order || "",
      visibility: faq.Visible?.data[0] || "",
      idFaqsType: faq.id_faqs_types || ""
    })
    setModalTitle(modalTitleValue)
    setDisabled(disableValue)
    if (faq.id) {
      setFaqFiles(await getFilesInfo("faq", faq.id))
    }
  }

  const runAction = () => {
    switch (action) {
      case "create":
        handleCreate()
        break
      case "update":
        handleUpdate()
        break
      case "delete":
        handleDelete()
        break
      default:
        break;
    }
  }

  const filterFaqsType = (e) => {
    let filters = { ...filter, typeFilter: e.value };
    doFilters(filters)
  }

  const filterFaqs = (e, updatedFaqs) => {
    try {
      let name = e.target.name
      let value = e.target.value.toLowerCase()

      let filters = {}
      if (name === "valueDp") {
        filters = { ...filter, [name]: value }
      } else if (name === "textFilter") {
        filters = { ...filter, [name]: value }
      }

      doFilters(filters);
    } catch (e) {
      setFilteredFaqs(faqs)
    }
  }

  const doFilters = (filters = filter, tmpList) => {
    setFilter(filters)
    let listToFilter = Array.isArray(tmpList) ? tmpList : faqs;
    listToFilter = listToFilter.filter(e => e.id_faqs_types === filters.typeFilter || filters.typeFilter === -1)
    switch (filters.valueDp) {
      case '1':
        listToFilter = listToFilter.filter(faq => faq.Questions.toLowerCase().includes(filters.textFilter))
        break
      case '2':
        listToFilter = listToFilter.filter(faq => faq.Response.toLowerCase().includes(filters.textFilter))
        break
    }
    setFilteredFaqs(listToFilter)
  }

  const checkEmptyFields = () => {
    let emptyFlag = true
    let fields = Object.keys(modalContent)
    for (const field of fields) {
      if (!modalContent[field] &&
        !Number.isInteger(modalContent[field]) &&
        field !== "id" &&
        field !== "position" &&
        field !== "visibility" &&
        field !== "idCreator") {
        emptyFlag = false
      }
    }
    return emptyFlag
  }

  const getFaqs = async () => {
    let tmpFaqs = await retrieveFaqs()
    setFaqs(tmpFaqs)
    doFilters(filter, tmpFaqs)
  }

  const handleCreate = async () => {
    if (checkEmptyFields()) {
      let data = Object.assign({}, modalContent)
      delete data.id
      let result = await createFaq(data)
      if (Number.isInteger(result)) {
        await customFileInputRef.current.uploadInMemoryFiles(result)
        document.getElementById("closeFaqsForm").click()
        await getFaqs()
      }
    } else {
      messageService.printToast("Generics", "error", 1)
    }
  }

  const handleUpdate = async () => {
    if (checkEmptyFields()) {
      if (await updateFaq(modalContent)) {
        document.getElementById("closeFaqsForm").click()
        await getFaqs()
      }
    } else {
      messageService.printToast("Generics", "error", 1)
    }
  }

  const handleDelete = async () => {
    if (Number.isInteger(modalContent.id)) {
      if (await removeFaq(modalContent.id)) {
        document.getElementById("closeDeleteFaqs").click()
        await getFaqs()
      }
    } else {
      messageService.printToast("Faqs", "error", 2)
    }
  }

  const uploadAttachments = async (files, formName, entity) => {
    if (await uploadFiles(files, formName, entity)) {
      setFaqFiles(await getFilesInfo("faq", modalContent.id))
    }
  }

  const closeModal = () => {
    let mainLinkTab = document.getElementById("faqsModalMainTab")
    if (mainLinkTab) {
      mainLinkTab.click()
    }
  }

  const deleteAttachment = async (idFile) => {
    if (await removeFile("faq", idFile)) {
      setFaqFiles(await getFilesInfo("faq", modalContent.id))
    }
  }

  const downloadFaqAttachment = async (idFile, name, mimetype, extension) => {
    download("faq", idFile, name, mimetype, extension)
  }

  const downloadImage = async (path, mimetype) => {
    return await downloadFileByPath(path, mimetype)
  }
  //#endregion

  //#region 'USE LAYOUT EFFECT AND USE EFFECT'
  useLayoutEffect(() => {
    userContext.retrieveUserData().then(res => {
      if (res.idRole === 3) {
        history.push("/main-screen/cases")
      }
    })
  }, [])

  useEffect(() => {
    document.querySelector('.modal-backdrop')?.remove()
    propertiesEditor()
    userContext.retrieveUserData().then(async res => {
      if (res.idRole === 3) {
        history.push("/main-screen/cases")
      } else {
        let tmpFaqs = await retrieveFaqs()
        tmpFaqs.forEach(item => {
          item.responseHTML = UpdateStateEditor(item.Response || '')
          return item
        })
        setFaqs(tmpFaqs)
        setFilteredFaqs(tmpFaqs)
      }
    })
  }, [])
  //#endregion

  return (
    <FaqsUI
      filteredFaqs={filteredFaqs}
      cbfilterObj={cbfilterObj}
      faqsTypes={faqsTypes}
      views={{
        type: views,
        funcKanban: kanbanFunction,
        funcList: listFunction
      }}
      disabled={disabled}
      modalTitle={modalTitle}
      modalContent={modalContent}
      openModal={openModal}
      handleInputChange={handleInputChange}
      handleSelectChange={handleSelectChange}
      filterFaqs={filterFaqs}
      filterFaqsType={filterFaqsType}
      runAction={runAction}
      buttonText={buttonText}
      modalRef={modalRef}
      closeModal={closeModal}
      action={action}
      userData={userContext.userData}
      uploadAttachments={uploadAttachments}
      faqFiles={faqFiles}
      deleteAttachment={deleteAttachment}
      downloadFaqAttachment={downloadFaqAttachment}
      handleDelete={handleDelete}
      downloadImage={downloadImage}
      fullscreenImageBlob={fullscreenImageBlob}
      setFullscreenImageBlob={setFullscreenImageBlob}
      stateEditor={stateEditor}
      onEditorStateChange={onEditorStateChange}
      customFileInputRef={customFileInputRef}
    />
  )

}

export { FaqsScreen }
