import { useContext, useState, useEffect } from 'react'
import { getStudents } from '../Services/Students/GetStudents'
import { UserContext } from '../Contexts/User/UserContext'

const useStudents = () => {
    const userContext = useContext(UserContext)
    const [students, setStudents] = useState([])

    const getDataStudent = async () => {
        let userData = await userContext.retrieveUserData()
        if (userData.idRole == 1) {
            getStudents().then(item => {
                const doc = []
                item.forEach(element => {
                    doc.push({ value: element.id, label: `${element.Name} ${element.Lastname} - ${element.Vat}`, photo: element.Photo_Profile })
                })
                setStudents(doc)
            })
        } else {
            setStudents([])
        }
    }

    useEffect(() => {
        getDataStudent()
    }, [])

    return { students, setStudents }
}

export { useStudents }