import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const putResponse = (idResponse, idDoubt, response, idFaq) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("PUT", RouterCtrl.responses)
        xhr.withCredentials = true
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.send(JSON.stringify({ idResponse, idDoubt, response, idFaq }))

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)

                console.log(res)

                messageService.printToast(res.scope, 'success', 0)

                if (res.type === "success") {
                    resolve(true)
                } else {
                    resolve(false)
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 0)
                resolve(false)
            }
        }

    xhr.onerror = () => {
        messageService.printToast("Generics", "error", 0)
        resolve(false)
    }
    })
}

export { putResponse }