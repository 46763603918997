import React from 'react';
import { ContentGeneral } from '../Tools/ContentGeneral';
import { ContentItem } from '../Tools/ContentItem';
import CustomFilter from '../Tools/CustomFilter';
import { HeadTitle } from '../Tools/HeadTitle/HeadTitle';
import { ItemKanban } from '../Tools/Kanban/ItemKanban';
import { KanbanCard } from '../Tools/Kanban/KanbanCard';
import { ModalCasesPackDuration } from '../Tools/Modals/ModalCasesPackDuration';
import { TableList } from '../Tools/Table/TableList';
import { ViewData } from '../Tools/ViewsData/ViewData';
import { ViewType } from '../Tools/ViewTypes/ViewType';

const CasesPackDurationScreenUI = ({ casesPacksDuration, views, filters, selection, events }) => {

    //#region 'VARIABLES'
    const obFields = {
        titleForm: "Duración de packs",
        name: "Nombre",
        duration: "Duración",
    }
    //#endregion

    //#region 'USE REF'

    //#endregion

    //#region 'USE CONTEXT'

    //#endregion

    //#region 'USE STATES CUSTOMIZED'

    //#endregion

    //#region 'USE STATES'

    //#endregion

    //#region 'FUNCTIONS'

    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'

    //#endregion


    //#region 'STRUCTURE'

    //#region 'VIEW KANBAN'
    const ViewKanban = ({ item, index }) => (
        <KanbanCard
            index={index} title={item.name}
            classHead={`ps-3 pe-3 pt-2 blackAndWhite clickable`}
            dataTarget={"#packsDurationModal"}
            onclickHead={() => events.evOnClickItem(item)}
            cardBody={[
                <ContentItem itemBody={[
                    <ItemKanban classCol={'col-6'} label={obFields.duration} value={item.duration} />,
                ]} />
            ]}
        />
    )
    //#endregion

    //#region 'VIEW LIST'
    const HeadList = () => (
        <tr>
            <th></th>
            <th>{obFields.name}</th>
            <th style={{ textAlign: 'center' }}>{obFields.duration}</th>
        </tr>
    )

    const ContentList = ({ item, index }) => (
        <tr key={item.id} className='clickable' onClick={() => events.evOnClickItem(item)}
            data-bs-toggle="modal" data-bs-target="#packsDurationModal">
            <th scope="row">{index + 1}</th>
            <td>{item.name}</td>
            <td style={{ width: 100, minWidth: 100, textAlign: 'center' }}>{item.duration}</td>
        </tr>
    )

    const ViewList = () => (
        <TableList headList={<HeadList />} bodyList={ArrayMaping} />
    )
    //#endregion

    const ArrayMaping = casesPacksDuration.data.filter(filters.onFilter).map((item, index) => (
        views.type.kanban ? <ViewKanban item={item} index={index} /> : <ContentList item={item} index={index} />
    ))
    //#endregion

    return ([
        <HeadTitle title={obFields.titleForm} />,
        <ContentGeneral content={[
            <ViewType showCreateBtn={true} dataTarget={"#packsDurationModal"} views={views.type}
                kanbanFunction={views.funcKanban} listFunction={views.funcList}
                handleSubmit={() => selection.set({})} textButton={"Crear"} />,
            <CustomFilter keyValue={"searchDataPacks"} nameSelect={"valueDp"} nameInput={"textFilter"}
                options={filters.dpFieldsFilters}
                onChange={filters.onChange}
                valueSelect={filters.selFilter.valueDp}
                valueInput={filters.selFilter.textFilter}
                cssClasses={"paddinglr0"} />,
            <ViewData kanban={< ContentItem itemBody={ArrayMaping} />}
                list={<ViewList />}
                viewType={views.type.kanban} />,
        ]} />,
        <ModalCasesPackDuration setData={casesPacksDuration.set} selection={selection} />
    ])
}

export { CasesPackDurationScreenUI }