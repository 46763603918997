import { useState, useEffect } from "react"
import { getLOPD } from "../Services/LOPD/getLOPD"
import { getLOPDById } from "../Services/LOPD/getLOPDById"
import { postLOPD } from "../Services/LOPD/postLOPD"
import { putLOPD } from "../Services/LOPD/putLOPD"

const useLOPD = () => {

    const [data, setData] = useState([])

    const getDataLOPD = async () => {
        return await getLOPD()
    }

    const getDataLOPDById = async (id) => {
        return await getLOPDById(id)
    }

    const insertLOPD = async (name, content) => {
        return await postLOPD(name, content)
    }

    const updateLOPD = async (name, content, idLopd) => {
        return await putLOPD(name, content, idLopd)
    }

    const funcGetLOPD = async () => {
        setData(await getDataLOPD())
    }

    useEffect(() => {
        funcGetLOPD()
    }, [])


    return { data, setData, getDataLOPD, insertLOPD, updateLOPD, getDataLOPDById }
}

export { useLOPD }