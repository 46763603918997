import { MessageService } from "../message_service";
import RouterCtrl from '../../routes/Routes'

export const getCasesEditions = () => new Promise((resolve, reject) => {
    let messageService = new MessageService()

    let xhr = new XMLHttpRequest()
    xhr.open("GET", RouterCtrl.getCasesEditions)
    xhr.withCredentials = true
    xhr.send()

    xhr.onload = () => {
        try {
            let res = JSON.parse(xhr.response)
            if (Array.isArray(res)) {
                resolve(res)
            } else {
                messageService.printToast(res.scope, res.type, res.code)
                resolve([])
            }
        } catch (e) {
            messageService.printToast("Generics", "error", 9)
            resolve([])
        }
    }

    xhr.onerror = () => {
        messageService.printToast("Generics", "error", 9)
        resolve([])
    }
})