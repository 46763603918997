const headerColors = {
    1: "openedHeader",
    2: "assignedHeader",
    3: "inProgressheader",
    4: "closedHeader",
    5: "pauseHeader"
}

const legacyHeaderColors = {
    1: {
        backgroundColor: "rgb(153 208 247)"
    },
    2: {
        backgroundColor: "rgb(136 233 139)"
    },
    3: {
        backgroundColor: "rgb(255 155 85)"
        // backgroundColor: "#D87846"
    },
    4: {
        backgroundColor: "rgb(177 178 180)"
    },
    5: {
        backgroundColor: "rgb(125 51 143)",
    }
}

const helpers = {
    getFormattedDate: (e) => `${e.getDate() < 10 ? '0' + (e.getDate()+1) : e.getDate() }-${e.getMonth() < 10 ? '0' + (e.getMonth()+1) : e.getMonth()}-${e.getFullYear()}`
}

export {
    headerColors,
    legacyHeaderColors,
    helpers
}