import React, { useEffect, useState } from 'react';
import { useLOPD } from '../../../Hooks/useLOPD';
import SimpleBootstrapModal from '../SimpleBootstrapModal';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg'

const ModalLopd = ({ idLopd, dataTarget }) => {

    //#region 'USE STATES CUSTOMIZED'
    const { getDataLOPDById } = useLOPD()
    const [data, setData] = useState([])
    //#endregion

    //#region 'FUNCTIONS'
    const UpdateStateEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
    }

    const getLOPD = async () => {
        let result = await getDataLOPDById(idLopd)
        if (result) {
            result.contentHtml = UpdateStateEditor(result.content || '')
        }
        setData(result)
    }
    //#endregion

    //#region 'USE LAYOUT EFFECT AND USE EFFECT'
    useEffect(() => {
        if (idLopd) {
            getLOPD()
        }
    }, [])
    //#endregion

    return <SimpleBootstrapModal
        id={dataTarget} title={"Politica de privacidad"}
        closeButtonId={"closeModalLopd"} fullScreen={true}
        cancelButtonMessage="Aceptar">
        <div className='p-4'>
            <Editor spellCheck toolbarHidden={true} readOnly={true} editorState={data?.contentHtml} />
        </div>
    </SimpleBootstrapModal>
}
export { ModalLopd }