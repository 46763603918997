import { getDoubtsTypes } from '../Services/DoubtsTypes/getDoubtsTypes'

const useDoubtsTypes = () => {

    const retrieveDoubtsTypes = async () => {
        return await getDoubtsTypes()
    }

    return { retrieveDoubtsTypes }
}

export { useDoubtsTypes }