import RouterCtrl from '../../../routes/Routes'

const checkToken = () => {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest()
        xhr.open("GET", RouterCtrl.checkToken)
        xhr.withCredentials = true
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (!res.type) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            } catch (e) {
                resolve(false)
            }
        }

        xhr.onerror = () => {
            resolve(false)
        }
    })
}

export { checkToken }