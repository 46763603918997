import React from 'react'
import ButtonSave from './ButtonSave'
import { useSession } from '../../Hooks/useSession'

export default ({ corpLogo, msg }) => {
    const { finishSession } = useSession()

    const divStyles = {
        position: "absolute",
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        textAlign: "center"
    }

    const innerDivStyles = {
        marginTop: "1rem",
    }

    const msgStyles = {
        margin: "0 auto",
        marginBottom: "1rem",
        textAlign: "justify",
        width: "50%"
    }

    return (
        <div style={divStyles}>
            <div style={innerDivStyles}>
                <img src={corpLogo} />
                <p className="activationText"
                    style={msgStyles}>{msg}</p>
                <ButtonSave
                    textBtn={"Salir"}
                    handleSubmit={finishSession}
                />
            </div>
        </div>
    )
}