//#region 'IMPORTS'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import ButtonSave from '../../Tools/ButtonSave'
import TextInputCustom from '../../Tools/TextInputCustom'
import ButtonOutline from '../ButtonOutline/ButtonOutline'
//#endregion

const UserEdition = ({ index, user_edition, updateMethod, removeMethod }) => {
    /* console.log("🚀 ~ file: UserEdition.js:10 ~ index:", index)
    console.log("🚀 ~ file: UserEdition.js:10 ~ user_edition:", user_edition.Name)
    console.log(moment(user_edition.Start_date).format('DD-MM-YYYY HH:mm:ss')) */
    useEffect(() => {
        console.log(user_edition.Name)
        setLimitCases(user_edition.Limit_cases)
    }, [])

    const [limitCases, setLimitCases] = useState(user_edition.Limit_cases)
    const [disabled, setDisabled] = useState((moment().isAfter(user_edition.End_date) || (user_edition.consumed_cases || 0) >= limitCases || index > 0))

    const handleInputChange = e => {
        setLimitCases(Number.parseInt(e.target.value))
    }
    return (
        <tr>
            <th >{index + 1}</th>
            <td><p style={{ margin: 0 }}>{user_edition.Name}</p></td>
            <td style={{ textAlign: "center" }}><p style={{ margin: 0 }}>{(user_edition.consumed_cases || 0)}</p></td>
            <td style={{ textAlign: "center" }}>
                <TextInputCustom
                    nameInput={"limitCases"}
                    typeInput={"text"}
                    required={true}
                    value={limitCases}
                    disabled={moment().isAfter(user_edition.End_date) || disabled}
                    pattern={[0 - 9]}
                    evOnChange={handleInputChange}
                    cssClassesGroup={"margin0"}
                    minimumValue={1}
                />
            </td>
            <td style={{ textAlign: "center" }}>
                {index === 0 && moment().isBefore(user_edition.End_date) && (
                    <ButtonSave
                        withContent={false}
                        icoClasses={"fas fa-save"}
                        cssClasses={"float-left"}
                        handleSubmit={() => updateMethod(user_edition.id_editions, limitCases)}
                        buttonStyles={{
                            background: "#5BC5F1",
                            color: "#fff",
                            border: 0,
                            fontWeight: 450,
                            marginRight: 5
                        }}
                    />)}
                {index === 0 && moment().isBefore(user_edition.End_date) && (
                    <ButtonOutline
                        withContent={false}
                        icoClasses={disabled ? "fa fa-lock" : "fa fa-unlock"}
                        cssClasses={"float-left"}
                        handleSubmit={() => setDisabled(!disabled)}
                    />)}
                {index === 0 && moment().isBefore(user_edition.End_date) && (
                    <ButtonSave
                        withContent={false}
                        icoClasses={"fas fa-trash-alt"}
                        cssClasses={"float-left"}
                        buttonClasses={"light-red"}
                        handleSubmit={() => removeMethod(user_edition.id_editions)}
                        buttonStyles={{
                            //background: "#5BC5F1",
                            color: "#fff",
                            border: 0,
                            fontWeight: 450,
                            marginLeft: 5
                        }}
                    />)}
            </td>
            <td>
                {
                    moment().isAfter(user_edition.End_date) 
                    ? <p style={{ margin: 0 }}>CADUCADO</p>
                    : (user_edition.consumed_cases || 0) >= limitCases 
                        ? <p style={{ margin: 0 }}>CONSUMIDO</p>
                        : index > 0 
                            ? <p>DESACTIVADO</p>
                            : <p style={{ margin: 0 }}>ACTIVO</p>
                }
            </td>
        </tr>
    )
}


export default UserEdition