import { MessageService } from '../message_service'
import RouterCtrl from '../../routes/Routes'

const retrieveFile = (fileName) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()

        let xhr = new XMLHttpRequest()
        xhr.open("POST", RouterCtrl.retrieveFile)
        xhr.withCredentials = true
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')
        xhr.responseType = "blob"
        xhr.send(JSON.stringify({ fileName: fileName }))

        xhr.onload = () => {
            try {
                let fileBlob = xhr.response
                //let file = new File([fileBlob], "name");
    
                let fr = new FileReader()
                fr.readAsArrayBuffer(fileBlob)
            } catch (e) {
                messageService.printToast("Files", "error", 3)
                resolve(false)
            }
        }

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve(false)
        }
    })
}

export { retrieveFile }