import { MessageService } from '../message_service'
import RouteCtrl from '../../routes/Routes'

const getFilterTeacherStats = (dateIni, dateEnd) => {
    return new Promise((resolve, reject) => {
        let messageService = new MessageService()
        let xhr = new XMLHttpRequest()
        xhr.open("GET", RouteCtrl.filterTeachersStats)
        xhr.withCredentials = true
        xhr.setRequestHeader("dateIni", dateIni)
        xhr.setRequestHeader("dateEnd", dateEnd)
        xhr.send()

        xhr.onload = () => {
            try {
                let res = JSON.parse(xhr.response)
                if (Array.isArray(res)) {
                    resolve(res)
                } else if (res.type === "error") {
                    messageService.printToast(res.scope, res.type, res.code)
                    resolve([])
                } else {
                    messageService.printToast("Generics", "error", 9)
                    resolve([])
                }
            } catch (e) {
                messageService.printToast("Generics", "error", 9)
                resolve([])
            }
        }
        

        xhr.onerror = () => {
            messageService.printToast("Generics", "error", 9)
            resolve([])
        }
    })
}

export { getFilterTeacherStats }