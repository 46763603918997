
import { toast } from 'react-toastify'
class MessageService {

    constructor() {
        this.RegisterAndLogin = {}

        this.RegisterAndLogin.Errors = {
            0: "Rellena los campos obligatorios",
            1: "Credenciales incorrectas",
            2: "Elige una imagen con formato aceptado (jpg o png)",
            3: "Datos incorrectos",
            4: "La contraseña no cumple con los requisitos de complejidad",
            5: "Tiempo de espera agotado",
            6: "El tamaño máximo de subida es de 16 MB",
            7: "El formato de DNI/NIF no es correcto",
            8: "Rellena los campos obligatorios (*)",
            9: "Error interno: contacta con el administrador",
            10: "Datos incorrectos",
            11: "DNI/NIF ya usado",
            12: "Email ya usado",
            13: "Token invalido",
            14: "Esta acción requiere iniciar sesión",
            15: "Permisos insuficientes",
            16: "Email no encontrado",
            17: "Usuario no activado",
            18: "Usuario no habilitado",
            19: "No hay ninguna cuenta con los datos facilitados"
        }

        this.RegisterAndLogin.Warnings = {
            0: "Token expirado",
            2: "Este usuario ya ha sido activado previamente",
            3: "Email de confirmación aún vigente",
        }

        this.RegisterAndLogin.Success = {
            0: "Usuario creado satisfactoriamente",
            1: "Usuario activado satisfactoriamente",
            2: "Solicitud de restablecimiento de contraseña completado",
            3: "Contraseña actualizada correctamente",
            4: "Sesión iniciada correctamente",
            5: "Nuevo correo de confirmación enviado",
            6: "Usuario actualizado correctamente"
        }

        this.Cases = {}

        this.Cases.Errors = {
            0: "No se pudo asignar el caso",
            1: "La solicitud de revisión ya ha sido envíada",
            2: "Este caso ya esta siendo revisado",
            3: "Este caso no tiene la revisión activada",
            4: "No se pudo crear el caso",
            5: "No se ha encontrado el caso",
            6: "No se pudo actualizar el caso",
            7: "Límite de casos alcanzado en esta edición"
        }

        this.Cases.Warnings = {}

        this.Cases.Success = {
            0: "Caso actualizado",
            1: "Caso creado",
            2: "Caso eliminado",
            3: "Caso asignado",
            4: "Solicitud de revisión de caso envíada",
            5: "Revisión de caso cancelada",
            6: "Solicitud de cancelación de revisión envíada",
            7: "Revisión activada correctamente",
            8: "Caso cerrado",
            9: "Se han actualizado casos a urgente"
        }

        this.CasesTypes = {}

        this.CasesTypes.Errors = {
            0: "No se ha encontrado el tipo de caso",
            1: "El caso no cumple con los requerimientos",
            2: "Tipo de caso no actualizado",
            3: "Tipo de caso no encontrado",
            4: "El tipo de caso tiene casos asociados"
        }

        this.CasesTypes.Warnings = {
        }

        this.CasesTypes.Success = {
            0: "Tipo de caso creado",
            1: "Tipo de caso actualizado",
            2: "Tipo de caso eliminado",
        }

        this.FaqsTypes = {}

        this.FaqsTypes.Errors = {
            0: "Este tipo de FAQ tiene FAQs asociados"
        }

        this.FaqsTypes.Warnings = {}

        this.FaqsTypes.Success = {
            0: "Tipo de FAQ actualizado",
            1: "Tipo de FAQ creado",
            2: "Tipo de FAQ eliminado"

        }

        this.Diagnosis = {}

        this.Diagnosis.Errors = {}

        this.Diagnosis.Warnings = {}

        this.Diagnosis.Success = {
            0: "Otro diagnóstico guardado",
            1: "Diagnósticos guardados",
            2: "Otro diagnóstico actualizado",
            3: "Diagnósticos actualizados",
        }

        this.Doubts = {}

        this.Doubts.Errors = {
            0: "Esta duda ya ha sido respondida"
        }

        this.Doubts.Warnings = {}

        this.Doubts.Success = {
            0: "Duda creada",
            1: "Duda guardada",
            2: "Duda eliminada"
        }

        this.Users = {}
        this.Users.Errors = {
            0: "Usuario no actualizado",
            1: "DNI ya usado",
            2: "Email ya usado"
        }
        this.Users.Success = {
            0: "Usuario actualizado",
            1: "Usuario borrado correctamente."
        }
        this.Users.Warnings = {}

        this.UserEditions = {}
        this.UserEditions.Success = {
            0: "Edición asignada al usuario",
            1: "Límite de casos actualizado",
            2: "Edición de usuario eliminada",
        }
        this.UserEditions.Errors = {
            0: "Está edición ya esta asignada",
            1: "El usuario tiene casos",
            3: "El usuario tiene más casos"
        }
        this.Users.Warnings = {}

        this.Editions = {}

        this.Editions.Errors = {
            0: "Esta edición tiene casos asociados",
            1: "Fechas incoherentes",
            2: "Formato de fechas incorrecto",
            3: "Edición no encontrada"
        }

        this.Editions.Warnings = {}

        this.Editions.Success = {
            0: "Edición creada",
            1: "Edición actualizada",
            2: "Edición eliminada",
            3: "Edición archivada",
            4: "Edición desarchivada"
        }

        this.Files = {}
        this.Files.Errors = {
            0: "Formulario desconocido",
            1: "No se puedo actualizar",
            2: "El archivo/s no cumple con los requerimientos",
            3: "Archivo no encontrado",
            4: "Hubo un problema con los adjuntos",
            5: "No se pudo eliminar el archivo",
            6: "Solo se pueden subir 5 archivos por caso",
            7: "No se pudo subir el formulario",
            8: "Solo se pueden subir 5 archivos"
        }

        this.Files.Warnings = {}
        this.Files.Success = {
            0: "Foto actualizada",
            1: "Adjuntos subidos",
            2: "Archivo eliminado",
            8: "Formulario subido"
        }

        this.Faqs = {}
        this.Faqs.Success = {
            0: "FAQ creado",
            1: "FAQ actualizado",
            2: "FAQ eliminado"
        }
        this.Faqs.Warnings = {}
        this.Faqs.Errors = {
            0: "FAQ no creado",
            1: "FAQ no actualizado",
            2: "FAQ no eliminado",
            3: "Este FAQ tiene adjuntos asociados"
        }

        this.StoredResponsesTypes = {}
        this.StoredResponsesTypes.Success = {}
        this.StoredResponsesTypes.Warnings = {}
        this.StoredResponsesTypes.Errors = {
            0: "El tipo de respuesta está siendo usada"
        }

        this.Responses = {}
        this.Responses.Success = {
            0: "Respuesta guardada"
        }

        this.Responses.Errors = {
            0: "La respuesta no se pudo crear",
            1: "Duda ya respondida"
        }

        this.Generics = {}

        this.Generics.Errors = {
            0: "Error interno: contacta con el administrador",
            1: "Rellena los campos obligatorios (*)",
            2: "No puedes realizar esta acción",
            3: "Sesión corrupta",
            4: "Esta acción requiere iniciar sesión",
            5: "Datos inválidos",
            6: "El tamaño máximo de subida es de 16 MB",
            7: "Revisa los campos del formulario",
            8: "Los archivos no cumplen los requisitos",
            9: "Por favor vuelve a iniciar sesión",
            10: "No se pudo guardar",
            11: "No se pudo eliminar",
            12: "No se pudo crear",
            13: "Acutalmente asignado"
        }

        this.Generics.Warnings = {
            0: "Los archivos se guardarán al guardar la duda"
        }

        this.Generics.Success = {
            0: "Notificación enviada",
            1: "Eliminado correctamente",
            2: "Creado correctamente",
            3: "Actualizado correctamente"
        }

        this.Lopd = {}

        this.Lopd.Success = {
            0: "LOPD creado",
            1: "LOPD actualizado",
        }
        this.Lopd.Warnings = {}
        this.Lopd.Errors = {
            0: "FAQ no creado",
            1: "FAQ no actualizado",
        }

        this.Observation = {}

        this.Observation.Success = {
            0: "Observación creada",
            1: "Observación actualizada",
        }
        this.Observation.Warnings = {}
        this.Observation.Errors = {
            0: "Observación no creada",
            1: "Observación no actualizada",
        }
        this.CasesPacks = {}
        this.CasesPacks.Success = {
            0: "Pack de casos creado.",
            1: "Pack de casos modificado.",
            2: "Pack de casos eliminado."
        }
        this.CasesPacks.Warnings = {}
        this.CasesPacks.Errors = {
            0: "Pack de casos no creado.",
            1: "Pack de casos no modificado.",
            2: "Pack de casos no eliminado."
        }
        this.CasesUsersPacks = {}
        this.CasesUsersPacks.Success = {
            0: "Se asignado el pack de casos al usuario.",
            1: "Se ha modificado el pack de casos del usuario."
        }
        this.CasesUsersPacks.Warnings = {}
        this.CasesUsersPacks.Errors = {
            0: "No se ha podido asignar el pack de casos al usuario.",
            1: "No se ha podido modificar el pack de casos del usuario.",
        }

        this.ShowNotification = {}
        this.ShowNotification.Success = {
            0: "Se generado la notificación para el doctor.",
            1: "Se ha modificado la notificación del doctor."
        }
        this.CasesUsersPacks.Warnings = {}
        this.CasesUsersPacks.Errors = {
            0: "No se ha generado la notificación para el doctor.",
            1: "No se ha modificado la notificación del doctor.",
        }
        this.CasesAssessments = {}
        this.CasesAssessments.Success = {
            0: "Se ha registrado su valoración.",
            1: "Se ha modificado su valoración."
        }
        this.CasesAssessments.Warnings = {}
        this.CasesAssessments.Errors = {
            0: "No se ha registrado su valoración.",
            1: "No se ha modificado su valoración.",
        }
        this.CasesPackDuration = {}
        this.CasesPackDuration.Success = {
            0: "Se ha registrado la duración del pack.",
            1: "Se ha modificado el registro.",
            2: "Se ha eliminado la duración del pack."
        }
        this.CasesPackDuration.Warnings = {}
        this.CasesPackDuration.Errors = {
            0: "No se ha registrado la duración del pack.",
            1: "No se ha modificado el registro.",
        }
        this.AudioResponse = {}

        this.AudioResponse.Errors = {
            0: "No se ha podido registrar el audio.",
            1: "No se ha podido eliminar el audio.",
        }

        this.AudioResponse.Warnings = {
        }

        this.AudioResponse.Success = {
            0: "Se ha registrado correctamente el audio.",
            1: "Se ha eliminado correctamente el audio",
        }
    }

    selectMessage = (list, type, code) => {
        switch (type) {
            case "success":
                return list.Success[code]
            case "warning":
                return list.Warnings[code]
            case "error":
                return list.Errors[code]
            default:
                break;
        }
    }

    printToast(target, type, code, duration = 1500) {
        let message = ""
        switch (target) {
            case "RegisterAndLogin":
                message = this.selectMessage(this.RegisterAndLogin, type, code)
                break
            case "Cases":
                message = this.selectMessage(this.Cases, type, code)
                break
            case "CasesTypes":
                message = this.selectMessage(this.CasesTypes, type, code)
                break
            case "FaqsTypes":
                message = this.selectMessage(this.FaqsTypes, type, code)
                break
            case "Diagnosis":
                message = this.selectMessage(this.Diagnosis, type, code)
                break
            case "Doubts":
                message = this.selectMessage(this.Doubts, type, code)
                break
            case "Editions":
                message = this.selectMessage(this.Editions, type, code)
                break
            case "Users":
                message = this.selectMessage(this.Users, type, code)
                break
            case "Files":
                message = this.selectMessage(this.Files, type, code)
                break
            case "Faqs":
                message = this.selectMessage(this.Faqs, type, code)
                break
            case "Responses":
                message = this.selectMessage(this.Responses, type, code)
                break
            case "UserEditions":
                message = this.selectMessage(this.UserEditions, type, code)
                break
            case "storedResponsesTypes":
                message = this.selectMessage(this.StoredResponsesTypes, type, code)
                break
            case "LOPD":
                message = this.selectMessage(this.Lopd, type, code)
                break
            case "Observation":
                message = this.selectMessage(this.Observation, type, code)
                break
            case "CasesPacks":
                message = this.selectMessage(this.CasesPacks, type, code)
                break
            case "CasesUsersPacks":
                message = this.selectMessage(this.CasesUsersPacks, type, code)
                break
            case "ShowNotification":
                message = this.selectMessage(this.ShowNotification, type, code)
                break
            case "CasesAssessments":
                message = this.selectMessage(this.CasesAssessments, type, code)
                break
            case "CasesPackDuration":
                message = this.selectMessage(this.CasesPackDuration, type, code)
                break
            case "AudioResponse":
                message = this.selectMessage(this.AudioResponse, type, code)
                break
            case "Generics":
                message = this.selectMessage(this.Generics, type, code)
                break
            default:
                break;
        }

        toast(message, { type: type, autoClose: duration })
    }
}

export { MessageService }