import { getAllCases } from '../Services/Cases/getAllCases'
import { getUserCases } from '../Services/Cases/getUserCases'
import { postCase } from '../Services/Cases/postCase'
import { putCase } from '../Services/Cases/putCase'
import { putCaseUser } from '../Services/Cases/putCaseUser'
import { selectCase } from '../Services/Cases/selectCase'
import { getCaseData } from '../Services/Cases/getCaseData'
import { patchRevisionRequest } from '../Services/Cases/patchRevisionRequest'
import { patchRevisionCancel } from '../Services/Cases/patchRevisionCancel'
import { patchTeacher } from '../Services/Cases/patchTeacher'
import { patchRevisionCancelRequest } from '../Services/Cases/patchRevisionCancelRequest'
import { pathRevisionActivate } from '../Services/Cases/patchRevisionActivate'
import { patchCaseOnHold } from '../Services/Cases/patchCaseOnHold'
import { patchCaseInProgress } from '../Services/Cases/patchCaseInProgress'
import { notifyAssign } from '../Services/Cases/notifyAssign'
import { deleteCase } from '../Services/Cases/deleteCase'
import { closeCase } from '../Services/Cases/closeCase'
import { GetDaysFromCreation } from '../Components/Tools/GeneralFunctions/GetDaysFromCreation'
import { putCasePriority } from '../Services/Cases/putCasePriority'
import { getTeacherAssignCases } from '../Services/Cases/getTeacherAssignCases'
import { patchReassignCase } from '../Services/Cases/patchReassignCase'
import { getCountCaseOnHold } from '../Services/Cases/getCountCaseOnHold'
import { getCountCaseByUser } from '../Services/Cases/getCountCaseByUser'

const useCase = () => {

    const MAX_DAYS_FOR_URGENT = 7

    const UpdateCasePriority = (data) => {
        data.map(async el => {
            el.created_doubts = GetDaysFromCreation(el.created_doubts)
            if (el.created_doubts > MAX_DAYS_FOR_URGENT && el.id_priorities === null) {
                let res = await putCasePriority(el.id)
                if (res) {
                    el.id_priorities = 0
                }
            }
        })
        return data
    }

    const createCase = async (formData, lopdCheck) => {
        let caseData = {}
        Object.assign(caseData, formData)
        delete caseData.diagnosis
        formData.allow_lopd = lopdCheck
        console.log("🚀 ~ file: useCase.js:47 ~ createCase ~ formData:", formData)
        return await postCase(formData)
    }

    const updateCase = async (formData) => {
        let caseData = {}
        Object.assign(caseData, formData)
        delete caseData.caseCode
        delete caseData.editable
        delete caseData.diagnosis
        return await putCase(caseData)
    }

    const updateCaseUser = async (data) => {
        return await putCaseUser(data)
    }
    const getCase = async (id) => {
        return await selectCase(id)
    }

    const getCaseInfo = async (id) => {
        return await getCaseData(id)
    }

    const requestRevision = async (id, id_students) => {
        console.log("requestRevision", id, id_students)
        return await patchRevisionRequest(id, id_students)
    }

    const reassignCase = async (id, id_teacher) => {
        return await patchReassignCase(id, id_teacher)
    }

    const cancelRevision = async (id, id_students) => {
        return await patchRevisionCancel(id, id_students)
    }

    const cancelRevisionRequest = async (id) => {
        return await patchRevisionCancelRequest(id)
    }

    const retrieveAllCases = async () => {
        return UpdateCasePriority(await getAllCases())
    }

    const retrieveUserCases = async () => {
        return UpdateCasePriority(await getUserCases())
    }

    const retrievesTeacherAssignCases = async () => {
        return UpdateCasePriority(await getTeacherAssignCases())
    }

    const assignTeacher = async (idUser, idCase) => {
        return await patchTeacher(idUser, idCase)
    }

    const activateRevision = async (data) => {
        return await pathRevisionActivate(data)
    }

    const notifyAssignation = async (caseId, teacherId) => {
        const data = { id: caseId, idTeacher: teacherId }
        return await notifyAssign(data)
    }

    const removeCase = async (id) => {
        return await deleteCase(id)
    }

    const finishCase = id => closeCase(id)

    const changeCaseStateToOnHold = id => patchCaseOnHold(id)

    const changeCaseStateInProgress = id => patchCaseInProgress(id)

    const getDataCountCasesOnHold = async (idUser) => await getCountCaseOnHold(idUser)

    const countCasesByUser = async (idUser, idRole) => await getCountCaseByUser(idUser, idRole)

    return {
        createCase,
        updateCase,
        updateCaseUser,
        getCase,
        requestRevision,
        cancelRevision,
        activateRevision,
        retrieveAllCases,
        retrievesTeacherAssignCases,
        retrieveUserCases,
        cancelRevisionRequest,
        notifyAssignation,
        assignTeacher,
        removeCase,
        getCaseInfo,
        finishCase,
        changeCaseStateToOnHold,
        changeCaseStateInProgress,
        reassignCase,
        getDataCountCasesOnHold,
        countCasesByUser
    }
}

export { useCase }