import React from 'react';
import { AudioRecord } from "../Tools/AudioRecorder/AudioRecord"
import { ContentGeneral } from "../Tools/ContentGeneral"
import { HeadTitle } from "../Tools/HeadTitle/HeadTitle"

const TestForm = () => {

    return ([
        <HeadTitle title={"TEST"} />,
        <ContentGeneral content={<AudioRecord />} />
    ])
}

export { TestForm }