import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { useShowView } from "../../Hooks/useShowView";
import { useTeacherStatistics } from "../../Hooks/useTeacherStatistics";
import { TeacherStatisticsScreenUI } from "./TeacherStatisticsScreenUI";
import { toast } from 'react-toastify'
import { CheckTime } from "../Tools/GeneralFunctions/CheckTime";

const TeacherStatisticsScreen = () => {

    const dateNow = moment(new Date()).format("YYYY-MM-DD")
    const { views, kanbanFunction, listFunction } = useShowView({ visibleList: true })
    const { teacherStats, setTeacherStats, filterDateTeacherStats, getAllTeacherStats } = useTeacherStatistics()
    const [sumResponseCases, setSumResponseCases] = useState()
    const [sumResponseDoubts, setSumResponseDoubts] = useState()
    const [sumTimeResponse, setSumTimeCases] = useState()
    const [sumAvarage, setSumAvarage] = useState()
    const [dateIni, setDateIni] = useState()
    const [dateEnd, setDateEnd] = useState(dateNow)


    const doFilter = async () => {
        if (dateIni > dateEnd) {
            toast.warn("La fecha inicial no puede ser más grande que la fecha final")
            resetValue()
        } else if (dateIni || dateIni === '') {
            setTeacherStats(await filterDateTeacherStats(dateIni, dateEnd))
        } else if (dateEnd || dateEnd === '') {
            setTeacherStats(await filterDateTeacherStats(dateIni, dateEnd))
        } else {
            resetValue()
        }
    }

    const resetValue = async () => {
        setTeacherStats(await getAllTeacherStats())
        setDateEnd(dateNow)
        setDateIni('')
    }

    const hanleResponseCases = () => {
        const sum = teacherStats.map(item => item.n_response_cases)
            .reduce((prev, current) => {
                return prev + current;
            }, 0)
        setSumResponseCases(sum)
    }

    const hanleResponseDoubts = () => {
        const sum = teacherStats.map(item => item.n_response_doubts)
            .reduce((prev, current) => {
                return prev + current;
            }, 0)
        setSumResponseDoubts(sum)
    }

    const hanleTimeCases = () => {
        const sum = teacherStats.map(item => CheckTime(item.sum_response_time))
            .reduce((prev, current) => {
                return prev + current;
            }, 0)
        setSumTimeCases(sum)
    }

    const hanleSumAvarage = () => {
        const sum = teacherStats.map(item => CheckTime(item.avarage_response_time))
            .reduce((prev, current) => {
                return prev + current;
            }, 0)
        const total = parseFloat(sum / teacherStats.length).toPrecision(3)
        setSumAvarage(total)
    }

    useEffect(() => {
        document.querySelector('.modal-backdrop')?.remove()
        hanleResponseCases()
        hanleResponseDoubts()
        hanleTimeCases()
        hanleSumAvarage()
    }, [teacherStats])


    return (
        <TeacherStatisticsScreenUI
            views={{
                type: views,
                funcKanban: kanbanFunction,
                funcList: listFunction
            }}
            teacherStats={{
                data: teacherStats,
                funcFilter: doFilter,
                funcResetVal: resetValue
            }}
            totals={{
                response_cases: sumResponseCases,
                response_doubts: sumResponseDoubts,
                time_response: sumTimeResponse,
                avarage_time: sumAvarage
            }}
            dateIni={{
                value: dateIni,
                set: setDateIni
            }}
            dateEnd={{
                value: dateEnd,
                set: setDateEnd
            }}
        />
    )
}

export { TeacherStatisticsScreen }