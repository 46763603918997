import React from 'react';
import invisalign_fondo from '../../../img/invisalign_fondo.avif'

const HeadTitle = ({ title }) => {

    return (
        <div className='d-flex w-100 p-2 contentHeadTitle' style={{backgroundColor:'white',zIndex:-2}}>
            <img className='imgHeadTitle' src={invisalign_fondo} alt={"invisalign_fondo"} />
            <h2 className="pageTitle">{title}</h2>
        </div>
    )
}

export { HeadTitle }